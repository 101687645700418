import React, { useEffect, useState } from 'react'
import "./index.scss";
import Header from '../../../components/Header'
import Post from '../../../components/Post'
import Cardlist from '../../../components/Cardlist'
import PostCard from '../../../components/PostCard';
import ApiContentDetail from '../../../api/ApiContentDetail';
import { ConvertDateShort } from '../../../utils/date';
import CardTaglist from "../../../components/CardTaglist";
import { WEB_IMAGE } from '../../../env';
import { useSelector } from 'react-redux';
import ApiMtTag from '../../../api/ApiTag';
import ApiFollow from '../../../api/ApiFollow';
import MainPost from '../../../components/PostV2';
import CSwl from '../../../utils/alert';
import { Helmet } from "react-helmet";
import ProfileList from "../../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import Menu from '../../../components/Menu';
import { useTranslation } from "react-i18next";
import { destroyPage, useWindowUnloadEffect } from '../../../utils';
import PostLiveSharingDetail from '../../../components/PostLiveSharingDetail';
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const ContentDetailLiveSharing = (props) => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "View Content", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const contentId = new URLSearchParams(props.location.search).get(
    'id'
  );

  const friend = [{
    name: 'Savannah Nguyen'
  },
  {
    name: 'Jacob Jones'
  },
  {
    name: 'Janis Joplin'
  }
  ]
  const dataPost = [{
    name: 'Annette Black',
    time: 'Jan 18',
    title: 'ถ้าไปรษณีย์ไทย อยู่ในตลาดหลักทรัพย์ จะใหญ่แค่ไหน',
    detail: 'แม้ว่าห้างสรรพสินค้า จะเป็นสถานที่ที่คนไทยคุ้นเคยกันมานานหลายสิบปีแต่ ซีบีอาร์อี บริษัทที่ปรึกษาด้าน อสังหาริมทรัพย์ชั้นนำ ได้เห็นถึงความนิยมที่ลดลง และมีการเติบโตเพียงเล็กน้อย โดยเฉพาะในปี 2563 ที่โควิด 19 ส่งผลกระทบโดยตรงต่อธุรกิจค้าปลีก',
    subdetail: 'ซีบีอาร์อี เชื่อว่า นอกจากจะต้องมีการปรับตัวให้เข้ากับอีคอมเมิร์ช และสอดคล้องกับพฤติกรรมของผู้บริโภคที่เปลี่ยนแปลงไป <br><br> ห้างสรรพสินค้าในปี 2564 จะต้องปรับรูปแบบการทำธุรกิจ ในแง่ของการสร้างเสริมประสบการณ์ใหม่ๆให้กับลูกค้าที่เข้ามาใช้บริการ รวมไปถึงการจัดกิจกรรมที่สร้างสรรค์ และบริการเสริม เพื่อรักษาความนิยมจากนักชอปชาวไทย <br><br> นางสาวจริยา ถ้าตรงกิจกุล หัวหน้าแผนกพื้นที่ค้าปลีก ซีบีอาร์อี ประเทศไทย อธิบายว่า"ในขณะที่ห้างสรรพสินค้า มอบความสะดวกสบายให้แก้นักชอปด้วยการประหยัดเวลาเพราะมีการนำเสนอและจัดกลุ่มสินค้าที่มีความหลากหลายไว้ตามแผนกต่างๆ',
    totalLike: 293,
    totalComment: 68
  }]

  const [dataDetail, setDataDetail] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [trend, setTrend] = React.useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [stateContentid, setcontentId] = useState(0);
  const [description, setdescription] = useState('');
  const [imageBanner, setimageBanner] = useState('');
  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getData();
    const promise2 = getToptag();
    const promise3 = getProfileUnFollow();
    Promise.all([promise1, promise2, promise3]).then(results => {
      CSwl.SwalClose();
    });
    return () => { };
  }, []);



  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };

  const getData = async () => {
    try {
      const res = await ApiContentDetail.getLiveSharing(parseInt(contentId));
      if (res.status === 200) {
        setDataDetail(res.data.data)
        var div = document.createElement("div");
        div.innerHTML = res.data.data.ctdeschtml;
        var text = div.textContent || div.innerText || "";
        setdescription(text);
        if (res.data.data.ctdeschtml.includes('<img')) {
          var str = res.data.data.ctdeschtml;
          var elem = document.createElement('div');
          elem.style.display = 'none';
          document.body.appendChild(elem);
          elem.innerHTML = str;
          setimageBanner(elem.querySelector('img').src);
        }
        // console.log(res.data.data.result)
      }
      setLoading(true);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(initialState);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  if (loading === true) {
    return (
      <div className="text-center mt-5">
      </div>
    );
  }

  const callBackComment = (result) => {
    const { data } = result;
    let comments = [{ ...data }, ...dataDetail.comments];
    setDataDetail({
      ...dataDetail,
      comments: comments
    })
    //getData();
  }





  return (
    <div id="contentDetail-page">
      {
        dataDetail.id && <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={`${description}`} />
          <meta property="og:title" content={`${dataDetail.ctname}`} />
          <meta property="og:image" content={`${imageBanner}`} />
          <meta property="og:url" content={`${window.location.href}`} />
          <title>{dataDetail.ctname}</title>
          <link rel="canonical" href={`${window.location.href}`} />
        </Helmet>
      }

      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header='บทความ' />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header='บทความ' />
        </div>
        {/* section post */}
        <div className="column is-7 mt-5">
          <div className="row mb-2">
            <PostLiveSharingDetail
              page={'detail'}
              contentid={dataDetail.id}
              imageComment={userState.profile.domainPath ? process.env.REACT_APP_IMAGE + userState.profile.domainPath : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
              image={dataDetail.imageProfile ? process.env.REACT_APP_IMAGE + dataDetail.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
              name={`${dataDetail.employee?.firstNameEnglish} ${dataDetail.employee?.lastNameEnglish}`}
              time={ConvertDateShort(dataDetail.cttrandate, "DD MMM YYYY HH:mm น.")}
              title={dataDetail.ctname}
              detail={dataDetail.ctdeschtml}
              isLike={dataDetail.isLike}
              item={dataDetail}
              isComment={userState.profile.id == dataDetail.profileid ? true : true}
              comments={dataDetail.comments ?? []}
              // subdetail={d.ctdeschtml}
              totalLike={dataDetail.ctlikeAmt}
              totalComment={dataDetail.comments?.length}
              isCommentFirst={false}
              onComment={(result) => callBackComment(result)}
            />
          </div>
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden-b"
        >
          <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContentDetailLiveSharing

