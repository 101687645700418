import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiComment extends Component {
  static create = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranComment",
      method: "post",
      data: data,
    });
    return result;
  };
  static update = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranComment",
      method: "put",
      data: data,
    });
    return result;
  };
  static delete = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranComment/${id}`,
      method: "delete",
    });
    return result;
  };
  static Like = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranComment/Like/${id}`,
      method: "get",
    });
    return result;
  };

}

export default ApiComment;
