import addNotification from "react-push-notification";

export const notificationOpen = ({ title, subtitle, message }) => {
    addNotification({
        title: title,
        subtitle: subtitle,
        message: message,
        theme: "darkblue",
        native: true, // when using native, your OS will handle theming.
    });
};
