import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiContentDetail extends Component {
    static get = async (contentId) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranContent/GetContentById/${contentId}`,
            method: "get",
        });
        return result;
    };
    static getLiveSharing = async (contentId) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranContent/GetContentLiveSharingById/${contentId}`,
            method: "get",
        });
        return result;
    };
    static getPreview = async (contentId) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranContent/GetContentByIdPreview/${contentId}`,
            method: "get",
        });
        return result;
    };
}

export default ApiContentDetail;