import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiCategory extends Component {
  static get = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtCategory/GetList`,
      method: "get",
    });
    return result;
  };

  static MenuGetList = async (limit) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtCategory/MenuGetList?limit=${limit}`,
      method: "get",
    });
    return result;
  };
}

export default ApiCategory;
