import axios from 'axios';
import { WEB_API } from '../env';
import { useSelector, useDispatch } from 'react-redux';
import configureStore from './../store';
import { useEffect } from 'react';
const { store, persistor } = configureStore();
axios.defaults.baseURL = process.env.REACT_APP_API;
const SetupInterceptors = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.isLogged);
  const callBackUrl = useSelector((state) => state.callBackUrl);
  useEffect(() => {
    if ((isLogged === "true") && (window.location.pathname != "/")) {
      setTimeout(() => {
        window.location.href = "/"
      }, 1000);
    }
  }, [isLogged]);
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {

        if (error.response.status === 401) {
          dispatch({ type: 'clear_all', callBackUrl: window.location.pathname + window.location.search, isLogged: "true" });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

      return Promise.reject(error);
    }
  );

}

export default SetupInterceptors;
