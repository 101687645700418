import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import "../profilePage/index.scss";
import Cardlist from "../../components/Cardlist";
import Post from "../../components/Post";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiAuth from "../../api/ApiAuth";
import CSwl from "../../utils/alert";
import ApiContent from "../../api/ApiContent";
import { ConvertDateShort } from "../../utils/date";
import Swal from "sweetalert2";
import { CheckFile, destroyPage, useWindowUnloadEffect } from "../../utils";
import { WEB_IMAGE } from "../../env";
import ApiFollow from "../../api/ApiFollow";
import ApiTranProfile from "../../api/ApiTranProfile";
import ApiMtTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import { TagsInput as ReactTags } from "react-tag-input-component";
import MainPost from "../../components/PostV2";
import ProfileList from "../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import PostLiveSharing from "../../components/PostLiveSharing";
const pageState = {
  pageNumber: 1,
  pageSize: 10,
};

const ProfilePage = () => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const [tags, setTags] = useState([]);
  const [speciallistTags, setSpeciallistTags] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [myContent, setmyContent] = useState("");
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [isAddtag, setIsAddtag] = useState(false);
  const [showCer, setShowCer] = useState(false)
  const [showProject, setShowProject] = useState(false);
  const listTypeImage = [".jpeg", ".jpg", ".png"]
  const [certificate, setcertificate] = useState([]);
  const [projectExp, setprojectExp] = useState([]);
  const [sasToken, setsasToken] = useState('');


  React.useEffect(() => {
    CSwl.SwalLoad();
    const promise0 = getBlobContainerSAS();
    const promise1 = getProfile();
    const promise2 = getContent();
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();
    const promise5 = getCertificate();
    const promise6 = getProjectExp();
    Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6]).then((result) => {
      CSwl.SwalClose();
    })
    return () => { };
  }, []);

  const getProfile = async () => {
    CSwl.SwalLoad();
    try {
      const response = await ApiAuth.getUser();
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        dispatch({ type: "set_user", user: data });

        if (data.profile.tagJson != null) {
          var tagP = JSON.parse(data.profile.tagJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push("#" + tagP[i].Name);
          }
          setTags(tmpTag);
        }

        if (data.profile.specialistJson != null) {
          var tagP = JSON.parse(data.profile.specialistJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push(tagP[i].name);
          }
          setSpeciallistTags(tmpTag);
        }
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getContent = async () => {
    CSwl.SwalLoad();
    try {
      const response = await ApiContent.getByUser(pageState);
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        console.log(data);
        setmyContent(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getCertificate = async () => {
    try {
      const response = await ApiContent.GetCertificate();
      if (response.status == 200) {
        const { data } = response.data;
        setcertificate(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getProjectExp = async () => {
    try {
      const response = await ApiContent.GetProjectExp();
      if (response.status == 200) {
        const { data } = response.data;
        console.log('projectExp::', data)
        setprojectExp(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getBlobContainerSAS = async () => {
    try {
      const response = await ApiContent.GetBlobContainerSAS();
      if (response.status == 200) {
        const { sasToken } = response.data;
        setsasToken(sasToken)
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };


  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputCover = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleClickCover = (event) => {
    hiddenFileInputCover.current.click();
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (
        CheckFile({
          file,
          size: 2,
          type: ["image/png", "image/jpg", "image/jpeg"],
        })
      ) {
        uploadFiles(file, file.name);
      }
    } catch (error) { }
  };

  const uploadFiles = async (uploadFileObj, filename) => {
    CSwl.SwalLoad();
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiAuth.upload(formData);
    if (result.status == 200) {
      const { data } = result.data;
      getProfile();
    }
    Swal.close();
  };

  const handleFileChangeCover = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (
        CheckFile({
          file,
          size: 2,
          type: ["image/png", "image/jpg", "image/jpeg"],
        })
      ) {
        uploadFilesCover(file, file.name);
      }
    } catch (error) { }
  };

  const uploadFilesCover = async (uploadFileObj, filename) => {
    CSwl.SwalLoad();
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiAuth.uploadcover(formData);
    if (result.status == 200) {
      const { data } = result.data;
      getProfile();
    }
    Swal.close();
  };

  const callBackComment = (result) => {
    try {
      const comment = result.data;
      // const { data } = result;
      let newContents = { ...myContent };
      let newContentsItem = newContents.items;

      const findIndex = newContentsItem.findIndex((x) => x.id == comment.ctid);
      newContentsItem[findIndex].comments.unshift(comment);
      newContents.items = newContentsItem;
      setmyContent(newContents);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const func_setAddingTag = () => {
    if (isAddtag) {
      var tagModel = [];
      for (var i = 0; i < tags.length; i++) {
        var tagsub = {
          name: tags[i],
        };
        tagModel.push(tagsub);
      }
      func_saveTag(tagModel);
    } else {
      setIsAddtag(true);
    }
  };

  const handleTagAddition = (tag) => {
    if (tag.length > 0) {
      var tagName = tag[tag.length - 1];
      tag[tag.length - 1] = "#" + tagName.replace("#", "");
    }

    setTags(tag);
  };

  const func_saveTag = async (tagmodel) => {
    var model = {
      id: userState.profile.id,
      tagjontran: tagmodel,
    };
    try {
      const result = await ApiTranProfile.UpdateTag(model);
      if (result.status == 200) {
        const { data } = result.data;
        setIsAddtag(false);
        if (data.tagJson != null) {
          var tagP = JSON.parse(data.tagJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push("#" + tagP[i].Name);
          }
          setTags(tmpTag);
        }
      }
    } catch (ex) {
      CSwl.SwalErr("Can Not Update Tag");
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageSize: 3,
      pageNumber: 1,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };


  const checkStatus = (status, reason) => {
    switch (status) {
      case "W":
        return "รออนุมัติ";
      case "A":
        return "อนุมัติเรียบร้อย";
      case "R":
        return `ไม่อนุมัติ (${reason})`;
      default:
        return "";
    }
  }

  return (
    <div id="ProfilePage">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <Header />

      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('menu.profile')} />
        </div>
        <div className="column is-7">
          <div className="">
            <figure    onClick={() => handleClickCover()} className="image cursor-pointer">
              {/* <img
                src={process.env.PUBLIC_URL + "/images/profilePage/BG.png"}
                alt="backgroundImage"
              /> */}
              <img
                className="image-cover"
                style={{ maxHeight: 300 }}
                src={
                  userState.profile.coverDomainPath
                    ? process.env.REACT_APP_IMAGE +
                    userState.profile.coverDomainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/BG.png"
                }
                alt="backgroundImage"
              />
            </figure>
          </div>
          <div className="layer1 section-content flex gap-x-[50px] items-center px-10 py-4">
            <figure
              onClick={() => handleClick()}
              className="image cursor-pointer"
            >
              <img
                src={
                  userState.profile.domainPath
                    ? process.env.REACT_APP_IMAGE + userState.profile.domainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
                }
                alt="profile_image"
                //class="is-rounded img-card image-cover"
                className={`is-rounded img-card image-cover ${userState.ranking?.rankingName == "SMEs" ? `border-[#f97316] border-[5px]` : ""}`}
              />
            </figure>
            <div className="is-flex is-flex-direction-column layer2 flex-1 !ml-0">
              <div className="flex gap-x-2 items-center">
                <p className="title-h">{`${userState.employee?.firstNameEnglish} ${userState.employee?.lastNameEnglish}`}</p>

                <div style={{ paddingRight: "10px" }}>
                  {
                    userState.ranking?.rankingName == "SMEs" ?
                      <Link
                        to="/ranking">
                        <div className="bg-[#f97316] px-2 left-7">
                          <span className="text-white text-sm">SMEs</span>
                        </div>
                      </Link> :
                      <Link
                        to="/ranking">
                        <div className="bg-[dodgerblue] px-2 left-7">
                          <span className="text-white text-sm">{userState.ranking?.rankingName}</span>
                        </div>
                      </Link>
                  }
                </div>
              </div>
              <p className="subtitle-h pb-3">
                {userState.employee?.positionNameEnglish} /{" "}
                {userState.employee?.companyDescription}
              </p>
            </div>
          </div>
          <div className="is-flex section-content is-justify-content-space-around py-3">
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.followerAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-ss-following1.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('menu.follower')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.followAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-rr-users2.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('text.following')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.articleAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-ss-document1.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('text.content')}</p>
              </div>
            </div>
          </div>
          <div className="is-flex is-flex-direction-column section-content p-5">
            <div className="is-flex is-align-items-center mb-3">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge1.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 pl-3">{t('text.expertise')}</p>
            </div>
            <div className="is-flex is-flex-wrap-wrap pb-2">
              {speciallistTags.map((i, index) => {
                return (
                  <div
                    onClick={() => {
                      history.push(`/catalog?q=${i}`);
                    }}
                    className="mr-2 mb-2"
                    key={index}
                  >
                    <button className="button is-info is-rounded has-text-grey has-background-info-light">
                      {i}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="is-flex is-flex-direction-column section-content p-5">
            <div className="is-flex is-align-items-center mb-3">
              <figure className="image is-24x24">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/vector.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 pl-3">{t('menu.attention')}</p>
            </div>
            <div className="is-flex is-flex-wrap-wrap pb-2">
              {!isAddtag ? (
                tags.map((i, index) => {
                  return (
                    <div
                      onClick={() => {
                        history.push(`/tag?q=${i.replace("#", "")}`);
                      }}
                      className="mr-2 mb-2"
                      key={index}
                    >
                      <button className="button is-info is-rounded has-text-grey has-background-info-light">
                        {i}
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="mt-3" style={{ width: "100%" }}>
                  <ReactTags
                    className="form-control form-control-md mt-3"
                    value={tags}
                    name="tags"
                    onChange={(tag) => handleTagAddition(tag)}
                    placeholder="พิมพ์ tag แล้วกด Enter"
                  />
                </div>
              )}
            </div>
            <div className="is-flex is-align-items-center mb-3">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/vector1.png"
                  }
                  alt="logo1"
                />
              </figure>
              {!isAddtag ? (
                <p
                  className="subtitle-h1 pl-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    func_setAddingTag();
                  }}
                >
                  {t('text.edit_attention')}
                </p>
              ) : (
                <p
                  className="subtitle-h1 pl-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    func_setAddingTag();
                  }}
                >
                  บันทึก
                </p>
              )}
            </div>
          </div>
          <Link
            to="/ranking"
            className="is-flex section-content is-justify-content-space-between p-5"
          >
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge1.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 px-2">{t('text.experience')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <p className="subtitle-h2 px-2">
                {userState.profile?.rankingAmt}
              </p>
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/vector2.png"
                  }
                  alt="logo1"
                />
              </figure>
            </div>
          </Link>
          <Link
            to="/redeem"
            className="is-flex section-content is-justify-content-space-between p-5"
          >
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge2.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 px-2">{t('text.point')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <p className="subtitle-h2 px-2">{userState.profile?.pointAmt}</p>
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/vector2.png"
                  }
                  alt="logo1"
                />
              </figure>
            </div>
          </Link>
          <div className="section-content p-5">
            <div className="is-flex is-justify-content-space-between">
              <div className="is-flex is-align-items-center">
                <figure className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/certificate.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="title-h1 px-2">{t('text.certificate')} ({certificate.length})</p>
              </div>
              <div className="is-flex  is-align-items-center cursor-pointer">
                <div className="is-flex is-align-items-center gap-x-4">
                  <Link to={`/certificateEdit/0`}>
                    <figure className="image mr-2">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/profilePage/add.png"
                        }
                        alt="logo1"
                      />
                    </figure>
                  </Link>
                  <figure className="image" onClick={() => setShowCer(!showCer)}>
                    <img
                      src={
                        process.env.PUBLIC_URL + `/images/profilePage/${!showCer ? "vector3.png" : "vector4.png"}`
                      }
                      alt="logo1"
                    />
                  </figure>

                </div>
              </div>
            </div>
            {
              certificate.length > 0 && showCer &&
              <div className="pt-5 sm:pl-5">
                {
                  certificate.map((item, index) => {
                    if (index < 3) {
                      const files = item.files ? JSON.parse(item.files) : "";
                      let fileImage = "";
                      if (files) {
                        fileImage = files.find((x) => {
                          return listTypeImage.some(v => x.Path.includes(v))
                        })
                      }

                      return (
                        <div className="is-flex  is-justify-content-space-between py-3 cer-line" key={item.id}>
                          <div className="is-flex">
                            <div className="img-cer">
                              {
                                fileImage ?
                                  <img
                                    src={
                                      fileImage.Path + sasToken
                                    }
                                    alt="cer"
                                  /> :
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                    }
                                    alt="cer"
                                  />
                              }
                            </div>
                            <div className="pl-3">
                              <Link to={`/certificateDetail/${item.id}`}>
                                <div className="flex gap-x-1">
                                  <p className="subtitle-h2">{t('text.name')}:</p>
                                  <p className="subtitle-h2">{item.name}</p>
                                </div>

                              </Link>
                              <div className="flex gap-x-1">
                                <p className="subtitle-h3">{t('text.institutionDetail')}:</p>
                                <p className="subtitle-h3">{item.instituteName}</p>
                              </div>
                              <p className="cer-date">{item.issuedDate ? `${t('text.dateofissue')} ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `${t('text.expirationdate')} ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                              <div className="flex gap-x-2">
                                <p className="cer-date">สถานะ:</p>
                                <p className={`cer-date ${item.status == "A" && "!text-green-600"} ${item.status == "R" && "!text-red-500"}`}>{checkStatus(item.status, item.reason)}</p>
                              </div>
                            </div>
                          </div>
                          <Link to={`/certificateEdit/${item.id}`}>
                            <figure className="image-cover cursor-pointer">
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                }
                                alt="logo1"
                              />
                            </figure>
                          </Link>
                        </div>
                      )
                    } else {
                      return null;
                    }
                  })
                }
                <div className="is-flex  is-justify-content-center is-align-items-center pt-5">
                  <Link to="/certificate">
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <p className="subtitle-h2">แสดงทั้งหมด</p>
                      <figure className="image px-3">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/vector2.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                </div>
              </div>
            }
          </div>
          <div className="section-content p-5">
            <div className="is-flex is-justify-content-space-between">
              <div className="is-flex is-align-items-center">
                <figure className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/history_project.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="title-h1 px-2">{t('text.project_history')} ({projectExp.length})</p>
              </div>
              <div className="is-flex is-align-items-center cursor-pointer">

                <div className="is-flex  is-align-items-center cursor-pointer">
                  <div className="is-flex is-align-items-center gap-x-4">
                    <Link to={`/pastProjectEdit/0`}>
                      <figure className="image mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/add.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </Link>
                    <figure className="image" onClick={() => setShowProject(!showProject)}>
                      <img
                        src={
                          process.env.PUBLIC_URL + `/images/profilePage/${!showProject ? "vector3.png" : "vector4.png"}`
                        }
                        alt="logo1"
                      />
                    </figure>

                  </div>
                </div>
              </div>
            </div>
            {
              projectExp.length > 0 && showProject &&
              <div className="pt-5 sm:pl-5">
                {
                  projectExp.map((item, index) => {
                    if (index < 3) {
                      const files = item.files ? JSON.parse(item.files) : "";
                      let fileImage = "";
                      if (files) {
                        const fileImage = files.find((x) => {
                          return listTypeImage.some(v => x.Path.includes(v))
                        })
                      }
                      return (
                        <div className="is-flex  is-justify-content-space-between py-3 cer-line" key={item.id}>
                          <div className="is-flex">
                            <div className="img-cer">
                              {
                                fileImage ?
                                  <img
                                    src={
                                      fileImage.Path + sasToken
                                    }
                                    alt="cer"
                                  /> :
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                    }
                                    alt="cer"
                                  />
                              }
                            </div>

                            <div className="pl-3">
                              <Link to={`/pastProjectDetail/${item.id}`}>
                                <div className="flex gap-x-1">
                                  <p className="subtitle-h2">{t('text.name')}:</p>
                                  <p className="subtitle-h2">{item.name}</p>
                                </div>

                              </Link>
                              <div className="flex gap-x-1">
                                <p className="subtitle-h3">{t('text.projectroles_short')}:</p>
                                <p className="subtitle-h3">{item.role}</p>
                              </div>
                              <p className="cer-date">{item.startDate ? `${t('text.projectstartdate')} ${ConvertDateShort(item.startDate, 'DD MMM YYYY')}` : "-"} • {item.endDate ? `${t('text.projectenddate')} ${ConvertDateShort(item.endDate, 'DD MMM YYYY')}` : "-"}</p>
                              <div className="flex gap-x-2">
                                <p className="cer-date">{t('text.status')}:</p>
                                <p className={`cer-date ${item.status == "A" && "!text-green-600"} ${item.status == "R" && "!text-red-500"}`}>{checkStatus(item.status, item.reason)}</p>
                              </div>
                            </div>
                          </div>
                          <Link to={`/pastProjectEdit/${item.id}`}>
                            <figure className="image-cover cursor-pointer">
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                }
                                alt="logo1"
                              />
                            </figure>
                          </Link>
                        </div>
                      )
                    } else {
                      return null;
                    }
                  })
                }
                <div className="is-flex  is-justify-content-center is-align-items-center py-3">
                  <Link to="/pastProject">
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <p className="subtitle-h2">แสดงทั้งหมด</p>
                      <figure className="image px-3">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/vector2.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                </div>
              </div>
            }
          </div>
          {/* <div className="is-flex section-content is-justify-content-space-between p-5">
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/history_project.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 px-2">{t('text.project_history')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/add.png"
                  }
                  alt="logo1"
                />
              </figure>
            </div>
          </div> */}
          <div className="is-flex is-flex-direction-column section-content">
            {/* <div className="is-flex p-5 is-flex-wrap-wrap section-content1">
              <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">
                ทั้งหมด
              </button>
              <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">
                บทความยอดนิยม
              </button>
              <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">
                บทควาทปักหมุด
              </button>
              <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">
                วิดีโอ
              </button>
            </div> */}
            <div>
              {/* {dataPost.map((d, index) => {
                return (
                  <div className="mb-2" key={index}>
                    <Post name={d.name} time={d.time} title={d.title} detail={d.detail} totalLike={d.totalLike} totalComment={d.totalComment} />
                  </div>
                )
              })} */}
              {myContent &&
                myContent.items.map((d, index) => {
                  return (
                    // <div className="row mb-2" key={index}>
                    //   <Post
                    //     contentid={d.id}
                    //     imageComment={
                    //       userState.profile.domainPath
                    //         ? process.env.REACT_APP_IMAGE + userState.profile.domainPath
                    //         : process.env.PUBLIC_URL +
                    //         "/images/profilePage/Profile.png"
                    //     }
                    //     image={
                    //       d.imageProfile
                    //         ? process.env.REACT_APP_IMAGE + d.imageProfile
                    //         : process.env.PUBLIC_URL +
                    //         "/images/profilePage/Profile.png"
                    //     }
                    //     name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                    //     time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                    //     title={d.ctname}
                    //     detail={d.ctdeschtml}
                    //     isLike={d.isLike}
                    //     isComment={
                    //       userState.profile.id == d.profileid ? true : true
                    //     }
                    //     comments={d.comments}
                    //     // subdetail={d.ctdeschtml}
                    //     totalLike={d.ctlikeAmt}
                    //     totalComment={d.ctcommentAmt}
                    //     contentStyles={{ maxHeight: 440, overflow: "hidden" }}
                    //     onComment={(result) => callBackComment(result)}
                    //   />
                    // </div>
                    <div
                      className="row fn ar"
                      key={d.id}
                    >
                      {d.cttype == "CONTENT" ? <MainPost
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                        : <PostLiveSharing
                          stateOrg={[]}
                          time={ConvertDateShort(d.ctstartdate, "DD MMM YYYY HH:mm น.")}
                          totalLike={d.ctlikeAmt}
                          isLike={d.isLike}
                          isBookmark={d.isBookmark}
                          item={d}
                          title={d.ctname + " (LIVE SHARING)"}
                          comments={d.comments}
                          detail={d.ctdeschtml}
                          name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                          image={
                            d.imageProfile
                              ? process.env.REACT_APP_IMAGE + d.imageProfile
                              : process.env.PUBLIC_URL +
                              "/images/profilePage/Profile.png"
                          }
                        />
                      }
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div
            className="is-3 flex-1"
            style={{ position: "fixed", width: "inherit" }}
          >
            <div className="mt-3 mr-6">
              <CardTaglist
                title={t('text.trending')}
                data={trend}
                topage={"trend"}
              />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        </div>
      </div>
      <input
        accept=".png,.jpg,.jpeg"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        type="file"
        style={{ display: "none" }}
      />

      <input
        accept=".png,.jpg,.jpeg"
        ref={hiddenFileInputCover}
        onChange={handleFileChangeCover}
        type="file"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ProfilePage;
