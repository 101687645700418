import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Unfollow from "../../components/Unfollow";
import Cardlist from "../../components/Cardlist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import { ConvertDateShort } from "../../utils/date";
import ApiMtTag from "../../api/ApiTag";
import { WEB_IMAGE } from "../../env";
import CardTaglist from "../../components/CardTaglist";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const UnFollow = () => {
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const friend = [
    {
      name: "Savannah Nguyen",
    },
    {
      name: "Jacob Jones",
    },
    {
      name: "Janis Joplin",
    },
  ];
  const dataPro = [
    {
      name: "Sab Khasa...",
    },
    {
      name: "Martha Cr...",
    },
    {
      name: "Tibitha P...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
  ];

  const [state, setstate] = useState(initialState);
  const [profileUnFollow, setProfileUnFollow] = useState([]);

  useEffect(() => {
    getProfileUnFollow();
    getToptag();
    return () => { };
  }, []);

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const func_unfollow = (index) => {
    var data = [...profileUnFollow];
    data.splice(index, 1);
    setProfileUnFollow(data);
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };

  return (
    <div id="unfollow-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={dataPro} page="rank" header={t("text.following")} />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={dataPro} page="rank" header={t("text.following")} />
        </div>
        {/* section post */}
        <div className="column is-7">
          <div className="is-flex is-justify-content-space-between px-4 mt-2">
            <div
              to="/"
              className="has-text mr-6 is-flex is-align-items-center"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/firstPage/fi-rr-portrait1.png"
                }
                alt="logo1"
                width="24"
                height="24"
              />
              <strong className="m-3">{t('text.who_follow')}</strong>
            </div>
            {/* <Link
              to="/"
              className="button is-light is-outlined "
              style={{ color: "black", fontWeight: "bold" }}
            >
              ดูทั้งหมด
            </Link> */}
          </div>
          <hr />
          {profileUnFollow &&
            profileUnFollow.map((d, index) => {
              return (
                <div className="row mb-2" key={index}>
                  <Unfollow
                    image={
                      d.image
                        ? process.env.REACT_APP_IMAGE + d.image
                        : process.env.PUBLIC_URL +
                        "/images/profilePage/Profile.png"
                    }
                    index={index}
                    profileid={d.profileID}
                    name={d.fullNameEng}
                    depart={d.deptThai}
                    level={d.rankEng}
                    smeCategory={d.smeCategory}
                    totalArticle={d.countContent}
                    totalFollow={d.countFollow}
                    onunfollow={(index) => {
                      func_unfollow(index);
                    }}
                  />
                </div>
              );
            })}
        </div>
        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div class="mt-3 mr-6">
            <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnFollow;
