import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../utils/alert";
import { CertificateStatus, destroyPage, useWindowUnloadEffect } from "../../utils";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import ProfileList from "../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import ApiContent from "../../api/ApiContent";
import { ConvertDateShort } from "../../utils/date";
import Drawer from "rc-drawer";
import MenuDrawer from "../../components/MenuDrawer";

const Certificate = () => {
    const [firstTime, setFirstTime] = useState(new Date());
    useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
    const { t, i18n } = useTranslation();
    const [trend, setTrend] = useState([]);
    const userState = useSelector((state) => state.user);
    const [profileUnFollow, setProfileUnFollow] = useState([]);
    const [showApprove, setShowApprove] = useState(false)
    const [showNoApprove, setShowNoApprove] = useState(false)
    const [showDraft, setShowDraft] = useState(false)
    const [countApprove, setCountApprove] = useState(0)
    const [countNoApprove, setCountNoApprove] = useState(0)
    const [countDraft, setCountDraft] = useState(0)
    const [certificate, setcertificate] = useState([]);
    const listTypeImage = [".jpeg", ".jpg", ".png"]
    const [sasToken, setsasToken] = useState('');

    React.useEffect(() => {
        console.log("user::", userState);
        CSwl.SwalLoad();
        const promise1 = getProfileUnFollow();
        const promise2 = getToptag();
        const promise3 = getCertificate();
        const promise4 = getBlobContainerSAS();
        Promise.all([promise1, promise2, promise3, promise4]).then((result) => {
            CSwl.SwalClose();
        })
        getCountStatus()
        return () => { };
    }, []);

    const getBlobContainerSAS = async () => {
        try {
            const response = await ApiContent.GetBlobContainerSAS();
            if (response.status == 200) {
                const { sasToken } = response.data;
                setsasToken(sasToken)
            }
        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message);
        }
    };

    const getProfileUnFollow = async () => {
        const result = await ApiFollow.GetTranProfileForUnFollow({
            pageNumber: 1,
            pageSize: 10,
        });
        if (result.status == 200) {
            setProfileUnFollow(result.data.data);
        }
    };

    const getToptag = async () => {
        const result = await ApiMtTag.GetTopTag({
            pageSize: 3,
            pageNumber: 1,
        });
        if (result.status == 200) {
            if (result.data.data.length > 0) {
                setTrend(result.data.data);
                console.log(result.data.data);
            }
        }
    };

    const getCertificate = async () => {
        try {
            const response = await ApiContent.GetCertificate();
            if (response.status == 200) {
                const { data } = response.data;
                console.log(data);
                setcertificate(data);
            }
        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message);
        }
    };


    const getCountStatus = () => {
        var countApprove = 0
        var countNoApprove = 0
        var countDraft = 0
        certificate.forEach((item) => {
            if (item.status === "A") {
                countApprove += 1
            } else if (item.status === "W") {
                countNoApprove += 1
            } else if (item.status === "R") {
                countDraft += 1
            }
        })
        setCountApprove(countApprove)
        setCountNoApprove(countNoApprove)
        setCountDraft(countDraft)
    }

    return (
        <div id="CertificatePage">
            <Drawer className="is-hidden-desktop is-hidden-tablet">
                <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
            </Drawer>
            <Header />

            <div className="columns">
                <div className="hidden sm:block column is-2 my-3 layer hidden-b">
                    <Menu page="rank" header={t('text.certificate')} />
                </div>
                <div className="column is-7 pt-0">
                    <div className="is-flex is-flex-direction-column section-content px-5 py-4 sm:py-5 sm:pt-6">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center">
                                <figure className="image">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/profilePage/list_certificate.png"
                                        }
                                        alt="logo1"
                                    />
                                </figure>
                                <p className="title-h1 px-2">{t('menu.list_certificate')} ({certificate.length})</p>
                            </div>
                            <Link to={`/certificateEdit/0`}>
                                <div className="is-flex is-align-items-center cursor-pointer">
                                    <figure className="image h-6 w-6 sm:h-8 sm:w-8">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/images/profilePage/addx2.png"
                                            }
                                            alt="logo1"
                                        />
                                    </figure>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.approve')} ({certificate.filter(x => x.status == "A").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showApprove ?
                                        <figure className="image" onClick={() => setShowApprove(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowApprove(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        <div className="sm:pl-10">
                            {
                                certificate.length > 0 &&
                                showApprove &&
                                certificate.map((item, index) => {
                                    if (item.status === "A") {
                                        const files = item.files ? JSON.parse(item.files) : "";
                                        let fileImage = "";
                                        if (files) {
                                            fileImage = files.find((x) => {
                                                return listTypeImage.some(v => x.Path.includes(v))
                                            })
                                        }
                                        return (
                                            <div className={`is-flex is-justify-content-space-between py-3 ${index < certificate.length - 1 && "cer-line"}`} key={item.id}>
                                                <div className="is-flex gap-x-3">
                                                    <div className="img-cer !h-full !object-cover">
                                                        {
                                                            fileImage ?
                                                                <img
                                                                    src={
                                                                        fileImage.Path + sasToken
                                                                    }
                                                                    alt="cer"
                                                                /> :
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                    }
                                                                    alt="cer"
                                                                />
                                                        }
                                                    </div>
                                                    <div className="pl-3">
                                                        <Link to={`/certificateDetail/${item.id}`}>
                                                            <div className="flex gap-x-1">
                                                                <p className="subtitle-h2">ชื่อ:</p>
                                                                <p className="subtitle-h2">{item.name}</p>
                                                            </div>

                                                        </Link>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h3">ออกโดย:</p>
                                                            <p className="subtitle-h3">{item.instituteName}</p>
                                                        </div>
                                                        <p className="cer-date">{item.issuedDate ? `วันที่ออก ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `วันที่หมดอายุ ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                                                        {/* <div className="flex gap-x-2">
                                                            <p className="cer-date">สถานะ:</p>
                                                            <p className={`cer-date ${item.status == "A" && "!text-green-600"} ${item.status == "R" && "!text-red-500"}`}>{CertificateStatus(item.status, item.reason)}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <Link to={`/certificateEdit/${item.id}`}>
                                                    <figure className="image-cover cursor-pointer">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                            }
                                                            alt="logo1"
                                                        />
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.waitingforapproval')} ({certificate.filter(x => x.status == "W").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showNoApprove ?
                                        <figure className="image" onClick={() => setShowNoApprove(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowNoApprove(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        <div className="sm:pl-10">
                            {
                                certificate.length > 0 &&
                                showNoApprove &&
                                certificate.map((item, index) => {
                                    if (item.status === "W") {
                                        const files = item.files ? JSON.parse(item.files) : "";
                                        let fileImage = "";
                                        if (files) {
                                            fileImage = files.find((x) => {
                                                return listTypeImage.some(v => x.Path.includes(v))
                                            })
                                        }

                                        return (
                                            <div className={`is-flex is-justify-content-space-between py-3 ${index < certificate.length - 1 && "cer-line"}`} key={item.id}>
                                                <div className="is-flex gap-x-3">
                                                    <div className="img-cer !h-full !object-cover">
                                                        {
                                                            fileImage ?
                                                                <img
                                                                    src={
                                                                        fileImage.Path + sasToken
                                                                    }
                                                                    alt="cer"
                                                                /> :
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                    }
                                                                    alt="cer"
                                                                />
                                                        }
                                                    </div>
                                                    <div className="pl-3">
                                                        <Link to={`/certificateDetail/${item.id}`}>
                                                            <div className="flex gap-x-1">
                                                                <p className="subtitle-h2">ชื่อ:</p>
                                                                <p className="subtitle-h2">{item.name}</p>
                                                            </div>

                                                        </Link>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h3">ออกโดย:</p>
                                                            <p className="subtitle-h3">{item.instituteName}</p>
                                                        </div>
                                                        <p className="cer-date">{item.issuedDate ? `วันที่ออก ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `วันที่หมดอายุ ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                                                        {/* <div className="flex gap-x-2">
                                                            <p className="cer-date">สถานะ:</p>
                                                            <p className={`cer-date ${item.status == "A" && "!text-green-600"} ${item.status == "R" && "!text-red-500"}`}>{CertificateStatus(item.status, item.reason)}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <Link to={`/certificateEdit/${item.id}`}>
                                                    <figure className="image-cover cursor-pointer">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                            }
                                                            alt="logo1"
                                                        />
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.reject')} ({certificate.filter(x => x.status == "R").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showDraft ?
                                        <figure className="image" onClick={() => setShowDraft(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowDraft(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        <div className="sm:pl-10">
                            {
                                certificate.length > 0 &&
                                showDraft &&
                                certificate.map((item, index) => {
                                    if (item.status === "R") {
                                        const files = item.files ? JSON.parse(item.files) : "";
                                        let fileImage = "";
                                        if (files) {
                                            fileImage = files.find((x) => {
                                                return listTypeImage.some(v => x.Path.includes(v))
                                            })
                                        }
                                        return (
                                            <div className={`is-flex is-justify-content-space-between py-3 ${index < certificate.length - 1 && "cer-line"}`} key={item.id}>
                                                <div className="is-flex gap-x-3">
                                                    <div className="img-cer !h-full !object-cover">
                                                        {
                                                            fileImage ?
                                                                <img
                                                                    src={
                                                                        fileImage.Path + sasToken
                                                                    }
                                                                    alt="cer"
                                                                /> :
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                    }
                                                                    alt="cer"
                                                                />
                                                        }
                                                    </div>
                                                    <div className="pl-3">
                                                        <Link to={`/certificateDetail/${item.id}`}>
                                                            <div className="flex gap-x-1">
                                                                <p className="subtitle-h2">ชื่อ:</p>
                                                                <p className="subtitle-h2">{item.name}</p>
                                                            </div>

                                                        </Link>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h3">ออกโดย:</p>
                                                            <p className="subtitle-h3">{item.instituteName}</p>
                                                        </div>
                                                        <p className="cer-date">{item.issuedDate ? `วันที่ออก ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `วันที่หมดอายุ ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                                                        <div className="flex gap-x-2">
                                                            <p className="cer-date">เหตุผล:</p>
                                                            <p className={`cer-date !text-red-500`}>{item.reason}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to={`/certificateEdit/${item.id}`}>
                                                    <figure className="image-cover cursor-pointer">
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                            }
                                                            alt="logo1"
                                                        />
                                                    </figure>
                                                </Link>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                    </div>

                </div>
                {/* section Cardlist */}
                <div className="column is-3 hidden-b hidden sm:block">
                    <div
                        className="is-3 flex-1"
                        style={{ position: "fixed", width: "inherit" }}
                    >
                        <div className="mt-3 mr-6">
                            <CardTaglist
                                title={t('text.trending')}
                                data={trend}
                                topage={"trend"}
                            />
                        </div>
                        <div className="mt-3 mr-6">
                            <ProfileList
                                title={t('text.following')}
                                data={profileUnFollow.map((x) => {
                                    return {
                                        name: x.fullNameEng,
                                        image: x.image
                                            ? process.env.REACT_APP_IMAGE + x.image
                                            : process.env.PUBLIC_URL +
                                            "/images/profilePage/Profile.png",
                                        profileID: x.profileID,
                                    };
                                })}
                                topage={"unfollow"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Certificate