import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApiComment from "../api/ApiComment";
import ApiContent from "../api/ApiContent";
import IconComponent from "./Icons";
import './Comment.scss';
import { faEllipsisH, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from "react-quill";
import CSwl from "../utils/alert";
import { useTranslation } from "react-i18next";
const Comment = ({
    contentid = 0,
    comment,
    image,
    fullName,
    time,
    totalLike,
    item,
    isLike = false,
    reply = () => { }
}
) => {
    const { t, i18n } = useTranslation();
    const modules = useMemo(
        () => ({
            toolbar: false
        }),
        []
    );
    var submitEl = React.useRef(null);
    const history = useHistory();
    const userState = useSelector((state) => state.user);
    const [like, setLike] = useState(totalLike);
    const [pIsLike, setPisLike] = useState(isLike);
    const [replyText, setreply] = useState('');
    const [options, setOptions] = useState(false);
    const [isModal, setisModal] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [replyState, setreplyState] = useState({
        commentId: 0,
        createby: 0
    })
    const [state, setstate] = useState({
        "contentid": contentid,
        "commentId": item.id,
        "comment": comment
    })
    const func_like = async () => {
        const result = await ApiComment.Like(item.id);
        if (result.status == 200) {
            if (result.data.statusLike) {
                setLike(like + 1);
                setPisLike(true);
            } else {
                setLike(like - 1);
                setPisLike(false);
            }
        }
    };

    const renderEditButton = () => {
        return <div
            className="pl-2 is-flex is-align-items-center is-absolute" style={{ top: 30, right: 30 }}
        >
            <div style={{ position: 'relative' }}>
                <div className="cursor-pointer" onClick={(e) => {
                    e.stopPropagation();
                    setOptions(!options);
                }}>
                    <IconComponent
                        className="icon-20x ml-3" color="#333"
                        icon={faEllipsisH}
                    />
                </div>
                {options && <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="spam-container">
                    <ul>
                        <li className="cursor-pointer" onClick={(e) => {
                            e.stopPropagation();
                            setOptions(false);
                            setisEdit(true);
                            setstate({ ...state, comment: comment })
                        }}>แก้ไขคอมเมนท์นี้</li>
                        <li className="cursor-pointer" onClick={async (e) => {
                            e.stopPropagation();
                            setOptions(false);
                            const result = await CSwl.SwalConfirm("คุณต้องการจะลบคอมเมนท์นี้");
                            if (result.isConfirmed) {
                                const result = await ApiComment.delete(item.id);
                                if (result.status == 200) {
                                    await CSwl.SwalSuccess("ลบสำเร็จ");
                                    window.location.reload();
                                }
                            }
                        }}>ลบคอมเมนท์นี้</li>
                    </ul>
                </div>
                }

            </div>
        </div>
    }

    const func_reply = async (comment) => {
        const input_comment = document.getElementById('input_comment');
        input_comment.focus();
        setreplyState({ commentId: comment.id, createby: comment.createby });
        setstate({ ...state, comment: `<a href="${window.location.origin}/profileDetail?id=${comment.createby}">@${comment.fullNameEng}</a>` })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const created = await ApiComment.update(state);
            if (created.status == 200) {
                setstate({ ...state, comment: state.comment });
                setisEdit(false);
            }
        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.title)
        }
    }

    return <div id="comment-card" className=" container d-flex ">
        {
            (item.createby == userState.profile.id && !isEdit) && renderEditButton()
        }
        <img
            onClick={() => history.push(
                userState.profile.id == item.createby ?
                    "/profile" :
                    `/profileDetail?id=${item.createby}`
            )}
            style={{ height: '100%', width: '100%' }}
            alt={'image_profile'}
            className="is-rounded image-profile-40 mr-3 image-cover cursor-pointer"
            src={image}
        />
        <div style={{ flex: 1 }}>
            {!isEdit &&
                <div className="comment-card mb-2">
                    <p className="fullname cursor-pointer"
                        onClick={() => history.push(
                            userState.profile.id == item.createby ?
                                "/profile" :
                                `/profileDetail?id=${item.createby}`
                        )}>{fullName}</p>

                    <p className="comment" dangerouslySetInnerHTML={{ __html: state.comment }} />

                </div>
            }
            {isEdit &&
                <form onSubmit={onSubmit}>
                    <div className="content container d-flex is-align-items-center" style={{ paddingTop: 0, paddingLeft: 0 }}>
                        <ReactQuill
                            className="w-100"
                            id="input_comment"
                            placeholder={t('text.comment')}
                            value={state.comment}
                            modules={modules}
                            onChange={(content, delta, source, editor) => {
                                setstate({ ...state, comment: content });
                            }}
                        />
                        <div className="cursor-pointer" onClick={() => {
                            submitEl.current.click();
                        }
                        }>
                            <IconComponent className="icon-20x ml-3" color="#888" icon={faPaperPlane} />
                        </div>
                        <button type="submit" ref={submitEl} style={{ display: 'none' }}></button>
                    </div>
                </form>
            }
            <div className="d-flex flex-wrap is-align-items-center">
                <div
                    className="pl-2 is-flex is-align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                        func_like(e);
                    }}
                >
                    {pIsLike ? (
                        <img
                            className="img-size"
                            src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
                            alt="Like2"
                            width="16"
                            height="16"
                        />
                    ) : (
                        <img
                            className="img-size"
                            src={process.env.PUBLIC_URL + "/images/firstPage/Like2.png"}
                            alt="Like2"
                            width="16"
                            height="16"
                        />
                    )}
                    <span className="pl-2">{t('text.like')}</span>
                </div>
                <div onClick={() => reply(item)} className="pl-4 is-flex is-align-items-center cursor-pointer">
                    <img
                        className="img-size"
                        src={process.env.PUBLIC_URL + "/images/firstPage/Comment2.png"}
                        alt="Comment2"
                        width="16"
                        height="16"
                    />
                    <span className="is-size-6 pl-2">{t('text.reply')}</span>
                </div>
                <span className="has-text-grey-light is-size-6 ml-2">{time}</span>
            </div>
        </div>
    </div>
}

export default Comment;