import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link, useHistory } from "react-router-dom";
import ApiAuth from "../../api/ApiAuth";
import CSwl from "../../utils/alert";
import { useDispatch, useSelector } from "react-redux";
import { setLocalStorage } from "../../utils/localStorage";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Login = () => {
  const { instance } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const languageState = useSelector((state) => state.language);
  const [state, setstate] = useState({
    username: "",
    password: ""
  });
  const login = async (e) => {
    CSwl.SwalLoad();
    e.preventDefault();
    try {
      const response = await ApiAuth.login(state);
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data, token } = response.data;
        dispatch({ type: 'set_user', user: data });
        dispatch({ type: 'set_token', token: token });
        setLocalStorage('token', token);
        history.push('/home');
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  }

  useEffect(() => {
    return () => {
    }
  }, [])

  function handleLogin(instance) {
    instance.loginPopout(loginRequest).catch(e => {
      console.error(e);
    });
  }

  function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }



  return (
    <div id="login-page">
      <div className="columns">
        <div className="column is-one-third is-align-items-center is-justify-content-center hidden" style={{ backgroundImage: 'url("/images/login/BGLogin.png")', minHeight: '110vh' }}>
          <img src="/images/login/Logo-login.png" alt="loginpage" />
        </div>
        <form className="w-100" onSubmit={login}>
          <div className="column item-login">
            <div className="is-flex  is-flex-direction-column">
              <div className="is-flex is-align-items-center">
                <p className="fontHeader pr-3">เข้าสู่ระบบ</p>
                <img className="logo-image"
                  src={process.env.PUBLIC_URL + "/images/firstPage/logoSCGP.svg"}
                />
              </div>
              <p className="fontSubHeader pb-4">กรุณากรอกข้อมูล</p>
            </div>
            {/* <div className="row pb-4">
              <p className="has-text-weight-bold">รหัสประจำตัว</p>
              <input
                className="input is-rounded is-normal"
                type="text"
                placeholder="Username input"
                required
                onChange={(e) => setstate({ ...state, username: e.target.value })}
                value={state.username}
              />
            </div>
            <div className="row pb-4 ">
              <p className="has-text-weight-bold">รหัสผ่าน</p>
              <input
                className="input is-rounded is-normal"
                type="password"
                placeholder="Password input"
                required
                onChange={(e) => setstate({ ...state, password: e.target.value })}
                value={state.password}
              />
            </div> */}
            {/* <div className="is-flex is-justify-content-space-between">
            <label classname="checkbox is-flex">
              <input type="checkbox" />
              <span className="pl-2">จดจำการเข้าสู่ระบบ</span>
            </label>
            <p style={{textDecoration: 'underline'}}>ลืมรหัสผ่าน?</p>
          </div> */}
            {/* <div className="row buttonLayout">
              <Link to="/home">
              <button type="submit" className="button is-rounded button-C">
                เข้าสู่ระบบ
              </button>
              </Link>
            </div> */}
            <div className="row buttonLayout">

              <button onClick={() => handleLogin(instance)} type="button" className="button is-rounded button-C">
                เข้าสู่ระบบ
              </button>

              <AuthenticatedTemplate>
                <button onClick={() => handleLogout(instance)} type="button" className="button is-rounded button-C">
                  SignOut AD
                </button>
              </AuthenticatedTemplate>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
