import React, { useState } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../../utils/alert";
import { destroyPage, useWindowUnloadEffect, CheckFile, ConvertDuration } from "../../../utils";
import ApiFollow from "../../../api/ApiFollow";
import ApiMtTag from "../../../api/ApiTag";
import CardTaglist from "../../../components/CardTaglist";
import ProfileList from "../../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import Select, { StylesConfig } from 'react-select';
import DatePicker from "react-datepicker";
import CSwal from "../../../utils/alert";
import Swal from "sweetalert2";
import ApiContent from "../../../api/ApiContent";
import ApiTranProfile from "../../../api/ApiTranProfile";
import Autosuggest from "react-autosuggest";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../../components/Icons";
import ApiConfig from "../../../api/ApiConfig";
import _ from 'lodash';
import { useEffect } from "react";
import moment from "moment";
import Drawer from "rc-drawer";
import MenuDrawer from "../../../components/MenuDrawer";
const PastProjectEdit = () => {
  const [isConfigEditApproved, setisConfigEditApproved] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const userState = useSelector((state) => state.user);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const { id } = useParams();
  const [projectType, setProjectType] = useState([])
  const [relatedcap, setRelatedCap] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fileDocState, setfileDocState] = useState("");
  const [fileDoctmp, setfiledoctmp] = useState([]);
  const [data, setData] = useState({})
  const history = useHistory();
  const [capabilitiesDefault, setCapabilitiesDefault] = useState([])
  const [capabilities, setCapabilities] = useState([])
  const [searchCapacity, setsearchCapacity] = useState('');
  const [isOverApproved, setisOverApproved] = useState(false);
  const [state, setState] = useState({
    "userId": userState.employee.userId,
    "userFullname": `${userState.employee?.firstNameThai} ${userState.employee?.lastNameThai}`,
    "category": "",
    "name": "",
    "description": "",
    "startDate": null,
    "endDate": null,
    "duration": 0,
    "durationText": "",
    "role": "",
    "roleDescription": "",
    "keyDeliverable": "",
    "location": "",
    "projectManager": "",
    "competency": "",
    "approvedBy": "",
    "approvedName": "",
    "reportToName": userState.employee.reportToName,
    "status": "",
    isEditUser: false,
    isEditApproved: false
  });

  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState({});

  const [suggestionApprove, setsuggestionApprove] = useState('');
  const [suggestionsApprove, setSuggestionsApprove] = useState([]);
  const [suggestionSelectApprove, setsuggestionsSelectApprove] = useState({});

  const [suggestionProjectUser, setsuggestionProjectUser] = useState('');
  const [suggestionsProjectUser, setSuggestionsProjectUser] = useState([]);
  const [suggestionSelectProjectUser, setsuggestionsSelectProjectUser] = useState({});

  React.useEffect(() => {
    CSwl.SwalLoad();
    // const promise1 = getProfileUnFollow();
    // const promise2 = getToptag();
    const promise3 = getCompetency();
    const promise5 = getConfig();
    Promise.all([promise3,promise5]).then(async (result) => {
      if (id !== "0") {
        await getData(result[0]);
      }
      CSwl.SwalClose();
    })
    return () => { };
  }, []);

  useEffect(() => {
    const diff = moment(state.endDate).diff(moment(state.startDate));
    if (!isNaN(diff)) {
      let durationText = "";

      var duration = moment.duration(diff);

      var years = parseInt(duration.asYears());
      if (years >= 1) {
        duration.subtract(moment.duration(years, 'years'));
        durationText += `${years} ปี `
      }


      var months = parseInt(duration.asMonths());
      if (months >= 1) {
        duration.subtract(moment.duration(months, 'months'));
        durationText += `${months} เดือน `
      }

      //Get Days and subtract from duration
      var days = duration.asDays();
      if (days >= 1) {
        duration.subtract(moment.duration(days, 'days'));
        durationText += `${days} วัน `
      }
      setState({ ...state, duration: diff, durationText: durationText });
    } else {
      setState({ ...state, duration: 0, durationText: "" });
    }
  }, [state.startDate, state.endDate]);

  
  const getConfig = async () => {
    const result = await ApiConfig.getList();
    if (result.status == 200) {
      const { data } = result.data;
      if (data.find(x => x.code == "pass_exp_approver")) {
        setisConfigEditApproved(data.find(x => x.code == "pass_exp_approver").value == 1 ? true : false)
      }
    }
  }

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageSize: 3,
      pageNumber: 1,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };


  const getCompetency = async () => {
    const result = await ApiConfig.getCompetency();
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        //setTrend(result.data.data);
        var groupBySizeBox = _(result.data.data)
          .groupBy('ocap')
          .map((value, key) => {
            return {
              label: key, options: value.map(x => {
                return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
              })
            }
          })
          .value();
        setCapabilities(groupBySizeBox);
        setCapabilitiesDefault(result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno, description: x.competencyDifination };
        }));
        return result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
        });
      }
    }
  };

  const getData = async (list_capabilities) => {
    try {
      const res = await ApiContent.GetProjectExpById(id);
      if (res.status === 200) {
        const userApprove = res.data.userApprove;
        const userLead = res.data.userLead;
        const data = res.data.data;
        let competency = "";
        if (data.competency?.split(',')?.length > 0) {
          competency = [];
          data.competency.split(',').map((item, index) => {
            competency.push(list_capabilities.find(x => x.value == item))
          })
        }
        if (data.files != "" && data.files != null) {
          setfiledoctmp(JSON.parse(data.files));
        }
        setState({
          ...state,
          "userId": data.userId,
          "userFullname": `${userState.employee?.firstNameThai} ${userState.employee?.lastNameThai}`,
          "category": data.category,
          "name": data.name,
          "description": data.description,
          "startDate": data.startDate ? new Date(moment(data.startDate)) : null,
          "endDate": data.endDate ? new Date(moment(data.endDate)) : null,
          "durationText": ConvertDuration(data.startDate, data.endDate),
          "role": data.role,
          "roleDescription": data.roleDescription,
          "keyDeliverable": data.keyDeliverable,
          "location": data.location,
          "projectManager": data.projectManager,
          "competency": competency,
          "approvedBy": data.projectManager,
          "approvedName": userApprove.firstNameEnglish + " " + userApprove.lastNameEnglish,
          "status": "",
        })
        setsuggestionProjectUser(userLead.firstNameEnglish + " " + userLead.lastNameEnglish)
        // const files = res.data.data.files ? JSON.parse(res.data.data.files) : "";
        // const fileImage = files.find((x) => {
        //   return listTypeImage.some(v => x.Path.includes(v))
        // })
        // setFile(files);
        // setfileImage(fileImage)
      }
    } catch (error) {
      console.log(error)
      // try {
      //   const { data } = error.response;
      //   CSwl.SwalErr(data.message).then(x => history.go(-1));
      // } catch (ex) {
      //   CSwl.SwalErr("ไม่สามารถสร้างบทความได้");
      // }
    }
  }


  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = capabilities.filter(group => {
      return group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
    }
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find(opt => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  const fileDoc = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    CheckFile({
      file,
      size: 100,
      type: ["application/pdf", "image/jpg", "image/jpeg", "image/png"],
      message: "รองรับเฉพาะไฟล์ .pdf,.docx,pptx เท่านั้น",
    });
    setfileDocState([...fileDocState, file]);
  };

  const list = ["test1", "test2", "test3"]

  const onSubmit = async (e) => {
    e.preventDefault();
    state.status = "W";
    CSwal.SwalLoad();
    try {
      if (id == 0) {
        const created = await ApiContent.createProjectExp(
          {
            ...state,
            competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
            // category: state.category?.value
          });
        const formData = new FormData();
        if (fileDocState) {
          fileDocState.map((item) => {
            formData.append("file", item);
          })
        }
        const refno = created.data.data.ref;
        formData.append("refno", refno);
        if (fileDocState) {
          const uploadedFile = await ApiContent.uploadProjectExp(formData);
        }
        Swal.fire("สำเร็จ", "", "success").then(async (result) => {
          history.push("/pastProject");
        });
      } else {
        const created = await ApiContent.updateProjectExp(
          {
            ...state,
            id: parseInt(id),
            competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
            filesTemp: fileDoctmp,
            // category: state.category?.value
          });
        const formData = new FormData();
        if (fileDocState) {
          fileDocState.map((item) => {
            formData.append("file", item);
          })
        }
        const refno = created.data.data.ref;
        formData.append("refno", refno);
        if (fileDocState) {
          const uploadedFile = await ApiContent.uploadProjectExp(formData);
        }
        Swal.fire("สำเร็จ", "", "success").then(async (result) => {
          history.push("/pastProject");
        });
      }
    } catch (error) {
      try {
        const { data } = error.response;
        CSwal.SwalErr(data.message);
      } catch (ex) {
        CSwal.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  };

  //#region suggestionUser
  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    // const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    // if (checked) {
    // } else {
    //   setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    // }
    setState({
      ...state,
      userFullname: suggestion.fullName,
      isEditUser: false,
      userId: suggestion.employee.userId,
    })
    setsuggestionsSelectUser(suggestion);
    return "";
  };

  const renderSuggestionUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.fullName.includes(suggestionUser) ? suggestion.fullName : suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    setsuggestionUser(newValue);
  };

  //#endregion

  //#region suggestionApprove
  const getSuggestionsApprove = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueApprove = (suggestion) => {
    // const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    // if (checked) {
    // } else {
    //   setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    // }
    setState({
      ...state,
      approvedName: suggestion.fullName,
      isEditApproved: false,
      approvedBy: suggestion.employee.userId,
    })
    setsuggestionsSelectApprove(suggestion);
    return "";
  };

  const renderSuggestionApprove = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.fullName.includes(suggestionsApprove) ? suggestion.fullName : suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedApprove = async ({ value }) => {

    setSuggestionsApprove(await getSuggestionsApprove(value));
  };

  const onSuggestionsClearRequestedApprove = () => {
    setSuggestionsApprove([]);
  };

  const onChangeSuggestionApprove = (event, { newValue }) => {
    setsuggestionApprove(newValue);
  };

  //#endregion

  //#region suggestionProjectUser
  const getSuggestionsProjectUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueProjectUser = (suggestion) => {
    // const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    // if (checked) {
    // } else {
    //   setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    // }
    setState({
      ...state,
      projectManager: suggestion.employee.userId,
      approvedBy: suggestion.employee.userId,
      approvedName: suggestion.fullName
    })
    setsuggestionsSelectProjectUser(suggestion);
    //setsuggestionProjectUser(suggestion.fullName);
    return suggestion.fullName;
  };

  const renderSuggestionProjectUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.fullName.includes(suggestionsProjectUser) ? suggestion.fullName : suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedProjectUser = async ({ value }) => {

    setSuggestionsProjectUser(await getSuggestionsProjectUser(value));
  };

  const onSuggestionsClearRequestedProjectUser = () => {
    setSuggestionsProjectUser([]);
  };

  const onChangeSuggestionProjectUser = (event, { newValue }) => {
    setsuggestionProjectUser(newValue);
  };

  //#endregion

  const list_category = [{ id: 1, label: "Capability development" }, { id: 2, label: "Cost Reduction" }, { id: 3, label: "Culture Improvement" },
  { id: 4, label: "Delivery Improvement" },
  { id: 5, label: "Environmental Improvement" },
  { id: 6, label: "Existing Product Improvement" },
  { id: 7, label: "Fixed Cost Reduction" },
  { id: 8, label: "Law & Regulations" },
  { id: 9, label: "Market Lost Avoidance" },
  { id: 10, label: "Morale & Culture" },
  { id: 11, label: "New Product Development" },
  { id: 12, label: "Others" },
  { id: 13, label: "Price increase" },
  { id: 14, label: "Process Improvement" },
  { id: 15, label: "Product Compliance" },
  { id: 16, label: "Product mix improvement" },
  { id: 17, label: "Productivitiy Improvement" },
  { id: 18, label: "Quality Improvement" },
  { id: 19, label: "Replacement" },
  { id: 20, label: "Revenue Increase" },
  { id: 21, label: "Safety Improvement" },
  { id: 22, label: "Sales volume increase" },
  { id: 23, label: "System Improvement" },
  { id: 24, label: "Uptime Improvement" },
  { id: 25, label: "Variable Cost Reduction" },
  { id: 26, label: "Waste Reduction" },
  { id: 27, label: "Working Capital Reduction" },
  ]

  return (
    <div id="PastProjectEditPage">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <div className="columns">
        <div className="hidden sm:block column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('menu.projecthistory')} />
        </div>

        <div className="column is-7 pt-0">  <form onSubmit={onSubmit}>
          <div className="is-flex flex-wrap is-flex-direction-column section-content sm:!px-5 sm:!py-6 !py-3 !px-2">
            <div className="is-flex is-justify-content-space-between flex-wrap gap-y-2">
              <div className="is-flex is-align-items-center">
                <figure className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/list_certificate.png"
                    }
                    alt="logo1"
                  />
                </figure>
                {
                  id === "0" ?
                    <p className="title-h1 px-2">{t('menu.addpastproject')}</p>
                    :
                    <p className="title-h1 px-2">{t('menu.editpastproject')}</p>
                }
              </div>

            </div>
          </div>
          <div className="section-content p-5">
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.employeename')}
              </div>
              <div className="column is-9 flex gap-x-2 items-center">
                {
                  state.isEditUser ? <div className="w-full"><Autosuggest
                    suggestions={suggestionsUser}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequestedUser}
                    onSuggestionsClearRequested={onSuggestionsClearRequestedUser}
                    getSuggestionValue={getSuggestionValueUser}
                    renderSuggestion={renderSuggestionUser}
                    inputProps={{
                      placeholder: "กรุณาระบุชื่อพนักงาน",
                      value: suggestionUser,
                      onChange: onChangeSuggestionUser,
                      readOnly: state.isEditUser == 1 ? false : true,
                      className: 'input w-full'
                    }
                    }
                  /></div> : <input
                    className="input"
                    type="text"
                    required
                    readOnly={state.isEditUser == 1 ? false : true}
                    value={state.userFullname}
                    onChange={(e) => setState({ ...state, userFullname: e.target.value })}
                    placeholder="กรุณาระบุชื่อพนักงาน"
                  />
                }


                <img
                  onClick={() => {
                    setState({ ...state, userFullname: "", userId: "", isEditUser: true })
                  }}
                  className="cursor-pointer w-6 h-6"
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                  }
                  alt="logo1"
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.projectname')}
              </div>
              <div className="column is-9">
                <input
                  className="input"
                  type="text"
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  placeholder="กรุณาระบุชื่อโครงการ"
                  required
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.projectdetail')}
              </div>
              <div className="column is-9">
                <textarea
                  className="textarea"
                  type="text"
                  rows="3"
                  value={state.description}
                  onChange={(e) => setState({ ...state, description: e.target.value })}
                  placeholder="กรุณาระบุรายละเอียดโครงการ"
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.projecttype')}
              </div>
              <div className="column is-9">
                {/* <Select
                  defaultValue={projectType}
                  className="basic-single"
                  classNamePrefix="select"
                  styles={{ width: 200 }}
                  required
                  isMulti
                  options={list.map((x) => {
                    return { label: x, value: x };
                  })}
                /> */}
                <Select
                  defaultValue={list_category.filter(x=>x.id == state.category) ??""}
                  value={list_category.filter(x=>x.id == state.category) ??""}
                  className="basic-single"
                  classNamePrefix="select"
                  styles={{ width: 200 }}
                  required
                  onChange={(e) => { setState({ ...state, category:e.value  }) }}
                  options={list_category.map((x) => {
                    return { label: x.label, value: x.id };
                  })}
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                {t('text.projectstartdate')}
              </div>
              <div className="column is-3">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  popperPlacement="bottom-start"
                  dropdownMode="select"
                  className="input size-input"
                  selected={state.startDate}
                  onChange={(e) => {
                    if (state.endDate < e) {
                      setState({ ...state, startDate: e, endDate: e })
                    } else {
                      setState({ ...state, startDate: e })
                    }

                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="column is-3 subtitle">
                {t('text.projectenddate')}
              </div>
              <div className="column is-3">
                <DatePicker
                  showMonthDropdown
                  showYearDropdown
                  popperPlacement="bottom-start"
                  dropdownMode="select"
                  className="input size-input"
                  selected={state.endDate}
                  minDate={state.startDate}
                  onChange={(e) => setState({ ...state, endDate: e })}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                {t('text.projectduration')}
              </div>
              <div className="column is-9">
                <input
                  className="input"
                  type="text"
                  readOnly
                  value={state.durationText}
                  // onChange={(e) => setState({ ...state, duration: e.target.value })}
                  placeholder="ระยะเวลาเป็นจำนวนวัน เดือน หรือปี"
                />
              </div>
            </div>
            <div className="columns pt-4 whitespace-nowrap">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.projectroles')}
              </div>
              <div className="column is-9">
                <input
                  className="input"
                  type="text"
                  value={state.role}
                  onChange={(e) => setState({ ...state, role: e.target.value })}
                  placeholder="กรุณาระบุบทบาทในโครงการ"
                  required
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.descriptionrole')}
              </div>
              <div className="column is-9">
                <textarea
                  className="textarea"
                  type="text"
                  rows="3"
                  required
                  value={state.roleDescription}
                  onChange={(e) => setState({ ...state, roleDescription: e.target.value })}
                  placeholder="กรุณาระบุคำอธิบายบทบาทในโครงการ"
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.resultsproject')}
              </div>
              <div className="column is-9">
                <textarea
                  className="textarea"
                  type="text"
                  rows="3"
                  required
                  value={state.keyDeliverable}
                  onChange={(e) => setState({ ...state, keyDeliverable: e.target.value })}
                  placeholder="กรุณาระบุผลลัพธ์ที่ได้จากโครงการ"
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.factory')}
              </div>
              <div className="column is-9">
                <input
                  className="input"
                  type="text"
                  value={state.location}
                  onChange={(e) => setState({ ...state, location: e.target.value })}
                  placeholder="กรุณาระบุชื่อโรงงาน/สถานที่"
                  required
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle">
                <span className="has-text-danger">*</span>{t('text.projectleader')}
              </div>
              <div className="column is-9">
                {/* <input
                  className="input"
                  type="text"
                  value={data.projectleader}
                  onChange={(e) => setData({ ...data, projectleader: e.target.value })}
                  placeholder="กรุณาระบุหัวหน้าโครงการ/บุคคลอ้างอิงในโครงการ"
                  required
                /> */}
                <Autosuggest
                  suggestions={suggestionsProjectUser}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestedProjectUser}
                  onSuggestionsClearRequested={onSuggestionsClearRequestedProjectUser}
                  getSuggestionValue={getSuggestionValueProjectUser}
                  renderSuggestion={renderSuggestionProjectUser}
                  inputProps={{
                    placeholder: "กรุณาระบุหัวหน้าโครงการ/บุคคลอ้างอิงในโครงการ",
                    value: suggestionProjectUser,
                    onChange: onChangeSuggestionProjectUser,
                    className: 'input w-full'
                  }
                  }
                />
              </div>
            </div>
            <div className="columns pt-4">
              <div className="column is-3 subtitle flex gap-x-2">
                {t('text.relatedcapabilities')}
                <IconComponent onClick={() => {
                  setisModal(true)
                }} className="h-6 w-6 cursor-pointer" color="#F24C20" icon={faInfoCircle} />
              </div>
              <div className="column is-9">
                <div className="flex gap-x-2">
                  <Select
                    value={state.competency}
                    className="basic-single w-full"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    isMulti
                    onChange={(e) => {
                      setState({ ...state, competency: e })
                    }}
                    filterOption={filterOption}
                    // options={capabilities.map((x) => {
                    //   return { label: `(${x.fcno}) ${x.competencyNameEn}`, value: x.fcno };
                    // })}
                    options={capabilities}
                  />

                </div>

              </div>
            </div>
            <div className="is-flex file-container-card is-justify-content-space-between is-flex-wrap-wrap">
              <div className="is-flex is-flex-direction-column">
                <p className="title-h1">
                  {t('text.projectattachment')}
                </p>
                <div className="file is-boxed">
                  <label className="file-label">
                    <input
                      onChange={fileDoc}
                      accept="application/pdf, application/vnd.ms-powerpoint, application/msword"
                      id="doc_file"
                      className="file-input"
                      type="file"
                      name="resume"
                    />
                    <span className="file-cta is-flex-direction-row is-justify-content-center padding-file border-s">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/content/IconFile.png"
                        }
                        alt="Icon"
                      />
                      <div className="is-flex is-flex-direction-column pl-2">
                        <p className="subtitle-p2">{t('text.content_file_project_20mb')}</p>
                      </div>
                    </span>
                  </label>
                </div>
                {fileDocState && (
                  fileDocState.map((item, index) => {
                    return <div key={item.name + index} className="d-flex file-row mt-3" style={{ position: 'relative' }}>
                      <label className="flex-1">
                        {item.name}
                      </label>
                      <button
                        type="button"
                        onClick={(e) => {
                          let files = [...fileDocState];
                          files.splice(index, 1);
                          e.stopPropagation();
                          setfileDocState(files)
                        }} className="delete bg-red-500" style={{ position: 'absolute', right: 10 }}></button>
                    </div>
                  })

                )}
                {fileDoctmp.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex file-row mt-3"
                      style={{ position: "relative" }}
                    >
                      <label className="flex-1">{item.Name}</label>
                      <button
                        type="button"
                        onClick={(e) => {
                          let files = [...fileDoctmp];
                          files.splice(index, 1);
                          e.stopPropagation();
                          setfiledoctmp(files)
                        }}
                        className="delete bg-red-500"
                        style={{ position: "absolute", right: 10 }}
                      ></button>
                    </div>
                  );
                })}
              </div>
            </div>
            {
              !isConfigEditApproved && <div className="columns mt-4">
              <div className="column is-6">
                <p className="title-h1 relative">
                  {t('text.approvedby')}
                  <IconComponent
                    onMouseOver={() => {
                      setisOverApproved(true)
                    }}
                    onMouseLeave={() => {
                      setisOverApproved(false)
                    }}
                    onClick={() => {
                    }} className="h-6 w-6 cursor-pointer" color="#F24C20" icon={faInfoCircle} />
                  {
                    isOverApproved && <div className="!text-sm !text-black absolute top-[-60px] right-[20px] bg-white p-2 border-[#afafaf] border-2">
                      “if project manager can’t approval every issue
                      please change approval to current my manage.”
                    </div>
                  }

                </p>
                <div className="flex gap-x-2 items-center">
                  {
                    state.isEditApproved ? <div className="w-full"><Autosuggest
                      suggestions={suggestionsApprove}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequestedApprove}
                      onSuggestionsClearRequested={onSuggestionsClearRequestedApprove}
                      getSuggestionValue={getSuggestionValueApprove}
                      renderSuggestion={renderSuggestionApprove}
                      inputProps={{
                        placeholder: "กรุณาระบุชื่อพนักงาน",
                        value: suggestionApprove,
                        onChange: onChangeSuggestionApprove,
                        readOnly: state.isEditApproved == 1 ? false : true,
                        className: 'input w-full'
                      }
                      }
                    /></div> :
                      <input
                        className="input"
                        type="text"
                        required
                        readOnly={state.isEditApproved == 1 ? false : true}
                        value={state.approvedName}
                        onChange={(e) => setState({ ...state, approvedName: e.target.value })}
                        placeholder="กรุณาระบุชื่อพนักงาน"
                      />
                  }


                  <img
                    onClick={() => {
                      setState({ ...state, approvedName: "", approvedBy: "", isEditApproved: true })
                    }}
                    className="cursor-pointer w-6 h-6"
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                    }
                    alt="logo1"
                  />
                </div>
              </div>
              <div className="column is-6">
                <p className="title-h1">
                  {t('text.manager')}
                </p>
                <input
                  className="input"
                  type="text"
                  value={state.reportToName}
                  readOnly

                //onChange={(e) => setState({ ...data, approvedName: e.target.value })}
                />
              </div>
            </div>
            }
            
          </div>
          <div className="is-flex flex-wrap is-flex-direction-column section-content sm:!px-5 sm:!py-6 !py-3 !px-2">
            <div className="is-flex is-justify-content-space-between flex-wrap gap-y-2">
              <div className="is-flex is-align-items-center">

              </div>

              <div className="is-flex">
                <Link to={`/pastProject`}>
                  <button className="button is-light is-rounded px-6">{t('menu.cancel')}</button>
                </Link>
                <button
                  type={"submit"}
                  className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2 px-6"
                  style={{ backgroundColor: "#F24C20" }}
                >
                  {"บันทึก"}
                </button>
              </div>
            </div>
          </div>
        </form>
        </div>

        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div
            className="is-3 flex-1"
            style={{ position: "fixed", width: "inherit" }}
          >
            <div className="mt-3 mr-6">
              <CardTaglist
                title={t('text.trending')}
                data={trend}
                topage={"trend"}
              />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        </div>
      </div>
      <div class={`modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content !w-full sm:!w-3/4  bg-white p-5" style={{ borderRadius: 5 }}>
          <h1 className="text-2xl">{t('text.capabilities')}</h1>
          <div className="flex-column overflow-auto max-h-[500px] mt-3">
            <div className="pr-2">
              <input
                className="input"
                type="text"
                value={searchCapacity}
                onChange={(e) => setsearchCapacity(e.target.value)}
                placeholder="ค้นหา"
              />
            </div>
            <div className="flex flex-col gap-y-2 mt-2">
              {
                capabilitiesDefault.filter(x => x.label.includes(searchCapacity)).map((item, index) => {
                  return <div className="flex flex-col gap-y-1">
                    <span className="font-bold text-lg">{item.label}:</span>
                    <span className="text-gray-">{item.description}</span>
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <button onClick={() => {
          setisModal(false);
        }}
          class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  )
}

export default PastProjectEdit