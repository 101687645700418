import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import IconComponent from "../../components/Icons";
import Navbar from "../../components/Navbar";
import PostCard from "../../components/PostCard";
import PostCardShort from "../../components/PostCardShort";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import ApiCategory from "../../api/ApiCategory";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import CSwl from "../../utils/alert";
import ApiAuth from "../../api/ApiAuth";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import { useTranslation } from "react-i18next";
const myMsal = new PublicClientApplication(msalConfig);
const Home = (props) => {
  const { t, i18n } = useTranslation();
  const { instance, accounts, inProgress } = useMsal();

  const [catagories, setcatagories] = useState([]);
  const tokenState = useSelector((state) => state.token);
  const languageState = useSelector((state) => state.language);
  const callBackUrl = useSelector((state) => state.callBackUrl);
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();


  React.useEffect(() => {
    if (isAuthenticated) {
      
      const username = accounts[0] && accounts[0].username;
      var model = {
        Email: username,
      };
      login(model);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch({ type: "set", isLogged: "false" });
    return () => {
    }
  }, [])

  const login = async (model) => {
    CSwl.SwalLoad();
    try {
      const response = await ApiAuth.login(model);
      if (response.status == 200) {
        CSwl.SwalClose();
        const callBack = callBackUrl == "" ? "/home" : callBackUrl;
        const { data, token } = response.data;
        dispatch({ type: "set_user", user: data, callBackUrl: "" });
        dispatch({ type: "set_token", token: token });
        setLocalStorage("token", token);
        history.push(callBack);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message).then(() => {
        handleLogout(instance);
      });
    }
  };

  function handleLogin() {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  }



  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }


  return (
    <div>
      <Navbar loginRequest={handleLogin} />
      <div class="columns mt-5">
        <div class="column is-three-fifths" style={{ paddingLeft: 50 }}>
          <div class="content p-5">
            <h1 className="title">
              {t('home.title')}
            </h1>
            <p>
              {t('home.title2')}
              <br />
              {/* <Link to="/more" className="has-text-danger">
                เรียนรู้เพิ่มเติม
              </Link> */}
            </p>
            {/* <Link to='/login' className='button is-danger is-outlined mt-5'>
              เข้าสู่ระบบ
            </Link> */}
          </div>
        </div>
        <div class="column">
          <img
            src={process.env.PUBLIC_URL + "/images/home/homepage.png"}
            alt="home"
          />
        </div>
      </div>
      {/* <div className='w-100 p-5 has-background-info-light'>
        <div className='container'>
          <div className='content'>
            <h4 className='has-text-danger'>
              <IconComponent className='mr-3' icon={faFire} />
              Trending on SCGP KM
            </h4>
          </div>
          <div className='columns mt-3'>
            <div className='column'>
              <PostCardShort />
            </div>
            <div className='column'>
              <PostCardShort />
            </div>
            <div className='column'>
              <PostCardShort />
            </div>
          </div>
          <div className='columns mt-3'>
            <div className='column'>
              <PostCardShort />
            </div>
            <div className='column'>
              <PostCardShort />
            </div>
            <div className='column'>
              <PostCardShort />
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-100 p-5">
        <div className="container">
          <div className="columns">
            {/* <div className='column is-two-thirds'>
              <div>
                <PostCard />
              </div>
              <div className='mt-5'>
                <PostCard />
              </div>
              <div className='mt-5'>
                <PostCard />
              </div>
            </div> */}
            <div className="column p-5">
              {/* <div className='content'>
                <h4 className='has-text-danger'>
                  DISCOVER MORE OF WHAT MATTERS TO YOU
                </h4>
                <p>
                  <a className='text-link'>ดูหมวดหมู่ทั้งหมด</a>
                </p>
              </div>
              <div
                className='d-flex'
                style={{ flexWrap: 'wrap', marginTop: '-10px' }}
              >
                {catagories.map((item) => (
                  <CatagoryGroup text={item.categoryName} />
                ))}
              </div> */}
              <div className="hr mt-5" />
              <div className="d-flex mt-5 flex-wrap">
                <div className="mr-3">
                  <Link className="has-text-grey" to="/help">
                    Help
                  </Link>
                </div>
                <div className="mr-3">
                  <Link className="has-text-grey" to="/privacy">
                    Privacy Policy
                  </Link>
                </div>
                <div className="mr-3">
                  <Link className="has-text-grey" to="/terms">
                    Terms & Conditions
                  </Link>
                </div>
                <div>
                  <Link className="has-text-grey" to="/about">
                    {t('home.about')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CatagoryGroup = ({ text }) => {
  return (
    <Link to="/" className="button is-grey is-outlined has-text-grey mt-5 mr-2">
      {text}
    </Link>
  );
};

export default Home;
