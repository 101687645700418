import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuTag from "../../components/MenuTag";
import ApiTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import ApiMtTag from "../../api/ApiTag";
import MainPost from "../../components/PostV2";
import CSwl from "../../utils/alert";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import { destroyPage, useWindowUnloadEffect } from "../../utils";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const BookmarkPage = (props) => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Bookmark",firstTime:firstTime }), true);
  const { t, i18n } = useTranslation();
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getContent();
    Promise.all([promise1]).then(result => {
      CSwl.SwalClose();
    })
    return () => { };
  }, []);




  const getContent = async () => {
    const result = await ApiContent.getBookmark({ ...state });
    if (result.status == 200) {
      setcontents(result.data.data);
    }
  };



  return (
    <div id="first-page">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.bookmarks')} />
      </Drawer>

      <div className="columns">
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.bookmarks')} />
        </div>
        <div className="column is-7 middle-container layer2">
          {contents &&
            contents.items.map((d, index) => {
              return (
                <div className="row fn ar" key={d.id}>
                  <MainPost
                    time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                    totalLike={d.ctlikeAmt}
                    isLike={d.isLike}
                    isBookmark={d.isBookmark}
                    item={d}
                    title={d.ctname}
                    comments={d.comments}
                    detail={d.ctdeschtml}
                    name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                    image={d.imageProfile ? process.env.REACT_APP_IMAGE + d.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
                  />
                </div>
              );
            })}
          {contents.items == 0 && (
            <div className="m-5">ไม่พบบทความ</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookmarkPage;
