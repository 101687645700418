import React from 'react'
import { useParams } from 'react-router-dom'
import Cardlist from '../../../components/Cardlist'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import { Link } from 'react-router-dom';
import './index.scss'
import { useTranslation } from "react-i18next";
const SettingEdit = () => {
  const { t, i18n } = useTranslation();
  const friend =[{
    name:'Savannah Nguyen'
  },
  {
    name:'Jacob Jones'
  },
  {
    name:'Janis Joplin'
  }
]
  const trend = [{
    name:'#JessieMueller',
    like: 5000
  },
  {
    name:'#ThelmaJones',
    like: 4000
  },
  {
    name:'#LoisPearson',
    like: 2000
  }]
  const {edit} = useParams();
  return (
    <div id="SettingEditPage" >
      <Header />
      <div className="columns" style={{height:'max-content'}}>
        <div className="column is-2 my-3 layer">
          <Menu page="rank"/>
        </div>
        <div className="column is-7 border-div1">
          <Link to="/setting" className="is-flex is-align-items-center m-4">
            <figure className="image mr-3">
              <img src={process.env.PUBLIC_URL + "/images/settingPage/vector3.png"} alt="vertor3"/>
            </figure>
            <p className="title-h">{t('text.edit_profile')}</p>
          </Link>
          <hr className="hr-size1"/>
          <div className="is-flex is-align-items-center m-4">
            <p className="title-h mt-4">{edit === 'tel' ? 'เบอร์โทรศัพท์' : 'อีเมล'}</p>
          </div>
          <hr className="hr-size1"/>
          <div className="is-flex is-align-items-center m-4">
            <input className="input is-rounded" type="text" placeholder="" />
          </div>
          <hr className="hr-size1"/>
          <div className="is-flex is-justify-content-center mt-3">
            <button className="button is-rounded subtitle-p4 button-s">บันทึก</button>
          </div>
        </div>

        <div className="column is-3 pl-5 pt-5">
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.trending')} data={trend}/>
          </div>
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.following')} data={friend}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingEdit
