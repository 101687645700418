import React, { useEffect, useMemo, useState } from "react";
import "../style/Post/post.scss";
import { Link } from "react-router-dom";
import ApiContent from "../api/ApiContent";
import IconComponent from "./Icons";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ApiComment from "../api/ApiComment";
import Swal from "sweetalert2";
import CSwl from "../utils/alert";
import Comment from './Comment'
import { WEB_IMAGE } from "../env";
import { ConvertDateShort } from "../utils/date";
import { useHistory } from 'react-router-dom'
import { Player } from 'video-react';
import ReactQuill from "react-quill";



const PostDetailPreview = ({
  name,
  image,
  time,
  title,
  detail,
  subdetail,
  totalLike,
  page,
  contentid,
  contentStyles,
  item,
  onComment = () => { }
}) => {
  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );
  const [tags, settags] = useState([]);
  const [videos, setvideos] = useState([]);
  const [docs, setdocs] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (item?.cttagtJson && item.cttagtJson != "[]") {
      let tags = JSON.parse(item.cttagtJson);

      settags(tags.map(x => x.Name));
    }
    if (item?.ctfilevideo && item.ctfilevideo != "[]") {
      let videos = JSON.parse(item.ctfilevideo);
      console.log(videos.map(x => process.env.REACT_APP_IMAGE + x.Path))
      setvideos(videos.map(x => process.env.REACT_APP_IMAGE + x.Path));
    }
    if (item?.ctfiledoc && item.ctfiledoc != "[]") {
      let docs = JSON.parse(item.ctfiledoc);
      setdocs(docs);
    }
  }, []);

  //state
  const [like, setLike] = useState(0);
  const [pIsLike, setPisLike] = useState(false);
  const [state, setstate] = useState({
    "contentId": contentid,
    "comment": ""
  })

  const [replyState, setreplyState] = useState({
    commentId: 0,
    createby: 0
  })

  const func_like = async () => {
    const result = await ApiContent.Like(contentid);
    if (result.status == 200) {
      if (result.data.statusLike) {
        setLike(like + 1);
        setPisLike(true);
      } else {
        setLike(like - 1);
        setPisLike(false);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      state.replyToCommentId = replyState.commentId;
      state.replyToUser = replyState.createby;
      const created = await ApiComment.create(state);
      if (created.status == 200) {
        setstate({ ...state, comment: "" })
        onComment(created.data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.title)
    }
  }

  const reply = async (comment) => {
    const input_comment = document.getElementById('input_comment');
    input_comment.focus();
    setreplyState({ commentId: comment.id, createby: comment.createby });
    setstate({ ...state, comment: `<a href="${window.location.origin}/profileDetail?id=${comment.createby}">@${comment.fullNameEng}</a>` })
  }

  var submitEl = React.useRef(null);


  return (
    <div id="post-com" key={contentid} className="card">
      <div className="content container-header">
        <div className="media is-justify-content-space-between is-align-items-center">
          <div className="media pt-5" style={{ borderTop: 0 }}>
            <div>
              <figure className="image is-48x48">
                <img
                  alt={'image_profile'}
                  className="is-rounded image-profile-50"
                  src={image}
                />
              </figure>
            </div>
            <div className="media-content title-header">
              <span className="has-text-weight-bold">{name}</span>
              <span className="has-text-grey-light">{time}</span>
            </div>
          </div>

        </div>
      </div>
      <div className="container-header content p-4">
        <h3 className="has-text-link m-3 has-text-weight-bold">{title}</h3>
      </div>
      <div style={{ minHeight: 'inherit' }} className="ql-editor pt-0" >
        <p
          style={contentStyles}
          className='subtitle-c-p mx-3'
          dangerouslySetInnerHTML={{ __html: detail }}
        />
        {page !== 'detail' &&
          <div className="mt-2 mx-3">
            <Link to={`/contentDetail?id=${contentid}`}>
              <span className="has-text-danger">อ่านต่อ</span>
            </Link>
          </div>
        }
      </div>
      {docs.length > 0 &&
        <div className="d-flex p-5 flex-column">
          {docs && docs.map((item, index) => {
            return (
              <div onClick={() =>
                window.open(`${process.env.REACT_APP_IMAGE}${item.Path}`, '_blank')
              } className="file-container w-100 mt-2">
                {item.Name}
              </div>
            )
          })}
        </div>
      }

      <div className="d-flex">
        {videos && videos.map((item, index) => {
          return (
            <Player>
              <source src={item} />
            </Player>
          )
        })}
      </div>
      <div className="mt-2 pb-5 d-flex flex-wrap">
        {tags && tags.map((item, index) => {
          return (
            <div style={{ marginTop: 10 }} onClick={() => {
              // history.push(`/tag?q=${item}`);
            }}
              className="pl-5 is-flex is-align-items-center">
              <span className="cursor-pointer is-size-7 tag">{item}</span>
            </div>
          )
        })}
      </div>
    </div >
  );
};

export default PostDetailPreview;
