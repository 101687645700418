import PropType from "prop-types";
import React, { useState, useEffect } from "react";
import "./index.css";

const CTable = (props) => {
  const [fields, setFields] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.fields) {
      setFields(props.fields);
    }
  }, [props.fields]);

  useEffect(() => {
    if (props.items) {
      setItems(props.items);
    }
  }, [props.items]);

  return (
    <div style={{ overflowX: "scroll", width: "100%" }}>
      <table className="ctable">
        <thead>
          <tr className="ctrhead">
            {fields.length > 0 &&
              fields.map((field, index) => {
                return (
                  <td className="ctd" style={field.thStyle} key={index}>
                    {field.label}
                  </td>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <tr className="ctr" key={index}>
                  {fields.map((field, indexfield) => {
                    return (
                      <td
                        className="ctd"
                        key={indexfield}
                        style={field.tbStyle}
                      >
                        {props.CustomField && props.CustomField[field.key]
                          ? props.CustomField[field.key](item, index)
                          : item[field.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

CTable.propTypes = {
  fields: PropType.array,
  items: PropType.array,
  CustomField: PropType.object,
};

export default CTable;
