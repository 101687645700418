import React, { useEffect, useState } from "react";
import "../style/Menu/MenuDrawer.scss";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiContent from "../api/ApiContent";
import { useTranslation } from "react-i18next";
const MenuDrawer = ({ data, page, subpage, header = "หน้าแรก" }) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const userState = useSelector((state) => state.user);
    const dataPro = data;
    const [search, setsearch] = useState('');
    const [countManager, setCountManager] = useState(0);
    const [countSme, setCountSme] = useState(0);
    const [countCertificate, setcountCertificate] = useState(0);
    const [countProjectExp, setcountProjectExp] = useState(0);
    const count_login = useSelector((state) => state.count_login);
    const getContentManager = async () => {
        const result = await ApiContent.getCountTranContentByBO();
        if (result.status == 200) {
            setCountManager(result.data.data);
            //setcontents(result.data.data);
        }
    };

    const getContentSme = async () => {
        const result = await ApiContent.GetListSMEByBO({
            pageNumber: 1,
            pageSize: 10,
        });
        if (result.status == 200) {
            setCountSme(result.data.data.totalItems);
        }
    };

    const getApproveCentificate = async () => {
        const result = await ApiContent.getCountApproveCertificate();
        if (result.status == 200) {
            setcountCertificate(result.data.data);
            //setcontents(result.data.data);
        }
    };


    const getApproveProjectExp = async () => {
        const result = await ApiContent.getCountProjectExp();
        if (result.status == 200) {
            setcountProjectExp(result.data.data);
            //setcontents(result.data.data);
        }
    };


    useEffect(() => {
        getContentManager();
        getContentSme();
        getApproveCentificate();
        getApproveProjectExp();
    }, []);

    const searchPost = () => {
        window.location.href = `/search?q=${search}`;
    }


    return (
        <div id="Menu-Drawer">
            <div>
                <div>
                    <p className="fontH">{header}</p>
                </div>
                <div className="row ml-4 hidden-b">
                    <div className="is-hidden-desktop is-hidden-tablet pt-2">
                        <input value={search} onChange={(e) => {
                            setsearch(e.target.value);
                        }} onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                if (!search.length == 0) {
                                    searchPost();
                                }
                            }
                        }}
                            className="input is-rounded has-background-light search hidden-search" type="text" placeholder={t('menu.search')} />
                    </div>
                    <p
                        onClick={() => history.push("/profile")}
                        className="font pt-5 cursor-pointer"
                    >{`${userState.employee?.firstNameThai} ${userState.employee?.lastNameThai}`}</p>
                    <p
                        onClick={() => history.push("/bookmark")}
                        className="font pt-5 cursor-pointer"
                    >
                        {t('menu.bookmarks')}
                    </p>
                    <p
                        onClick={() => history.push("/interest")}
                        className="cursor-pointer font pt-5"
                    >
                        {t('menu.attention')}
                    </p>
                    <hr />
                    <div className="flex gap gap-y-5 flex-col py-5">
                        <Link
                            to="/createcontent"
                            className="has-text is-flex is-align-items-center"
                        >
                            <button className="button button-size is-link is-rounded is-fullwidth max-w-[200px]">
                                {t('menu.createcontent')}
                            </button>
                        </Link>
                        {userState.isAdmin && (
                            <Link
                                to="/createLiveSession"
                                className="has-text is-flex is-align-items-center"
                            >
                                <button className="button is-link is-rounded is-fullwidth max-w-[200px]  !py-2">
                                    {t('menu.createLiveSession')}
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div className="row ml-4 hidden-b">
                <hr />
                <Link to="/contentwaiting">
                    <p className="font">{t('menu.draft')}</p>
                </Link>
                {/* <Link to="/contentPlan">
                <p className="font pt-5">กำหนดเวลา</p>
              </Link> */}
                <Link to="/setting">
                    <p className="font pt-5">{t('menu.setting')}</p>
                </Link>


                {countManager > 0 && (
                    <Link to="/managerapprove" >
                        <div className="!mt-5">
                            <button className="button is-link is-rounded is-fullwidth flex gap-x-2 pr-3">
                                <span className="flex flex-1 mr-2">{t('menu.approve')}</span>
                                {countManager > 0 && (
                                    <span
                                        // className="count-approve !text-white !border-white ml-2"
                                        className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countManager}</span>
                                )}
                            </button>
                        </div>
                    </Link>
                )}

                {userState.issme && (
                    <Link to="/smeapprove" >
                        <div className="!mt-5">
                            <button className="button is-link is-rounded is-fullwidth max-w-[200px] flex gap-x-2 pr-3">
                                <span className="flex flex-1 mr-2">{t('menu.sme_approve')}</span>
                                {countSme > 0 && (
                                    <span
                                        // className="count-approve !text-white !border-white ml-2"
                                        className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countSme}</span>
                                )}
                            </button>
                        </div>
                    </Link>
                )}
                {countCertificate > 0 && (
                    <Link to="/certificateApprove" >
                        <div className="!mt-5">
                            <button className="button is-link is-rounded is-fullwidth flex gap-x-2 pr-3">
                                <span className="flex flex-1 mr-2">{t('menu.approve_certificate')}</span>
                                {countCertificate > 0 && (
                                    <span
                                        // className="count-approve !text-white !border-white ml-2"
                                        className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countCertificate}</span>
                                )}
                            </button>
                        </div>
                    </Link>
                )}
                {countProjectExp > 0 && (
                    <Link to="/projectExpApprove" >
                        <div className="!mt-5">
                            <button className="button is-link is-rounded is-fullwidth flex gap-x-2 pr-3">
                                <span className="flex flex-1">{t('menu.approve_projectExp')}</span>
                                {countProjectExp > 0 && (
                                    <span className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countProjectExp}</span>
                                )}
                            </button>
                        </div>
                    </Link>
                )}
                {page == "redeem" && <Link to="/redeemHistory">
                    <p className="font pt-5">ประวัติแลกรางวัล</p>
                </Link>}
            </div>
            <div className="row ml-4 hidden-b mt-5">
                <div className="text-sm">ยอดจำนวนผู้เข้าชม <b>({count_login})</b></div>
            </div>
        </div>
    );
};

export default MenuDrawer;
