import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiFollow extends Component {
  static FollowORUnFollow = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranFollow/FollowORUnFollow/${id}`,
      method: "get",
    });
    return result;
  };

  static GetTranProfileForFollow = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranFollow/GetTranProfileForFollow`,
      method: "get",
    });
    return result;
  };

  static GetTranProfileForFollowPopular = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranFollow/GetTranProfileForFollowPopular`,
      method: "get",
    });
    return result;
  };
  

  static GetTranProfileForUnFollow = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranFollow/GetTranProfileForUnFollow`,
      method: "post",
      data: data,
    });
    return result;
  };


}

export default ApiFollow;
