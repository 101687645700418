import React, { useEffect, useState } from 'react'
import './index.scss';
import Header from "../../../components/Header";
import CardNotification from '../../../components/CardNotification';
import CSwl from '../../../utils/alert';
import ApiNotification from '../../../api/ApiNotification';
import { WEB_IMAGE } from "../../../env";
import { ConvertDateShort } from '../../../utils/date';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import Menu from '../../../components/Menu';
import { useTranslation } from "react-i18next";
import { destroyPage, useWindowUnloadEffect } from '../../../utils';
import InfiniteScroll from 'react-infinite-scroll-component';
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const Notification = () => {
  const [firstTime, setFirstTime] = useState(new Date());
  const [state, setstate] = useState(initialState);
  useWindowUnloadEffect(() => destroyPage({ page: "Notification", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [data, setData] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const languageState = useSelector((state) => state.language);
  const [loadingMore, setloadingMore] = useState(false);
  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      CSwl.SwalLoad();
      const res = await ApiNotification.getHistory(state);
      if (res.status === 200) {
        setData(res.data.data);
        CSwl.SwalClose();
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.title)
    }
  }

  const getDataLoadmore = async () => {
    const result = await ApiNotification.getHistory(state);
    if (result.status == 200) {
      setData({
        ...data,
        items: [...data.items, ...result.data.data.items],
      });
      console.log("loadmore::", result.data.data.items);
    }
  };

  const onRead = async (item) => {

    if (item.isRead == 1) {
      if (item.message.includes('Certificate')) {
        history.push(`/certificateApprove`)
      }
      else if (item.message.includes('Project')) {
        history.push(`/projectExpApprove`)
      } else {
        history.push(`/contentDetail?id=${item.idRelate}`);
      }

    } else {
      const result = await ApiNotification.read(item.id);
      if (result.status == 200) {
        const filter = [...data.items];
        const index = filter.findIndex(x => x.id == item.id);
        filter[index].isRead = 1;
        setData({
          ...data,
          items: [...filter],
        });
        const notificationCount = await ApiNotification.count();
        if (notificationCount.status == 200) {
          const { data, logsLogin, isShare, chatNotification } = notificationCount.data;
          dispatch({ type: "set_user", count_notification: data, count_login: logsLogin, isShare: isShare, count_chat_notification: chatNotification });
          if (item.message.includes('Certificate')) {
            history.push(`/certificateApprove`)
          }
          else if (item.message.includes('Project')) {
            history.push(`/projectExpApprove`)
          } else {
            history.push(`/contentDetail?id=${item.idRelate}`);
          }
        }
      }
    }

  }

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 });
    }
  }


  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getDataLoadmore();
      Promise.all([promise1]).then((results) => {
        setloadingMore(false);
      })
    }
    return () => { };
  }, [state]);



  return (
    <div id="first-page">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.notification')} />
      </Drawer>

      <div className="columns pr-4 lg:pr-0">
      <div className="column mr-4 lg:mr-0 is-hidden-mobile is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.notification')} />
        </div>
        <div className="column is-7 md:!flex-1 middle-container layer2">
          {data?.items?.length == 0 && (
            <div className="m-5">ไม่พบข้อมูล</div>
          )}
          {data &&
            <InfiniteScroll
              dataLength={data.items.length}
              next={() => handleLoadMore()}
              hasMore={data.totalPages > state.pageNumber}
              loader={data.items.length > 1 && <h4>Loading...</h4>}
            >
              {data.items.map((t, index) => {
                return (
                  <CardNotification
                    item={t}
                    onRead={onRead}
                    time={ConvertDateShort(t.createdate, "DD MMM YYYY HH:mm",languageState)}
                    message={t.message}
                    image={t.domainPath ? process.env.REACT_APP_IMAGE + t.domainPath : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
                  />
                )
              })}
            </InfiniteScroll>
          }
        </div>
      </div>

    </div>

  )
}

export default Notification
