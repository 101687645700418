import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuCatalog from "../../components/MenuCatalog";
import ApiCategory from "../../api/ApiCategory";
import MainPost from "../../components/PostV2";
import InfiniteScroll from "react-infinite-scroll-component";
import { destroyPage, useWindowUnloadEffect } from "../../utils";
import ApiTranProfile from "../../api/ApiTranProfile";
import ProfileCardSearch from "../../components/ProfileCardSearch";
import PostLiveSharing from "../../components/PostLiveSharing";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const CatalogPage = (props) => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Catagories", firstTime: firstTime }), true);
  const [search, setSearch] = useState(
    new URLSearchParams(props.location.search).get("q")
  );
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [enterSearch, setenterSearch] = useState("");
  const [catagory, setcatagory] = useState([]);
  const [limit, setlimit] = useState(10);
  const [loadingMore, setloadingMore] = useState(false);
  const [profiles, setprofiles] = useState("");
  useEffect(() => {
    setstate(initialState);
    getContentBysearch();
    GetProfileByCategory();
    return () => { };
  }, [search]);

  useEffect(() => {
    getCatagory(limit);
  }, [limit]);

  useEffect(() => {
    setSearch(new URLSearchParams(props.location.search).get("q"));
    return () => { };
  }, [props.location]);

  const getContentBysearch = async () => {

    const result = await ApiContent.getByCatalog({ ...initialState, catalog: search });
    if (result.status == 200) {
      setcontents(result.data.data);
    }
  };

  const GetProfileByCategory = async () => {
    const result = await ApiTranProfile.GetProfileByCategory({ ...initialState, search: search });
    if (result.status == 200) {
      setprofiles(result.data.data);
    }
  };

  const callBackComment = (result) => {
    try {
      const comment = result.data;
      console.log("comment::", comment);
      // const { data } = result;
      let newContents = { ...contents };
      let newContentsItem = newContents.items;

      const findIndex = newContentsItem.findIndex((x) => x.id == comment.ctid);
      newContentsItem[findIndex].comments.unshift(comment);
      newContents.items = newContentsItem;
      setcontents(newContents);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      console.log(error);
    }
  };

  const getCatagory = async (limit) => {
    const result = await ApiCategory.MenuGetList(limit);
    const { data } = result.data;
    setcatagory(data);
  };

  const func_loadmorelimit = () => {
    setlimit(limit + 10);
  };


  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 })
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then(results => {
        setloadingMore(false);
      })
    }
    return () => {
    }
  }, [state])

  const getContentLoadmore = async () => {
    const result = await ApiContent.getByCatalog({ ...state, catalog: search });
    if (result.status == 200) {
      setcontents({
        ...contents,
        items: [...contents.items, ...result.data.data.items]
      });
      console.log("loadmore::", result.data.data.items)
    }
  };


  return (
    <div id="search-page">
      <Header />

      <div className="columns">
        <div className="column columns-menu-left is-3 my-3 layer">
          <MenuCatalog
            data={catagory}
            onChangeloadlimit={() => {
              func_loadmorelimit();
            }}
            istag={true}
          />
        </div>
        <div className="column middle-container is-8 mt-5">
          <h2 className="fontH mb-2">{`${search ?? "ทั้งหมด"}`}</h2>
          <h2 className="text-lg mb-2">{`People ${`(${(profiles && profiles?.items.length) ?? 0})`}`}</h2>
          <div className="ar" style={{ marginBottom: 15 }}></div>
          <div className="flex overflow-auto gap-2 mb-4">
            {profiles && profiles.items.filter(x => x.smeCategory && x.smeCategory.length > 0).map((d, index) => {
              return (
                <div className="column size-procard !h-fit" key={index}>
                  <ProfileCard
                    profile={d}
                    key={d.id}
                    profileid={d.id}
                    // tagsArray={tagsArray}
                    // specialistArray={specialistArray}
                    depart={d.dept}
                    totalArticle={d.articleAmt}
                    totalFollow={d.followerAmt}
                    isFollow={d.isFollow}
                    image={d.image
                      ? process.env.REACT_APP_IMAGE + d.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png"}
                    name={d.firstNameEnglish + " " + d.lastNameEnglish}
                    email={d.email}
                    smeCategory={d.smeCategory ? d.smeCategory.split(',') : []}
                  />
                </div>
              );
            })}
          </div>
          <h2 className="text-lg mb-2">{`บทความ ${`(${contents.totalItems ?? 0})`}`}</h2>
          <div className="ar" style={{ marginBottom: 15 }}></div>
          {contents &&
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  return (
                    <div className="row fn ar" key={d.id}>
                      {
                        d.cttype == "CONTENT" ? <MainPost
                          time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                          totalLike={d.ctlikeAmt}
                          isLike={d.isLike}
                          isBookmark={d.isBookmark}
                          item={d}
                          title={d.ctname}
                          comments={d.comments}
                          detail={d.ctdeschtml}
                          name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                          image={
                            d.imageProfile
                              ? process.env.REACT_APP_IMAGE + d.imageProfile
                              : process.env.PUBLIC_URL +
                              "/images/profilePage/Profile.png"
                          }
                        />
                          : <PostLiveSharing
                            stateOrg={[]}
                            time={ConvertDateShort(d.ctstartdate, "DD MMM YYYY HH:mm น.")}
                            totalLike={d.ctlikeAmt}
                            isLike={d.isLike}
                            isBookmark={d.isBookmark}
                            item={d}
                            title={d.ctname + " (LIVE SHARING)"}
                            comments={d.comments}
                            detail={d.ctdeschtml}
                            name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                            image={
                              d.imageProfile
                                ? process.env.REACT_APP_IMAGE + d.imageProfile
                                : process.env.PUBLIC_URL +
                                "/images/profilePage/Profile.png"
                            }
                          />
                      }


                    </div>
                  );
                })}
            </InfiniteScroll>}
          {search && contents.items == 0 && (
            <div className="m-5">ไม่พบบทความ</div>
          )}
        </div>
      </div>

    </div>
  );
};

export default CatalogPage;
