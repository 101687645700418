import React from 'react'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import Cardlist from '../../../components/Cardlist';
import '../history/index.scss'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import ApiRedeem from '../../../api/ApiRedeem'
import { ConvertDateShort } from '../../../utils/date';
import CSwl from "../../../utils/alert";
import { checkStatusRedeem } from '../../../utils';

const RedeemHistoryPage = () => {
  const userState = useSelector((state) => state.user);

  const trips = [{
    topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม วันที่ 19-26 ธันวาคม 2564',
    date: 'แลกเมื่อ Jan 24 12:42'
  },
  {
    topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม วันที่ 19-26 ธันวาคม 2564',
    date: 'แลกเมื่อ Jan 24 12:42'
  },
  {
    topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม วันที่ 19-26 ธันวาคม 2564',
    date: 'แลกเมื่อ Jan 24 12:42'
  },
  {
    topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม วันที่ 19-26 ธันวาคม 2564',
    date: 'แลกเมื่อ Jan 24 12:42'
  },
  {
    topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม วันที่ 19-26 ธันวาคม 2564',
    date: 'แลกเมื่อ Jan 24 12:42'
  },
  ]
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      CSwl.SwalLoad();
      const res = await ApiRedeem.getHistory({
        pageNumber: 1,
        pageSize: 10,
      })
      if (res.status === 200) {
        setData(res.data.data.items);
        CSwl.SwalClose();
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.title)
    }
  }
  return (
    <div id="RedeemHistoryPage">
      <Header />
      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="redeem" />
        </div>
        <div className="column is-7">
          <Link to="/redeem" className="is-flex border-div pt-6 pl-4 pb-3">
            <img src={process.env.PUBLIC_URL + "/images/redeemPage/Vector.png"} alt="logo" width='24' height='24' />
            <p className="subtitle-p pl-4">ย้อนกลับ</p>
          </Link>
          {
            data.length == 0 && <div>
              <div className="is-flex border-div1 p-5">ไม่พบประวัติการแลกของรางวัล</div>
            </div>
          }
          {data.map((t, index) => {
            return (
              <div className="is-flex border-div1 p-5">
                <div className="is-flex is-align-items-center mr-3">
                  <figure class="image is-320x160">
                    <img
                      src={process.env.REACT_APP_IMAGE_BACKOFFICE + t.domainPath}
                      alt="image_domain" />
                  </figure>
                </div>
                <div className="is-flex is-flex-direction-column is-justify-content-center">
                  <p className="title-h">{t.redeemName}</p>
                  <p className="subtitle-p1">ใช้คะแนน {t.rdpoint} คะแนน</p>
                  <p>สถานะ : {checkStatusRedeem(t.rdstatus)}</p>
                  <p>แลกเมื่อ {ConvertDateShort(t.createdate, "DD MMM YYYY HH:mm น.")}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div className="column is-3 pl-5 pt-5 hidden-b">
          {/* <div class="mt-3 mr-6">
            <Cardlist title={t('text.trending')} data={trend}/>
          </div>
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.following')} data={friend}/>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default RedeemHistoryPage
