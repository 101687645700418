import React from 'react'
import Cardlist from '../../../components/Cardlist'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import Post from '../../../components/Post'
import './index.scss'
import { useTranslation } from "react-i18next";
const ProfileFollow = () => {
  const { t, i18n } = useTranslation();
  const friend =[{
    name:'Savannah Nguyen'
  },
  {
    name:'Jacob Jones'
  },
  {
    name:'Janis Joplin'
  }
]
  const trend = [{
    name:'#JessieMueller',
    like: 5000
  },
  {
    name:'#ThelmaJones',
    like: 4000
  },
  {
    name:'#LoisPearson',
    like: 2000
  }]
  const interest = ['การตลาด','ไอที','อาหาร','ยานยนต์','ท่องเที่ยว','เว็บไซต์','ยานยนต์','ท่องเที่ยว','เว็บไซต์']
  const dataPost = [{
    name:'Annette Black',
    time:'Jan 18',
    title:'ถ้าไปรษณีย์ไทย อยู่ในตลาดหลักทรัพย์ จะใหญ่แค่ไหน',
    detail:'รู้ไหมว่าไปรษณีย์ของประเทศไทย ก่อตั้งมาตั้งแต่สมัยรัชกาลที่ 5 ทำธุรกิจมายาวนานกว่า 138 ปีและก็ไม่น่าเชื่อเหมือนกันว่าบริษัทอายุร้อยปีในวันนั้น',
    totalLike:293,
    totalComment:68
  },{
    name:'Annette Black',
    time:'Jan 18',
    title:'ถ้าไปรษณีย์ไทย อยู่ในตลาดหลักทรัพย์ จะใหญ่แค่ไหน',
    detail:'รู้ไหมว่าไปรษณีย์ของประเทศไทย ก่อตั้งมาตั้งแต่สมัยรัชกาลที่ 5 ทำธุรกิจมายาวนานกว่า 138 ปีและก็ไม่น่าเชื่อเหมือนกันว่าบริษัทอายุร้อยปีในวันนั้น',
    totalLike:293,
    totalComment:68
  }]
  const dataProfile = {
    comp: 'Siam Nippon Paper Co..Ltd.',
    agency: 'Business Development',
    depart: 'ผู้ช่วยกรรมการผู้จัดการใหญ่ พัฒนาธุรกิจ',
    workplace: 'สถานที่ปฏิบัติงาน',
    tel: '094 961 9569',
    mail: 'phatsarn.ming@gmail.com'
  }
  return (
    <div id="ProfilefollowPage">
      <Header />
      <div className="columns">
          <div className="column is-2 my-3 layer hidden-b">
            <Menu page="profile"/>
          </div>
          <div className="column is-7">
            <div className="">
              <figure className="image">
                <img src={process.env.PUBLIC_URL + "/images/profilePage/profilefollow/BG.png"} alt="backgroundImage"/>
              </figure>
            </div>
            <div className="layer1 section-content">
              <figure className="image">
                <img class="is-rounded img-card" src={process.env.PUBLIC_URL + "/images/profilePage/profilefollow/Profile.png"} alt="Placeholder image"/>
              </figure>
              <div className="layer1 layer2">
                <p className="title-h">ภัทร์ศรัณย์ สวาทยานนท์</p>
                <p className="subtitle-h pb-3">Business Development / Siam Nippon Paper</p>
                <button className="button is-danger is-outlined is-rounded size-button">Expertise / Platinum</button>
                <div className="is-flex my-4">
                  <button className="button is-rounded color-button">ติดตาม</button>
                  <button className="button is-rounded color-button">ติดต่อนัดหมาย</button>
                  <button className="button is-rounded color-button">ส่งข้อความ</button>
                </div>
              </div>
            </div>
            <div className="is-flex section-content is-justify-content-space-around py-3">
              <div className="is-flex is-flex-direction-column is-align-items-center">
                <p className="title-h">9,589</p>
                <div className="is-flex is-align-items-center">
                  <figure className="image is-16x16">
                    <img  src={process.env.PUBLIC_URL + "/images/profilePage/fi-ss-following1.png"} alt="logo1"/>
                  </figure>
                  <p className="subtitle-h pl-2">ผู้ติดตาม</p>
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center">
                <p className="title-h">195</p>
                <div className="is-flex is-align-items-center">
                  <figure className="image is-16x16">
                    <img  src={process.env.PUBLIC_URL + "/images/profilePage/fi-rr-users2.png"} alt="logo1"/>
                  </figure>
                  <p className="subtitle-h pl-2">ติดตาม</p>
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center">
                <p className="title-h">70</p>
                <div className="is-flex is-align-items-center">
                  <figure className="image is-16x16">
                    <img  src={process.env.PUBLIC_URL + "/images/profilePage/fi-ss-document1.png"} alt="logo1"/>
                  </figure>
                  <p className="subtitle-h pl-2">บทความ</p>
                </div>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column section-content p-5">
              <div className="is-flex is-align-items-center mb-3">
                <figure className="image is-24x24">
                  <img src={process.env.PUBLIC_URL + "/images/profilePage/vector.png"} alt="logo1"/>
                </figure>
                <p className="title-h1 pl-3">{t('text.expertise')}</p>
              </div>
              <div className="is-flex is-flex-wrap-wrap pb-2">
                {interest.map((i, index) =>{
                  return (
                    <div className="mr-2 mb-2" key={index}>
                      <button className="button is-info is-rounded has-text-grey has-background-info-light">{i}</button>
                    </div>
                  )
                })}
              </div>
              <div className="is-flex is-align-items-center mb-3">
                <figure className="image">
                  <img src={process.env.PUBLIC_URL + "/images/profilePage/vector1.png"} alt="logo1"/>
                </figure>
                <p className="subtitle-h1 pl-3">เพิ่มความสนใจ</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column section-content p-5">
              <div className="is-flex is-align-items-center mb-3">
                <figure className="image">
                  <img src={process.env.PUBLIC_URL + "/images/profilePage/profilefollow/vector1.png"} alt="logo1"/>
                </figure>
                <p className="title-h1 pl-3">ข้อมูลการทำงาน</p>
              </div>
              <div className="is-flex">
                <div className="is-flex is-flex-direction-column is-flex-grow-1">
                  <p className="subtitle-p1">บริษัท</p>
                  <p className="subtitle-p1">หน่วยงาน</p>
                  <p className="subtitle-p1">ตำแหน่ง</p>
                  <p className="subtitle-p1">สถานที่ปฏิบัติงาน</p>
                  <p className="subtitle-p1">เบอร์โทร</p>
                  <p className="subtitle-p1">Email</p>
                </div>
                <div className="is-flex is-flex-direction-column is-flex-grow-4">
                  <p className="subtitle-p1">{dataProfile.comp}</p>
                  <p className="subtitle-p1">{dataProfile.agency}</p>
                  <p className="subtitle-p1">{dataProfile.depart}</p>
                  <p className="subtitle-p1">{dataProfile.workplace}</p>
                  <p className="subtitle-p1">{dataProfile.tel}</p>
                  <p className="subtitle-p1">{dataProfile.mail}</p>
                </div>
              </div>
              <div className="is-flex is-justify-content-center my-4">
                <button className="button is-rounded color-button mx-4">ติดต่อนัดหมาย</button>
                <button className="button is-rounded color-button mx-4">ส่งข้อความ</button>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column section-content">
              {/* <div className="is-flex p-5 is-flex-wrap-wrap section-content1">
                <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">ทั้งหมด</button>
                <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">บทความยอดนิยม</button>
                <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">บทควาทปักหมุด</button>
                <button className="button is-info is-rounded has-text-grey has-background-info-light mr-2 mb-2">วิดีโอ</button>
              </div> */}
              <div>
                {dataPost.map((d, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <Post name={d.name} time={d.time} title={d.title} detail={d.detail} totalLike={d.totalLike} totalComment={d.totalComment}/>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="column is-3 pl-5 pt-5 hidden-b">
            <div class="mt-3 mr-6">
              <Cardlist title={t('text.trending')} data={trend}/>
            </div>
            <div class="mt-3 mr-6">
              <Cardlist title={t('text.following')} data={friend}/>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ProfileFollow
