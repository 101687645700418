import React, { useEffect, useState } from "react";
import "../style/Menu/Menu.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
const MenuCatalog = ({
  data = [],
  header = "องค์ความรู้",
  page = "catalog",
  istag = false,
  onChangeloadlimit = () => { },
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);

  const func_loadTagList = () => {
    onChangeloadlimit();
  };

  useEffect(() => {
    func_setData();
  }, [data]);

  const func_setData = () => {
    if (istag) {
      console.log("xxxxxxxx");
      console.log(data.item);
      if (data.item != undefined && data.item.length > 0) {
        setItems(data.item);
        if (data.item.length >= data.count) {
          setShow(false);
        } else {
          setShow(true);
        }
      }
    } else {
      setItems(data.item);
    }
  };
  return (
    <div id="Menu-com" style={{ paddingRight: 50 }}>
      <div>
        <div>
          <p className="fontH">{header}</p>
        </div>

        <div className="main-menu-container" style={{ display: "flex", flexWrap: "wrap", maxHeight: 500, overflow: 'auto' }}>
          {items.map((item, index) => {
            return (
              <div className="mr-2 mb-2 w-100" key={index}>
                <button
                  style={{
                    fontSize: "12px",
                    maxWidth: "200px",
                    whiteSpace: "normal",
                    height: "auto",
                    textAlign: 'left'
                  }}
                  onClick={() => {
                    history.push(`/${page}?q=${item.categoryName}`);
                  }}
                  className="button is-info is-rounded has-text-grey has-background-info-light"
                >
                  {item.categoryName}
                </button>
              </div>
            );
          })}
        </div>
        <div>
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: "14px",
            }}
            onClick={() => {
              func_loadTagList();
            }}
          >
            {show && (
              <>
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    func_loadTagList();
                  }}
                >
                  ดูเพิ่มเติม
                </span>
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MenuCatalog;
