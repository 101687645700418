import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuTag from "../../components/MenuTag";
import ApiTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import ApiMtTag from "../../api/ApiTag";
import MainPost from "../../components/PostV2";
import InfiniteScroll from "react-infinite-scroll-component";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const TrendPage = (props) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState(
    new URLSearchParams(props.location.search).get("q")
  );
  const [trend, setTrend] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [enterSearch, setenterSearch] = useState("");
  const [tag, setTag] = useState([]);
  const [limit, setlimit] = useState(40);
  const [loadingMore, setloadingMore] = useState(false);

  useEffect(() => {
    setstate(initialState);
    getContentBysearch();
    return () => { };
  }, [search]);


  useEffect(() => {
    getTag(limit);
  }, [limit]);

  useEffect(() => {
    setSearch(new URLSearchParams(props.location.search).get("q"));
    return () => { };
  }, [props.location]);



  const getContentBysearch = async () => {
    if (search != null) {
      const result = await ApiContent.getByTag({ ...initialState, tag: search });
      if (result.status == 200) {
        setcontents(result.data.data);
      }
    }
  };



  const getTag = async (limit) => {
    const result = await ApiMtTag.MenuGetListTopTag(limit);
    const { data } = result.data;
    setTag(data);

    if (!search) {
      setSearch(data.item[0].tagname);
    }
  };

  const func_loadmorelimit = () => {
    setlimit(limit + 10);
  };

  const getContentLoadmore = async () => {
    const result = await ApiContent.getByTag({ ...state, tag: search });
    if (result.status == 200) {
      setcontents({
        ...contents,
        items: [...contents.items, ...result.data.data.items]
      });
    }
  };

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 })
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then(results => {
        setloadingMore(false);
      });
    }
    return () => {
    }
  }, [state])

  return (
    <div id="search-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('text.trending')} />
      </Drawer>
      <Header />
      <div className="columns">
        <div className="column is-3 columns-menu-left layer">
          <MenuTag
            onChangeloadlimit={() => {
              func_loadmorelimit();
            }}
            header={t('text.trending')}
            page="trend"
            data={tag}
            istag={true}
          />
        </div>
        <div className="column middle-container is-8">
          {/* <h2 className="fontH mb-2 mt-2">{`${search ?? "ทั้งหมด"} ${`(${contents.totalItems ?? 0})`}`}</h2> */}
          {search && <h2 className="fontH mb-2 mx-2"># {search} {`(${contents.totalItems ?? 0})`}</h2>}
          {!search && <h2 className="fontH mb-2 mx-2 has-text-grey-light"># กรุณาเลือกเทรนด์</h2>}
          <div className="ar" style={{ marginBottom: 15 }}></div>
          {/* <div className="mb-5"></div> */}
          {contents &&
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  return (
                    <div className="row fn ar" key={d.id}>
                      <MainPost
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          }
          {search && contents.items == 0 && (
            <div className="m-5">ไม่พบบทความ</div>
          )}
        </div>
        {/* section Cardlist */}
        {/* <div className="column hidden-b">
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"tag"} />
            </div>
            <div className="mt-3 mr-6">
              <Cardlist
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        */}
      </div>

    </div>
  );
};

export default TrendPage;
