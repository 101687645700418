import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiAuth extends Component {
  static getUser = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `Auth/GetUser`,
      method: "get",
    });
    return result;
  };

  static getUserByProfile = async (profileId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Auth/GetUserById/${profileId}`,
      method: "get",
    });
    return result;
  };

  static login = async (data) => {
    const result = await axios({
      url: "Auth/Login",
      method: "post",
      data: data,
    });
    return result;
  };

  static upload = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Auth/UploadImage",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };

  static uploadcover = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Auth/UploadCover",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };

  static uploadSignature = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Auth/UploadSignature",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };

  static tokenFirebase = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Auth/Token",
      method: "post",
      data: data,
    });
    return result;
  };
}

export default ApiAuth;
