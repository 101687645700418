import React, { useState, useMemo, useEffect } from "react";
import "./index.scss";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InitialState from "./initialState";
import ReactQuill, { Quill } from "react-quill";
import ApiContent from "../../../api/ApiContent";
import { WEB_IMAGE } from "../../../env";
import Swal from "sweetalert2";
import { ConvertDateShort } from "../../../utils/date";
import CSwal from "../../../utils/alert";
import Select from "react-select";
import ApiCategory from "../../../api/ApiCategory";
import { TagsInput as ReactTags } from "react-tag-input-component";
import { useHistory } from "react-router-dom";
import { arrayRemoveByIndex, CheckFile } from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PostV2Preview from "../../../components/PostV2Preview";
import PostDetailPreview from "../../../components/PostBackendPreview";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import Autosuggest from 'react-autosuggest';
import ApiTranProfile from "../../../api/ApiTranProfile";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
window.Quill = Quill;

const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);

const EditContentSharing = (props) => {
  const userState = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [idc, setIdc] = useState(0);
  const history = useHistory();
  const [state, setState] = useState(InitialState);
  const [catagory, setcatagory] = useState([]);
  const [tags, setTags] = useState(0);
  const [fileDocState, setfileDocState] = useState("");
  const [fileDoctmp, setfiledoctmp] = useState([]);
  const [fileVideoState, setVideoState] = useState("");
  const [fileVideotmp, setfileVideotmp] = useState([]);
  const [isCalendar, setisCalendar] = useState(false);
  const [postDate, setpostDate] = useState(new Date());
  const [createDate, setCreateDate] = useState("");
  const [dataPreview, setdataPreview] = useState({});
  const [isPreview, setisPreview] = useState(false);
  const [cttagtJsonForcheck, setCttagtJsonForcheck] = useState([]);
  const [suggestion, setsuggestion] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionSelect, setsuggestionsSelect] = useState([]);

  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState([]);
  const [desc, setDesc] = useState("");
  var quillObj = React.useRef(null);
  var form = React.useRef(null);

  useEffect(() => {
    setState({ ...state, ctdeschtml: desc });
  }, [desc]);

  const imageHandler = (image, callback) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;

      uploadFiles(file, fileName, quillObj.current);
    };
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        // See optional "config" below
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"], // remove formatting button
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const uploadFiles = async (uploadFileObj, filename, quillObj) => {
    Swal.showLoading();
    const range = quillObj.getEditorSelection();
    console.log(range);
    console.log(uploadFileObj);
    console.log(filename);
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiContent.uploadHtmlFile(formData);
    const { data } = result.data;
    quillObj
      .getEditor()
      .insertEmbed(
        range.index,
        "image",
        process.env.REACT_APP_IMAGE + data.path
      );
    Swal.close();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    state.ctstatus = "W";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};
    let jsonRef = "";
    let jsonRefUser = "";
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
      return false;
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }

    try {
      const created = await ApiContent.UpdateLiveSharing({
        ...state,
        cttype: "LIVESHARING",
        ctcatagoryid: jsonCatagoryId,
        ctcatagoryJson: jsonCatagoryJson,
        ctstartdate: moment(state.ctstartdate).add(7, 'hours'),
        ctenddate: moment(state.ctenddate).add(7, 'hours'),
      });

      const formData = new FormData();
      if (fileVideoState) {
        formData.append("video", fileVideoState);
      }
      const refno = created.data.data.ctrefno;
      formData.append("refno", refno);
      if (fileDocState || fileVideoState) {
        const uploadedFile = await ApiContent.uploadLiveSharing(formData);
      }
      Swal.fire("สำเร็จ", "", "success").then(async (result) => {
        history.push("/liveSharing");
      });
    } catch (error) {
      console.log(error.response);
      const { data } = error.response;
      CSwal.SwalErr(data.message);
    }
  };

  const getCatagory = async () => {
    const result = await ApiCategory.get();
    const { data } = result.data;
    setcatagory(data);
  };

  const preview = async () => {
    state.ctstatus = "P";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
      return false;
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }
    //state.cttagtJsonForcheck = cttagtJsonForcheck;
    if (tags.length > 0) {
      state.cttagtJson = tags.map((x) => {
        return { Id: 0, Name: x };
      });
    }
    //console.log(cttagtJsonForcheck);
    try {
      const created = await ApiContent.createPreview({
        ...state,
        ctcatagoryid: jsonCatagoryId,
        ctcatagoryJson: jsonCatagoryJson,
        cttagtJsonForcheck: cttagtJsonForcheck,
      });
      CSwal.SwalClose();
      if (created.status == 200) {
        const { data } = created.data;
        console.log(JSON.stringify(data));
        setdataPreview(data);
        setisPreview(true);
      }
    } catch (error) {
      const { data } = error.response;
      CSwal.SwalErr(data.message);
    }
  };

  useEffect(() => {
    var parm = new URLSearchParams(props.location.search).get("c");
    if (parm != null) {
      setIdc(Number(parm));
      func_contentById(Number(parm));
    }
    getCatagory();
    return () => { };
  }, []);

  const func_contentById = async (idc) => {
    const result = await ApiContent.GetEditById(idc);
    const { data, status } = result.data;
    if (data.ctfilevideo != "" && data.ctfilevideo != null) {
      setfileVideotmp(JSON.parse(data.ctfilevideo));
    }

    if (status) {
      var cateData =
        data.ctcatagoryJson != null && data.ctcatagoryJson != ""
          ? JSON.parse(data.ctcatagoryJson)
          : [];

      var ctCate = [];
      for (let i = 0; i < cateData.length; i++) {
        var ctCateChild = {
          value: cateData[i].Id,
          label: cateData[i].Name,
        };
        ctCate.push(ctCateChild);
      }
      var newState = {
        id: idc,
        ctpostdate: new Date(data.ctpostdate),
        ctname: data.ctname,
        ctdeschtml: data.ctdeschtml,
        ctcatagoryJson: ctCate,
        "ctstartdate": new Date(data.ctstartdate),
        "ctenddate": new Date(data.ctenddate),
        cturl: data.cturl
      };
      setState(newState);
      setDesc(data.ctdeschtml);
    }
  };


  const fileVideo = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    CheckFile({
      file,
      size: 100,
      type: ["video/mp4"],
      message: "รองรับเฉพาะไฟล์ .mp4 เท่านั้น",
    });
    setVideoState(file);
    setfileVideotmp([]);
  };

  const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiContent.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    const checked = suggestionSelect.find(x => x.ctrefno == suggestion.ctrefno);
    if (checked) {

    } else {
      setsuggestionsSelect([...suggestionSelect, suggestion])
    }

    return "";
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.ctname}
    </div>
  );

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {

    setSuggestions(await getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeSuggestion = (event, { newValue }) => {
    setsuggestion(newValue);
  };

  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    if (checked) {
    } else {
      setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    }

    return "";
  };

  const renderSuggestionUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    setsuggestionUser(newValue);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div id="createContent-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.createLiveSession')} />
      </Drawer>
      <Header />
      <div className="columns" style={{ height: "100%" }}>
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.createLiveSession').replace("สร้าง", "").replace("Create", "")} />
        </div>
        {
          state.id > 0 && <form ref={form} className="d-flex flex-1" onSubmit={onSubmit}>
            <div className="column is-8 border-div1">
              <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap size-wrap">
                <div className="is-flex is-align-items-center">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h1">{t('menu.createLiveSession')}</p>
                </div>
                <div className="is-flex">
                  <button
                    type={"submit"}
                    className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2"
                    style={{ backgroundColor: "#F24C20" }}
                  >
                    {t('text.publish')}
                  </button>
                </div>
              </div>
              <hr className="hr-size1" />
              <div className="is-flex is-flex-direction-column size-col">

                <div className="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap my-4 px-1">
                  <p className="font">{t('text.content_name')}</p>
                  <input
                    className="input size-input"
                    type="text"
                    placeholder="กรุณาระบุชื่อความรู้"
                    defaultValue={state.ctname}
                    value={state.ctname}
                    required
                    onChange={(e) =>
                      setState({ ...state, ctname: e.target.value })
                    }
                  />
                </div>

                <div className="is-flex is-flex-direction-column px-2">
                  <p className="font">{t('text.content_desc')}</p>
                  <ReactQuill
                    style={{ marginTop: 20, marginBottom: 20 }}
                    ref={quillObj}
                    value={state.ctdeschtml}
                    modules={modules}
                    onChange={(content, delta, source, editor) => {
                      setState({ ...state, ctdeschtml: content });
                    }}
                  />
                </div>
                <div className="flex px-1 mb-3 gap-x-4 justify-between">
                  <div className="flex-1">
                    <div className="is-flex is-justify-content-space-between">
                      <div className="is-flex is-align-items-center">
                        <p>วัน-เวลาที่เริ่ม Live</p>
                      </div>
                    </div>
                    <div className="is-flex is-justify-content-space-between ">
                      <DatePicker
                        minDate={new Date()}
                        filterTime={filterPassedTime}
                        className="input"
                        selected={state.ctstartdate}
                        onChange={(date) => setState({ ...state, ctstartdate: date })}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm"
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="is-flex is-justify-content-space-between">
                      <div className="is-flex is-align-items-center">
                        <p>วัน-เวลาที่ปิด Live</p>
                      </div>
                    </div>
                    <div className="is-flex is-justify-content-space-between">
                      <DatePicker
                        minDate={new Date()}
                        filterTime={filterPassedTime}
                        className="input"
                        selected={state.ctenddate}
                        onChange={(date) => setState({ ...state, ctenddate: date })}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm"
                      />
                    </div>
                  </div>
                </div>

                {/* inputFile */}

                <div className="flex gap-x-4 flex-wrap grid grid-cols-1 px-1">
                  {/* section-inputVdo */}
                  <div className="is-flex is-flex-direction-column flex-1">
                    <p className="title-h1">{t('text.content_video')}</p>
                    <div className="flex flex-col gap-y-4">
                      <div className="flex-1">
                        <input
                          className="input"
                          type="text"
                          placeholder="ระบุเป็น Link จาก MS Stream"
                          value={state.cturl}

                          onChange={(e) =>
                            setState({ ...state, cturl: e.target.value })
                          }
                        />
                      </div>
                      <div className="file is-boxed flex-1">
                        <label className="file-label">
                          <input
                            className="file-input"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={fileVideo}
                            id="video_file"
                            type="file"
                            name="resume"
                          />
                          <span className="file-cta !w-full is-flex-direction-row is-justify-content-center border-s">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/content/IconVdo.png"
                              }
                              alt="Icon"
                            />
                            <div className="is-flex is-flex-direction-column pl-2">
                              <p className="subtitle-p2">{t('text.content_video_desc_100mb')}</p>
                            </div>
                          </span>

                        </label>
                      </div>

                    </div>
                    {fileVideoState && (
                      <div className="d-flex file-row mt-3" style={{ position: 'relative' }}>
                        <label className="flex-1">
                          {fileVideoState.name}
                        </label>
                        <button onClick={(e) => {
                          e.stopPropagation();
                          setVideoState("")
                        }}
                          class="delete" style={{ position: 'absolute', right: 10 }}></button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="column is-4 pl-5 pt-5 hidden-d">
                  <div className="is-flex is-align-items-center mt-4">
                    <figure className="image mr-3">
                      <img
                        src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                        alt="Icon"
                      />
                    </figure>
                    <p className="title-h1">{t('text.summary')}</p>
                  </div>
                  <div className="is-flex mt-4">
                    <div className="is-flex is-flex-direction-column my-1">
                      <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                      <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                      <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
                    </div>
                    <div className="is-flex is-flex-direction-column my-1">
                      <p className="subtitle-p5 mr-6">
                        {ConvertDateShort(new Date(), "DD MMMM YYYY")}
                      </p>
                      <p className="subtitle-p5 mr-6">-</p>
                    </div>
                  </div>
                  {/* <hr className="hr-size2" /> */}
                  <div className="column">
                    <div className="is-flex is-align-items-center">
                      <figure className="image mr-3">
                        <img
                          src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                          alt="Icon"
                        />
                      </figure>
                      <p className="title-h2">{t('text.category')}</p>
                    </div>
                    <div className="mt-3" style={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={state.ctcatagoryJson ?? ""}
                        styles={{ width: 200 }}
                        required
                        isMulti
                        onChange={(e) => {
                          setState({
                            ...state,
                            ctcatagoryJson: e,
                          });
                        }}
                        options={catagory.map((x) => {
                          return { label: x.categoryName, value: x.id };
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* column-right */}
            <div className="column is-4 hidden-b pl-3 pr-3">
              <div className="is-flex is-align-items-center mt-4">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h1">{t('text.summary')}</p>
              </div>
              <div className="is-flex mt-4">
                <div className="is-flex is-flex-direction-column my-1">
                  <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                  <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                  <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
                </div>
                <div className="is-flex is-flex-direction-column my-1">
                  <p className="subtitle-p5 mr-6">
                    {ConvertDateShort(new Date(), "DD MMMM YYYY")}
                  </p>
                  <p className="subtitle-p5 mr-6">-</p>
                </div>
              </div>
              {/* <hr className="hr-size2" /> */}
              <div className="column">
                <div className="is-flex is-align-items-center mt-5">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h2">{t('text.category')}</p>
                </div>
                <div className="mt-3" style={{ width: "100%" }}>
                  <Select
                    defaultValue={state.ctcatagoryJson ?? ""}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    isMulti
                    onChange={(e) => {
                      setState({
                        ...state,
                        ctcatagoryJson: e,
                      });
                    }}
                    options={catagory.map((x) => {
                      return { label: x.categoryName, value: x.id };
                    })}
                  />
                </div>
              </div>

            </div>
          </form>
        }

      </div >
    </div >
  );
};

export default EditContentSharing;
