import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import MainPost from "../../components/PostV2";
import InfiniteScroll from "react-infinite-scroll-component";
import MenuDrawer from "../../components/MenuDrawer";
import Drawer from "rc-drawer";
import ApiTranProfile from "../../api/ApiTranProfile";
import CSwl from "../../utils/alert";
import ProfileCardSearch from "../../components/ProfileCardSearch";
import { useTranslation } from "react-i18next";
import { destroyPage, useWindowUnloadEffect } from "../../utils";
import PostLiveSharing from "../../components/PostLiveSharing";
const initialState = {
    pageNumber: 1,
    pageSize: 10,
};

const SearchPage = (props) => {
    const [firstTime, setFirstTime] = useState(new Date());
    useWindowUnloadEffect(() => destroyPage({ page: "Search", firstTime: firstTime }), true);
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState(new URLSearchParams(props.location.search).get("q"));
    const [state, setstate] = useState(initialState);
    const [contents, setcontents] = useState("");
    const [profiles, setprofiles] = useState("");
    const userState = useSelector((state) => state.user);
    const history = useHistory();
    const [enterSearch, setenterSearch] = useState('');
    const [loadingMore, setloadingMore] = useState(false);
    useEffect(() => {
        setstate(initialState);
        const promise1 = getContentBysearch();
        const promise2 = getProfileBySearch();
        CSwl.SwalLoad();
        Promise.all([promise1, promise2]).then(result => {
            CSwl.SwalClose();
        })
        return () => { };
    }, [search]);

    // useEffect(() => {
    //     if (enterSearch.length > 0) {
    //         getContentBysearch();
    //     }
    //     return () => {
    //     }
    // }, [enterSearch])

    useEffect(() => {
        setSearch(new URLSearchParams(props.location.search).get("q"));
        return () => { };
    }, [props.location]);


    const getContentBysearch = async () => {
        const result = await ApiContent.getBySearch({ ...initialState, search: search });
        if (result.status == 200) {
            setcontents(result.data.data);
        }
    };

    const getProfileBySearch = async () => {
        const result = await ApiTranProfile.GetProfileBySearch({ ...initialState, search: search });
        if (result.status == 200) {
            setprofiles(result.data.data);
        }
    };


    const callBackComment = (result) => {
        try {
            const comment = result.data;
            console.log('comment::', comment)
            // const { data } = result;
            let newContents = { ...contents };
            let newContentsItem = newContents.items;

            const findIndex = newContentsItem.findIndex(x => x.id == comment.ctid);
            newContentsItem[findIndex].comments.unshift(comment);
            newContents.items = newContentsItem;
            setcontents(newContents);
        } catch (error) {
            console.log(error);
        }
    }

    const searchPost = () => {
        history.push(`/search?q=${search}`)
        setenterSearch(search);
    }

    function handleLoadMore() {
        if (loadingMore == false) {
            setloadingMore(true);
            setstate({ ...state, pageNumber: state.pageNumber + 1 })
        }
    }

    useEffect(() => {
        if (state.pageNumber != 1) {
            const promise1 = getContentLoadmore();
            Promise.all([promise1]).then(results => {
                setloadingMore(false);
            })
        }
        return () => {
        }
    }, [state])

    const getContentLoadmore = async () => {
        const result = await ApiContent.getBySearch({ ...state, search: search });
        if (result.status == 200) {
            setcontents({
                ...contents,
                items: [...contents.items, ...result.data.data.items]
            });
            console.log("loadmore::", result.data.data.items)
        }
    };



    return (
        <div id="search-page">
            <Drawer className="is-hidden-desktop is-hidden-tablet">
                <MenuDrawer data={[]} page="rank" header={t('menu.search')} />
            </Drawer>
            <Header />
            <div className="columns">
                <div className="column is-hidden-mobile  is-2 layer">
                    <Menu data={[]} page="rank" header={t('menu.search')} />
                </div>
                <div className="column middle-container is-9 layer2 mt-0">
                    <h2 className="fontH mb-2">{`${search ?? "ทั้งหมด"}`}</h2>
                    {/* section menu */}
                    {/* <div className="column is-2 layer my-3">
                    <Menu data={dataPro} page="first" />
                </div> */}
                    {/* section post */}
                    <div className="column">
                        <h2 className="text-lg mb-2">{`People ${`(${(profiles && profiles?.items.length) ?? 0})`}`}</h2>
                        <div className="ar" style={{ marginBottom: 15 }}></div>
                        {/* <div className="d-flex" style={{ maxWidth: '100%', overflow: 'auto', padding: !(profiles && profiles.items.length == 0) ? '20px 20px' : '0px' }}>

                        </div> */}
                        <div className="flex overflow-auto gap-2 mb-4">

                            <div className="flex-1">
                                {/* <div
                                    // to="/"
                                    className="has-text mr-6 is-flex is-align-items-center"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/firstPage/fi-rr-portrait1.png"
                                        }
                                        alt="logo1"
                                        width="24"
                                        height="24"
                                    />
                                    <strong className="m-3">{t('text.who_smes')}</strong>
                                </div> */}
                                <div className="flex overflow-auto gap-2">
                                    {profiles && profiles.items.map((d, index) => {
                                        let tagsArray = [];
                                        let smeCategoryArray = [];
                                        let specialistArray = [];
                                        if (d.tagJson && d.tagJson != "[]") {
                                            let tags = JSON.parse(d.tagJson);
                                            tagsArray = tags.map(x => x.Name);
                                        }
                                        if (d.smeCategory && d.smeCategory != "[]") {

                                            let smes = d.smeCategory.split(',');
                                            smeCategoryArray = smes;
                                        }
                                        if (d.specialistJson && d.specialistJson != "[]") {
                                            let tags = JSON.parse(d.specialistJson);
                                            specialistArray = tags.map(x => x.name);
                                        }
                                        return (
                                            <div className="column size-procard" key={index}>
                                                <ProfileCard
                                                    image={
                                                        d.image
                                                            ? process.env.REACT_APP_IMAGE + d.image
                                                            : process.env.PUBLIC_URL +
                                                            "/images/profilePage/Profile.png"
                                                    }
                                                    profile={d}
                                                    profileid={d.id}
                                                    name={d.firstNameEnglish + " " + d.lastNameEnglish}
                                                    depart={d.dept}
                                                    level={d.rankEng}
                                                    totalArticle={d.articleAmt}
                                                    totalFollow={d.followerAmt}
                                                    isFollow={d.isFollow}
                                                />
                                            </div>
                                            // <ProfileCardSearch
                                            //     profile={d}
                                            //     key={d.id}
                                            //     profileid={d.id}
                                            //     tagsArray={tagsArray}
                                            //     specialistArray={specialistArray}
                                            //     depart={d.dept}
                                            //     totalArticle={d.articleAmt}
                                            //     totalFollow={d.followerAmt}
                                            //     isFollow={d.isFollow}
                                            //     image={d.image
                                            //         ? process.env.REACT_APP_IMAGE + d.image
                                            //         : process.env.PUBLIC_URL +
                                            //         "/images/profilePage/Profile.png"}
                                            //     name={d.firstNameEnglish + " " + d.lastNameEnglish}
                                            //     email={d.email}
                                            //     smeCategory={d.smeCategory ? d.smeCategory.split(',') : []}
                                            // />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="flex-1">
                                {/* <div
                                    // to="/"
                                    className="has-text mr-6 is-flex is-align-items-center"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/firstPage/fi-rr-portrait1.png"
                                        }
                                        alt="logo1"
                                        width="24"
                                        height="24"
                                    />
                                    <strong className="m-3">{t('text.who_follow')}</strong>
                                </div> */}
                                {/* <div className="flex overflow-auto gap-2">

                                    {profiles && profiles.items.filter(x => !x.smeCategory).map((d, index) => {
                                        let tagsArray = [];
                                        let smeCategoryArray = [];
                                        let specialistArray = [];
                                        if (d.tagJson && d.tagJson != "[]") {
                                            let tags = JSON.parse(d.tagJson);
                                            tagsArray = tags.map(x => x.Name);
                                        }
                                        if (d.smeCategory && d.smeCategory != "[]") {

                                            let smes = d.smeCategory.split(',');
                                            smeCategoryArray = smes;
                                        }
                                        if (d.specialistJson && d.specialistJson != "[]") {
                                            let tags = JSON.parse(d.specialistJson);
                                            specialistArray = tags.map(x => x.name);
                                        }
                                        return (
                                            <ProfileCardSearch
                                                key={d.id}
                                                profileid={d.id}
                                                tagsArray={tagsArray}
                                                specialistArray={specialistArray}
                                                depart={d.dept}
                                                totalArticle={d.articleAmt}
                                                totalFollow={d.followerAmt}
                                                isFollow={d.isFollow}
                                                image={d.image
                                                    ? process.env.REACT_APP_IMAGE + d.image
                                                    : process.env.PUBLIC_URL +
                                                    "/images/profilePage/Profile.png"}
                                                name={d.firstNameEnglish + " " + d.lastNameEnglish}
                                                email={d.email}
                                                smeCategory={d.smeCategory ? d.smeCategory.split(',') : []}
                                            />
                                        );
                                    })}
                                </div> */}
                            </div>
                        </div>
                        {/* <h2 className="fontH mb-2">{`${"บทความ"} ${`(${contents.totalItems ?? 0})`}`}</h2> */}
                        <h2 className="text-lg mb-2">{`บทความ ${`(${contents.totalItems ?? 0})`}`}</h2>
                        {!(profiles && profiles.items.length == 0) && <div className="mb-5"></div>}


                        <div className="ar" style={{ marginBottom: 15 }}></div>
                        {contents &&
                            <InfiniteScroll
                                dataLength={contents.items.length}
                                next={() => handleLoadMore()}
                                hasMore={contents.totalPages > state.pageNumber}
                                loader={<h4>Loading...</h4>}
                            >
                                {contents &&
                                    contents.items.map((d, index) => {
                                        return (
                                            <div className="row fn ar" key={d.id}>
                                                {
                                                    d.cttype == "CONTENT" ? <MainPost
                                                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                                                        totalLike={d.ctlikeAmt}
                                                        isLike={d.isLike}
                                                        isBookmark={d.isBookmark}
                                                        item={d}
                                                        title={d.ctname}
                                                        comments={d.comments}
                                                        detail={d.ctdeschtml}
                                                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                                                        image={
                                                            d.imageProfile
                                                                ? process.env.REACT_APP_IMAGE + d.imageProfile
                                                                : process.env.PUBLIC_URL +
                                                                "/images/profilePage/Profile.png"
                                                        }
                                                    />
                                                        : <PostLiveSharing
                                                            stateOrg={[]}
                                                            time={ConvertDateShort(d.ctstartdate, "DD MMM YYYY HH:mm น.")}
                                                            totalLike={d.ctlikeAmt}
                                                            isLike={d.isLike}
                                                            isBookmark={d.isBookmark}
                                                            item={d}
                                                            title={d.ctname + " (LIVE SHARING)"}
                                                            comments={d.comments}
                                                            detail={d.ctdeschtml}
                                                            name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                                                            image={
                                                                d.imageProfile
                                                                    ? process.env.REACT_APP_IMAGE + d.imageProfile
                                                                    : process.env.PUBLIC_URL +
                                                                    "/images/profilePage/Profile.png"
                                                            }
                                                        />
                                                }
                                            </div>
                                        );
                                    })}
                            </InfiniteScroll>
                        }
                        {contents && contents.items == 0 && (
                            <div className="m-5">ไม่พบบทความ</div>
                        )}
                    </div>

                </div>
                {(enterSearch && contents.items == 0) &&
                    <div className="m-5">ไม่พบบทความ</div>
                }

            </div>

        </div>
    );
};

export default SearchPage;
