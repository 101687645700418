import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { WEB_IMAGE } from "../env";
import { useHistory } from 'react-router-dom'
import '../style/Header/Header.scss'
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "../authConfig";
import IconComponent from "./Icons";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { removeLocalStorage } from '../utils/localStorage'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Menu from "./Menu";
import DrawerComponent from "./Drawer";
import { useTranslation } from "react-i18next";
import { ChatBubbleBottomCenterIcon, PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid'
const myMsal = new PublicClientApplication(msalConfig);
const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const notifitionCount = useSelector((state) => state.count_notification);
  const chatCount = useSelector((state) => state.count_chat_notification);
  const isShareState = useSelector((state) => state.isShare);
  const [search, setsearch] = useState('');
  const history = useHistory();
  const { instance, accounts, inProgress } = useMsal();
  const [isProfile, setisProfile] = useState(false);
  const [isMenu, setisMenu] = useState(true)

  const searchPost = () => {
    window.location.href = `/search?q=${search}`;
  }

  const logout = async () => {
    const logoutRequest = {
      account: myMsal.getAccountByHomeId(accounts[0]?.homeAccountId),
      mainWindowRedirectUri: process.env.REACT_APP_URL
    }
    const result = await myMsal.logoutPopup(logoutRequest);
    await removeLocalStorage('token');
    localStorage.clear();
    sessionStorage.clear();
    dispatch({
      type: 'clear_all',
    });
    setTimeout(() => {
      history.push("/")
    }, 200);

  }

  return (
    <div id="header-com">
      <nav
        id="header-com"
        class="navbar bottom-shadow header-size"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="layer-c is-justify-content-space-between">
          <div className="logo-f">
            <div className="d-flex-center">
              {/* <div className="cursor-pointer hidden-desktop mr-5 mt-2" style={{ position: 'relative' }} onClick={(e) => {
              }}>
                <IconComponent
                  className="icon-20x ml-3" color="#333"
                  icon={faBars}
                />
                {isMenu && <HamburgurMenu userState={userState} history={history} logout={logout} />}
              </div> */}
              <Link className="img-logo" to="/home">
                <img
                  className="logo-image"
                  src={process.env.PUBLIC_URL + "/images/firstPage/logoSCGP.svg"}
                // width="204"
                // height="40"
                />
              </Link>

            </div>


            <div className="hidden-desktop">
              <div onClick={() => setisProfile(!isProfile)} className="cursor-pointer"
                style={{ position: "relative" }}>
                <figure className="image img-s img-p">
                  <img
                    alt="Placeholder_image"
                    class="is-rounded mage-cover"
                    src={userState.profile.domainPath ?
                      process.env.REACT_APP_IMAGE + userState.profile.domainPath
                      : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
                    }
                    style={{ height: 40 }}
                  />
                </figure>
                {isProfile &&
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="spam-container">
                    <ul>

                      <li className="cursor-pointer" onClick={(e) => {
                        history.push('/profile')
                      }}>{t('menu.profile')}</li>
                      <li className="cursor-pointer" onClick={(e) => {
                        history.push('/setting')
                      }}>{t('menu.setting')}</li>
                      <li className="cursor-pointer" onClick={(e) => {
                        logout();
                      }}>{t('text.logout')}</li>
                    </ul>
                  </div>
                }
              </div>

            </div>

          </div>

          <div className="header-item">
            <Link to="/home" className="has-text posi-header">
              <div className="row">
                <img src={process.env.PUBLIC_URL + "/images/firstPage/Home_logo.png"} />
              </div>
              <div className="row">
                <span className="title">{t('menu.home')}</span>
              </div>
            </Link>
            <Link to="/liveSharing" className="has-text posi-header">
              <div className="d-flex-center flex-column" style={{ position: 'relative' }}>
                <div className="row">
                  <img className="h-6 w-6" src={process.env.PUBLIC_URL + "/images/Menu/live.png"} />
                </div>
                <div className="row">
                  <span className="title">{'Live Sharing'}</span>
                </div>
                {isShareState && <span style={{ position: 'absolute', right: -10, top: -5, padding: 5, borderRadius: 5, fontSize: 12 }} class="tag is-danger">LIVE</span>}
              </div>

            </Link>
            <Link to="/explore" className="has-text posi-header">
              <div className="row">
                <img src={process.env.PUBLIC_URL + "/images/firstPage/Explore_logo.png"} />
              </div>
              <div className="row">
                <span className="title">{t('menu.explore')}</span>
              </div>
            </Link>
            <Link to="/popular" className="has-text posi-header">
              <div className="row">
                <img src={process.env.PUBLIC_URL + "/images/firstPage/Popular_logo.png"} />
              </div>
              <div className="row">
                <span className="title">{t('menu.popular')}</span>
              </div>
            </Link>
            <Link to="/notification" className="has-text posi-header1">
              <div className="d-flex-center flex-column" style={{ position: 'relative' }}>
                <div className="row">
                  <img src={process.env.PUBLIC_URL + "/images/firstPage/Notification_logo.png"} />
                </div>
                <div className="row">
                  <span className="title">{t('menu.notification')}</span>
                </div>
                {notifitionCount > 0 && <span style={{ position: 'absolute', right: -5, top: -5, padding: 5, borderRadius: 5, fontSize: 12 }} class="tag is-danger">{notifitionCount}</span>}
              </div>
            </Link>
            <Link to="/chat" className="has-text posi-header1">
              <div className="d-flex-center flex-column" style={{ position: 'relative' }}>
                <div className="row">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                  </svg>
                </div>
                <div className="row">
                  <span className="title">{t('menu.chat')}</span>
                </div>
                {chatCount > 0 && <span style={{ position: 'absolute', right: -5, top: -5, padding: 5, borderRadius: 5, fontSize: 12 }} class="tag is-danger">{chatCount}</span>}
              </div>
            </Link>
          </div>
          <div className="hidden navbar-end ml-0">
            <div className="is-flex is-align-items-center">
              <input value={search} onChange={(e) => {
                setsearch(e.target.value);
              }} onKeyDown={(e) => {
                if (e.key == "Enter") {
                  if (!search.length == 0) {
                    searchPost();
                  }
                }
              }} className="input is-rounded has-background-light search hidden-search" type="text" placeholder={t('menu.search')} />
              <div onClick={() => setisProfile(!isProfile)} className="cursor-pointer"
                style={{ position: "relative" }}>
                <figure className="image img-s img-p">
                  <img
                    alt="Placeholder_image"
                    class="is-rounded image-cover"
                    src={userState.profile.domainPath ? process.env.REACT_APP_IMAGE + userState.profile.domainPath : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
                    style={{ height: 40 }}
                  />
                </figure>
                {isProfile &&
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="spam-container">
                    <ul>

                      <li className="cursor-pointer" onClick={(e) => {
                        history.push('/profile')
                      }}>{t('menu.profile')}</li>
                      <li className="cursor-pointer" onClick={(e) => {
                        history.push('/setting')
                      }}>{t('menu.setting')}</li>
                      <li className="cursor-pointer" onClick={(e) => {
                        logout();
                      }}>{t('text.logout')}</li>
                    </ul>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </nav >
    </div >
  );
};


export default Header;
