import React from 'react'
import { Link } from 'react-router-dom'
import Cardlist from '../../../components/Cardlist'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import './index.scss'
import { useTranslation } from "react-i18next";
const ContentPlan = () => {
  const { t, i18n } = useTranslation();
  const friend =[{
    name:'Savannah Nguyen'
  },
  {
    name:'Jacob Jones'
  },
  {
    name:'Janis Joplin'
  }
]
  const trend = [{
    name:'#JessieMueller',
    like: 5000
  },
  {
    name:'#ThelmaJones',
    like: 4000
  },
  {
    name:'#LoisPearson',
    like: 2000
  }]
  const dataplan = [{
    name: 'Brooklyn Simmons',
    depart: 'Infarstructure',
    topic: 'บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก',
    detail: 'For people stressed or intimidated by fitness culture',
    date: 'Jan 24 ● 12:42'
  },{
    name: 'Brooklyn Simmons',
    depart: 'Infarstructure',
    topic: 'บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก',
    detail: 'For people stressed or intimidated by fitness culture',
    date: 'Jan 24 ● 12:42'
  },{
    name: 'Brooklyn Simmons',
    depart: 'Infarstructure',
    topic: 'บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก',
    detail: 'For people stressed or intimidated by fitness culture',
    date: 'Jan 24 ● 12:42'
  }]
  return (
    <div id="ContentPlan" >
      <Header />
      <div className="columns" style={{height:'max-content'}}>
        <div className="column is-2 my-3 layer">
          <Menu page="rank"/>
        </div>
        <div className="column is-7 border-div1">
          <div className="is-flex is-align-items-center m-4">
            <figure className="image mr-3">
              <img src={process.env.PUBLIC_URL + "/images/content/IconTime.png"} alt="Icon"/>
            </figure>
            <p className="title-h1">กำหนดเวลา</p>
          </div>
          <hr className="hr-size1"/>
          <div className="layout">
            {dataplan.map((d,index) => {
              return (
                <div className="pb-6" key={index}>
                  <div className="is-flex is-align-items-center">
                    <figure className="image">
                      <img src={process.env.PUBLIC_URL + "/images/content/profile.png"} />
                    </figure>
                    <p className="subtitle-p2 px-2">{d.name}</p>  
                    <p className="subtitle-p1">in</p>
                    <p className="subtitle-p2 px-2">{d.depart}</p>  
                  </div>
                  <div className="is-flex is-justify-content-space-between">
                    <div className="is-flex is-flex-direction-column  size-content mt-3">
                      <p className="title-h">{d.topic}</p>
                      <p className="subtitle-p4 pt-2">{d.detail}</p>
                      <p className="subtitle-p5 pt-3">{d.date}</p>  
                    </div>
                    <figure className="image">
                      <img src={process.env.PUBLIC_URL + "/images/content/img1.png"} />
                    </figure>
                  </div>
                </div>
              )
            })}
          </div>
          <hr className="hr-size1"/>
        </div>

        <div className="column is-3 pt-5">
          <div className="mt-3">
            <Cardlist title={t('text.trending')} data={trend}/>
          </div>
          <div className="mt-3 mr-6">
            <Cardlist title={t('text.following')} data={friend}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentPlan
