import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ApiContent from "../api/ApiContent";
import ApiMtGdcemployee from "../api/ApiMtGdcemployee";
import CSwl from "../utils/alert";
import IconComponent from "./Icons";
import {
  faEllipsisH,
  faBookmark as faBookmarkSolid,
} from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import "./PostV2.scss";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import CTable from "./CTable/CTable";
import { useTranslation } from "react-i18next";
const MainPost = ({
  image,
  name,
  title,
  detail,
  page,
  item,
  isLike,
  isBookmark,
  totalLike,
  time,
  mode = "normal",
  onchange = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const [imageFirst, setimageFirst] = useState("");
  const [detailRemoveImage, setdetailRemoveImage] = useState(detail);
  const [like, setLike] = useState(totalLike);
  const [pIsLike, setPisLike] = useState(isLike);
  const [pIsBookMark, setPisBookmark] = useState(isBookmark);
  const [tags, settags] = useState([]);
  const [spam, setspam] = useState(false);
  const [options, setOptions] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [isSmeModal, setisSmeModal] = useState(false);
  const [spamValue, setspamValue] = useState("spam");
  const [spamNote, setspamNote] = useState("");
  const [reason, setReason] = useState("");
  const [smeGroup, setSmeGroup] = useState([]);
  const [isCheckAllGdc, setIsCheckAllGdc] = useState(false);
  const [isRemarkModal, setIsRemarkModal] = useState(false);

  const history = useHistory();
  const func_movePage = async () => {
    history.push(`editcontent?c=${item.id}`);
  };

  const func_tag = async (e, item) => {
    e.stopPropagation();
    history.push(`/tag?q=${item}`);
  };

  const func_remove = async () => {
    const result = await CSwl.SwalConfirm("คุณต้องการจะลบบทความนี้");
    if (result.isConfirmed) {
      const result = await ApiContent.Delete(item.id);
      if (result.status == 200) {
        await CSwl.SwalSuccess("ลบสำเร็จ");
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (detail.includes("<img")) {
      var str = detail;
      var elem = document.createElement("div");
      elem.style.display = "none";
      document.body.appendChild(elem);
      elem.innerHTML = str;
      setimageFirst(elem.querySelector("img").src);
      var content = detail.replace(/<img[^>]*>/g, "");
      setdetailRemoveImage(content);
    }
    if (item.cttagtJson != "[]") {
      let tags = JSON.parse(item.cttagtJson);
      settags(tags.map((x) => x.Name));
    }
    // if (item.ctcatagoryJson != "[]") {
    //   let catagory = JSON.parse(item.ctcatagoryJson);
    //   setcatalogs(catagory.map(x => x.Name));
    // }
    return () => {};
  }, []);

  return (
    <>
      <div id="subpost" class="columns block is-relative">
        {/*renderEditButton()*/}
        <div class={`column ${imageFirst ? " is-8" : ""} pb-0`}>
          <div
            id="post-share-pdf"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/contentPreview?id=${item.id}`);
            }}
          >
            <div className="d-flex is-align-items-center">
              <div className="d-flex is-align-items-center flex-1">
                <figure className="image is-32x32">
                  <img
                    // alt={'image_profile'}
                    className="is-rounded"
                    src={image}
                  />
                </figure>
                <span className="has-text-weight-bold is-size-7 ml-2">
                  {name}
                </span>
              </div>
            </div>
            <div>
              <div className="mt-3 ml-1">
                <h2 className="has-text-weight-bold is-size-4">{title}</h2>
                <h3
                  className="description-short"
                  dangerouslySetInnerHTML={{ __html: detailRemoveImage }}
                ></h3>

                {item.ctstatus == "W" && (
                  <h3 className="description-short">สถานะ : รออนุมัติ</h3>
                )}

                {item.ctstatus == "R" && (
                  <h3 className="description-short">สถานะ : ไม่อนุมัติ</h3>
                )}

                {item.ctstatus == "S" && (
                  <h3 className="description-short">สถานะ : แบน</h3>
                )}
                {item.remark != null &&
                  item.remark != undefined &&
                  item.remark != "" && (
                    <span style={{ color: "red" }}>
                      เหตุผลการไม่อนุมัติ : {item.remark}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div
            className="mt-2 d-flex flex-wrap"
            style={{ marginLeft: "-1rem" }}
          >
            {tags &&
              tags.map((item, index) => {
                if (index > 4) {
                  return false;
                }
                return (
                  <div
                    onClick={(e) => {
                      func_tag(e, item);
                    }}
                    className="pl-4 is-flex is-align-items-center cursor-pointer"
                  >
                    <span className="is-size-7 tag">#{item}</span>
                  </div>
                );
              })}
          </div>

          <div className="mt-3 d-flex flex-wrap">
            <div className="pr-2">
              <button
                onClick={() => {
                  func_movePage();
                }}
                className="button is-link is-rounded size-fontonPost"
              >
                <div className="font-button">{t('text.edit')}</div>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  func_remove();
                }}
                className="button is-rounded ml-2 bg-danger size-fontonPost"
              >
                <div>{t('text.delete')}</div>
              </button>
            </div>
            {/*<div>
              <button
                className="button is-link is-rounded size-fontonPost"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRemarkModal(true);
                }}
              >
                <div className="font-button">เหตุผล</div>
              </button>
              </div>*/}
          </div>
        </div>
        {imageFirst && (
          <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
            <img
              src={imageFirst}
              alt="image_content"
              class="size-img"
              width="160"
              height="160"
            ></img>
          </div>
        )}
      </div>
    </>
  );
};

export default MainPost;
