import React, { useEffect, useState } from "react";
import "../style/Menu/Menu.scss";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiContent from "../api/ApiContent";
import { useTranslation } from "react-i18next";
import { Popover } from '@headlessui/react'
import { ChevronDownIcon, EllipsisHorizontalIcon, PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Autosuggest from "react-autosuggest";
import ApiTranProfile from "../api/ApiTranProfile";
import CSwl from "../utils/alert";
import ApiChat from "../api/ApiChat";
const MenuChat = ({ page, subpage, header, profileFollow = [], setRoomPrivate = () => { }, roomList = [], groupList = [], getChatGroupList, getChatRoomList }) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const userState = useSelector((state) => state.user);
    const count_login = useSelector((state) => state.count_login);
    const [open, setopen] = useState(false);
    const [openGroup, setopenGroup] = useState(true);
    const [openChat, setopenChat] = useState(true);
    const [isModal, setisModal] = useState();

    const [suggestionUser, setsuggestionUser] = useState('');
    const [suggestionsUser, setSuggestionsUser] = useState([]);
    const [suggestionSelectUser, setsuggestionsSelectUser] = useState([]);

    const [groupName, setgroupName] = useState('');

    useEffect(() => {

    }, []);


    const getSuggestionsUser = async (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        if (inputLength < 3) return [];
        else {
            try {
                const content = await ApiTranProfile.GetAutocomplete(inputValue);
                if (content.status == 200) {
                    const { data } = content;
                    return data.data;
                }
            } catch (error) {
                return [];
            }
        }
    };

    const getSuggestionValueUser = (suggestion) => {
        const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
        if (checked) {
        } else {
            console.log(suggestion)
            setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
        }
        //setsuggestionsSelectUser(suggestion);
        return "";
    };

    const renderSuggestionUser = suggestion => (
        <div style={{ padding: 10, border: '1px solid #efefef' }}>
            {suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
        </div>
    );

    const onSuggestionsFetchRequestedUser = async ({ value }) => {

        setSuggestionsUser(await getSuggestionsUser(value));
    };

    const onSuggestionsClearRequestedUser = () => {
        setSuggestionsUser([]);
    };

    const onChangeSuggestionUser = (event, { newValue }) => {
        console.log(suggestionSelectUser)
        setsuggestionUser(newValue);
    };

    const deletePrivateRoom = async (roomId) => {
        const deleteConfirm = await CSwl.SwalConfirmBody(`การลบประวัติข้อความของคุณจะไม่สามารถเรียกคืนได้ คุณจะไม่ สามารถดูข้อความก่อนหน้าได้ แต่จะยังคงได้รับข้อความใหม่ใน แชทนี้`);
        if (deleteConfirm.isConfirmed) {
            const deleteResponse = await ApiChat.deleteRoom(roomId)
            if (deleteResponse.status == 200) {
                await getChatRoomList();
                setRoomPrivate({
                    sendToUserId: 0
                })
            }

        }
    }

    const deletePrivateGroup = async (item) => {
        if (item.userId == userState.employee.userId) {
            const deleteConfirm = await CSwl.SwalConfirmBody(`การลบประวัติข้อความของคุณจะไม่สามารถเรียกคืนได้ คุณจะไม่ สามารถดูข้อความก่อนหน้าได้ แต่จะยังคงได้รับข้อความใหม่ใน แชทนี้`);
            if (deleteConfirm.isConfirmed) {
                const deleteResponse = await ApiChat.deleteGroup(item.roomId)
                if (deleteResponse.status == 200) {
                    await getChatGroupList();
                    setRoomPrivate({
                        sendToUserId: 0
                    })
                }
            }
        } else {
            const deleteConfirm = await CSwl.SwalConfirmBody(`คุณต้องการออกจากกลุ่มหรือไม่ ?`);
            if (deleteConfirm.isConfirmed) {
                const deleteResponse = await ApiChat.leaveGroup(item.roomId, userState.employee.userId)
                if (deleteResponse.status == 200) {
                    await getChatGroupList();
                    setRoomPrivate({
                        sendToUserId: 0
                    })
                }
            }
        }
    }

    const getChatRoom = async (data) => {
        CSwl.SwalLoad();
        try {
            const roomResult = await ApiChat.getRoom(data.userID, data.sendToUserId);
            if (roomResult.status == 200) {
                if (roomResult.data?.data) {
                    setRoomPrivate({
                        userId: userState.employee.userId,
                        sendToUserId: data.sendToUserId,
                        toUserId: data.userID,
                        roomId: roomResult.data?.data.id,
                        profileID:data.profileID
                    })
                } else {
                    setRoomPrivate({
                        userId: userState.employee.userId,
                        sendToUserId: data.sendToUserId,
                        toUserId: data.userID,
                        roomId: 0,
                        profileID:data.profileID
                    })
                }

            }
        } catch (error) {

        }
        CSwl.SwalClose()
    }

    const sendGroup = async () => {

        if (suggestionSelectUser.length == 0) {
            CSwl.SwalErr("กรุณาระบุสมาชิกในกลุ่ม");
            return false;
        } else {
            var userId = suggestionSelectUser.map(x => x.employee.userId).join(',');
            const model = {
                userId: userId,
                name: groupName,
                createBy: userState.employee.userId
            }
            const groupResponse = await ApiChat.createGroup(model);

            if (groupResponse.status == 200) {
                const { data } = groupResponse;
                setsuggestionsSelectUser([]);
                setgroupName('');
                setisModal(false);
                await getChatGroupList();
            }
        }


    }


    return (
        <>
            <div id="Menu-chat" style={{ position: 'block' }}>
                <div>
                    <div>
                        <p className="fontH">{header}</p>
                    </div>
                </div>
                <div className="row ml-4 hidden-b !flex flex-column gap-y-4 mt-3">
                    <div>
                        <Popover className="relative">
                            {/* {({ open }) => ( */}
                            <>
                                <div className="flex">
                                    <Popover.Button className={"flex-1"} onClick={() => {
                                        setopenGroup(!openGroup)
                                    }}>

                                        <div className="flex gap-x-2 whitespace-nowrap">
                                            <ChevronDownIcon className={openGroup ? 'rotate-180 transform h-6 w-6' : 'h-6 w-6'} />
                                            <span className="font-bold">{t("text.group")}</span>

                                        </div>
                                    </Popover.Button>
                                    <PlusIcon onClick={(e) => {
                                        setisModal(true)
                                    }} className="h-6 w-6 cursor-pointer" />
                                </div>

                                {
                                    openGroup && <Popover.Panel static={true} className="">
                                        {
                                            groupList.map((x, i) => {
                                                return <GroupListComponent deleteMessage={deletePrivateGroup} setRoomPrivate={setRoomPrivate} item={x} i={i} />
                                            })
                                        }
                                        {/* <ChatList
                                            setRoomPrivate={setRoomPrivate}
                                            title={t('text.following')}
                                            data={roomList.map((x) => {
                                                return {
                                                    name: x.fullname,
                                                    image: x.image
                                                        ? process.env.REACT_APP_IMAGE + x.image
                                                        : process.env.PUBLIC_URL +
                                                        "/images/profilePage/Profile.png",
                                                    profileID: x.profileID,
                                                    message: x.message,
                                                    roomId: x.message.roomId,
                                                    item: x
                                                };
                                            })}
                                            deleteMessage={deletePrivateRoom}
                                            topage={"unfollow"}
                                        /> */}
                                    </Popover.Panel>
                                }

                            </>
                            {/* )} */}
                        </Popover>
                    </div>
                    <div>
                        <Popover className="relative">
                            {/* {({ open }) => ( */}
                            <>
                                <Popover.Button onClick={() => {
                                    setopenChat(!openChat)
                                }}>

                                    <div className="flex gap-x-2 whitespace-nowrap">
                                        <ChevronDownIcon className={openChat ? 'rotate-180 transform h-6 w-6' : 'h-6 w-6'} />
                                        <span className="font-bold">{t("menu.chat")}</span>
                                    </div>
                                </Popover.Button>
                                {
                                    openChat && <Popover.Panel static={true} className="">
                                        <ChatList
                                            setRoomPrivate={setRoomPrivate}
                                            title={t('text.following')}
                                            data={roomList.map((x) => {
                                                return {
                                                    name: x.fullname,
                                                    image: x.image
                                                        ? process.env.REACT_APP_IMAGE + x.image
                                                        : process.env.PUBLIC_URL +
                                                        "/images/profilePage/Profile.png",
                                                    profileID: x.profileID,
                                                    message: x.message,
                                                    roomId: x.message.roomId,
                                                    profile: x.profile,
                                                    employee: x.employee,
                                                    item: x
                                                };
                                            })}
                                            deleteMessage={deletePrivateRoom}
                                            topage={"unfollow"}
                                        />
                                    </Popover.Panel>
                                }

                            </>
                            {/* )} */}
                        </Popover>
                    </div>
                    <div>
                        <Popover className="relative">
                            {/* {({ open }) => ( */}
                            <>
                                <Popover.Button onClick={() => {
                                    setopen(!open)
                                }}>

                                    <div className="flex gap-x-2 whitespace-nowrap">
                                        <ChevronDownIcon className={open ? 'rotate-180 transform h-6 w-6' : 'h-6 w-6'} />
                                        <span className="font-bold">{t("text.following")}</span>
                                    </div>
                                </Popover.Button>
                                {
                                    open && <Popover.Panel static={true} className="">
                                        <ProfileList
                                            setRoomPrivate={setRoomPrivate}
                                            title={t('text.following')}
                                            getChatRoom={getChatRoom}
                                            data={profileFollow.map((x) => {
                                                return {
                                                    name: x.fullNameEng,
                                                    image: x.image
                                                        ? process.env.REACT_APP_IMAGE + x.image
                                                        : process.env.PUBLIC_URL +
                                                        "/images/profilePage/Profile.png",
                                                    profileID: x.profileID,
                                                    item: x
                                                };
                                            })}
                                            topage={"unfollow"}
                                        />
                                    </Popover.Panel>
                                }

                            </>
                            {/* )} */}
                        </Popover>
                    </div>
                    {/* <div>ยอดจำนวนผู้เข้าชม <b>({count_login})</b></div> */}
                </div>

            </div>
            <div class={`modal modal ${isModal ? "d-flex" : ""}`}>
                <div class="modal-background"></div>
                <div class="modal-content bg-white p-5 flex flex-column gap-y-4" style={{ borderRadius: 5 }}>
                    <div
                        className="flex justify-between items-center"
                    >
                        <span className="font-bold text-lg">{t("text.createGroup")}</span>
                        <XMarkIcon onClick={() => {
                            setisModal(false)
                        }} className="w-6 h-6 cursor-pointer" />
                    </div>
                    <div className="flex flex-column gap-y-2">
                        <span className="font-bold">{t("text.groupName")}</span>
                        <input value={groupName} onChange={(e) => {
                            setgroupName(e.target.value);
                        }} className="input size-input" placeholder="#ชื่อกลุ่ม" />
                    </div>
                    <div className="flex flex-column gap-y-2">
                        <span className="font-bold">{t("text.searchMember")}</span>
                        {/* <input className="input size-input" placeholder="#ชื่อกลุ่ม" /> */}
                        <Autosuggest
                            suggestions={suggestionsUser}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequestedUser}
                            onSuggestionsClearRequested={onSuggestionsClearRequestedUser}
                            getSuggestionValue={getSuggestionValueUser}
                            renderSuggestion={renderSuggestionUser}
                            inputProps={{
                                placeholder: t('ค้นหา'),
                                value: suggestionUser,
                                onChange: onChangeSuggestionUser,
                                className: 'input'
                            }
                            }
                        />
                    </div>
                    <div className="flex flex-column gap-y-2">
                        <span className="font-bold">{t("text.memberGroup")}</span>
                        {
                            suggestionSelectUser.map((item, index) => {
                                return <div className="flex-1 relative" key={"userSelect-" + index}>
                                    <div className="flex justify-between items-center">
                                        <div className="flex justify-between items-center gap-x-3">
                                            <figure className="image is-48x48">
                                                <img className="is-rounded image-cover"
                                                    style={{ width: '100%', height: '100%' }}
                                                    src={item.image
                                                        ? process.env.REACT_APP_IMAGE + item.image
                                                        : process.env.PUBLIC_URL +
                                                        "/images/profilePage/Profile.png"} />
                                            </figure>
                                            <span>
                                                {item.firstNameEnglish + " " + item.lastNameEnglish}
                                            </span>
                                        </div>
                                        <XMarkIcon onClick={() => {
                                            const x = suggestionSelectUser.splice(index, 1);
                                            setSuggestionsUser(x);
                                        }} className="w-6 h-6 cursor-pointer" />
                                    </div>
                                    {
                                        item.smeCategory && <div className="bg-[#f97316] px-1 absolute top-[-5px] left-7">
                                            <span className="text-white text-xs">SMEs</span>
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className="flex justify-end gap-x-4 items-center">
                        <span className="cursor-pointer" onClick={() => {
                            setisModal(false)
                        }}>{t("menu.cancel")}</span>
                        <div onClick={() => sendGroup()} className="bg-[#3273DC] text-[#FFF] px-4 py-2 rounded-lg">
                            <span>สร้างกลุ่ม</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const GroupListComponent = ({ item, i, setRoomPrivate, deleteMessage = () => { } }) => {
    const [Style, setStyle] = useState({ display: 'none' });
    return <div onMouseEnter={e => {
        setStyle({ display: 'block' });
    }}
        onMouseLeave={e => {
            setStyle({ display: 'none' })
        }} key={'group-' + i}
        onClick={() => {
            setRoomPrivate({
                profiles: item.profiles,
                roomId: item.roomId,
                type: "group"
            })
        }} className="flex pl-2 relative flex-1 mt-3 px-4 cursor-pointer">
        <span className="font-bold w-60 break-words">{"# " + item.fullname + ` (${item.profiles.length})`}</span>
        <div style={Style} onClick={(e) => {
            e.stopPropagation();
            deleteMessage(item);
        }} className="absolute right-0 top-0 bg-whire px-2 bg-white">
            <TrashIcon color="red" className="w-5 w-5" />
        </div>
    </div>
}

const ProfileList = ({ title, data, topage = "", setRoomPrivate = () => { }, getChatRoom }) => {
    const datas = data;
    const userState = useSelector((state) => state.user);


    return (
        <div>
            {datas.map((d, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <ul className="">
                            <li className="">
                                <li className="is-flex">
                                    <Link onClick={() => {
                                        //console.log(d)
                                        // setRoomPrivate({
                                        //     userId: d.item.userID
                                        // })
                                        console.log("dddd", d)
                                        getChatRoom({
                                            sendToUserId: d.item.userID,
                                            image: d.image,
                                            userID: userState.employee.userId,
                                            profileID: d.profileID
                                        })
                                    }} className="is-flex cursor-pointer">
                                        <figure className="image is-48x48">
                                            <img
                                                // className="is-rounded image-cover"
                                                className={`is-rounded image-cover ${d.item.rankEng == "SMEs" ? `border-[#f97316] border-4` : ""}`}
                                                style={{ width: '100%', height: '100%' }}
                                                src={d.image} />
                                        </figure>
                                        <p className="is-flex is-align-items-center has-text-black has-text-weight-bold pl-2">
                                            {d.name}
                                        </p>
                                    </Link>
                                </li>
                            </li>
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

const ChatList = ({ title, data, topage = "", setRoomPrivate = () => { }, deleteMessage = () => { } }) => {
    const datas = data;
    const [Style, setStyle] = useState({ display: 'none' });
    return (
        <div>
            {datas.map((d, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <ul className="">
                            <li className="">
                                <li onMouseEnter={e => {
                                    setStyle({ display: 'block' });
                                }}
                                    onMouseLeave={e => {
                                        setStyle({ display: 'none' })
                                    }} className="is-flex">
                                    <div onClick={() => {
                                        console.log(d)
                                        setRoomPrivate({
                                            sendToUserId: d.profileID,
                                            image: d.image,
                                            roomId: d.roomId,
                                            toUserId: d.item.userId
                                        })
                                    }} className="flex flex-1 cursor-pointer">
                                        <figure className="image w-[48px] h-[48px]">
                                            <img
                                                className={`is-rounded image-cover ${d.employee?.smeCategory ? `border-[#f97316] border-4` : ""}`}
                                                //className="is-rounded image-cover"
                                                style={{ width: '48px', height: '48px' }}
                                                src={d.image} />
                                        </figure>
                                        <div className="flex flex-column pl-2 relative flex-1">
                                            <p className="is-flex is-align-items-center has-text-black has-text-weight-bold ">
                                                {d.name}
                                            </p>
                                            <div className="flex gap-x-2">
                                                <span className="text-[#ababab]" style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: 150
                                                }}>{d.message.type == "image"
                                                    ? "ได้ส่งรูปภาพ" : (d.message.type == "filedoc" ? "ได้ส่งไฟล์" : d.message.message)

                                                    }</span>
                                                {
                                                    d.message.countNotRead > 0 && <span className="px-2 py-1 text-white rounded text-xs is-danger tag">{d.message.countNotRead}</span>
                                                }
                                            </div>
                                            <div style={Style} onClick={(e) => {
                                                e.stopPropagation();
                                                deleteMessage(d.roomId);
                                            }} className="absolute right-0 top-0 bg-whire px-2 bg-white">
                                                <TrashIcon color="red" className="w-5 w-5" />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </li>
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default MenuChat;
