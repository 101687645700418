import React from "react";
import "../style/Cardlist/Cardlist.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const ListTrend = ({ idx, name, like, image = "", tolink = "" }) => {
  const history = useHistory();
  return (
    <div id="Cardlist-com">
      <ul className="list-group list-group-flush">
        {like !== undefined ? (
          <li className="list-group-item">
            <h1
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(tolink);
              }}
            >
              {name}
            </h1>
            <p>{like}</p>
            <hr className="hr-s" />
          </li>
        ) : (
          <li className="list-group-item is-align-item-center">
            <li className="is-flex">
              <div to="/profilefollow" className="is-flex">
                <figure className="image is-48x48">
                  <img className="is-rounded image-cover"
                    style={{ width: '100%', height: '100%' }}
                    src={image} />
                </figure>
                <p className="is-flex is-align-items-center has-text-black has-text-weight-bold pl-2">
                  {name}
                </p>
              </div>
            </li>
            <hr className="hr-s" />
          </li>
        )}
      </ul>
    </div>
  );
};

export default ListTrend;
