import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiNotification extends Component {
    static getHistory = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Notification/History`,
            method: "post",
            data: data
        });
        return result;
    };

    static read = async (id) => {
        await setHeaderAuth();
        const result = await axios({
            url: `Notification/Read/${id}`,
            method: "get",
        });
        return result;
    };

    static count = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `Notification`,
            method: "get",
        });
        return result;
    };
}

export default ApiNotification;
