import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ApiNotification from "../api/ApiNotification";
import '../style/Notification/index.scss';

const CardNotification = ({ item, onRead, message, time, image }) => {
    const history = useHistory();

    return <div className={`card mb-2 ${item.isRead == 1 ? "" : "noti-notread"}`}>
        <div class="card-content">
            <div class="media">
                <div class="media-left">
                    <figure class="image is-48x48">
                        <img
                            alt={'image_profile'}
                            className="is-rounded"
                            src={image}
                        />
                    </figure>
                </div>
                {/* <Link to={`/contentDetail?id=${item.idRelate}`}> */}
                <div onClick={() => onRead(item)} className="content cursor-pointer">
                    <span className="is-link">{message}</span>
                    <br />
                    <small style={{ color: 'hsl(0, 0%, 71%)' }} className="is-light">{time}</small>
                </div>
                {/* </Link> */}
            </div>
        </div>
    </div>
}

export default CardNotification;