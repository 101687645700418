// eslint-disable-next-line no-undef
const InitialState = {
    "id": 0,
    "profileid": 0,
    "ctrefno": "",
    "cttrandate": new Date(),
    "ctpostdate": new Date(),
    "ctname": "",
    "ctdeschtml": "",
    "ctlikeAmt": 0,
    "ctcommentAmt": 0,
    "ctstatus": "A",
    "ctcatagoryid": "",
    "ctcatagoryJson": [],
    "cttagid": "",
    "cttagtJson": [],
    "ctfavProfileJson": "",
    "isVerify": 0,
    "isSpecific": 0,
    "bu": [],
    "subbu": [],
    "company": [],
    "division": [],
    "sub_division": [],
    "department": [],
    "sub_department": [],
    "section": [],
    "competency": [],
}
export default InitialState;