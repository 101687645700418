import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApiNotification from '../api/ApiNotification';

function ScrollToTop({ history }) {
    const dispatch = useDispatch();
    const getNotification = async () => {
        const notificationCount = await ApiNotification.count();
        if (notificationCount.status == 200) {
            const { data, logsLogin, isShare, chatNotification } = notificationCount.data;
            dispatch({ type: "set_user", count_notification: data, count_login: logsLogin, isShare: isShare, count_chat_notification: chatNotification });
        }
    };

    useEffect(() => {

        const unlisten = history.listen(() => {
            getNotification();
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
}

export default withRouter(ScrollToTop);