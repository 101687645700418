import React, { useEffect, useState } from 'react'
import "./index.scss";
import Header from '../../../components/Header'
import Post from '../../../components/Post'
import Cardlist from '../../../components/Cardlist'
import PostCard from '../../../components/PostCard';
import ApiContentDetail from '../../../api/ApiContentDetail';
import { ConvertDateShort } from '../../../utils/date';
import CardTaglist from "../../../components/CardTaglist";
import { WEB_IMAGE } from '../../../env';
import { useSelector } from 'react-redux';
import ApiMtTag from '../../../api/ApiTag';
import ApiFollow from '../../../api/ApiFollow';
import MainPost from '../../../components/PostV2';
import PostDetailPreview from '../../../components/PostBackendPreview';
import CSwl from '../../../utils/alert';

const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const ContentPreview = (props) => {
  const userState = useSelector((state) => state.user);
  const contentId = new URLSearchParams(props.location.search).get(
    'id'
  );

  const friend = [{
    name: 'Savannah Nguyen'
  },
  {
    name: 'Jacob Jones'
  },
  {
    name: 'Janis Joplin'
  }
  ]
  const dataPost = [{
    name: 'Annette Black',
    time: 'Jan 18',
    title: 'ถ้าไปรษณีย์ไทย อยู่ในตลาดหลักทรัพย์ จะใหญ่แค่ไหน',
    detail: 'แม้ว่าห้างสรรพสินค้า จะเป็นสถานที่ที่คนไทยคุ้นเคยกันมานานหลายสิบปีแต่ ซีบีอาร์อี บริษัทที่ปรึกษาด้าน อสังหาริมทรัพย์ชั้นนำ ได้เห็นถึงความนิยมที่ลดลง และมีการเติบโตเพียงเล็กน้อย โดยเฉพาะในปี 2563 ที่โควิด 19 ส่งผลกระทบโดยตรงต่อธุรกิจค้าปลีก',
    subdetail: 'ซีบีอาร์อี เชื่อว่า นอกจากจะต้องมีการปรับตัวให้เข้ากับอีคอมเมิร์ช และสอดคล้องกับพฤติกรรมของผู้บริโภคที่เปลี่ยนแปลงไป <br><br> ห้างสรรพสินค้าในปี 2564 จะต้องปรับรูปแบบการทำธุรกิจ ในแง่ของการสร้างเสริมประสบการณ์ใหม่ๆให้กับลูกค้าที่เข้ามาใช้บริการ รวมไปถึงการจัดกิจกรรมที่สร้างสรรค์ และบริการเสริม เพื่อรักษาความนิยมจากนักชอปชาวไทย <br><br> นางสาวจริยา ถ้าตรงกิจกุล หัวหน้าแผนกพื้นที่ค้าปลีก ซีบีอาร์อี ประเทศไทย อธิบายว่า"ในขณะที่ห้างสรรพสินค้า มอบความสะดวกสบายให้แก้นักชอปด้วยการประหยัดเวลาเพราะมีการนำเสนอและจัดกลุ่มสินค้าที่มีความหลากหลายไว้ตามแผนกต่างๆ',
    totalLike: 293,
    totalComment: 68
  }]

  const [dataDetail, setDataDetail] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [trend, setTrend] = React.useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);


  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getData();
    Promise.all([promise1]).then(results => {
      CSwl.SwalClose();
    })
    return () => { };
  }, []);



  const getData = async () => {
    try {
      const res = await ApiContentDetail.getPreview(parseInt(contentId));
      if (res.status === 200) {
        setDataDetail(res.data.data)

        // console.log(res.data.data.result)
      }
      setLoading(true);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
    finally {
      setLoading(false);
    }
  }



  if (loading === true) {
    return (
      <div className="text-center mt-5">
      </div>
    );
  }

  const callBackComment = (result) => {
    const { data } = result;
    getData();
  }





  return (
    <div id="contentDetail-page">
      <div className="columns">
        {/* section menu */}
        <div className="column is-2 layer my-3 hidden-b">

        </div>
        {/* section post */}
        <div className="column is-7 mt-5">
          <div className="row mb-2">
            <PostDetailPreview
              page={'detail'}
              contentid={dataDetail.id}
              image={dataDetail.imageProfile ? process.env.REACT_APP_IMAGE + dataDetail.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
              name={`${dataDetail.employee?.firstNameEnglish} ${dataDetail.employee?.lastNameEnglish}`}
              time={ConvertDateShort(dataDetail.cttrandate, "DD MMM YYYY HH:mm น.")}
              title={dataDetail.ctname}
              detail={dataDetail.ctdeschtml}
              item={dataDetail}
              comments={dataDetail.comments}
            />
          </div>

        </div>
        {/* section Cardlist */}
      </div>
    </div>
  )
}

export default ContentPreview

