import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
const Navbar = ({ loginRequest }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const changeLanguage = async () => {
    dispatch({ type: "set", language: languageState == "th" ? "en" : "th" });
    i18n.changeLanguage(languageState == "th" ? "en" : "th");
  }
  return (
    <nav
      class='navbar bottom-shadow pb-3'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='container'>
        <div class='navbar-brand'>
          <Link className='img-logo is-flex is-align-items-center' to='/'>
            <img
              src={process.env.PUBLIC_URL + '/images/firstPage/logoSCGP.svg'}
              // width='204'
              // height='40'
              className="logo-image"
            />
          </Link>

          {/* <a
            role='button'
            class='navbar-burger'
            aria-label='menu'
            aria-expanded='false'
            data-target='navbarBasicExample'
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a> */}
        </div>
        <div id='navbarBasicExample' class='navbar-menu'>
          <div class='navbar-end'>
            <div class='navbar-item'>
              <div class='buttons'>
                {/* <Link to='/register' class='has-text-danger mr-2'>
                  <strong>ลงทะเบียน</strong>
                </Link>
                <span className='mr-2'>หรือ</span> */}
                <div onClick={() => loginRequest()} class='button is-danger is-outlined'>
                  {t('home.signin')}
                </div>
                <div className='cursor-pointer' onClick={() => changeLanguage()}>
                  <p className="mb-2 ml-2">{languageState == "th" ? "English" : "ภาษาไทย"}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
