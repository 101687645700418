import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import CardTaglist from "../../components/CardTaglist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import MainPost from "../../components/PostV2Approve";
import CSwl from "../../utils/alert";
import ApiNotification from "../../api/ApiNotification";
import ApiAuth from "../../api/ApiAuth";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileList from "../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const Managerapprove = () => {
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);

  const dataPro = [
    {
      name: "Sab Khasa...",
    },
    {
      name: "Martha Cr...",
    },
    {
      name: "Tibitha P...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
  ];
  const dispatch = useDispatch();
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const [loadingMore, setloadingMore] = useState(false);
  const [profileFollow, setProfileFollow] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };
  const userState = useSelector((state) => state.user);
  const tokenFirebase = useSelector((state) => state.tokenFirebase);

  const func_reset = async () => {
    getContent();
  };

  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getContent();
    const promise2 = getProfileFollow();
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();
    //const promise5 = getNotification();
    sendToken();

    Promise.all([promise1, promise2, promise3, promise4]).then(
      (results) => {
        CSwl.SwalClose();
      },
    );

    return () => { };
  }, []);

  const getContent = async () => {
    const result = await ApiContent.getListByBO(state);
    if (result.status == 200) {
      console.log(result.data.data);
      setcontents(result.data.data);
    }
  };

  const getContentLoadmore = async () => {
    const result = await ApiContent.getListByBO(state);
    if (result.status == 200) {
      setcontents({
        ...contents,
        items: [...contents.items, ...result.data.data.items],
      });
      console.log("loadmore::", result.data.data.items);
    }
  };

  const getProfileFollow = async () => {
    const result = await ApiFollow.GetTranProfileForFollow();

    if (result.status == 200) {
      setProfileFollow(result.data.data);
    }
  };

  const callBackComment = (result) => {
    try {
      const comment = result.data;
      // const { data } = result;
      let newContents = { ...contents };
      let newContentsItem = newContents.items;

      const findIndex = newContentsItem.findIndex((x) => x.id == comment.ctid);
      newContentsItem[findIndex].comments.unshift(comment);
      newContents.items = newContentsItem;
      setcontents(newContents);
    } catch (error) {
      console.log(error);
    }
  };


  const sendToken = async () => {
    if (tokenFirebase) {
      await ApiAuth.tokenFirebase({ token: tokenFirebase });
    }
  };

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 });
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then((results) => {
        setloadingMore(false);
      });
    }
    return () => { };
  }, [state]);

  return (
    <div id="first-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.approve')} />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.approve')} />
        </div>
        {/* section post */}
        <div className="column is-7 layer2">
          <div className="is-flex is-justify-content-space-between px-4 layer3">
            <div
              // to="/"
              className="has-text mr-6 is-flex is-align-items-center"
            >
              <strong className="mt-3 mb-3">รายการอนุมัติ</strong>
            </div>
            {/* <Link
              to="/"
              className="button is-light is-outlined "
              style={{ color: "black", fontWeight: "bold" }}
            >
              ดูทั้งหมด
            </Link> */}
          </div>
          <hr className=" hidden-b my-1" />
          {contents && (
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  return (
                    <div className="row fn ar" key={d.id}>
                      <MainPost
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                        onchange={() => {
                          func_reset();
                        }}
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
          {contents && contents.items.length == 0 && (
            <div className="m-5">ไม่พบบทความ</div>
          )}
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden-b"
        >
          <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Managerapprove;
