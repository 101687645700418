import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconComponent = ({ icon, color = '', style = {}, className = '', onClick = () => { }, onMouseOver = () => { }, onMouseLeave = () => { } }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={className}
      style={style}
      color={color ? color : ''}
      icon={icon}
    />
  );
};

export default IconComponent;
