import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const initialState = {
  sidebarShow: 'responsive',
  user: {
    profile: {

    },
    employee: {

    },
    ranking: {

    }
  },
  token: '',
  count_notification: 0,
  count_login: 0,
  count_chat_notification:0,
  tokenFirebase: "",
  language: "th",
  callBackUrl: "",
  isLogged: "false",
  isShare: false,
  roomId: 0
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'set_user':
      return { ...state, ...rest };
    case 'set_token':
      return { ...state, ...rest };
    case 'set_url':
      return { ...state, ...rest };
    case 'clear_all':
      const model = initialState;
      model.callBackUrl = rest?.callBackUrl;
      model.isLogged = rest?.isLogged;
      return model;
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStoreWithMiddleware(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

// const store = createStore(changeState);

// export default store;
