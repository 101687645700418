import React, { useEffect, useState } from "react";
import "../style/Post/post.scss";
import { Link } from "react-router-dom";
import ApiFollow from "../api/ApiFollow";
import { useTranslation } from "react-i18next";

const Unfollow = ({
  image,
  index,
  profileid,
  name,
  depart,
  level,
  totalArticle,
  totalFollow,
  smeCategory,
  onunfollow = () => { },
}) => {
  useEffect(() => { }, []);
  const { t, i18n } = useTranslation();
  const func_unfollow = async () => {
    const result = await ApiFollow.FollowORUnFollow(profileid);
    if (result.status == 200) {
      onunfollow(index);
    }
  };
  return (
    <div id="post-com" className="card">
      <div className="content container-header">
        <div className="media is-justify-content-space-between is-align-items-center">
          <div className="media pt-5" style={{ borderTop: 0 }}>
            <div>
              {/* <figure className="image is-48x48">
                <img
                  class="is-rounded"
                  src={image}
                  alt="Placeholder_image"
                />

              </figure> */}
              <Link to={`profileDetail?id=${profileid}`}>
                <figure className="image is-48x48">
                  <img
                    className={`is-rounded image-cover ${smeCategory ? `border-[#f97316] border-4` : ""}`}
                    alt="Placeholder_image"
                    style={{ width: '100%', height: '100%' }}
                    src={image} />
                </figure>
              </Link>
            </div>
            <div className="media-content title-header">
              <span className="has-text-weight-bold font-button">{name}</span>
              <span className="has-text-grey-light font-button">{depart}</span>
            </div>
          </div>
          <div className="is-flex is-align-items-center mr-3">
            <button
              onClick={() => {
                func_unfollow();
              }}
              className="button is-link is-rounded"
            >
              <div className="font-button">{t('menu.unfollow')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unfollow;
