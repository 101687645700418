import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { useHistory } from 'react-router-dom';

const Page404 = () => {
  const history = useHistory();
  return (
    <div>
      <Navbar />
      <div className='container'>
        <div className='content d-flex-center d-flex flex-column' style={{ height: '50vh' }}>
          <h1 className='has-text-danger'> 404 Page Not Found</h1>
          <button type="button" onClick={() => history.goBack()} className='button is-danger is-outlined mt-5 '>
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page404;
