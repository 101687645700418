import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import CardTaglist from "../../components/CardTaglist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import MainPost from "../../components/PostV2";
import CSwl from "../../utils/alert";
import ApiNotification from "../../api/ApiNotification";
import ApiAuth from "../../api/ApiAuth";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileList from "../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import { destroyPage, firstViewPage, useWindowUnloadEffect } from "../../utils";
import ApiOrg from "../../api/ApiOrg";
import ProfileTopRankList from "../../components/ProfileTopRank";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const FirstPage = () => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Home", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const languageState = useSelector((state) => state.language);
  const [stateOrg, setstateOrg] = useState({
    orgData:[],
    bu: [],
    subbu: [],
    company: [],
    division: [],
    department: [],
    section: []
  });

  const dataPro = [
    {
      name: "Sab Khasa...",
    },
    {
      name: "Martha Cr...",
    },
    {
      name: "Tibitha P...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
    {
      name: "Wade War...",
    },
  ];
  const dispatch = useDispatch();
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const [loadingMore, setloadingMore] = useState(false);
  const [profileFollow, setProfileFollow] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [creatorTop, setCreatorTop] = useState([]);
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getCreator = async () => {
    const result = await ApiContent.getCreator();
    if (result.status == 200) {
      console.log("creator::", result.data);
      setCreatorTop(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };
  const userState = useSelector((state) => state.user);
  const tokenFirebase = useSelector((state) => state.tokenFirebase);

  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getContent();
    const promise2 = getProfileFollow();
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();
    const promise5 = func_loadOrg();
    const promise6 = getCreator();
    getNotification();
    //const promise5 = getNotification();
    sendToken();
    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then(
      (results) => {
        CSwl.SwalClose();
      }
    )
    return () => { };
  }, []);

  const func_loadOrg = async () => {
    try {
      var res = await ApiOrg.getOrg();
      const { result, status } = res.data;
      if (res.status === 200) {
        setstateOrg({
          ...stateOrg,
          orgData:result
        })
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
  };

  const getContent = async () => {
    const result = await ApiContent.get(state);
    if (result.status == 200) {
      console.log(result.data.data);
      setcontents(result.data.data);
    }
  };

  const getContentLoadmore = async () => {
    const result = await ApiContent.get(state);
    if (result.status == 200) {
      setcontents({
        ...contents,
        items: [...contents.items, ...result.data.data.items],
      });
      console.log("loadmore::", result.data.data.items);
    }
  };

  const getProfileFollow = async () => {
    const result = await ApiFollow.GetTranProfileForFollow();

    if (result.status == 200) {
      setProfileFollow(result.data.data);
    }
  };

  const callBackComment = (result) => {
    try {
      const comment = result.data;
      // const { data } = result;
      let newContents = { ...contents };
      let newContentsItem = newContents.items;

      const findIndex = newContentsItem.findIndex((x) => x.id == comment.ctid);
      newContentsItem[findIndex].comments.unshift(comment);
      newContents.items = newContentsItem;
      setcontents(newContents);
    } catch (error) {
      console.log(error);
    }
  };

  const getNotification = async () => {
    try {
      const notificationCount = await ApiNotification.count();
      if (notificationCount.status == 200) {
        const { data, logsLogin, isShare, chatNotification } = notificationCount.data;
        dispatch({ type: "set_user", count_notification: data, count_login: logsLogin, isShare: isShare, count_chat_notification: chatNotification });
      }
    } catch (error) {

    }
  };

  const sendToken = async () => {
    if (tokenFirebase) {
      try {
        await ApiAuth.tokenFirebase({ token: tokenFirebase });
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    }
  };

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 });
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then((results) => {
        setloadingMore(false);
      })
    }
    return () => { };
  }, [state]);

  return (
    <div id="first-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={dataPro} page="rank" header={t('menu.home')} />
      </Drawer>
      <Header />
      <div className="columns pr-4 lg:pr-0">

        {/* section menu */}
        <div className="column mr-4 lg:mr-0 is-hidden-mobile is-2 layer">
          <Menu data={dataPro} page="rank" header={t('menu.home')} />
        </div>
        {/* section post */}
        <div className="column is-7 md:!flex-1 middle-container layer2">
          {profileFollow && profileFollow.length > 0 && <div className="is-flex is-justify-content-space-between px-4 layer3">
            <div
              // to="/"
              className="has-text mr-6 is-flex is-align-items-center"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/firstPage/fi-rr-portrait1.png"
                }
                alt="logo1"
                width="24"
                height="24"
              />
              <strong className="m-3">{t('text.who_follow')}</strong>
            </div>
            {/* <Link
              to="/"
              className="button is-light is-outlined "
              style={{ color: "black", fontWeight: "bold" }}
            >
              ดูทั้งหมด
            </Link> */}
          </div>
          }
          {profileFollow.length > 0 && <>
            <div className="row">
              <div className="is-flex" style={{ overflow: "auto" }}>
                {profileFollow.map((d, index) => {

                  return (
                    <div className="column size-procard" key={index}>
                      <ProfileCard
                        image={
                          d.image
                            ? process.env.REACT_APP_IMAGE + d.image
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                        profile={d}
                        profileid={d.profileID}
                        name={d.fullNameEng}
                        depart={d.deptThai}
                        level={d.rankEng}
                        totalArticle={d.countContent}
                        totalFollow={d.countFollow}
                      />
                    </div>
                  );
                })}
                {profileFollow.length < 1 && (
                  <div className="full">
                    <strong className="m-3">คุณได้ติดตามทุกคนแล้ว</strong>
                    <hr className="hidden-b my-1" />
                  </div>
                )}
              </div>
            </div></>
          }
          {profileFollow.length == 0 && <div style={{ height: 10 }}></div>}
          {
            (contents && contents.liveShareId > 0) && <div className="mb-5 mt-5">
              <div className="flex gap-x-2">
                <Link className="flex-1" to={`/contentdetailLiveSharing?id=${contents.liveShareId}`}>
                  <div className="flex items-center flex-1 gap-x-2">
                    <span style={{ padding: 5, borderRadius: 5, fontSize: 18 }} className="text-white tag is-danger">LIVE</span>
                    <span className="fontH cursor-pointer whitespace-nowrap">{`LIVE SHARING :`}</span>
                    <div className="relative flex">
                      <span style={{ fontFamily: 'Prompt' }} className="fontH !font-light line-clamp-1">{contents.liveShare}</span>

                    </div>
                  </div>
                </Link>

              </div>

              <div className="ar"></div>
            </div>
          }

          <h2 className="fontH mb-2 mt-2">{`${t('text.all_content')} ${`(${contents.totalItems ?? 0})`}`}</h2>
          {/* <div className="ar" style={{ marginBottom: 15 }}></div> */}
          <div className="ar"></div>
          {contents && (
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  console.log('content::',d)
                  return (
                    <div className="row fn ar" key={d.id}>
                      <MainPost
                        stateOrg={stateOrg}
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm",languageState)}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden lg:block"
        >
          <div className="is-3 flex-1" style={{ position: "", width: 'auto' }}>
            <div className="mt-3 mr-6">
              {
                creatorTop.length > 0 ? <ProfileTopRankList
                  title={t('text.topActive')}
                  data={creatorTop.map((x) => {
                    return {
                      name: x.fullNameEng,
                      image: x.image
                        ? process.env.REACT_APP_IMAGE + x.image
                        : process.env.PUBLIC_URL +
                        "/images/profilePage/Profile.png",
                      profileID: x.profileID,
                      totalArticle: x.countContent,
                      totalFollow: x.countFollow
                    };
                  })}
                  topage={"unfollow"}
                /> :
                  <div
                    id="Cardlist-com"
                    className="card p-4 "
                    style={{
                      backgroundColor: "#f6f8fa",
                      borderRadius: "1rem",
                      boxShadow: "0 0em 0em",
                    }}
                  >
                    <div className="is-flex is-justify-content-space-between">
                      <h1 className="header">{t('text.topActive')}</h1>
                    </div>
                    <div className="flex flex-col">
                      <span>พื้นที่นี้กำลังรอคุณอยู่...
                      </span>
                      <span>เพียงคุณ publish ความรู้ ที่เป็นประโยชน์แก่เพื่อนชาว SCGP มากที่สุดภายในเดือน</span>
                    </div>
                  </div>
              }

            </div>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                    smeCategory:x.smeCategory
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FirstPage;
