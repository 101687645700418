import React, { useEffect, useState } from "react";
import "../style/ProCard/procard.scss";
import ApiFollow from "../api/ApiFollow";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const ProfileCard = ({
  profileid,
  name,
  depart,
  level,
  totalArticle,
  totalFollow,
  image,
  profile
}) => {
  useEffect(() => { }, []);
  const { t, i18n } = useTranslation();
  const [follow, setFollow] = useState(false);

  const func_follow = async (e) => {
    e.stopPropagation();
    const result = await ApiFollow.FollowORUnFollow(profileid);
    if (result.status == 200) {
      if (!follow) {
        setFollow(true);
      } else {
        setFollow(false);
      }
    }
  };

  useEffect(() => {
    console.log(profile)
  }, []);

  const history = useHistory();

  return (
    <>
      <div id="procard-com" onClick={() => history.push(`/profileDetail?id=${profileid}`)} className="card  cursor-pointer">
        <div className="card-image">
          <figure className="image">
            <img
              src={process.env.PUBLIC_URL + "/images/firstPage/BG1.png"}
              alt="backgroundImage"
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="is-flex is-justify-content-center">
            <figure className="image img-card is-64x64">
              <img
                style={{ height: '100%', width: '100%' }}
                className={`is-rounded image-cover ${profile.smeCategory ? `border-[#f97316] border-2` : ""}`}
                src={image}
                alt="Placeholder_image"
              />
            </figure>
          </div>
          <div className="mt-3">
            <div className="media-content">
              <p className="header-title">{name}</p>
              <p className="sub-title">{depart}</p>
              {/* <p className="sub-title">{level}</p> */}
            </div>
          </div>
          <div className="row">
            <div className="is-flex is-justify-content-space-between">
              <div className="is-flex is-flex-direction-column">
                <p className="sub-title1">{t('text.content')}</p>
                <p className="sub-title1">{t('menu.follower')}</p>
              </div>
              <div className="is-flex is-flex-direction-column">
                <p className="sub-title1">{totalArticle}</p>
                <p className="sub-title1">{totalFollow}</p>
              </div>
            </div>
          </div>
          <div className="row mt-3 is-flex is-justify-content-center">
            <button
              onClick={(e) => {
                func_follow(e);
              }}
              className={`button button-size is-link is-rounded is-fullwidth ${profile.smeCategory ? `!bg-[#f97316]` : ""}`}
            >
              {!follow ? t('menu.follow') : t('menu.unfollow')}
            </button>
          </div>
        </div>
        {
          profile.smeCategory && <div className="bg-[#f97316] py-1 px-4 absolute top-0">
            <span className="text-white text-sm">SMEs</span>
          </div>
        }
      </div>
    </>
  );
};

export default ProfileCard;
