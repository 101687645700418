import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiLog extends Component {
    static create = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "TranLog",
            method: "post",
            data: data,
        });
        return result;
    };
    static downloadfile = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "TranLog/Downloadfile",
            method: "post",
            data: data,
        });
        return result;
    };
}

export default ApiLog;
