import React, { useState } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../../utils/alert";
import { destroyPage, useWindowUnloadEffect, CheckFile } from "../../../utils";
import ApiFollow from "../../../api/ApiFollow";
import ApiMtTag from "../../../api/ApiTag";
import CardTaglist from "../../../components/CardTaglist";
import ProfileList from "../../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import Select, { StylesConfig } from 'react-select';
import DatePicker from "react-datepicker";
import ApiContent from "../../../api/ApiContent";
import CSwal from "../../../utils/alert";
import Swal from "sweetalert2";
import ApiConfig from "../../../api/ApiConfig";
import _ from 'lodash';
import moment from "moment";
import { ConvertDateShort } from "../../../utils/date";
import Autosuggest from "react-autosuggest";
import ApiTranProfile from "../../../api/ApiTranProfile";
import IconComponent from "../../../components/Icons";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Drawer from "rc-drawer";
import MenuDrawer from "../../../components/MenuDrawer";

const CertificateEdit = () => {
  const [isModal, setisModal] = useState(false);
  const [firstTime, setFirstTime] = useState(new Date());
  const history = useHistory();
  // useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const userState = useSelector((state) => state.user);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [searchCapacity, setsearchCapacity] = useState('');
  const { id } = useParams();
  const [state, setState] = useState({
    "userId": userState.employee.userId,
    "userFullname": `${userState.employee?.firstNameThai} ${userState.employee?.lastNameThai}`,
    "country": "",
    "name": "",
    "code": "",
    "instituteName": "",
    "issuedDate": null,
    "expiredDate": null,
    "isExpired": 0,
    "type": "",
    "competency": "",
    "approvedBy": userState.employee.reportToUserId,
    "approvedName": userState.employee.reportToName,
    "reportToName": userState.employee.reportToName,
    "status": "",
    isEditUser: false,
    isEditApproved: false,

  });
  const [isConfigEditApproved, setisConfigEditApproved] = useState(false);
  const [country, setCountry] = useState("")
  const [certType, setCertType] = useState([])
  const [capabilitiesDefault, setCapabilitiesDefault] = useState([])
  const [capabilities, setCapabilities] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fileDocState, setfileDocState] = useState("");
  const [fileDoctmp, setfiledoctmp] = useState([]);
  const [data, setData] = useState({})


  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState({});

  const [suggestionApprove, setsuggestionApprove] = useState('');
  const [suggestionsApprove, setSuggestionsApprove] = useState([]);
  const [suggestionSelectApprove, setsuggestionsSelectApprove] = useState({});


  React.useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getProfileUnFollow();
    const promise2 = getToptag();
    const promise3 = getCompetency();
    const promise4 = getCountry();
    const promise5 = getConfig();
    Promise.all([promise1, promise2, promise3, promise4, promise5]).then(async (result) => {
      if (id !== "0") {
        await getData(result[2], result[3]);
      }
      CSwl.SwalClose();
    })

    return () => { };
  }, []);

  const mockupEdit = () => {
    setData({
      approveStatus: "no-approve",
      employeeName: "สมชาย ราชโนนเขวา",
      certName: "ผู้ควบคุมหม้อไอน้ำ",
      certCode: "RB102938900",
      from: "กระทรวงอุตสาหกรรม",
      expiredDate: 1,
      approve_by: "xxxxxxxxxx xxxxxxxxx"
    })
    // setCountry("ประเทศไทย")
    // setCertType([{ label: "ภาษา", value: "ภาษา" }])
    // setCapabilities([{ label: "การปฏิรูปทางธุรกิจและองค์กร", value: "การปฏิรูปทางธุรกิจและองค์กร" }])
    // setStartDate("7/06/2022")
    // setEndDate(null)
  }

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageSize: 3,
      pageNumber: 1,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };

  const getData = async (list_capabilities, list_country) => {
    try {
      const res = await ApiContent.GetCertificateById(id);
      if (res.status === 200) {
        const userApprove = res.data.userApprove;
        const data = res.data.data;
        let competency = "";
        if (data.competency?.split(',')?.length > 0) {
          competency = [];
          data.competency.split(',').map((item, index) => {
            competency.push(list_capabilities.find(x => x.value == item))
          })
        }
        if (data.files != "" && data.files != null) {
          setfiledoctmp(JSON.parse(data.files));
        }
        setState({
          ...state,
          "userId": data.userId,
          "userFullname": `${userState.employee?.firstNameThai} ${userState.employee?.lastNameThai}`,
          "country": list_country.find(x => x.value == data.country),
          "name": data.name,
          "code": data.code,
          "instituteName": data.instituteName,
          "issuedDate": data.issuedDate ? new Date(moment(data.issuedDate)) : null,
          "expiredDate": data.expiredDate ? new Date(moment(data.expiredDate)) : null,
          "isExpired": data.isExpired,
          "type": data.type,
          "competency": competency,
          "approvedBy": data.approvedBy,
          "approvedName": userApprove?.firstNameEnglish + " " + userApprove?.lastNameEnglish,
          "status": "",
        })
        // const files = res.data.data.files ? JSON.parse(res.data.data.files) : "";
        // const fileImage = files.find((x) => {
        //   return listTypeImage.some(v => x.Path.includes(v))
        // })
        // setFile(files);
        // setfileImage(fileImage)
      }
    } catch (error) {
      console.log(error)
      // try {
      //   const { data } = error.response;
      //   CSwl.SwalErr(data.message).then(x => history.go(-1));
      // } catch (ex) {
      //   CSwl.SwalErr("ไม่สามารถสร้างบทความได้");
      // }
    }
  }

  const getCountry = async () => {
    const result = await ApiConfig.getCountry();
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setCountry(result.data.data.map(x => {
          return { label: `${x.countryNameEn}`, value: x.countryNameEn };
        }))
        return result.data.data.map(x => {
          return { label: `${x.countryNameEn}`, value: x.countryNameEn };
        });
      }
    }
  };

  const getConfig = async () => {
    const result = await ApiConfig.getList();
    if (result.status == 200) {
      const { data } = result.data;
      if (data.find(x => x.code == "certificate_approver")) {
        setisConfigEditApproved(data.find(x => x.code == "certificate_approver").value == 1 ? true : false)
      }
    }
  }

  const getCompetency = async () => {
    const result = await ApiConfig.getCompetency();
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        //setTrend(result.data.data);
        var groupBySizeBox = _(result.data.data)
          .groupBy('ocap')
          .map((value, key) => {
            return {
              label: key, options: value.map(x => {
                return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
              })
            }
          })
          .value();
        setCapabilities(groupBySizeBox);
        setCapabilitiesDefault(result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno, description: x.competencyDifination };
        }));
        return result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
        });
      }
    }
  };

  const fileDoc = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    CheckFile({
      file,
      size: 100,
      type: ["application/pdf", "image/jpg", "image/jpeg", "image/png"],
      message: "รองรับเฉพาะไฟล์ .pdf,.docx,pptx เท่านั้น",
    });
    setfileDocState([...fileDocState, file]);
    //setfiledoctmp([]);
  };

  const list_country = ["Thailand"]
  const list_certType = [{ id: 1, label: "Language" }, { id: 2, label: "Safety" }, { id: 3, label: "Software / IT / Digital" }
    , { id: 4, label: "ISO / Environment" }
    , { id: 5, label: "Technical / Operation / Engineering" }
    , { id: 6, label: "Soft side / Leadership" }
    , { id: 7, label: "Accounting / Finance / Audit" }
    , { id: 8, label: "Other" }]
  const list_capabilities = [{ id: 1, label: "test" }, { id: 2, label: "test" }, { id: 3, label: "test" }]


  const onSubmit = async (e) => {
    e.preventDefault();
    state.status = "W";
    CSwal.SwalLoad();
    try {
      if (id == 0) {
        const created = await ApiContent.createCertificate(
          {
            ...state,
            competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
            country: state.country?.value
          });
        const formData = new FormData();
        if (fileDocState) {
          fileDocState.map((item) => {
            formData.append("file", item);
          })
        }
        const refno = created.data.data.ref;
        formData.append("refno", refno);
        if (fileDocState) {
          const uploadedFile = await ApiContent.uploadCertificate(formData);
        }
        Swal.fire("สำเร็จ", "", "success").then(async (result) => {
          history.push("/certificate");
        });
      } else {
        const created = await ApiContent.updateCertificate(
          {
            ...state,
            id: parseInt(id),
            competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
            filesTemp: fileDoctmp,
            country: state.country?.value
          });
        const formData = new FormData();
        if (fileDocState) {
          fileDocState.map((item) => {
            formData.append("file", item);
          })
        }
        const refno = created.data.data.ref;
        formData.append("refno", refno);
        if (fileDocState) {
          const uploadedFile = await ApiContent.uploadCertificate(formData);
        }
        Swal.fire("สำเร็จ", "", "success").then(async (result) => {
          history.push("/certificate");
        });
      }
    } catch (error) {
      try {
        const { data } = error.response;
        CSwal.SwalErr(data.message);
      } catch (ex) {
        CSwal.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  };

  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = capabilities.filter(group => {
      return group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
    }
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find(opt => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  //#region suggestionUser
  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    // const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    // if (checked) {
    // } else {
    //   setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    // }
    setState({
      ...state,
      userFullname: suggestion.fullName,
      isEditUser: false,
      userId: suggestion.employee.userId,
    })
    setsuggestionsSelectUser(suggestion);
    return "";
  };

  const renderSuggestionUser = suggestion => {
    return (
      <div style={{ padding: 10, border: '1px solid #efefef' }}>
        {suggestion.fullName.includes(suggestionUser) ? suggestion.fullName : suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
      </div>
    )
  };

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    setsuggestionUser(newValue);
  };

  //#endregion

  //#region suggestionApprove
  const getSuggestionsApprove = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueApprove = (suggestion) => {
    // const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    // if (checked) {
    // } else {
    //   setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    // }
    setState({
      ...state,
      approvedName: suggestion.fullName,
      isEditApproved: false,
      approvedBy: suggestion.employee.userId,
    })
    setSuggestionsApprove(suggestion);
    return "";
  };

  const renderSuggestionApprove = suggestion => (

    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.fullName.includes(suggestionsApprove) ? suggestion.fullName : suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedApprove = async ({ value }) => {

    setSuggestionsApprove(await getSuggestionsApprove(value));
  };

  const onSuggestionsClearRequestedApprove = () => {
    setSuggestionsApprove([]);
  };

  const onChangeSuggestionApprove = (event, { newValue }) => {
    setsuggestionApprove(newValue);
  };

  //#endregion

  return (
    <div id="CertificateEditPage">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('text.certificate')} />
        </div>
        <div className="column is-7 pt-0">
          <form onSubmit={onSubmit}>
            <div className="is-flex flex-wrap is-flex-direction-column section-content sm:!px-5 sm:!py-6 !py-3 !px-2">
              <div className="is-flex is-justify-content-space-between flex-wrap">
                <div className="is-flex is-align-items-center">
                  <figure className="image">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/profilePage/list_certificate.png"
                      }
                      alt="logo1"
                    />
                  </figure>
                  {
                    id === "0" ?
                      <p className="title-h1 px-2">{t('menu.addcertificate')}</p>
                      :
                      <p className="title-h1 px-2">{t('menu.editcertificate')}</p>
                  }
                </div>

              </div>
            </div>
            <div className="section-content p-5">
              {/* <div className="is-flex is-align-items-center is-justify-content-end">
              <span className="is-left">
                <p>{t('text.approvalstatus')} : </p>
              </span>
              <span className="select is-rounded px-3">
                <select value={data.approveStatus} 
                  onChange={(e) => setData({...data, approveStatus: e.target.value})}>
                  <option value="approve">Approved</option>
                  <option value="no-approve">Waiting for Approval</option>
                  <option value="draft">Draft</option>
                </select>
              </span>
            </div> */}
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  <span className="has-text-danger">*</span>{t('text.employeename')}
                </div>
                <div className="column is-9 flex gap-x-2 items-center">
                  {
                    state.isEditUser ? <div className="w-full"><Autosuggest
                      suggestions={suggestionsUser}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequestedUser}
                      onSuggestionsClearRequested={onSuggestionsClearRequestedUser}
                      getSuggestionValue={getSuggestionValueUser}
                      renderSuggestion={renderSuggestionUser}
                      inputProps={{
                        placeholder: "กรุณาระบุชื่อพนักงาน",
                        value: suggestionUser,
                        onChange: onChangeSuggestionUser,
                        readOnly: state.isEditUser == 1 ? false : true,
                        className: 'input w-full'
                      }
                      }
                    /></div> : <input
                      className="input"
                      type="text"
                      required
                      readOnly={state.isEditUser == 1 ? false : true}
                      value={state.userFullname}
                      onChange={(e) => setState({ ...state, userFullname: e.target.value })}
                      placeholder="กรุณาระบุชื่อพนักงาน"
                    />
                  }


                  <img
                    onClick={() => {
                      setState({ ...state, userFullname: "", userId: "", isEditUser: true })
                    }}
                    className="cursor-pointer w-6 h-6"
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                    }
                    alt="logo1"
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  <span className="has-text-danger">*</span>{t('text.nameofcertificate')}
                </div>
                <div className="column is-9">
                  <input
                    className="input"
                    type="text"
                    value={state.name}
                    onChange={(e) => setState({ ...state, name: e.target.value })}
                    placeholder="กรุณาระบุชื่อรางวัล/เกียรติบัตร"
                    required
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  {t('text.certificatecode')}
                </div>
                <div className="column is-9">
                  <input
                    className="input"
                    type="text"
                    value={state.code}
                    onChange={(e) => setState({ ...state, code: e.target.value })}
                    placeholder="กรุณาระบุรหัสรางวัล/เกียรติบัตร"
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  <span className="has-text-danger">*</span>{t('text.country')}
                </div>
                <div className="column is-9">
                  <Select
                    defaultValue={state.country}
                    value={state.country}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    onChange={(e) => { setState({ ...state, country: e }) }}
                    // options={list_country.map((x) => {
                    //   return { label: x, value: x };
                    // })}
                    options={country}
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  <span className="has-text-danger">*</span>{t('text.institution')}
                </div>
                <div className="column is-9">
                  <input
                    className="input"
                    type="text"
                    value={state.instituteName}
                    onChange={(e) => setState({ ...state, instituteName: e.target.value })}
                    placeholder="กรุณาระบุชื่อสถาบัน"
                    required
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3">
                </div>
                <div className="column is-flex is-align-items-center is-9 subtitle">
                  <input
                    className="is-checkbox"
                    type="checkbox"
                    value={state.isExpired === 1 ? true : false}
                    checked={state.isExpired === 1 ? true : false}
                    onChange={(e) => {
                      setState({ ...state, isExpired: e.target.checked == true ? 1 : 0 })
                    }}
                  />
                  <label className="pl-5">{t('text.certhasnoexpiration')}</label>
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  {t('text.dateofissue')}
                </div>
                <div className="column is-3">
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    popperPlacement="bottom-start"
                    dropdownMode="select"
                    className="input size-input"
                    selected={state.issuedDate}
                    onChange={(e) => {
                      if (state.expiredDate < e && state.isExpired == 0) {
                        setState({ ...state, issuedDate: e, expiredDate: e })
                      } else {
                        setState({ ...state, issuedDate: e })
                      }

                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="column is-6 flex sm:gap-x-10 gap-x-2">
                  <div className="subtitle">
                    {t('text.expirationdate')}
                  </div>
                  <div className="">
                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      popperPlacement="bottom-start"
                      dropdownMode="select"
                      readOnly={state.isExpired == 1 ? true : false}
                      className="input size-input"
                      minDate={state.issuedDate}
                      selected={state.expiredDate}
                      onChange={(e) => setState({ ...state, expiredDate: e })}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>

              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle">
                  <span className="has-text-danger">*</span>{t('text.certtype')}
                </div>
                <div className="column is-9">
                  <Select
                    defaultValue={list_certType.filter(x=>x.id == state.type) ??""}
                    value={list_certType.filter(x=>x.id == state.type) ??""}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    onChange={(e) => { setState({ ...state, type: e.value }) }}
                    options={list_certType.map((x) => {
                      return { label: x.label, value: x.id };
                    })}
                  />
                </div>
              </div>
              <div className="columns pt-4">
                <div className="column is-3 subtitle flex gap-x-2">
                  {t('text.capabilities')}
                  <IconComponent onClick={() => {
                    setisModal(true)
                  }} className="h-6 w-6 cursor-pointer" color="#F24C20" icon={faInfoCircle} />
                </div>
                <div className="column is-9">
                  <div className="flex gap-x-2">
                    <Select
                      value={state.competency}
                      className="basic-single w-full"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      required
                      isMulti
                      onChange={(e) => {
                        setState({ ...state, competency: e })
                      }}
                      filterOption={filterOption}
                      // options={capabilities.map((x) => {
                      //   return { label: `(${x.fcno}) ${x.competencyNameEn}`, value: x.fcno };
                      // })}
                      options={capabilities}
                    />

                  </div>

                </div>
              </div>
              <div className="is-flex file-container-card is-justify-content-space-between is-flex-wrap-wrap">
                <div className="is-flex is-flex-direction-column">
                  <p className="title-h1">
                    <span className="has-text-danger">*</span>{t('text.certattachment')}
                  </p>
                  <div className="file is-boxed">
                    <label className="file-label">
                      <input
                        onChange={fileDoc}
                        accept="application/pdf, image/*"
                        id="doc_file"
                        className="file-input"
                        type="file"
                        name="resume"
                      />
                      <span className="file-cta is-flex-direction-row is-justify-content-center padding-file border-s">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/content/IconFile.png"
                          }
                          alt="Icon"
                        />
                        <div className="is-flex is-flex-direction-column pl-2">
                          <p className="subtitle-p2">{t('text.content_file_cert_20mb')}</p>
                        </div>
                      </span>
                    </label>
                  </div>
                  {fileDocState && (
                    fileDocState.map((item, index) => {
                      return <div key={item.name + index} className="d-flex file-row mt-3" style={{ position: 'relative' }}>
                        <label className="flex-1">
                          {item.name}
                        </label>
                        <button
                          type="button"
                          onClick={(e) => {
                            let files = [...fileDocState];
                            files.splice(index, 1);
                            e.stopPropagation();
                            setfileDocState(files)
                          }} className="delete bg-red-500" style={{ position: 'absolute', right: 10 }}></button>
                      </div>
                    })

                  )}
                  {fileDoctmp.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex file-row mt-3"
                        style={{ position: "relative" }}
                      >
                        <label className="flex-1">{item.Name}</label>
                        <button
                          type="button"
                          onClick={(e) => {
                            let files = [...fileDoctmp];
                            files.splice(index, 1);
                            e.stopPropagation();
                            setfiledoctmp(files)
                          }}
                          className="delete bg-red-500"
                          style={{ position: "absolute", right: 10 }}
                        ></button>
                      </div>
                    );
                  })}
                </div>
              </div>
              {
                !isConfigEditApproved && <div className="columns mt-4">
                  <div className="column is-6">
                    <p className="title-h1">
                      {t('text.approvedby')}
                    </p>
                    <div className="flex gap-x-2 items-center">
                      {
                        state.isEditApproved ? <div className="w-full"><Autosuggest
                          suggestions={suggestionsApprove}
                          onSuggestionsFetchRequested={onSuggestionsFetchRequestedApprove}
                          onSuggestionsClearRequested={onSuggestionsClearRequestedApprove}
                          getSuggestionValue={getSuggestionValueApprove}
                          renderSuggestion={renderSuggestionApprove}
                          inputProps={{
                            placeholder: "กรุณาระบุชื่อพนักงาน",
                            value: suggestionApprove,
                            onChange: onChangeSuggestionApprove,
                            readOnly: state.isEditApproved == 1 ? false : true,
                            className: 'input w-full'
                          }
                          }
                        /></div> :
                          <input
                            className="input"
                            type="text"
                            required
                            readOnly={state.isEditApproved == 1 ? false : true}
                            value={state.approvedName}
                            onChange={(e) => setState({ ...state, approvedName: e.target.value })}
                            placeholder="กรุณาระบุชื่อพนักงาน"
                          />
                      }

                      {
                        <img
                          onClick={() => {
                            setState({ ...state, approvedName: "", approvedBy: "", isEditApproved: true })
                          }}
                          className="cursor-pointer w-6 h-6"
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                          }
                          alt="logo1"
                        />
                      }

                    </div>
                  </div>
                  <div className="column is-6">
                    <p className="title-h1">
                      {t('text.manager')}
                    </p>
                    <input
                      className="input"
                      type="text"
                      value={state.reportToName}
                      readOnly

                    //onChange={(e) => setState({ ...data, approvedName: e.target.value })}
                    />
                  </div>
                </div>
              }

            </div>
            <div className="is-flex is-flex-direction-column section-content p-5 pt-6">
              <div className="is-flex is-justify-content-space-between flex-wrap">
                <div className="is-flex is-align-items-center">

                </div>
                <div className="is-flex">
                  <Link to={`/certificate`}>
                    <button className="button is-light is-rounded px-6">{t('menu.cancel')}</button>
                  </Link>
                  <button
                    type={"submit"}
                    className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2 px-6"
                    style={{ backgroundColor: "#F24C20" }}
                  >
                    {"บันทึก"}
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div
            className="is-3 flex-1"
            style={{ position: "fixed", width: "inherit" }}
          >
            <div className="mt-3 mr-6">
              <CardTaglist
                title={t('text.trending')}
                data={trend}
                topage={"trend"}
              />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        </div>
      </div>
      <div class={`modal  ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content !w-full sm:!w-3/4 bg-white p-5" style={{ borderRadius: 5 }}>
          <h1 className="text-2xl">{t('text.capabilities')}</h1>
          <div className="flex-column overflow-auto max-h-[500px] mt-3">
            <div className="pr-2">
              <input
                className="input"
                type="text"
                value={searchCapacity}
                onChange={(e) => setsearchCapacity(e.target.value)}
                placeholder="ค้นหา"
              />
            </div>
            <div className="flex flex-col gap-y-2 mt-2">
              {
                capabilitiesDefault.filter(x => x.label.includes(searchCapacity)).map((item, index) => {
                  return <div className="flex flex-col gap-y-1">
                    <span className="font-bold text-lg">{item.label}:</span>
                    <span className="text-gray-">{item.description}</span>
                  </div>
                })
              }

            </div>
          </div>
        </div>
        <button onClick={() => {
          setisModal(false);
        }}
          class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  )
}

export default CertificateEdit