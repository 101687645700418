import React, { useEffect, useMemo, useState } from "react";
import "../style/Post/post.scss";
import { Link } from "react-router-dom";
import ApiContent from "../api/ApiContent";
import IconComponent from "./Icons";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ApiComment from "../api/ApiComment";
import Swal from "sweetalert2";
import CSwl from "../utils/alert";
import Comment from './Comment'
import { WEB_IMAGE } from "../env";
import { ConvertDateShort } from "../utils/date";
import { useHistory } from 'react-router-dom'
import { Player } from 'video-react';
import ReactQuill from "react-quill";
import html2pdf from "html2pdf.js";
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisH, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { useClipboard } from 'use-clipboard-copy';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const PostLiveSharingDetail = ({
  name,
  image,
  imageComment,
  time,
  title,
  detail,
  subdetail,
  totalLike,
  totalComment,
  page,
  contentid,
  isLike,
  isBookmark,
  contentStyles,
  isComment = false,
  isCommentFirst = true,
  comments = [],
  item,
  onComment = () => { }
}) => {
  const { t, i18n } = useTranslation();
  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );
  const userState = useSelector((state) => state.user);
  const [contentRef, setContentRef] = useState([]);
  const [contentRefUser, setContentRefUser] = useState([]);
  const [tags, settags] = useState([]);
  const [videos, setvideos] = useState([]);
  const [docs, setdocs] = useState([]);
  const history = useHistory();
  const clipboard = useClipboard({ copiedTimeout: 800 });
  useEffect(() => {
    func_setData();
    if (item?.cttagtJson && item.cttagtJson != "[]") {
      let tags = JSON.parse(item.cttagtJson);

      settags(tags.map(x => x.Name));
    }
    if (item?.ctfilevideo && item.ctfilevideo != "[]") {
      let videos = JSON.parse(item.ctfilevideo);
      console.log(videos.map(x => process.env.REACT_APP_IMAGE + x.Path))
      setvideos(videos.map(x => process.env.REACT_APP_IMAGE + x.Path));
    }
    if (item?.ctfiledoc && item.ctfiledoc != "[]") {
      let docs = JSON.parse(item.ctfiledoc);
      setdocs(docs);
    }
    if (item?.refJson && item?.refJson != "[]") {
      setContentRef(JSON.parse(item.refJson));
    }
    if (item?.refUserJson && item?.refUserJson != "[]") {
      setContentRefUser(JSON.parse(item.refUserJson));
    }
  }, []);

  //state
  const [like, setLike] = useState(0);
  const [pIsLike, setPisLike] = useState(false);
  const [pIsBookMark, setPisBookmark] = useState(isBookmark);
  const [spam, setspam] = useState(false);
  const [options, setOptions] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [spamValue, setspamValue] = useState('spam');
  const [spamNote, setspamNote] = useState('');
  const [spamFormError, setspamFormError] = useState("");
  const [state, setstate] = useState({
    "contentId": contentid,
    "comment": "",
    contentType:"SHARING"
  })

  const [replyState, setreplyState] = useState({
    commentId: 0,
    createby: 0
  })

  const func_setData = () => {
    setLike(totalLike);
    setPisLike(isLike);
  };

  const func_like = async () => {
    const result = await ApiContent.LikeSharing(contentid);
    if (result.status == 200) {
      if (result.data.statusLike) {
        setLike(like + 1);
        setPisLike(true);
      } else {
        setLike(like - 1);
        setPisLike(false);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      state.replyToCommentId = replyState.commentId;
      state.replyToUser = replyState.createby;
      const created = await ApiComment.create(state);
      if (created.status == 200) {
        console.log('created::',created)
        setstate({ ...state, comment: "" })
        onComment(created.data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.title)
    }
  }

  const reply = async (comment) => {
    const input_comment = document.getElementById('input_comment');
    input_comment.focus();
    setreplyState({ commentId: comment.id, createby: comment.createby });
    setstate({ ...state, comment: `<a href="${window.location.origin}/profileDetail?id=${comment.createby}">@${comment.fullNameEng}</a>` })
  }

  const func_bookmark = async (e) => {
    // CSwl.SwalLoad();
    e.stopPropagation();
    const result = await ApiContent.Bookmark(item.id);
    if (result.status == 200) {
      if (result.data.statusBookmark) {
        setPisBookmark(true);
      } else {
        setPisBookmark(false);
      }
    }
    // CSwl.SwalClose();
  };

  // const _exportPdf = () => {
  //   var element = document.getElementById('element-to-print');
  //   window.html2pdf(element);
  // }

  const _exportPdf = async () => {
    var element = document.getElementById('element-to-print');
    const opt = {
      margin: 20,
      filename: title,
      //image: { type: 'jpeg', quality: 1 },
      // html2canvas: { scale: 3 },
      html2canvas: { dpi: 192, letterRendering: true },
      //jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
      image: { type: 'jpeg', quality: 0.98 },
      // filename: 'report.pdf',
      // html2canvas: { scale: 3 },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
    };


    // html2pdf().set(opt).from(element).save();

    html2pdf().from(element).set(opt).toPdf().get('pdf').then((pdf) => {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        // set footer to every page
        pdf.setPage(i);
        // set footer font
        pdf.setFont("helvetica");
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
        pdf.text(pdf.internal.pageSize.getWidth() - 30,
          pdf.internal.pageSize.getHeight() - 10, 'YOUR TEXT GOES HERE!');

        // you can add the line separator as an image, consult the docs below to properly set the place of the image
        // pdf.addImage(img, 'png', 0, 0, 52, 23)
      }
    }).save();
  }

  const renderBookmarkButton = () => {
    return <div
      className="pl-2 is-flex is-align-items-center is-absolute is-right-0 is-top-20 pr-5 pt-0"
    >
      <div style={{ cursor: "pointer" }} onClick={(e) => {
        func_bookmark(e);
      }}>
        {pIsBookMark ? (
          // <img
          //     className="img-size"
          //     src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
          //     alt="Like2"
          //     width="16"
          //     height="16"
          // />
          <IconComponent
            className="icon-20x ml-3" color="#000"
            icon={faBookmarkSolid}
          />
        ) : (
          <IconComponent
            className="icon-20x ml-3" color="#000"
            icon={faBookmark}
          />
        )}
      </div>
      <div style={{ position: 'relative' }}>
        <div className="cursor-pointer" onClick={(e) => {
          e.stopPropagation();
          setspam(!spam);
        }}>
          <IconComponent
            className="icon-20x ml-3" color="#333"
            icon={faEllipsisH}
          />
        </div>
        {spam && <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="spam-container">
          <ul>
            <li className="cursor-pointer" onClick={(e) => {
              e.stopPropagation();
              setisModal(true);
              setspamFormError("");
              setspam(false);
            }}>รายงานโพสต์นี้</li>
          </ul>
        </div>
        }

      </div>
    </div>
  }

  const renderEditButton = () => {
    return <div
      className="pl-2 is-flex is-align-items-center is-absolute is-right-0 is-top-10 p-5"
    >
      <div style={{ position: 'relative' }}>
        <div className="cursor-pointer" onClick={(e) => {
          e.stopPropagation();
          setOptions(!options);
        }}>
          <IconComponent
            className="icon-20x ml-3" color="#333"
            icon={faEllipsisH}
          />
        </div>
        {options && <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="spam-container">
          <ul>
            <li className="cursor-pointer" onClick={(e) => {
              e.stopPropagation();
              history.push(`editcontentSharing?c=${item.id}`);
            }}>แก้ไขโพสต์นี้</li>
          </ul>
        </div>
        }

      </div>
    </div>
  }

  const _sharePdf = async (e) => {
    const url = `${window.location.href}`;
    clipboard.copy(url);
  }

  const spamPost = async (e) => {
    e.stopPropagation();
    if (spamNote) {
      const result = await ApiContent.Spam({
        ctid: item.id,
        spamCode: spamValue,
        spamNote: spamNote
      });
      if (result.status == 200) {
        setspamValue('spam');
        setspamNote("")
        setisModal(false);
        CSwl.SwalSuccess("รายงานโพสต์นี้เรียบร้อยแล้ว")
      }
    } else {
      setspamFormError("กรุณากรอกเหตุผลในการรายงาน")
    }


  }





  var submitEl = React.useRef(null);


  return (
    <div id="post-com" key={contentid} className="card">
      {
        (item.profileid !== userState.profile.id) && renderBookmarkButton()
      }
      {
        (item.profileid == userState.profile.id) && renderEditButton()
      }
      <div className="content container-header">
        <div className="media is-justify-content-space-between is-align-items-center">
          <div className="media pt-5" style={{ borderTop: 0 }}>
            <div>
              <figure className="image is-48x48">
                <img
                  style={{ height: '100%', width: '100%' }}
                  alt={'image_profile'}
                  className="is-rounded image-cover cursor-pointer"
                  src={image}
                  onClick={() => history.push(
                    userState.profile.id == item.profileid ?
                      "/profile" :
                      `/profileDetail?id=${item.profileid}`
                  )}
                />
              </figure>
            </div>
            <div className="media-content title-header">
              <span onClick={() => history.push(
                userState.profile.id == item.profileid ?
                  "/profile" :
                  `/profileDetail?id=${item.profileid}`
              )} className="has-text-weight-bold cursor-pointer">{name}</span>
              <span className="has-text-grey-light">{time}</span>
            </div>
          </div>
          {/* <div className="is-flex is-align-items-center mr-3">
            <img
              className="mx-2"
              src={process.env.PUBLIC_URL + "/images/Menu/Vector.png"}
              alt="logo1"
              width="18"
              height="24"
            />
            <img
              className="mx-2"
              src={process.env.PUBLIC_URL + "/images/Menu/Vector6_grey.png"}
              alt="logo1"
              width="24"
              height="4"
            />
          </div> */}
        </div>
      </div>
      <div id="element-to-print">
        <div>
          <div className="container-header content p-4">
            <h3 className="has-text-link m-3 has-text-weight-bold">{title}</h3>
          </div>
          {item.coverimage &&
            <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
              <img src={`${process.env.REACT_APP_IMAGE}${item.coverimage}`} alt="image_content" class="size-img"></img>
            </div>
          }
          <div style={{ minHeight: 'inherit' }} className="ql-editor pt-0" >
            <p
              style={contentStyles}
              className='subtitle-c-p mx-3'
              dangerouslySetInnerHTML={{ __html: detail }}
            />
            {page !== 'detail' &&
              <div className="mt-2 mx-3">
                <Link to={`/contentDetail?id=${contentid}`}>
                  <span className="has-text-danger">อ่านต่อ </span>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
      {docs.length > 0 &&
        <div className="d-flex p-5 flex-column">
          {docs && docs.map((item, index) => {
            return (
              <div onClick={() =>
                window.open(`${process.env.REACT_APP_IMAGE}${encodeURIComponent(item.Path).replace("%2F", "/")}`, '_blank')
              } className="file-container w-100">
                {item.Name}
              </div>
            )
          })}
        </div>
      }





      <div className="d-flex">
        {videos && videos.map((item, index) => {
          return (
            <Player>
              <source src={item} />
            </Player>
          )
        })}
       
        {
          item.cturl && <div className="mb-2 mt-2" style={{ maxWidth: 1280 }}>
            {/* <iframe width="1280" height="720"
            //src="https://web.microsoftstream.com/embed/video/bdbf6d1a-59d8-4eab-acd3-6f3ce918ce94?autoplay=false&showinfo=true"
            src={"https://teams.microsoft.com/convene/meetings/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4__f44c78f1-e2bb-49db-8d2c-3f5519aab7ed__19:meeting_ZjkxNmFhNmMtYjBjMC00OTljLWIzOGMtZTk0ZWQzMTNmMTYx@thread.v2__0/join?embed=true"}
             allowfullscreen style={{ border: 'none', maxWidth: '100%' }}></iframe> */}
             <div id="iframeMSTeam" dangerouslySetInnerHTML={{__html:item.cturl}}></div>
          </div>
        }
      </div>
      <div className="mt-2 d-flex flex-wrap">
        {tags && tags.map((item, index) => {
          return (
            <div style={{ marginTop: 10 }} onClick={() => {
              history.push(`/tag?q=${item}`);
            }}
              className="pl-5 is-flex is-align-items-center">
              <span className="cursor-pointer is-size-7 tag">{item}</span>
            </div>
          )
        })}
      </div>
      {(contentRef && contentRef.length > 0) &&
        <>
          <div className="d-flex flex-column p-5">
            <label className="has-text-weight-semibold">โครงการอ้างอิง</label>
            {
              contentRef.map((item, index) => {
                return (
                  <div style={{ marginTop: 10 }} className="is-flex is-align-items-center">
                    <div
                      onClick={(e) => {
                        //  history.replace(`contentDetail?id=${item.id}`)
                        window.location.href = `contentDetail?id=${item.id}`;
                      }}
                      className="tag cursor-pointer" style={{ fontSize: 12, whiteSpace: 'initial', height: 'auto' }}>
                      <span>{item.ctname}</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </>
      }
      {(contentRefUser && contentRefUser.length > 0) &&
        <>
          <div className="d-flex flex-column" style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
            <label className="has-text-weight-semibold">{t('text.referal')}</label>
            <div className="d-flex">
              {
                contentRefUser.map((item, index) => {
                  return (
                    <div style={{ marginTop: 10 }} className="is-flex is-flex-directions-row is-align-items-center mr-2">
                      <div
                        onClick={(e) => {
                          //  history.replace(`contentDetail?id=${item.id}`)
                          window.location.href = `profileDetail?id=${item.id}`;
                        }}
                        className="tag cursor-pointer" style={{ fontSize: 12, whiteSpace: 'initial', height: 'auto' }}>
                        <span>{item.firstNameEnglish + " " + item.lastNameEnglish}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </>
      }
      <div className="content container section-item flex-wrap">
        <div className="is-flex">
          <div className="pl-2 is-flex is-align-items-center">
            <img
              className="img-size"
              src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
              alt="like"
              width="24"
              height="24"
            />
            <span className="subtitle pl-2">{like ?? 0}</span>
          </div>
          <div className="pl-4 is-flex is-align-items-center">
            <img
              className="img-size"
              src={process.env.PUBLIC_URL + "/images/firstPage/Comment.png"}
              alt="comment"
              width="24"
              height="24"
            />
            <span className="subtitle pl-2">{totalComment}</span>

          </div>

        </div>

      </div>
      <hr className="hr-size" />
      <div className="content container">
        <div
          className="pl-2 is-flex is-align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            func_like();
          }}
        >
          {pIsLike ? (
            <img
              className="img-size"
              src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
              alt="Like2"
              width="24"
              height="24"
            />
          ) : (
            <img
              className="img-size"
              src={process.env.PUBLIC_URL + "/images/firstPage/Like2.png"}
              alt="Like2"
              width="24"
              height="24"
            />
          )}
          <span className="subtitle pl-2">{t('text.like')}</span>
        </div>
        <div className="pl-4 is-flex is-align-items-center cursor-pointer">
          <img
            className="img-size"
            src={process.env.PUBLIC_URL + "/images/firstPage/Comment2.png"}
            alt="Comment2"
            width="24"
            height="24"
          />
          <span className="subtitle pl-2">{t('text.comment')}</span>
        </div>
        <div onClick={() => _sharePdf()} className="pl-4 is-flex is-align-items-center cursor-pointer">
          <img
            className="img-size"
            src={process.env.PUBLIC_URL + "/images/firstPage/share.png"}
            alt="Share"
            width="24"
            height="24"
          />
          <span className="subtitle pl-2">{clipboard.copied ? 'คัดลอกสำเร็จ' : t('text.share')}</span>
        </div>
      </div>

      {isComment && <>
        <hr className="hr-size" />
        <form onSubmit={onSubmit}>
          <div className="content container d-flex is-align-items-center">
            <img
              alt={'image_profile'}
              className="is-rounded image-profile-50 mr-3"
              src={imageComment}
            />
            {/* <textarea
              value={state.comment}
              onChange={(e) => {
                if (!(e.target.value.includes('@'))) {
                  setreplyState({ commentId: 0, createby: 0 })
                }
                setstate({ ...state, comment: e.target.value });
              }}

              // dangerouslySetInnerHTML={{ __html: state.comment }}
              className="input is-rounded has-background-light search "
              type="text"
              id="input_comment"
              required
              placeholder={t('text.comment')} /> */}
            <ReactQuill
              className="w-100"
              id="input_comment"
              placeholder={t('text.comment')}
              value={state.comment}
              modules={modules}
              onChange={(content, delta, source, editor) => {
                setstate({ ...state, comment: content });
              }}
            />
            <div className="cursor-pointer" onClick={() => {
              submitEl.current.click();
            }
            }>
              <IconComponent className="icon-20x ml-3" color="#888" icon={faPaperPlane} />
            </div>
            <button type="submit" ref={submitEl} style={{ display: 'none' }}></button>
          </div>
        </form>
      </>
      }
      {
        comments.map((item, index) => {
          return <Comment
            contentid={contentid}
            key={item.id}
            fullName={item.fullNameEng}
            image={item.imageProfile ? process.env.REACT_APP_IMAGE + item.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
            comment={item.comment}
            time={ConvertDateShort(item.createdate, "DD MMM YYYY HH:mm น.")}
            item={item}
            isLike={item.isLike}
            reply={reply}
          />
        }
        )
      }
      <div class={`modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
          <div className="d-flex-center flex-column">
            <h1>รายงานโพสต์</h1>
            <div className="control flex-column d-flex-center mt-5" style={{ alignItems: 'flex-start', minWidth: 300 }}>
              <label className="radio ml-0" >
                <input defaultChecked={true}
                  onChange={(e) => setspamValue(e.target.value)} type="radio"
                  name="radio-spam" value="spam" />
                สแปม
              </label>
              <label class="radio ml-0">
                <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="harassment" />
                ล่วงละเมิดทางเพศ
              </label>
              <label class="radio ml-0">
                <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="rules" />
                มีการละเมิดกฏ
              </label>
              <label class="radio ml-0">
                <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="spam_other" />
                อื่นๆ
              </label>
              <textarea value={spamNote} onChange={(e) => setspamNote(e.target.value)} placeholder='คำอธิบาย' required rows={4} className='p-2' style={{ width: '100%' }}>

              </textarea>
              {spamFormError && <span className='has-text-danger'>{spamFormError}</span>}
            </div>
            <div className="mt-5 d-flex">
              <button
                onClick={() => {
                  setisModal(false);
                }}
                className="button is-light is-link is-rounded mr-5"
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
              <button
                onClick={(e) => {
                  spamPost(e);
                }}
                className="button is-link is-rounded"
              >
                <div className="font-button">รายงาน</div>
              </button>
            </div>
          </div>
        </div>
        <button onClick={() => {
          setisModal(false);
        }}
          class="modal-close is-large" aria-label="close"></button>
      </div>
    </div >
  );
};

export default PostLiveSharingDetail;
