import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import ApiContent from '../api/ApiContent';
import CSwl from '../utils/alert';
import IconComponent from './Icons';
import { faEllipsisH, faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import html2pdf from "html2pdf.js";
import './PostV2.scss'
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { useClipboard } from 'use-clipboard-copy';
import { useTranslation } from "react-i18next";
const MainPost =
    (
        { image, name, title, detail, page, item, isLike, isBookmark, totalLike, time, mode = "normal", comments = [], stateOrg = [] }
    ) => {
        const { t, i18n } = useTranslation();
        const [spamFormError, setspamFormError] = useState("");
        const userState = useSelector((state) => state.user);
        const [imageFirst, setimageFirst] = useState('');
        const [coverImage, setCoverImage] = useState('');
        const [detailRemoveImage, setdetailRemoveImage] = useState(detail)
        const history = useHistory();
        const [like, setLike] = useState(totalLike);
        const [pIsLike, setPisLike] = useState(isLike);
        const [pIsBookMark, setPisBookmark] = useState(isBookmark);
        const [tags, settags] = useState([]);
        const [catalogs, setcatalogs] = useState([]);
        const [spam, setspam] = useState(false);
        const [options, setOptions] = useState(false);
        const [isModal, setisModal] = useState(false);
        const [spamValue, setspamValue] = useState('spam');
        const [spamNote, setspamNote] = useState('');
        const clipboard = useClipboard({ copiedTimeout: 800 });
        useEffect(() => {
            if (detail.includes('<img')) {
                // var str = detail;
                // var elem = document.createElement('div');
                // elem.style.display = 'none';
                // document.body.appendChild(elem);
                // elem.innerHTML = str;
                // setimageFirst(elem.querySelector('img').src);
                var content = detail.replace(/<img[^>]*>/g, "");
                setdetailRemoveImage(content);
            }
            if (item.cttagtJson != "[]") {
                let tags = JSON.parse(item.cttagtJson);
                settags(tags.map(x => x.Name));
            }
            if (item.ctcatagoryJson != "[]") {
                let catagory = JSON.parse(item.ctcatagoryJson);
                setcatalogs(catagory.map(x => x.Name));
            }
            if (item.coverimage && item.coverimage != "") {
                setCoverImage(item.coverimage);
            }
            return () => {

            }
        }, [])

        const func_like = async (e) => {
            // CSwl.SwalLoad();
            // e.stopPropagation();
            const result = await ApiContent.Like(item.id);
            if (result.status == 200) {
                if (result.data.statusLike) {
                    setLike(like + 1);
                    setPisLike(true);
                } else {
                    setLike(like - 1);
                    setPisLike(false);
                }
            }
            // CSwl.SwalClose();
        };

        const func_bookmark = async (e) => {
            // CSwl.SwalLoad();
            e.stopPropagation();
            const result = await ApiContent.Bookmark(item.id);
            if (result.status == 200) {
                if (result.data.statusBookmark) {
                    setPisBookmark(true);
                } else {
                    setPisBookmark(false);
                }
            }
            // CSwl.SwalClose();
        };

        const func_edit = async (e) => {
            e.stopPropagation();
            history.push(`editcontent?c=${item.id}`);
        };

        const func_tag = async (e, item) => {
            e.stopPropagation();
            history.push(`/tag?q=${item}`);
        };

        const func_catalog = async (e, item) => {
            e.stopPropagation();
            history.push(`/catalog?q=${item}`);
        };

        const spamPost = async (e) => {
            e.stopPropagation();
            if (spamNote) {
                const result = await ApiContent.Spam({
                    ctid: item.id,
                    spamCode: spamValue,
                    spamNote: spamNote
                });
                if (result.status == 200) {
                    setspamValue('spam');
                    setspamNote("")
                    setisModal(false);
                    CSwl.SwalSuccess("รายงานโพสต์นี้เรียบร้อยแล้ว")
                }
            } else {
                setspamFormError("กรุณากรอกเหตุผลในการรายงาน")
            }


        }

        const _exportPdf = async (e) => {
            CSwl.SwalLoad();
            console.log(e);
            var element = e.target.nextElementSibling;

            element.style.display = "inherit";
            const opt = {
                margin: 20,
                filename: title,
                //image: { type: 'jpeg', quality: 1 },
                // html2canvas: { scale: 3 },
                html2canvas: { dpi: 192, letterRendering: true },
                //jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
                image: { type: 'jpeg', quality: 0.98 },
                // filename: 'report.pdf',
                // html2canvas: { scale: 3 },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
            };

            html2pdf().from(element).set(opt).toPdf().get('pdf').then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    // set footer to every page
                    pdf.setPage(i);
                    // set footer font
                    pdf.setFont("helvetica");
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
                    pdf.text(pdf.internal.pageSize.getWidth() - 30,
                        pdf.internal.pageSize.getHeight() - 10, 'YOUR TEXT GOES HERE!');

                    // you can add the line separator as an image, consult the docs below to properly set the place of the image
                    // pdf.addImage(img, 'png', 0, 0, 52, 23)
                }
            }).save();
            setTimeout(() => {
                element.style.display = "none";
                CSwl.SwalClose();
            }, 1000);
        }

        const _sharePdf = async (e) => {
            const url = `${window.location.origin}/contentDetail?id=${item.id}`;
            clipboard.copy(url);
        }



        const renderBookmarkButton = () => {
            return <div
                className="pl-2 is-flex is-align-items-center is-absolute is-right-15"
            >
                <div style={{ cursor: "pointer" }} onClick={(e) => {
                    func_bookmark(e);
                }}>
                    {pIsBookMark ? (
                        // <img
                        //     className="img-size"
                        //     src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
                        //     alt="Like2"
                        //     width="16"
                        //     height="16"
                        // />
                        <IconComponent
                            className="icon-20x ml-3" color="#000"
                            icon={faBookmarkSolid}
                        />
                    ) : (
                        <IconComponent
                            className="icon-20x ml-3" color="#000"
                            icon={faBookmark}
                        />
                    )}
                </div>
                <div style={{ position: 'relative' }}>
                    <div className="cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        setspam(!spam);
                    }}>
                        <IconComponent
                            className="icon-20x ml-3" color="#333"
                            icon={faEllipsisH}
                        />
                    </div>
                    {spam && <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="spam-container">
                        <ul>
                            <li className="cursor-pointer" onClick={(e) => {
                                e.stopPropagation();
                                setisModal(true);
                                setspamFormError("");
                                setspam(false);
                            }}>รายงานโพสต์นี้</li>
                        </ul>
                    </div>
                    }

                </div>
            </div>
        }

        const renderEditButton = () => {
            console.log('renderEdit::',userState)
            return <div
                className="pl-2 is-flex is-align-items-center is-absolute is-right-15"
            >
                <div style={{ position: 'relative' }}>
                    <div className="cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        setOptions(!options);
                    }}>
                        <IconComponent
                            className="icon-20x ml-3" color="#333"
                            icon={faEllipsisH}
                        />
                    </div>
                    {options && <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        className="spam-container">
                        <ul>
                            <li className="cursor-pointer" onClick={(e) => {
                                e.stopPropagation();
                                history.push(`editcontent?c=${item.id}`);
                            }}>แก้ไขโพสต์นี้</li>
                        </ul>
                    </div>
                    }

                </div>
            </div>
        }

        const renderOrg = (type) => {
            if (type == "bu" && item.bu) {
                const bu = stateOrg?.orgData.filter(x => item.bu.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">BU :&nbsp;</span> {bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "subbu" && item.subbu) {
                const bu = stateOrg?.orgData.filter(x => item.subbu.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Sub BU :&nbsp;</span> {bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "company" && item.company) {
                const bu = stateOrg?.orgData.filter(x => item.company.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Company :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "division" && item.division) {
                const bu = stateOrg?.orgData.filter(x => item.division.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Division :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "sub_division" && item.sub_division) {
                const bu = stateOrg?.orgData.filter(x => item.sub_division.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Sub Division :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "department" && item.department) {
                const bu = stateOrg?.orgData.filter(x => item.department.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Department :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(', ')}</span>
                </div>
            }
            else if (type == "sub_department" && item.sub_department) {
                const bu = stateOrg?.orgData.filter(x => item.sub_department.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Sub Department :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(',')}</span>
                </div>
            }
            else if (type == "section" && item.section) {
                const bu = stateOrg?.orgData.filter(x => item.section.includes(x.departmentId));

                return <div className="">
                    <span className="is-size-7 tag !whitespace-normal"><span className="font-bold !whitespace-nowrap">Section :&nbsp;</span>{bu.map(x => x.organizationNameEn)?.join(', ')}</span>
                </div>
            }
        }

        const checkPermission = () => {
            if (item.isSpecific == 1 && !userState.isEdit) {

                let isLocked = false;

                if (item.subbu && !userState.employee.departmentBuId != "") {
                    isLocked = true;
                }
                else if (item.bu && !item.bu.includes(userState.employee.departmentBuId)) {
                    isLocked = true;
                }

                if (item.subbu && !userState.employee.departmentBsg2Id != "") {
                    isLocked = true;
                }
                else if (item.subbu && !item.subbu.includes(userState.employee.departmentBsg2Id)) {
                    isLocked = true;
                }

                if (item.company && !userState.employee.departmentComId != "") {
                    isLocked = true;
                }
                else if (item.company && !item.company.includes(userState.employee.departmentComId)) {
                    isLocked = true;
                }

                if (item.division && !userState.employee.departmentDivId != "") {
                    isLocked = true;
                }
                else if (item.division && !item.division.includes(userState.employee.departmentDivId)) {
                    isLocked = true;
                }

                if (item.department && !userState.employee.departmentDepId != "") {
                    isLocked = true;
                }
                else if (item.department && !item.department.includes(userState.employee.departmentDepId)) {
                    isLocked = true;
                }


                if (item.section && !userState.employee.departmentSecId != "") {
                    isLocked = true;
                }
                else if (item.section && !item.section.includes(userState.employee.departmentSecId)) {
                    isLocked = true;
                }

                if (isLocked) {
                    return "post-locked";
                }
            }
        }

        return (
            <>
                <div
                    id="subpost" className={`columns block is-relative ${checkPermission()}`}>
                    {
                        (item.profileid !== userState.profile.id) && renderBookmarkButton()
                    }

                    {
                        (item.profileid == userState.profile.id || userState.isEdit) && renderEditButton()
                    }
                   
                    <div class={`column ${imageFirst || coverImage ? " is-8" : ""} pb-0`}>

                        <div id="post-share-pdf">
                            <div className="d-flex is-align-items-center">
                                <div
                                    onClick={() => history.push(
                                        userState.profile.id == item.profileid ?
                                            "/profile" :
                                            `/profileDetail?id=${item.profileid}`
                                    )}
                                    className="d-flex is-align-items-center flex-1 cursor-pointer">
                                    <figure className={`image is-48x48`}>
                                        <img
                                            style={{ height: '100%', width: '100%' }}
                                            // alt={'image_profile'}
                                            className={`is-rounded image-cover ${item.smeCategory ? `border-[#f97316] border-4` : ""}`}
                                            src={image}
                                        />
                                    </figure>
                                    <div className='d-flex flex-column ml-3'>
                                        <span className="has-text-weight-bold is-size-7">{name}</span>
                                        <span className="has-text-grey-light is-size-10">{time}</span>
                                    </div>


                                </div>

                            </div>

                            <div className="cursor-pointer" onClick={() => {
                                if (mode !== "edit") {
                                    window.location.href = `/contentDetail?id=${item.id}`;
                                }
                            }}>
                                <div className="mt-3 ml-1">
                                    <h2 className="has-text-weight-bold is-size-4">{title}</h2>
                                    <h3 className="description-short">
                                        <p dangerouslySetInnerHTML={{ __html: detailRemoveImage }}></p>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2 d-flex flex-wrap" style={{ marginLeft: '-1rem' }}>
                            {catalogs && catalogs.map((item, index) => {
                                if (index > 4) {
                                    return false;
                                }
                                return (
                                    <div onClick={(e) => {
                                        func_catalog(e, item)
                                    }} className="pl-4 is-flex is-align-items-center cursor-pointer">
                                        <span className="is-size-7 tag">{item}</span>
                                    </div>
                                )
                            })}
                            {tags && tags.map((item, index) => {
                                if (index > 4) {
                                    return false;
                                }
                                return (
                                    <div onClick={(e) => {
                                        func_tag(e, item)
                                    }} className="pl-4 is-flex is-align-items-center cursor-pointer">
                                        <span className="is-size-7 tag">#{item}</span>
                                    </div>
                                )
                            })}
                        </div>
                        {
                            (item.isSpecific == 1 && stateOrg?.orgData) &&
                            <div className='flex flex-wrap gap-x-2 mt-2 gap-y-2  py-2'>
                                {
                                    renderOrg('bu')
                                }
                                {
                                    renderOrg('subbu')
                                }
                                {
                                    renderOrg('company')
                                }
                                {
                                    renderOrg('division')
                                }
                                 {
                                    renderOrg('sub_division')
                                }
                                {
                                    renderOrg('department')
                                }
                                 {
                                    renderOrg('sub_department')
                                }
                                {
                                    renderOrg('section')
                                }
                            </div>
                        }
                        {mode == "edit" && <div className="d-flex mt-2">
                            <button
                                onClick={(e) => {
                                    func_edit(e);
                                }}
                                className="button is-link is-rounded"
                            >
                                <div className="font-button">{t('text.edit')}</div>
                            </button>
                            {tags && tags.map((item, index) => {
                                return (
                                    <div
                                        className="pl-4 is-flex is-align-items-center">
                                        <span className="cursor-pointer is-size-7 tag">{item}</span>
                                    </div>
                                )
                            })}
                        </div>}
                        {mode !== "edit" && <div className="d-flex mt-3 is-align-items-center flex-wrap">
                            <div className="flex-1 d-flex is-align-items-center">
                                <div
                                    className="pl-2 is-flex is-align-items-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        func_like(e);
                                    }}
                                >
                                    {pIsLike ? (
                                        <img
                                            className="img-size"
                                            src={process.env.PUBLIC_URL + "/images/firstPage/Like.png"}
                                            alt="Like2"
                                            width="16"
                                            height="16"
                                        />
                                    ) : (
                                        <img
                                            className="img-size"
                                            src={process.env.PUBLIC_URL + "/images/firstPage/Like2.png"}
                                            alt="Like2"
                                            width="16"
                                            height="16"
                                        />
                                    )}
                                    <span className="pl-2">{t('text.like')} {`(${like ?? 0})`}</span>
                                </div>
                                <div onClick={() => {
                                    window.location.href = `/contentDetail?id=${item.id}`;
                                }} className="pl-4 is-flex is-align-items-center cursor-pointer">
                                    <img
                                        className="img-size"
                                        src={process.env.PUBLIC_URL + "/images/firstPage/Comment2.png"}
                                        alt="Comment2"
                                        width="16"
                                        height="16"
                                    />
                                    <span className="pl-2">{t('text.comment')} {`(${comments.length})`}</span>
                                </div>
                                <div onClick={(e) => _sharePdf(e)} className="pl-4 is-flex is-align-items-center cursor-pointer">
                                    <img
                                        className="img-size"
                                        src={process.env.PUBLIC_URL + "/images/firstPage/share.png"}
                                        alt="Share"
                                        width="16"
                                        height="16"
                                    />
                                    <span className="pl-2">{clipboard.copied ? 'คัดลอกสำเร็จ' : t('text.share')}</span>
                                    <div className="element-to-print" style={{ display: 'none' }}>
                                        <div>
                                            <div className="container-header content p-4">
                                                <h3 className="has-text-link m-3 has-text-weight-bold">{title}</h3>
                                            </div>
                                            <div style={{ minHeight: 'inherit' }} className="ql-editor pt-0" >
                                                <p

                                                    className='subtitle-c-p mx-3'
                                                    dangerouslySetInnerHTML={{ __html: detail }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="pl-4 is-flex is-align-items-center">
                                    <span className="is-size-7">{time}</span>
                                </div> */}
                            </div>

                        </div>
                        }

                    </div>
                    {coverImage &&
                        <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
                            <img src={`${process.env.REACT_APP_IMAGE}${coverImage}`} alt="image_content" class="size-img" width="160" height="160"></img>
                        </div>
                    }
                    {(imageFirst && !coverImage) &&
                        <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
                            <img src={imageFirst} alt="image_content" class="size-img" width="160" height="160"></img>
                        </div>
                    }

                </div>
                <div class={`modal ${isModal ? "d-flex" : ""}`}>
                    <div class="modal-background"></div>
                    <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
                        <div className="d-flex-center flex-column">
                            <h1>รายงานโพสต์</h1>
                            <div className="control flex-column d-flex-center mt-5" style={{ alignItems: 'flex-start', minWidth: 300 }}>
                                <label className="radio ml-0" >
                                    <input defaultChecked={true}
                                        onChange={(e) => setspamValue(e.target.value)} type="radio"
                                        name="radio-spam" value="spam" />
                                    สแปม
                                </label>
                                <label class="radio ml-0">
                                    <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="harassment" />
                                    ล่วงละเมิดทางเพศ
                                </label>
                                <label class="radio ml-0">
                                    <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="rules" />
                                    มีการละเมิดกฏ
                                </label>
                                <label class="radio ml-0">
                                    <input onChange={(e) => setspamValue(e.target.value)} type="radio" name="radio-spam" value="spam_other" />
                                    อื่นๆ
                                </label>
                                <textarea value={spamNote} onChange={(e) => setspamNote(e.target.value)} placeholder='คำอธิบาย' required rows={4} className='p-2' style={{ width: '100%' }}>

                                </textarea>
                                {spamFormError && <span className='has-text-danger'>{spamFormError}</span>}
                            </div>
                            <div className="mt-5 d-flex">
                                <button
                                    onClick={() => {
                                        setisModal(false);
                                    }}
                                    className="button is-light is-link is-rounded mr-5"
                                >
                                    <div className="font-button">{t("menu.cancel")}</div>
                                </button>
                                <button
                                    onClick={(e) => {
                                        spamPost(e);
                                    }}
                                    className="button is-link is-rounded"
                                >
                                    <div className="font-button">รายงาน</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => {
                        setisModal(false);
                    }}
                        class="modal-close is-large" aria-label="close"></button>
                </div>
            </>
        )
    }

export default MainPost;