// eslint-disable-next-line no-undef
const InitialState = {
    "id": 0,
    "profileid": 0,
    "ctrefno": "",
    "ctstartdate": new Date(),
    "ctenddate": new Date(),
    "cturl":"",
    "ctname": "",
    "ctdeschtml": "",
    "ctlikeAmt": 0,
    "ctcommentAmt": 0,
    "ctstatus": "A",
    "ctcatagoryid": "",
    "ctcatagoryJson": [],
    "cttagid": "",
    "cttagtJson": [],
    "ctfavProfileJson": "",
    "isVerify": 0,
    "isSpecific": 0,
    "bu":[],
    "subbu":[],
    "company":[],
    "division":[],
    "department":[],
    "section":[],
}
export default InitialState;