import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiOrg extends Component {

  static getOrg = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtOrgNew/GetOrg`,
      method: "get",
    });
    return result;
  };

}

export default ApiOrg;
