import React from "react";
import { Link } from "react-router-dom";
import ListTrend from "./ListTrend";
import "../style/Cardlist/Cardlist.scss";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const ProfileTopRankList = ({ title, data, topage = "" }) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const datas = data;
    return (
        <div
            id="Cardlist-com"
            className="card p-4 "
            style={{
                backgroundColor: "#f6f8fa",
                borderRadius: "1rem",
                boxShadow: "0 0em 0em",
            }}
        >
            <div className="is-flex is-justify-content-space-between">
                <h1 className="header">{title}</h1>
            </div>
            {datas.slice(0, 3).map((d, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item is-align-item-center">
                                <li className="is-flex gap-x-2">
                                    <Link to={d.profileID > 0 && `profileDetail?id=${d.profileID}`} className="is-flex cursor-pointer">
                                        <figure className="image is-48x48">
                                            <img className="is-rounded image-cover"
                                                style={{ width: '100%', height: '100%' }}
                                                src={d.image} />
                                        </figure>


                                    </Link>
                                    <Link to={d.profileID > 0 && `profileDetail?id=${d.profileID}`} className="is-flex cursor-pointer">
                                        <div className="d-flex flex-column gap-y-1">
                                            <div className="flex gap-x-1 items-center">
                                                <div className="bg-[#f97316] px-2 left-7">
                                                    <span className="text-white text-sm">Rank {index + 1}</span>
                                                </div>
                                                <p className="is-flex is-align-items-center has-text-black has-text-weight-bold">
                                                    {d.name}
                                                </p>
                                            </div>
                                            <div className="row">
                                                <div className="is-flex gap-x-2">
                                                    <div className="is-flex is-flex-direction-column">
                                                        <p className="sub-title1">{t('text.content')}</p>
                                                        <p className="sub-title1">{t('menu.follower')}</p>
                                                    </div>
                                                    <div className="is-flex is-flex-direction-column">
                                                        <p className="sub-title1">{d.totalArticle}</p>
                                                        <p className="sub-title1">{d.totalFollow}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <hr className="hr-s" />
                            </li>
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};


export default ProfileTopRankList;
