import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import ProfileCard from "../../../components/ProfileCard";
import Post from "../../../components/Post";
import Cardlist from "../../../components/Cardlist";
import CardTaglist from "../../../components/CardTaglist";
import ApiContent from "../../../api/ApiContent";
import { ConvertDateShort } from "../../../utils/date";
import { WEB_IMAGE } from "../../../env";
import ApiFollow from "../../../api/ApiFollow";
import MenuCatalog from "../../../components/MenuCatalog";
import ApiCategory from "../../../api/ApiCategory";
import MainPost from "../../../components/PostV2";
import ApiMtTag from "../../../api/ApiTag";
import CSwl from "../../../utils/alert";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileList from "../../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import ProfileTopRankList from "../../../components/ProfileTopRank";
import { useSelector } from "react-redux";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const Explore = () => {
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const [profileFollow, setProfileFollow] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [catagory, setcatagory] = useState([]);
  const [limit, setlimit] = useState(30);
  const [loadingMore, setloadingMore] = useState(false);
  const [creatorTop, setCreatorTop] = useState([]);
  const languageState = useSelector((state) => state.language);
  useEffect(() => {
    CSwl.SwalLoad();
    //const promise1 = getCatagory();
    const promise2 = getContent();
    const promise3 = getProfileFollow();
    const promise4 = getProfileUnFollow();
    const promise5 = getToptag();
    const promise6 = getCreator();
    Promise.all([promise2, promise3, promise4, promise5, promise6]).then((results) => {
      CSwl.SwalClose();
    })
    return () => { };
  }, []);
  const getCreator = async () => {
    const result = await ApiContent.getCreator();
    if (result.status == 200) {
      setCreatorTop(result.data.data);
    }
  };
  const getContent = async () => {
    const result = await ApiContent.get(state);
    console.log(result);
    if (result.status == 200) {
      setcontents(result.data.data);
    }
  };
  const getProfileFollow = async () => {
    const result = await ApiFollow.GetTranProfileForFollow();

    if (result.status == 200) {
      setProfileFollow(result.data.data);
    }
  };
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  useEffect(() => {
    getCatagory(limit);
  }, [limit]);

  const getCatagory = async (limit) => {
    const result = await ApiCategory.MenuGetList(limit);
    const { data } = result.data;
    setcatagory(data);
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };

  const func_loadmorelimit = () => {
    setlimit(limit + 10);
  };

  const getContentLoadmore = async () => {
    const result = await ApiContent.get(state);
    if (result.status == 200) {
      setcontents({
        ...contents,
        items: [...contents.items, ...result.data.data.items]
      });
    }
  };

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 })
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then(results => {
        setloadingMore(false);
      })
    }
    return () => {
    }
  }, [state])

  return (
    <div id="explore-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.explore')} />
      </Drawer>
      <Header />
      <div className="columns pr-4 lg:pr-0">
        {/* section menu */}
        <div className="column mr-4 lg:mr-0 is-hidden-mobile is-2 layer">
          <MenuCatalog
            header={t('menu.explore')}
            data={catagory}
            onChangeloadlimit={() => {
              func_loadmorelimit();
            }}
            istag={true}
          />
        </div>
        {/* section post */}
        <div className="column is-7 md:!flex-1 middle-container layer2">
          <div className="is-flex is-justify-content-space-between px-4 layer3">
            <div to="/" className="has-text mr-6 is-flex is-align-items-center">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/firstPage/fi-rr-portrait1.png"
                }
                alt="logo1"
                width="24"
                height="24"
              />
              <strong className="m-3">{t('text.who_follow')}</strong>
            </div>
            {/* <Link to='/' className='button is-light is-outlined ' style={{ color: 'black', fontWeight: 'bold' }}>
              ดูทั้งหมด
            </Link> */}
          </div>
          <hr className=" hidden-b my-1" />
          <div className="row">
            <div className="is-flex" style={{ overflow: "auto" }}>
              {profileFollow.map((d, index) => {
                return (
                  <div className="column size-procard" key={index}>
                    <ProfileCard
                      image={
                        d.image
                          ? process.env.REACT_APP_IMAGE + d.image
                          : process.env.PUBLIC_URL +
                          "/images/profilePage/Profile.png"
                      }
                      profile={d}
                      profileid={d.profileID}
                      name={d.fullNameEng}
                      depart={d.deptThai}
                      level={d.rankEng}
                      totalArticle={d.countContent}
                      totalFollow={d.countFollow}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <h2 className="fontH mb-2 mt-2">{`${t('text.all_content')} ${`(${contents.totalItems ?? 0})`}`}</h2>
          {/* <div className="ar" style={{ marginBottom: 15 }}></div> */}
          <div className="ar"></div>
          {contents &&
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  return (
                    <div className="row fn ar" key={d.id}>
                      <MainPost
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm", languageState)}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          }
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden lg:block"
        >
          <div className="is-3 flex-1" style={{ position: "", width: 'auto' }}>
            <div className="mt-3 mr-6">
              {
                creatorTop.length > 0 ? <ProfileTopRankList
                  title={t('text.topActive')}
                  data={creatorTop.map((x) => {
                    return {
                      name: x.fullNameEng,
                      image: x.image
                        ? process.env.REACT_APP_IMAGE + x.image
                        : process.env.PUBLIC_URL +
                        "/images/profilePage/Profile.png",
                      profileID: x.profileID,
                      totalArticle: x.countContent,
                      totalFollow: x.countFollow
                    };
                  })}
                  topage={"unfollow"}
                /> :
                  <div
                    id="Cardlist-com"
                    className="card p-4 "
                    style={{
                      backgroundColor: "#f6f8fa",
                      borderRadius: "1rem",
                      boxShadow: "0 0em 0em",
                    }}
                  >
                    <div className="is-flex is-justify-content-space-between">
                      <h1 className="header">{t('text.topActive')}</h1>
                    </div>
                    <div className="flex flex-col">
                      <span>พื้นที่นี้กำลังรอคุณอยู่...
                      </span>
                      <span>เพียงคุณ publish ความรู้ ที่เป็นประโยชน์แก่เพื่อนชาว SCGP มากที่สุดภายในเดือน</span>
                    </div>
                  </div>
              }

            </div>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                    smeCategory:x.smeCategory
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Explore;
