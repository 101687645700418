import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import CardTaglist from "../../components/CardTaglist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import MainPost from "../../components/PostV2Approve";
import CSwl from "../../utils/alert";
import ApiNotification from "../../api/ApiNotification";
import ApiAuth from "../../api/ApiAuth";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileList from "../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const ProjectExpApprove = () => {
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const [reason, setReason] = useState("");
  const [isModal, setisModal] = useState(false);
  const [itemSelected, setitemSelected] = useState({});
  const dispatch = useDispatch();
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const [loadingMore, setloadingMore] = useState(false);
  const [profileFollow, setProfileFollow] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };
  const userState = useSelector((state) => state.user);
  const tokenFirebase = useSelector((state) => state.tokenFirebase);

  const func_reset = async () => {
    getContent();
  };

  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getContent();
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();

    Promise.all([promise1, promise3, promise4]).then(
      (results) => {
        CSwl.SwalClose();
      },
    );

    return () => { };
  }, []);

  const getContent = async () => {
    const result = await ApiContent.getListApproveProjectExp(state);
    if (result.status == 200) {
      setcontents(result.data.data);
    }
  };

  const func_Approve = async (id) => {
    CSwl.SwalLoad();
    try {
      const result = await ApiContent.ApproveProjectExp({
        id: id,
      });
      if (result.status == 200) {
        CSwl.SwalSuccess("อนุมัติเรียบร้อย").then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      try {
        const { data } = error.response;
        CSwl.SwalErr(data.message);
      } catch (ex) {
        CSwl.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  };

  const func_Reject = async () => {
    if (reason == "") {
      CSwl.SwalErr("กรุณากรอกเหตุผล");
      return;
    }

    CSwl.SwalLoad();
    const result = await ApiContent.RejectProjectExp({
      id: itemSelected.id,
      remark: reason,
    });
    if (result.status == 200) {
      setisModal(false);
      CSwl.SwalSuccess("รายการถูก Reject แล้ว").then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <div id="certificate-approve-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.approve')} />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.approve')} />
        </div>
        {/* section post */}
        <div className="column is-7 layer2">
          <div className="is-flex is-justify-content-space-between px-4 layer3">
            <div
              // to="/"
              className="has-text mr-6 is-flex is-align-items-center"
            >
              <strong className="mt-3 mb-3">รายการอนุมัติ</strong>
            </div>
            {/* <Link
              to="/"
              className="button is-light is-outlined "
              style={{ color: "black", fontWeight: "bold" }}
            >
              ดูทั้งหมด
            </Link> */}
          </div>
          <hr className=" hidden-b my-1" />
          {contents && (
            <InfiniteScroll
              dataLength={contents.length}
              // next={() => handleLoadMore()}
              // hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
              className="space-y-2 flex flex-col divide-y"
            >
              {contents &&
                contents.map((item, index) => {

                  const files = item.files ? JSON.parse(item.files) : "";
                  return (
                    <div className={`py-4 first:pt-0 ${index < contents.length - 1 && "cer-line"}`} key={item.id}>
                      <div className="is-flex flex-column">
                        <div className="is-flex">
                          <div className="img-cer">
                            {
                              files && <>
                                {files[0].Path.includes('.png') || files[0].Path.includes('.jpg') ?
                                  <img
                                    onError={"this.onerror=null;this.src='https://placeimg.com/200/300/animals';"}
                                    src={
                                      process.env.REACT_APP_IMAGE + files[0].Path
                                    }
                                    alt="cer"
                                  /> :
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                    }
                                    alt="cer"
                                  />
                                }
                              </>
                            }
                          </div>
                          <div className="pl-3">
                            <Link to={`/pastProjectDetail/${item.id}`}>
                              <p className="subtitle-h2">{item.name}</p>
                            </Link>
                            <p className="subtitle-h3">{item.instituteName}</p>
                            <p className="cer-date">{item.issuedDate ? `วันที่ออก ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `วันที่หมดอายุ ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                          </div>
                        </div>
                        <div className="is-flex mt-2 gap-x-2">
                          <button className="px-5 py-1 bg-emerald-600 text-white rounded-full text-md" onClick={(e) => {
                            func_Approve(item.id);
                          }}>Approve</button>
                          <button className="px-5 py-1 bg-red-600 text-white rounded-full text-md" onClick={(e) => {
                            e.stopPropagation();
                            setisModal(true);
                            setitemSelected(item);
                            setReason("");
                          }}>Reject</button>
                        </div>
                      </div>
                    </div>
                    
                  );
                })}
                
            </InfiniteScroll>
          )}
          {contents && contents.length == 0 && (
            <div className="m-5">ไม่พบเกียรติบัตร</div>
          )}
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden-b"
        >
          <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
      <div class={`modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
          <div className="d-flex-center flex-column">
            <h1>Reject</h1>
            <div
              className="control flex-column d-flex-center mt-5"
              style={{ alignItems: "flex-start", minWidth: 300 }}
            >
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="เหตุผล"
                rows={4}
                className="p-2"
                style={{ width: "100%" }}
              ></textarea>
            </div>
            <div className="mt-5 d-flex">
              <button
                onClick={() => {
                  setisModal(false);
                }}
                className="button is-light is-link is-rounded mr-5"
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
              <button
                onClick={(e) => {
                  func_Reject();
                }}
                className="button is-link is-rounded"
              >
                <div className="font-button">ไม่อนุมัติ</div>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setisModal(false);
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
    </div>
  );
};

export default ProjectExpApprove;
