import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiRedeem extends Component {
    static get = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `MtRedeem/GetList`,
            method: "post",
        });
        return result;
    };
    static getById = async (id) => {
        await setHeaderAuth();
        const result = await axios({
            url: `MtRedeem/GetById/${id}`,
            method: "post",
        });
        return result;
    };
    static insert = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: "TranRedeem",
            method: "post",
            data: data,
        });
        return result;
    };
    static getHistory = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `TranRedeem/History`,
            method: "post",
            data: data
        });
        return result;
    };
}

export default ApiRedeem;
