import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Login from "./pages/login";
import Page404 from "./components/Page404";
import FirstPage from "./pages/firstPage";
import Explore from "./pages/firstPage/explore";
import Popular from "./pages/firstPage/popular";
import Notification from "./pages/firstPage/notification";
import RankingPage from "./pages/ranking";
import ProfilePage from "./pages/profilePage";
import ProfilePageDetail from "./pages/profileDetail";
import RedeemHistoryPage from "./pages/redeem/history";
import RedeemPage from "./pages/redeem";
import RedeemDetail from "./pages/redeem/detail";
import SettingPage from "./pages/setting";
import SettingEdit from "./pages/setting/settingEdit";
import ContentPlan from "./pages/content/contentPlan";
import ContentWaiting from "./pages/content/contentWaiting";
import ContentDetail from "./pages/content/contentDetail";
import ProfileFollow from "./pages/profilePage/profileFollow";
import CreateContent from "./pages/content/createContent";
import EditContent from "./pages/content/editContent";
import UnFollow from "./pages/unfollow";
import "react-quill/dist/quill.snow.css";
import ScrollToTop from "./utils/ScrollToTop";
import SearchPage from "./pages/search";
import CatalogPage from "./pages/catalog";
import Tag from "./pages/tag";
import Interest from "./pages/interest";
import ContentPreview from "./pages/content/contentPreview";
import BookmarkPage from "./pages/bookmark";
import firebase from "firebase/app";
import { messaging } from "./utils/firebase-init";
import { notificationOpen } from "./utils/notification";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Home2 from "./pages/home/index2";
import Managerapprove from "./pages/managerapprove";
import Smeapprove from "./pages/smeapprove";
import TrendPage from "./pages/trend";
import { useTranslation } from "react-i18next";
import SetupInterceptors from "./configs/axios";
import Certificate from "./pages/certificate";
import CertificateDetail from "./pages/certificate/certificateDetail/index";
import CertificateEdit from "./pages/certificate/certificateEdit/index";
import PastProject from "./pages/pastProject";
import PastProjectEdit from "./pages/pastProject/pastProjectEdit";
import PastProjectDetail from "./pages/pastProject/pastProjectDetail";
import CertificateApprove from "./pages/certificateApprove";
import ProjectExpApprove from "./pages/projectExpApprove";
import CreateLiveSession from "./pages/content/createLiveSession";
import LiveSharingPage from "./pages/firstPage/liveSharing";
import ContentDetailLiveSharing from "./pages/content/contentDetail/liveSharing";
import EditContentSharing from "./pages/content/editContentSharing";
import ChatPage from "./pages/chat";
import { ArrowUpIcon, XMarkIcon } from "@heroicons/react/20/solid";
import ApiTranProfile from "./api/ApiTranProfile";
import CSwl from "./utils/alert";
import ApiNotification from "./api/ApiNotification";
function App() {

  SetupInterceptors();
  const [isModal, setisModal] = useState();
  const { t, i18n } = useTranslation();
  const count_login = useSelector((state) => state.count_login);
  const dispatch = useDispatch();
  const [isNotification, setIsNotification] = useState(false);
  const languageState = useSelector((state) => state.language);
  const userState = useSelector((state) => state.user);
  const [messageNotification, setmessageNotification] = useState({
    title: "",
    subtitle: "",
    message: "",
  });


  useEffect(() => {
    if (messageNotification.title) {
      setIsNotification(true);
    } else {
      setIsNotification(false);
    }
    return () => { };
  }, [messageNotification]);

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      messaging
        .requestPermission()
        .then(async function () {
          const tokenFirebase = await messaging.getToken();
          dispatch({ type: "set_user", tokenFirebase: tokenFirebase });
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });
      navigator.serviceWorker.addEventListener("message", (message) => {
        const { data } = message.data["firebase-messaging-msg-data"];
        notificationOpen({
          title: data.title,
          subtitle: "",
          message: data.body,
        });
        setmessageNotification({
          title: data.title,
          subtitle: "",
          message: data.body,
        });
        setTimeout(() => {
          getNotification();
        }, 1000);
   
        setTimeout(() => {
         
          setmessageNotification({
            title: "",
            message: "",
            subtitle: "",
          });
        }, 3000);
      });
    }

    return () => { };
  }, []);

  const getNotification = async () => {
    try {
      const notificationCount = await ApiNotification.count();
      if (notificationCount.status == 200) {
        const { data, logsLogin, isShare, chatNotification } = notificationCount.data;
        dispatch({ type: "set_user", count_notification: data, count_login: logsLogin, isShare: isShare, count_chat_notification: chatNotification });
      }
    } catch (error) {

    }
  };


  useEffect(() => {
    if (userState?.profile?.isAcceptPdpa == false) {
      setisModal(true);
    }
  }, [userState.profile]);

  useEffect(() => {
    if (document.getElementById("tailwind-js")) {

    } else {
      const script = document.createElement("script");
      // script.src = `${StaticImage("./tailwind.js")}`;
      script.src = `./tailwind.js`
      script.id = "tailwind-js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languageState);
    return () => {

    }
  }, [languageState])

  const confirmPDPA = async () => {
    var model = {
      id: userState.profile.id,
      isAcceptPdpa: true,
    };
    try {
      const result = await ApiTranProfile.UpdatePDPA(model);
      if (result.status == 200) {
        const { data, token } = result.data;
        setisModal(false)
        dispatch({ type: 'set_user', user: data });
      }
    } catch (ex) {
      CSwl.SwalErr("Can Not Update PDPA");
    }
  };




  return (
    <div className="App">
      {isNotification && (
        <div class="notification is-link is-light" style={{
          position: "absolute",
          top: 0,
          width: "100%",
          left: 0,
          zIndex: 9999999,
          paddingRight: 50,
        }}>
          <button class="delete" onClick={() =>
            setmessageNotification({
              title: "",
              message: "",
              subtitle: "",
            })
          }></button>
          {messageNotification.title}
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{messageNotification.message}</span>
          </div> */}
        </div>
      )}
      <Router>
        <ScrollToTop />

        <Switch>


          <Route path="/login">
            <Login />
          </Route>

          <Route path="/home">
            <FirstPage />
          </Route>
          <Route path="/liveSharing">
            <LiveSharingPage />
          </Route>
          <Route path="/contentPreview" component={ContentPreview} />
          <Route path="/ranking">
            <RankingPage />
          </Route>
          <Route path="/explore">
            <Explore />
          </Route>
          <Route path="/bookmark">
            <BookmarkPage />
          </Route>
          <Route path="/popular" component={Popular} />
          <Route path="/profile">
            <ProfilePage />
          </Route>
          <Route path="/profileDetail" component={ProfilePageDetail} />
          <Route path="/profilefollow">
            <ProfileFollow />
          </Route>
          <Route path="/redeem">
            <RedeemPage />
          </Route>
          <Route path="/redeemHistory">
            <RedeemHistoryPage />
          </Route>
          <Route path="/redeemDetail" component={RedeemDetail} />
          <Route path="/chat" component={ChatPage} />


          <Route path="/setting">
            <SettingPage />
          </Route>
          <Route path="/contentdetail" component={ContentDetail} />
          <Route path="/contentdetailLiveSharing" component={ContentDetailLiveSharing} />
          <Route path="/createcontent">
            <CreateContent />
          </Route>
          <Route path="/editcontentSharing" component={EditContentSharing} />

          <Route path="/createLiveSession">
            <CreateLiveSession />
          </Route>
          <Route path="/editcontent" component={EditContent} />
          <Route path="/contentplan">
            <ContentPlan />
          </Route>
          <Route path="/contentwaiting">
            <ContentWaiting />
          </Route>
          <Route path="/settingEdit/:edit">
            <SettingEdit />
          </Route>
          <Route path="/notification">
            <Notification />
          </Route>
          <Route path="/unfollow">
            <UnFollow />
          </Route>
          <Route path="/certificate" component={Certificate} />
          <Route path="/certificateEdit/:id" component={CertificateEdit} />
          <Route path="/certificateDetail/:id" component={CertificateDetail} />
          <Route path="/pastProject" component={PastProject} />
          <Route path="/pastProjectEdit/:id" component={PastProjectEdit} />
          <Route path="/pastProjectDetail/:id" component={PastProjectDetail} />
          <Route path="/search" component={SearchPage} />
          <Route path="/catalog" component={CatalogPage} />
          <Route path="/tag" component={Tag} />
          <Route path="/trend" component={TrendPage} />
          <Route path="/interest" component={Interest} />
          <Route path="/managerapprove" component={Managerapprove} />
          <Route path="/certificateApprove" component={CertificateApprove} />
          <Route path="/projectExpApprove" component={ProjectExpApprove} />
          <Route path="/smeapprove" component={Smeapprove} />
          <Route path="/loginSuccess" component={Home} />
          <Route path="/" component={Home} />
          <Route path="*">
            <Page404 />
          </Route>

        </Switch >
      </Router >
      <div class={`modal modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5 flex flex-column gap-y-4" style={{ borderRadius: 5 }}>
          <div
            className="flex justify-between items-center"
          >
            <span className="font-bold text-lg">PDPA</span>
            {/* <XMarkIcon onClick={() => {
              setisModal(false)
            }} className="w-6 h-6 cursor-pointer" /> */}
          </div>
          <div className="flex flex-column gap-y-2 font-xl">
            <span>บริษัทเอสซีจี แพคเกจจิ้ง จํากัด (มหาชน) และบริษัทในกลุ่ม (รวมเรียกว่า“เรา”) เคารพสิทธิความเป็นส่วนตัว ของผู้เข้าร่วมกิจกรรม หรือผู้มาเข้าติดต่อ (รวมเรียกว่า “ท่าน”) และเพื่อให้เกิดความมั่นใจว่าท่านได้รับความ คุ้มครองข้อมูลส่วนบุคคล จึงได้จัดทํานโยบายความเป็นส่วนตัวขึ้น เพื่อแจ้งให้ทราบถึงรายละเอียดที่เกี่ยวข้อง กับการเก็บรวบรวม การใช้ และการเปิดเผย (รวมเรียกว่า “การ ประมวลผล”) รวมตลอดถึงการลบ และทําลาย ข้อมูลส่วนบุคคลของท่านทั้งช่องทางออนไลน์ และช่องทางอื่น ๆ ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกําหนด ท่านสามารถศึกษาเนื้อหาเพิ่มเติมเกี่ยวกับ นโยบายส่วนตัว (Privacy Notice) ได้ที่&nbsp;
              <a target="_blank" href="https://www.scgpackaging.com/th/privacy-notice" className="text-link">Privacy-Notice</a></span>
          </div>
          <div className="flex justify-end gap-x-4 items-center">
            <div onClick={() => confirmPDPA()} className="bg-[#3273DC] text-[#FFF] px-4 py-2 rounded-lg cursor-pointer">
              <span>ยืนยัน</span>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-to-top fixed">
        <div onClick={() => {
         window.scrollTo({ top: 0, behavior: 'smooth' });
        }}>
          <ArrowUpIcon/>
          {/* <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="color: rgb(12, 77, 162); font-size: 28px; min-width: 30px;"><path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path></svg> */}
        </div>
      </div>
    </div >
  );
}

export default App;
