import React, { useState } from 'react'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Cardlist from '../../components/Cardlist'
import CardTaglist from "../../components/CardTaglist";
import '../ranking/index.scss'
import RankingLevel from '../../components/RankingLevel';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ApiRanking from '../../api/ApiRanking'
import ApiFollow from '../../api/ApiFollow';
import CSwl from '../../utils/alert';
import ApiMtTag from '../../api/ApiTag';
import ApiAuth from '../../api/ApiAuth';
import ProfileList from '../../components/ProfileList';
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import IconComponent from '../../components/Icons';
const initialState = {
  pageNumber: 1,
  pageSize: 20,
};


const RankingPage = () => {
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const [state, setstate] = useState(initialState);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [trend, setTrend] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [rankList, setrankList] = useState([]);
  const [rankingActive, setRankingActive] = useState({});
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };


  const [dataRank, setDataRank] = React.useState([]);

  React.useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getProfile();
    const promise2 = getData();
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();
    Promise.all([promise1, promise2, promise3, promise4]).then(results => {
      CSwl.SwalClose();
    })
  }, [])

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };

  const getData = async () => {
    try {
      // const res = await ApiRanking.getById(userState.profile.rankingId)
      const res = await ApiRanking.getAll();
      if (res.status === 200) {
        const { data } = res.data;
        setrankList(data);
        setDataRank(data.find(x => x.id == userState.profile.rankingId) ?? data[0]);
        setRankingActive(data.find(x => x.id == userState.profile.rankingId) ?? data[0]);
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
  }

  const getProfile = async () => {
    CSwl.SwalLoad();
    try {
      const response = await ApiAuth.getUser();
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        dispatch({ type: "set_user", user: data });
      }
    } catch (error) {

    }
  }

  const renderImageRanking = () => {
    if (!rankingActive.rankingName) {
      return <></>
    }
    if (rankingActive.rankingName.toLowerCase() == "member") {
      return <img className="size-img" src={process.env.PUBLIC_URL + "/images/ranking/BeginnerX2.png"} alt="logo" />
    }
    else if (rankingActive.rankingName.toLowerCase() == "interester") {
      return <img className="size-img" src={process.env.PUBLIC_URL + "/images/ranking/IntermediateX2.png"} alt="logo" />
    }
    else if (rankingActive.rankingName.toLowerCase() == "contributor") {
      return <img className="size-img" src={process.env.PUBLIC_URL + "/images/ranking/ContributorX2.png"} alt="logo" />
    }
    else if (rankingActive.rankingName.toLowerCase() == "smes") {
      return <img className="size-img" src={process.env.PUBLIC_URL + "/images/ranking/SMEs.jpg"} alt="logo" />
    }
  }

  const renderProgress = () => {
    let components = [];
    const rankListWithout = rankList.filter(x => x.rankingName !== "SMEs");

    let max = _.maxBy(rankListWithout, 'scoremax');
    let percentSkip = 0;
    let percentSum = 0;
    let percentSelf = (value / max?.scoremax * 100);
    percentSelf = percentSelf >= 100 ? 100 : percentSelf;
    rankListWithout.map((item, index) => {
      const percent = item.scoremax / max.scoremax * 100;
      const percentSumIn = percentSum;
      if (item.scoremax <= value) {
        percentSkip += 5;
      }
      if (index != 2) {
        percentSum += percent;
      }
      if (index == 0) {
        components.push(<div className="progress-bar orange d-flex-center is-flex is-flex-direction-column" style={{ width: `${Math.floor(percent + 5)}%` }}>
          <label>
            0 - {item.scoremax}
          </label>
          <label>
            {item.rankingName}
          </label>
        </div>
        );
      }
      if (index == 1) {
        components.push(<div className="progress-bar blue d-flex-center is-flex is-flex-direction-column" style={{ width: `${Math.floor(percent - percentSumIn + 5)}%` }}>
          <label>
            {rankListWithout[index - 1].scoremax + 1} - {item.scoremax}
          </label>
          <label>
            {item.rankingName}
          </label>

        </div>
        );
      }
      if (index == 2) {
        components.push(<div className="progress-bar grey d-flex-center is-flex is-flex-direction-column" style={{ width: `${Math.floor(percent - percentSumIn - 1)}%` }}>

          <label>
            {rankListWithout[index - 1].scoremax + 1} - {item.scoremax}
          </label>
          <label>
            {item.rankingName}
          </label>
        </div>);
      }
    })
    return (
      <div style={{ position: 'relative' }}>
        <div className='d-flex-center flex-column p-1' style={{ position: 'absolute', left: `${Math.floor(percentSelf + percentSkip)}%`, top: -50 }}>
          <IconComponent
            className="icon-20x" color="#333"
            icon={faFlagCheckered}
          />
          {value}
        </div>
        <div className="mb-6 progress text-white is-size-small-mobile" style={{ height: 50, position: 'relative' }}>
          {components}

        </div>

      </div>
    )
  }


  const max = dataRank?.scoremax ?? 50;
  const value = userState.profile?.rankingAmt ?? 0;
  return (
    <div id="ranking-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('text.experience')} />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header={t('text.experience')} />
        </div>
        {/* section content */}
        <div className="column is-7 section-content p-5">
          <div className="mb-6">
            <figure className="image">
              {
                renderImageRanking()
              }

            </figure>
          </div>
          <div className="is-flex is-justify-content-space-between is-align-items-center mb-5">
            <div className="is-flex is-align-items-center">
              <img src={process.env.PUBLIC_URL + "/images/ranking/Logo.png"} alt="logo" width='24' height='24' />
              <span className="title-h has-text-link pl-3 ">{t('text.experience')}</span>
            </div>
            <h1 className="title title-h-b">{value}</h1>
          </div>
          {/* <div className="layout mb-6">
            <progress className="progress is-danger " value={value} max={rankingActive.scoremax}></progress>
          </div> */}
          {/* <div className="mb-6 progress">
            <div className="progress-bar orange" style={{width:'10%'}}></div>
            <div className="progress-bar blue" style={{width:'30%'}}></div>
            <div className="progress-bar yellow" style={{width:'50%'}}></div>
          </div> */}
          {renderProgress()}

          <div className="layout mb-6">
            {/* {
              (value < rankingActive.scoremax)
              && <p className="subtitle-h">{`คุณต้องสะสมของประสบการณ์ ${rankingActive.scoremax - value} คะแนน เพื่อปลดล็อคระดับถัดไป`}</p>
            } */}

            <p className="subtitle-h">ทุก Activities บนระบบสามารถแลกคะแนนเป็น KM Rewards Point</p>
            <p className="subtitle-h">เพื่อแลกรับของรางวัลมากมายเพื่อส่งเสริมการแบ่งปันความรู้และต่อยอดองค์กร</p>
          </div>
          {/* <div className="layout mb-6">
            <p className="mb-5 subtitle-h1">การสะสมคะแนน</p>
            <ul className="ul-1">
              <li>Upload knowledge (Verified) get 5 points</li>
              <li>Comment, like, share get 1 point</li>
              <li>Profile setting completed get 3 point</li>
            </ul>
          </div> */}
          <div className="layout mb-6">
            <p className="mb-5 subtitle-h1">{t('text.ranking_benefit')}</p>
            < ul className="ul-1" >
              <li>Redeem premium package</li>
            </ul>
          </div>
          <div className="layout mb-6">
            <p className="mb-5 subtitle-h1">{t('text.ranking_member')}</p>
          </div>
          <div className="mb-5">
            {/* <RankingLevel rank={dataRank.rankingName ?? "member"} /> */}
            <div className="is-flex flex-wrap">
              {rankList.map((item, index) => {
                if (item.rankingName.toLowerCase() == 'member') {
                  if (userState?.issme) {
                    return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Beginner - pass.png"} alt="logo" />
                  }
                  if (userState.profile.rankingAmt >= rankList[index].scoremax) {
                    if (dataRank?.id == item.id) {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Beginner_s.png"} alt="logo" />
                    } else {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Beginner - pass.png"} alt="logo" />
                    }

                  } else {
                    return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Beginner_s.png"} alt="logo" />
                  }
                }
                else {
                  if (item.rankingName.toLowerCase() == "interester") {
                    if (userState?.issme) {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Intermediate - pass.png"} alt="logo" />
                    }
                    if (userState.profile.rankingAmt >= rankList[index - 1].scoremax) {
                      if (dataRank?.id == item.id) {
                        return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Intermediate.png"} alt="logo" />
                      } else {
                        return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Intermediate - pass.png"} alt="logo" />
                      }

                    } else {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Intermediate2.png"} alt="logo" />
                    }
                  }
                  if (item.rankingName.toLowerCase() == "contributor") {
                    if (userState?.issme) {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Contributor - pass.png"} alt="logo" />
                    }
                    if (userState.profile.rankingAmt >= rankList[index - 1].scoremax) {
                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Contributor.png"} alt="logo" />
                    } else {

                      return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/Contributor2.png"} alt="logo" />
                    }
                  }
                  if (item.rankingName.toLowerCase() == "smes" && userState?.issme) {
                    return <img width={300} onClick={() => setRankingActive(item)} className="size-img mt-5 mr-2" src={process.env.PUBLIC_URL + "/images/ranking/SMEs_small.jpg"} alt="logo" />
                  }
                }

              }
              )}
            </div>
          </div>
          <Link to="/redeem" className="is-flex is-justify-content-space-between p-5">
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img src={process.env.PUBLIC_URL + "/images/profilePage/badge2.png"} alt="logo1" />
              </figure>
              <p className="title-h1 px-2">{t('text.point')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <p className="subtitle-h2 px-2">{userState.profile?.pointAmt}</p>
              <figure className="image">
                <img src={process.env.PUBLIC_URL + "/images/profilePage/vector2.png"} alt="logo1" />
              </figure>
            </div>
          </Link>
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden-b"
        >
          <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default RankingPage
