import React, { useState, useMemo, useEffect } from "react";
import "./index.scss";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InitialState from "./initialState";
import ReactQuill, { Quill } from "react-quill";
import ApiContent from "../../../api/ApiContent";
import { WEB_IMAGE } from "../../../env";
import Swal from "sweetalert2";
import { ConvertDateShort } from "../../../utils/date";
import CSwal from "../../../utils/alert";
import Select from "react-select";
import ApiCategory from "../../../api/ApiCategory";
import { TagsInput as ReactTags } from "react-tag-input-component";
import { useHistory } from "react-router-dom";
import { arrayRemoveByIndex, CheckFile } from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PostV2Preview from "../../../components/PostV2Preview";
import PostDetailPreview from "../../../components/PostBackendPreview";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import Autosuggest from 'react-autosuggest';
import ApiTranProfile from "../../../api/ApiTranProfile";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ApiOrg from "../../../api/ApiOrg";
import ApiConfig from "../../../api/ApiConfig";
import _ from 'lodash';
import IconComponent from "../../../components/Icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
window.Quill = Quill;

const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);

const CreateContent = (props) => {
  const userState = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [idc, setIdc] = useState(0);
  const history = useHistory();
  const [state, setState] = useState(InitialState);
  const [catagory, setcatagory] = useState([]);
  const [tags, setTags] = useState(0);
  const [fileDocState, setfileDocState] = useState("");
  const [fileDoctmp, setfiledoctmp] = useState([]);
  const [fileVideoState, setVideoState] = useState("");
  const [fileVideotmp, setfileVideotmp] = useState([]);
  const [isCalendar, setisCalendar] = useState(false);
  const [postDate, setpostDate] = useState(new Date());
  const [createDate, setCreateDate] = useState("");
  const [dataPreview, setdataPreview] = useState({});
  const [isPreview, setisPreview] = useState(false);
  const [cttagtJsonForcheck, setCttagtJsonForcheck] = useState([]);
  const [suggestion, setsuggestion] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionSelect, setsuggestionsSelect] = useState([]);
  const [isModal, setisModal] = useState(false);
  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState([]);
  const [capabilitiesDefault, setCapabilitiesDefault] = useState([])
  const [capabilities, setCapabilities] = useState([])
  const [desc, setDesc] = useState("");
  var quillObj = React.useRef(null);
  var form = React.useRef(null);

  const [stateOrg, setstateOrg] = useState({
    orgData: [],
    bu: [],
    subbu: [],
    company: [],
    division: [],
    sub_division: [],
    department: [],
    sub_department: [],
    section: []
  });

  useEffect(() => {
    setState({ ...state, ctdeschtml: desc });
  }, [desc]);

  const imageHandler = (image, callback) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;

      uploadFiles(file, fileName, quillObj.current);
    };
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        // See optional "config" below
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"], // remove formatting button
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const uploadFiles = async (uploadFileObj, filename, quillObj) => {
    Swal.showLoading();
    const range = quillObj.getEditorSelection();
    console.log(range);
    console.log(uploadFileObj);
    console.log(filename);
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiContent.uploadHtmlFile(formData);
    const { data } = result.data;
    quillObj
      .getEditor()
      .insertEmbed(
        range.index,
        "image",
        process.env.REACT_APP_IMAGE + data.path
      );
    Swal.close();
  };

  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = capabilities.filter(group => {
      return group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
    }
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find(opt => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  // useEffect(() => {
  //   if (state.isSpecific == 1) {
  //     if (stateOrg.bu.length == 0) {
  //       func_loadOrg();
  //     }
  //   }
  //   return () => { };
  // }, [state]);

  const func_loadOrg = async () => {
    try {
      CSwal.SwalLoad();
      var res = await ApiOrg.getOrg();
      CSwal.SwalClose();
      const { result, status } = res.data;
      if (res.status === 200) {
        setstateOrg({
          ...stateOrg, orgData: result
        })
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    state.ctstatus = "W";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};
    let jsonRef = "";
    let jsonRefUser = "";
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
      return false;
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }
    if (suggestionSelect.length > 0) {
      jsonRef = suggestionSelect.map((x) => {
        return { id: x.id, ctname: x.ctname, ctrefno: x.ctrefno };
      });
    }

    if (suggestionSelectUser.length > 0) {
      jsonRefUser = suggestionSelectUser.map((x) => {
        return {
          id: x.id,
          firstNameEnglish: x.firstNameEnglish,
          lastNameEnglish: x.lastNameEnglish,
        };
      });
    }

    try {
      const created = await ApiContent.UpdateContent({
        ...state,
        bu: state.bu.join(','),
        subbu: state.subbu.join(','),
        company: state.company.join(','),
        division: state.division.join(','),
        sub_division: state.sub_division.join(','),
        department: state.department.join(','),
        sub_department: state.sub_department.join(','),
        section: state.section.join(','),
        ctcatagoryid: jsonCatagoryId,
        ctcatagoryJson: jsonCatagoryJson,
        cttagtJsonForcheck: cttagtJsonForcheck,
        contentRefJson: jsonRef ? JSON.stringify(jsonRef) : "",
        contentUserRefJson: jsonRefUser ? JSON.stringify(jsonRefUser) : "",
        competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
      });

      const formData = new FormData();
      if (fileDocState) {
        fileDocState.map((item) => {
          formData.append("file", item);
        })
      }
      if (fileVideoState) {
        formData.append("video", fileVideoState);
      }
      const refno = created.data.data.ctrefno;
      formData.append("refno", refno);
      if (fileDocState || fileVideoState) {
        const uploadedFile = await ApiContent.upload(formData);
      }
      Swal.fire("สำเร็จ", "", "success").then(async (result) => {
        history.push("/contentwaiting");
      });
    } catch (error) {
      const { data } = error.response;
      CSwal.SwalErr(data.message);
    }
  };

  const getCatagory = async () => {
    const result = await ApiCategory.get();
    const { data } = result.data;
    setcatagory(data);
  };

  const preview = async () => {
    state.ctstatus = "P";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
      return false;
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }
    //state.cttagtJsonForcheck = cttagtJsonForcheck;
    if (tags.length > 0) {
      state.cttagtJson = tags.map((x) => {
        return { Id: 0, Name: x };
      });
    }
    //console.log(cttagtJsonForcheck);
    try {
      const created = await ApiContent.createPreview({
        ...state,
        ctcatagoryid: jsonCatagoryId,
        ctcatagoryJson: jsonCatagoryJson,
        cttagtJsonForcheck: cttagtJsonForcheck,
      });
      CSwal.SwalClose();
      if (created.status == 200) {
        const { data } = created.data;
        console.log(JSON.stringify(data));
        setdataPreview(data);
        setisPreview(true);
      }
    } catch (error) {
      const { data } = error.response;
      CSwal.SwalErr(data.message);
    }
  };

  useEffect(() => {
    var parm = new URLSearchParams(props.location.search).get("c");
    if (parm != null) {
      setIdc(Number(parm));
      const promise1 = func_loadOrg();
      const promise2 = getCompetency();
      Promise.all([promise1, promise2]).then((result) => {
        func_contentById(Number(parm), result[1]);
      })


    }
    getCatagory();
    return () => { };
  }, []);

  const getCompetency = async () => {
    const result = await ApiConfig.getCompetency();
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        //setTrend(result.data.data);
        var groupBySizeBox = _(result.data.data)
          .groupBy('ocap')
          .map((value, key) => {
            return {
              label: key, options: value.map(x => {
                return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
              })
            }
          })
          .value();
        setCapabilities(groupBySizeBox);
        setCapabilitiesDefault(result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno, description: x.competencyDifination };
        }));
        return result.data.data.map(x => {
          return { label: `(${x.fcno}) ${x.competencyNameTh}`, value: x.fcno };
        });
      }
    }
  };

  const func_contentById = async (idc, list_capabilities) => {
    const result = await ApiContent.GetEditById(idc);
    const { data, status } = result.data;

    if (data.ctfiledoc != "" && data.ctfiledoc != null) {
      setfiledoctmp(JSON.parse(data.ctfiledoc));
    }

    if (data.ctfilevideo != "" && data.ctfilevideo != null) {
      setfileVideotmp(JSON.parse(data.ctfilevideo));
    }

    if (data.refJson != "" && data.refJson != null) {
      setsuggestionsSelect(JSON.parse(data.refJson));
    }
    if (data.refUserJson != "" && data.refUserJson != null) {
      setsuggestionsSelectUser(JSON.parse(data.refUserJson));
    }

    if (status) {
      var cateData =
        data.ctcatagoryJson != null && data.ctcatagoryJson != ""
          ? JSON.parse(data.ctcatagoryJson)
          : [];

      var ctCate = [];
      for (let i = 0; i < cateData.length; i++) {
        var ctCateChild = {
          value: cateData[i].Id,
          label: cateData[i].Name,
        };
        ctCate.push(ctCateChild);
      }
      let competency = "";
      if (data.competency?.split(',')?.length > 0) {
        competency = [];
        data.competency.split(',').map((item, index) => {
          competency.push(list_capabilities.find(x => x.value == item))
        })
      }
      var newState = {
        id: idc,
        ctpostdate: new Date(data.ctpostdate),
        ctname: data.ctname,
        ctdeschtml: data.ctdeschtml,
        ctcatagoryJson: ctCate,
        cttagtJson: [],
        isSpecific: data.isSpecific,
        "bu": data.bu ? data.bu.split(',') : [],
        "subbu": data.subbu ? data.subbu.split(',') : [],
        "company": data.company ? data.company.split(',') : [],
        "division": data.division ? data.division.split(',') : [],
        "sub_division": data.sub_division ? data.sub_division.split(',') : [],
        "department": data.department ? data.department.split(',') : [],
        "sub_department": data.sub_department ? data.sub_department.split(',') : [],
        "section": data.section ? data.section.split(',') : [],
        competency: !competency || competency == "" ? [] : competency
      };
      console.log(newState)
      setCreateDate(
        ConvertDateShort(new Date(data.createdate), "DD MMMM YYYY")
      );
      setpostDate(new Date(data.ctpostdate));
      setState(newState);
      setDesc(data.ctdeschtml);

      if (data.cttagtJson != null && data.cttagtJson != "") {
        var tagJ = JSON.parse(data.cttagtJson);
        setCttagtJsonForcheck(tagJ);
        var tagM = [];
        for (let i = 0; i < tagJ.length; i++) {
          tagM.push(tagJ[i].Name);
        }
        setTags(tagM);
      } else {
        setTags([]);
      }
    }
  };
  const handleTagAddition = async (tag) => {
    console.log(tags);
    setTags(tag);

    var tagModel = [];
    for (var i = 0; i < tag.length; i++) {
      var tagsub = {
        name: tag[i],
      };
      tagModel.push(tagsub);
    }
    setState({ ...state, cttagtJson: tagModel });
  };

  const draft = async () => {
    state.ctstatus = "D";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};
    let jsonRef = "";
    let jsonRefUser = "";
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }

    if (suggestionSelect.length > 0) {
      jsonRef = suggestionSelect.map((x) => {
        return { id: x.id, ctname: x.ctname, ctrefno: x.ctrefno };
      });
    }

    if (suggestionSelectUser.length > 0) {
      jsonRefUser = suggestionSelectUser.map((x) => {
        return {
          id: x.id,
          firstNameEnglish: x.firstNameEnglish,
          lastNameEnglish: x.lastNameEnglish,
        };
      });
    }

    try {
      const created = await ApiContent.UpdateContent({
        ...state,
        bu: state.bu.join(','),
        subbu: state.subbu.join(','),
        company: state.company.join(','),
        division: state.division.join(','),
        sub_division: state.sub_division.join(','),
        department: state.department.join(','),
        sub_department: state.sub_department.join(','),
        section: state.section.join(','),
        ctcatagoryid: jsonCatagoryId,
        ctcatagoryJson: jsonCatagoryJson,
        cttagtJsonForcheck: cttagtJsonForcheck,
        contentRefJson: jsonRef ? JSON.stringify(jsonRef) : "",
        contentUserRefJson: jsonRefUser ? JSON.stringify(jsonRefUser) : "",
        competency: state.competency.length > 0 ? state.competency.map(x => x.value).join(',') : "",
      });

      const formData = new FormData();
      if (fileDocState) {
        formData.append("file", fileDocState);
      }
      if (fileVideoState) {
        formData.append("video", fileVideoState);
      }
      const refno = created.data.data.ctrefno;
      formData.append("refno", refno);
      if (fileDocState || fileVideoState) {
        const uploadedFile = await ApiContent.upload(formData);
      }
      Swal.fire("สำเร็จ", "", "success").then(async (result) => {
        history.push("/contentwaiting");
      });
    } catch (error) {
      const { data } = error.response;
      CSwal.SwalErr(data.message);
    }
    CSwal.SwalClose();
  };

  const fileDoc = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    CheckFile({
      file,
      size: 100,
      type: ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
      message: "รองรับเฉพาะไฟล์ .pdf,.docx,pptx เท่านั้น",
    });
    setfileDocState([...fileDocState, file]);
    setfiledoctmp([]);
  };

  const fileVideo = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    CheckFile({
      file,
      size: 100,
      type: ["video/mp4"],
      message: "รองรับเฉพาะไฟล์ .mp4 เท่านั้น",
    });
    setVideoState(file);
    setfileVideotmp([]);
  };

  const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiContent.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    const checked = suggestionSelect.find(x => x.ctrefno == suggestion.ctrefno);
    if (checked) {

    } else {
      setsuggestionsSelect([...suggestionSelect, suggestion])
    }

    return "";
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.ctname}
    </div>
  );

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = async ({ value }) => {

    setSuggestions(await getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeSuggestion = (event, { newValue }) => {
    setsuggestion(newValue);
  };

  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    if (checked) {
    } else {
      setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    }

    return "";
  };

  const renderSuggestionUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    setsuggestionUser(newValue);
  };

  return (
    <div id="createContent-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header='แก้ไขความรู้' />
      </Drawer>
      <Header />
      <div className="columns" style={{ height: "100%" }}>
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header='แก้ไขความรู้' />
        </div>
        <form ref={form} className="d-flex" onSubmit={onSubmit}>
          <div className="column is-8 border-div1">
            {isCalendar ? (
              <>
                <div className="is-flex is-justify-content-space-between m-4">
                  <div className="is-flex is-align-items-center">
                    <figure className="image mr-3">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/content/IconTime.png"
                        }
                        alt="Icon"
                      />
                    </figure>
                    <p className="title-h1">กำหนดเวลาเผยแพร่</p>
                  </div>
                  <div className="is-flex">
                    <button
                      onClick={() => setisCalendar(false)}
                      type="button"
                      className="button is-outlined has-text-weight-bold has-background-light subtitle-p4 mx-2"
                    >
                      {t("menu.cancel")}
                    </button>
                  </div>
                </div>
                <div className="is-flex is-justify-content-space-between m-4">
                  <div className="is-flex is-align-items-center">
                    <p>เลือกวันและเวลาในการโพสต์</p>
                  </div>
                  <div className="is-flex">
                    <button
                      onClick={() => {
                        setState({ ...state, ctpostdate: postDate });
                        setisCalendar(false);
                      }}
                      type="button"
                      className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2"
                      style={{ backgroundColor: "#F24C20" }}
                    >
                      {t('text.sche_content')}
                    </button>
                  </div>
                </div>
                <div className="is-flex is-justify-content-space-between m-4">
                  <DatePicker
                    className="input size-input mr-6"
                    selected={postDate}
                    onChange={(date) => setpostDate(date)}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy hh:mm:aa"
                  />
                </div>
              </>
            ) : (
              <div className="is-flex is-justify-content-space-between m-4 flex-wrap">
                <div className="is-flex is-align-items-center">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h1">{t('menu.createcontent')}</p>
                </div>
                <div className="is-flex">
                  <button
                    onClick={() => setisCalendar(true)}
                    type="button"
                    className="button is-outlined has-text-weight-bold has-background-light subtitle-p4 mx-2"
                  >
                    {t('text.sche_content')}
                  </button>
                  <button
                    type={"button"}
                    onClick={() => preview()}
                    className="button is-outlined has-text-weight-bold has-background-light subtitle-p4 mx-2"
                  >
                    {t('text.preview')}
                  </button>
                  <button
                    type={"submit"}
                    className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2"
                    style={{ backgroundColor: "#F24C20" }}
                  >
                    {t('text.publish')}
                  </button>
                </div>
              </div>
            )}
            <hr className="hr-size1" />
            <div className="is-flex is-flex-direction-column size-col">
              <div className="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap my-4 px-1">
                <p className="font">{t('text.content_name')}</p>
                <input
                  className="input size-input"
                  type="text"
                  placeholder="กรุณาระบุชื่อความรู้"
                  value={state.ctname}
                  required
                  onChange={(e) =>
                    setState({ ...state, ctname: e.target.value })
                  }
                />
              </div>
              <div className="is-flex is-flex-direction-column">
                <p className="font">{t('text.content_desc')}</p>
                <ReactQuill
                  style={{ marginTop: 20, marginBottom: 40 }}
                  ref={quillObj}
                  value={desc}
                  modules={modules}
                  onChange={(content, delta, source, editor) => {
                    setDesc(content);
                  }}
                />
              </div>
              {/* inputFile */}
              <div className="is-flex file-container-card is-justify-content-space-between is-flex-wrap-wrap">
                {/* section-inputFile */}
                <div className="is-flex is-flex-direction-column">
                  <p className="title-h1">{t('text.content_file')}</p>
                  <div className="file is-boxed">
                    <label className="file-label">
                      <input
                        onChange={fileDoc}
                        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        id="doc_file"
                        className="file-input"
                        type="file"
                        name="resume"
                      />
                      <span className="file-cta is-flex-direction-row is-justify-content-center border-s">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/content/IconFile.png"
                          }
                          alt="Icon"
                        />
                        <div className="is-flex is-flex-direction-column pl-2">
                          <p className="subtitle-p2">{t('text.content_file_desc_100mb')}</p>
                        </div>
                      </span>
                    </label>
                  </div>
                  {fileDocState && (
                    fileDocState.map((item, index) => {
                      return <div key={item.name + index} className="d-flex file-row mt-3" style={{ position: 'relative' }}>
                        <label className="flex-1">
                          {item.name}
                        </label>
                        <button
                          type="button"
                          onClick={(e) => {
                            let files = [...fileDocState];
                            files.splice(index, 1);
                            e.stopPropagation();
                            setfileDocState(files)
                          }} class="delete" style={{ position: 'absolute', right: 10 }}></button>
                      </div>
                    })

                  )}
                  {fileDoctmp.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex file-row mt-3"
                        style={{ position: "relative" }}
                      >
                        <label className="flex-1">{item.Name}</label>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setfiledoctmp([]);
                          }}
                          class="delete"
                          style={{ position: "absolute", right: 10 }}
                        ></button>
                      </div>
                    );
                  })}


                </div>
                {/* section-inputVdo */}
                <div className="is-flex is-flex-direction-column">
                  <p className="title-h1">{t('text.content_video')}</p>
                  <div className="file is-boxed">
                    <label className="file-label">
                      <input
                        className="file-input"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={fileVideo}
                        id="video_file"
                        type="file"
                        name="resume"
                      />
                      <span className="file-cta is-flex-direction-row is-justify-content-center border-s">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/content/IconVdo.png"
                          }
                          alt="Icon"
                        />
                        <div className="is-flex is-flex-direction-column pl-2">
                          <p className="subtitle-p2">{t('text.content_video_desc_100mb')}</p>
                        </div>
                      </span>
                    </label>
                  </div>
                  {fileVideoState && (
                    <div
                      className="d-flex file-row mt-3"
                      style={{ position: "relative" }}
                    >
                      <label className="flex-1">{fileVideoState.name}</label>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setVideoState("");
                        }}
                        class="delete"
                        style={{ position: "absolute", right: 10 }}
                      ></button>
                    </div>
                  )}
                  {fileVideotmp.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex file-row mt-3"
                        style={{ position: "relative" }}
                      >
                        <label className="flex-1">{item.Name}</label>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setfileVideotmp([]);
                          }}
                          class="delete"
                          style={{ position: "absolute", right: 10 }}
                        ></button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <label className="checkbox is-flex mt-4">
                <input
                  id={`checkgdc`}
                  type="checkbox"
                  onChange={(e) => {
                    setState({ ...state, isSpecific: e.target.checked ? 1 : 0 })
                  }}
                  checked={state.isSpecific == 1 ? true : false}
                  value={state.isSpecific == 1 ? true : false}
                />
                <span className="pl-2">{t('text.specify_content')}</span>
              </label>
              {
                state.isSpecific == 1 && <div className="grid sm:grid-cols-2 grid-cols-1 mt-3 gap-x-4 gap-y-2">
                  <div>
                    <p className="title-h1">{"BU"}</p>
                    <Select
                      value={stateOrg.orgData.filter(x => state.bu.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      hideSelectedOptions={false}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select BU..."
                      onChange={(e) => {
                        var listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        console.log('listData:', listData)
                        setstateOrg({
                          ...stateOrg,
                          subbu: listData.filter(x => x.organizationLevelId == 25),
                          company: listData.filter(x => x.organizationLevelId == 30),
                          division: listData.filter(x => x.organizationLevelId == 50),
                          sub_division: listData.filter(x => x.organizationLevelId == 60),
                          department: listData.filter(x => x.organizationLevelId == 70),
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          bu: e.length == 0 ? [] : e.map(x => x.value),
                          subbu: [],
                          company: [],
                          division: [],
                          department: [],
                          section: []
                        });
                      }}
                      options={stateOrg.orgData.filter(x => x.organizationLevelId == 10).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Sub BU"}</p>
                    <Select
                      value={stateOrg.orgData.filter(x => state.subbu.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      required
                      isMulti
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      placeholder="Please Select Sub Bu..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          company: listData.filter(x => x.organizationLevelId == 30),
                          division: listData.filter(x => x.organizationLevelId == 50),
                          sub_division: listData.filter(x => x.organizationLevelId == 60),
                          department: listData.filter(x => x.organizationLevelId == 70),
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          subbu: e.length == 0 ? [] : e.map(x => x.value),
                          company: [],
                          division: [],
                          department: [],
                          section: []
                        });
                      }}
                      options={stateOrg.subbu.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Company"}</p>
                    <Select
                      value={stateOrg.orgData.filter(x => state.company.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      required
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      isMulti
                      placeholder="Please Select Company..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          division: listData.filter(x => x.organizationLevelId == 50),
                          sub_division: listData.filter(x => x.organizationLevelId == 60),
                          department: listData.filter(x => x.organizationLevelId == 70),
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          company: e.length == 0 ? [] : e.map(x => x.value),
                          division: [],
                          department: [],
                          section: []
                        });
                      }}
                      options={stateOrg.company.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Division"}</p>
                    <Select
                      value={stateOrg.orgData.filter(x => state.division.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select Division..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          sub_division: listData.filter(x => x.organizationLevelId == 60),
                          department: listData.filter(x => x.organizationLevelId == 70),
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          division: e.length == 0 ? [] : e.map(x => x.value),
                          sub_division: [],
                          department: [],
                          section: []
                        });
                      }}
                      options={stateOrg.division.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Sub Division"}</p>
                    <Select
                      value={stateOrg.orgData.filter(x => state.sub_division.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select Sub Division..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          department: listData.filter(x => x.organizationLevelId == 70),
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          sub_division: e.length == 0 ? [] : e.map(x => x.value),
                          department: [],
                          section: []
                        });
                      }}
                      options={stateOrg.sub_division.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Department"}</p>
                    <Select
                       value={stateOrg.orgData.filter(x => state.department.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select Department..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          sub_department: listData.filter(x => x.organizationLevelId == 80),
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          department: e.length == 0 ? [] : e.map(x => x.value),
                          sub_department:[],
                          section: []
                        });
                      }}
                      options={stateOrg.department.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Sub Department"}</p>
                    <Select
                       value={stateOrg.orgData.filter(x => state.sub_department.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select Sub Department..."
                      onChange={(e) => {
                        const listData = [];
                        for (var i = 0; i < e.length; i++) {
                          var filter = stateOrg.orgData.filter((x) => x.departmentParentId === e[i].value);
                          for (var j = 0; j < filter.length; j++) {
                            listData.push(filter[j]);
                          }
                        }
                        setstateOrg({
                          ...stateOrg,
                          section: listData.filter(x => x.organizationLevelId == 90),
                        })
                        setState({
                          ...state,
                          sub_department: e.length == 0 ? [] : e.map(x => x.value),
                          section: []
                        });
                      }}
                      options={stateOrg.sub_department.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                  <div>
                    <p className="title-h1">{"Section"}</p>
                    <Select
                       value={stateOrg.orgData.filter(x => state.section.includes(x.departmentId)).map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      classNames={{
                        multiValueLabel: (state) =>
                          'whitespace-[break-spaces]',
                      }}
                      required
                      isMulti
                      placeholder="Please Select Section..."
                      onChange={(e) => {
                        setState({
                          ...state,
                          section: e.length == 0 ? [] : e.map(x => x.value)
                        });
                      }}
                      options={stateOrg.section.map((x) => {
                        return { label: x.organizationNameEn, value: x.departmentId };
                      })}
                    />
                  </div>
                </div>
              }
              {/* <div className="is-flex is-flex-direction-column mx-2">
              <p className="title-h1">รูปภาพเกลเลอรี่</p>
              <div className="file is-boxed">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" />
                  <span className="file-cta is-flex-direction-row is-justify-content-center border-s1">
                    <img src={process.env.PUBLIC_URL + "/images/content/IconImg.png"} alt="Icon" />
                    <div className="is-flex is-flex-direction-column pl-2">
                      <p className="subtitle-p1">เพิ่มไฟล์ประกอบความรู้</p>
                      <span className="subtitle-p2"><span className="subtitle-p">เลือก</span> ไฟล์รูปภาพจากเครื่องของคุณ</span>
                      <p className="subtitle-p3">ไฟล์ต้องมีขนาดไม่เกิน 20 MB เท่านั้น</p>
                    </div>
                  </span>
                </label>
              </div>
            </div> */}
            </div>
            <div className="column is-4 pl-5 pt-5 hidden-d">
              <div className="is-flex is-align-items-center mt-4">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h1">{t('text.summary')}</p>
              </div>
              <div className="is-flex mt-4">
                <div className="is-flex is-flex-direction-column my-1">
                  <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                  <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                  <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
                </div>
                <div className="is-flex is-flex-direction-column my-1">
                  <p className="subtitle-p5 mr-6">
                    {ConvertDateShort(new Date(), "DD MMMM YYYY")}
                  </p>
                  <p className="subtitle-p5 mr-6">-</p>
                </div>
              </div>
              <div className="is-flex mt-5">
                {/* <button to='/' className='button is-outlined has-text-weight-semibold has-text-white subtitle-p4 mx-2 px-5' style={{ backgroundColor: '#F24C20' }}>
                  ดูแบบร่าง
                </button> */}
                <button
                  onClick={() => {
                    setState({ ...state, ctstatus: "D" });
                    draft();
                  }}
                  type="button"
                  className="button is-outlined has-text-weight-semibold has-background-light subtitle-p4 mx-2 px-4"
                >
                  {t('text.save_draft')}
                </button>
              </div>
              {/* <hr className="hr-size2" /> */}
              <div className="column">
                <div className="is-flex is-align-items-center">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h2">{t('text.category')}</p>
                </div>
                <div className="mt-3" style={{ width: "100%" }}>
                  {state.id > 0 && (
                    <Select
                      defaultValue={state.ctcatagoryJson ?? ""}
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      required
                      isMulti
                      onChange={(e) => {
                        setState({
                          ...state,
                          ctcatagoryJson: e,
                        });
                      }}
                      options={catagory.map((x) => {
                        return { label: x.categoryName, value: x.id };
                      })}
                    />
                  )}
                </div>
              </div>

              {/* <hr className="hr-size2" /> */}
              <div className="column">
                <div className="is-flex is-align-items-center">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h2">{t('text.attention')}</p>
                </div>
                <div className="mt-3" style={{ width: "100%" }}>
                  {tags !== 0 && (
                    <ReactTags
                      className="form-control form-control-md mt-3"
                      value={tags}
                      name="tags"
                      onChange={(tag) => handleTagAddition(tag)}
                      placeholder="พิมพ์ tag แล้วกด Enter"
                    />
                  )}
                </div>
                {/* <hr />
                <div className="column">
                  <div className="is-flex is-align-items-center mt-5">
                    <figure className="image mr-3">
                      <img
                        src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                        alt="Icon"
                      />
                    </figure>
                    <p className="title-h2">{t('text.project_ref')}</p>
                  </div>
                  <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}

                      inputProps={{
                        placeholder: 'ชื่อโครงการอ้างอิง',
                        value: suggestion,
                        onChange: onChangeSuggestion,
                        className: 'input'
                      }
                      }
                    />
                  </div>
                  <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                    {suggestionSelect && suggestionSelect.map((item, index) => {
                      return (
                        <div style={{ marginTop: 10 }} onClick={(e) => {
                          // func_tag(e, item)
                        }} className="is-flex is-align-items-center">
                          <div className="tag" style={{ fontSize: 12, whiteSpace: 'initial', height: 'auto' }}>
                            <span>{item.ctname}</span>
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                var objectUpdate = arrayRemoveByIndex(suggestionSelect, index);
                                setsuggestionsSelect(objectUpdate);
                              }} class="delete"></button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div> */}
              </div>

              {/* <hr className="hr-size2" /> */}
              {/* <div className="is-flex is-flex-direction-column">
              <p className="title-h1">รูปภาพเกลเลอรี่</p>
              <div className="file is-boxed">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" />
                  <span className="file-cta is-flex-direction-row is-justify-content-center border-s2">
                    <img src={process.env.PUBLIC_URL + "/images/content/IconImg.png"} alt="Icon" />
                    <div className="is-flex is-flex-direction-column pl-2">
                      <p className="subtitle-p1">เพิ่มไฟล์ประกอบความรู้</p>
                      <span className="subtitle-p2"><span className="subtitle-p">เลือก</span> ไฟล์รูปภาพจากเครื่องของคุณ</span>
                      <p className="subtitle-p3">ไฟล์ต้องมีขนาดไม่เกิน 20 MB เท่านั้น</p>
                    </div>
                  </span>
                </label>
              </div>
            </div>
        */}
            </div>
          </div>
          {/* column-right */}
          <div className="column is-4 hidden-b pl-3 pr-3">
            <div className="is-flex is-align-items-center mt-4">
              <figure className="image mr-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                  alt="Icon"
                />
              </figure>
              <p className="title-h1">{t('text.summary')}</p>
            </div>
            <div className="is-flex mt-4">
              <div className="is-flex is-flex-direction-column my-1">
                <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
              </div>
              <div className="is-flex is-flex-direction-column my-1">
                <p className="subtitle-p5 mr-6">{createDate}</p>
                <p className="subtitle-p5 mr-6">-</p>
              </div>
            </div>
            <div className="is-flex mt-5">
              {/* <button to='/' className='button is-outlined has-text-weight-semibold has-text-white subtitle-p4 mx-2 px-5' style={{ backgroundColor: '#F24C20' }}>
                ดูแบบร่าง
              </button> */}
              <button
                type="button"
                onClick={() => {
                  draft();
                  //onSubmit();
                }}
                className="button is-outlined has-text-weight-semibold has-background-light subtitle-p4 mx-2 px-4"
              >
                {t('text.save_draft')}
              </button>
            </div>
            <div className="column mt-5">
              <div className="is-flex is-align-items-center">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.category')}</p>
              </div>
              <div className="mt-3" style={{ width: "100%" }}>
                {state.id > 0 && (
                  <Select
                    defaultValue={state.ctcatagoryJson ?? ""}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    isMulti
                    onChange={(e) => {
                      setState({
                        ...state,
                        ctcatagoryJson: e,
                      });
                    }}
                    options={catagory.map((x) => {
                      return { label: x.categoryName, value: x.id };
                    })}
                  />
                )}
              </div>
            </div>


            <div className="column mt-3">
              <div className="is-flex is-align-items-center">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.attention')}</p>
              </div>
              <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                {tags !== 0 && (
                  <ReactTags
                    className="form-control form-control-md mt-3"
                    value={tags}
                    name="tags"
                    onChange={(tag) => handleTagAddition(tag)}
                    placeholder="พิมพ์ tag แล้วกด Enter"
                  />
                )}
              </div>
            </div>

            <hr />
            <div className="column">
              <div className="is-flex is-align-items-center mt-5">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.project_ref')}</p>
              </div>
              <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}

                  inputProps={{
                    placeholder: 'ชื่อโครงการอ้างอิง',
                    value: suggestion,
                    onChange: onChangeSuggestion,
                    className: 'input'
                  }
                  }
                />
              </div>
              <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                {suggestionSelect && suggestionSelect.map((item, index) => {
                  return (
                    <div style={{ marginTop: 10 }} onClick={(e) => {
                      // func_tag(e, item)
                    }} className="is-flex is-align-items-center">
                      <div className="tag" style={{ fontSize: 12, whiteSpace: 'initial', height: 'auto' }}>
                        <span>{item.ctname}</span>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            var objectUpdate = arrayRemoveByIndex(suggestionSelect, index);
                            setsuggestionsSelect(objectUpdate);
                          }} class="delete"></button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="column">
              <div className="is-flex is-align-items-center mt-5">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.referal')}</p>
              </div>
              <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                <Autosuggest
                  suggestions={suggestionsUser}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestedUser}
                  onSuggestionsClearRequested={onSuggestionsClearRequestedUser}
                  getSuggestionValue={getSuggestionValueUser}
                  renderSuggestion={renderSuggestionUser}
                  inputProps={{
                    placeholder: t('text.referal'),
                    value: suggestionUser,
                    onChange: onChangeSuggestionUser,
                    className: 'input'
                  }
                  }
                />
              </div>
              <div className="mt-3" style={{ maxWidth: 'fit-content', minWidth: '100%' }}>
                {suggestionSelectUser && suggestionSelectUser.map((item, index) => {
                  return (
                    <div style={{ marginTop: 10 }} onClick={(e) => {
                      // func_tag(e, item)
                    }} className="is-flex is-align-items-center">
                      <div className="tag" style={{ fontSize: 12, whiteSpace: 'initial', height: 'auto' }}>
                        <span>{item.firstNameEnglish + " " + item.lastNameEnglish}</span>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            var objectUpdate = arrayRemoveByIndex(suggestionSelect, index);
                            setsuggestionsSelectUser(objectUpdate);
                          }} class="delete"></button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="column">
              <div className="is-flex is-align-items-center mt-5">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.capabilities')}</p>
                <IconComponent onClick={() => {
                  setisModal(true)
                }} className="h-6 w-6 cursor-pointer ml-2" color="#F24C20" icon={faInfoCircle} />
              </div>
              <div className="mt-3">
                <div className="flex gap-x-2">
                  <Select
                    value={state.competency}
                    className="basic-single w-full"
                    classNamePrefix="select"
                    styles={{ width: 200 }}
                    required
                    isMulti
                    onChange={(e) => {
                      setState({ ...state, competency: e })
                    }}
                    filterOption={filterOption}
                    // options={capabilities.map((x) => {
                    //   return { label: `(${x.fcno}) ${x.competencyNameEn}`, value: x.fcno };
                    // })}
                    options={capabilities}
                  />

                </div>

              </div>
            </div>
            {/* <div className="is-flex is-flex-direction-column">
            <p className="title-h1">รูปภาพเกลเลอรี่</p>
            <div className="file is-boxed">
              <label className="file-label">
                <input className="file-input" type="file" name="resume" />
                <span className="file-cta is-flex-direction-row is-justify-content-center border-s2">
                  <img src={process.env.PUBLIC_URL + "/images/content/IconImg.png"} alt="Icon" />
                  <div className="is-flex is-flex-direction-column pl-2">
                    <p className="subtitle-p1">เพิ่มไฟล์ประกอบความรู้</p>
                    <span className="subtitle-p2"><span className="subtitle-p">เลือก</span> ไฟล์รูปภาพจากเครื่องของคุณ</span>
                    <p className="subtitle-p3">ไฟล์ต้องมีขนาดไม่เกิน 20 MB เท่านั้น</p>
                  </div>
                </span>
              </label>
            </div>
          </div>
       */}
          </div>
        </form>
      </div >
      <div class={`modal ${isPreview ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5">
          {dataPreview.id && (
            // <PostV2Preview
            //   time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
            //   item={dataPreview}
            //   title={dataPreview.ctname}
            //   detail={dataPreview.ctdeschtml}
            //   name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
            //   image={dataPreview.imageProfile ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
            // />
            <PostDetailPreview
              page={"detail"}
              contentid={dataPreview.id}
              image={
                dataPreview.imageProfile
                  ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile
                  : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
              }
              name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
              time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
              title={dataPreview.ctname}
              detail={dataPreview.ctdeschtml}
              item={dataPreview}
              comments={[]}
            />
          )}
          {/* <iframe src={`https://appcreation-scgkm.netlify.app/contentPreview?id=${dataPreview.id}`}></iframe> */}
        </div>
        <button
          onClick={() => {
            setisPreview(false);
            setdataPreview({});
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
    </div >
  );
};

export default CreateContent;
