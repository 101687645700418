import React, { useState } from 'react'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Cardlist from '../../components/Cardlist'
import '../redeem/index.scss'
import { Link } from "react-router-dom";
import ApiRedeem from '../../api/ApiRedeem'
import { useDispatch, useSelector } from 'react-redux'
import Drawer from 'rc-drawer';
import MenuDrawer from "../../components/MenuDrawer";
import "rc-drawer/assets/index.css";
import { useTranslation } from 'react-i18next'
import { destroyPage, useWindowUnloadEffect } from '../../utils'


const RedeemPage = () => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Redeem",firstTime:firstTime }), true);
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const friend = [{
    name: 'Savannah Nguyen'
  },
  {
    name: 'Jacob Jones'
  },
  {
    name: 'Janis Joplin'
  }
  ]
  const trend = [{
    name: '#JessieMueller',
    like: 5000
  },
  {
    name: '#ThelmaJones',
    like: 4000
  },
  {
    name: '#LoisPearson',
    like: 2000
  }]
  //   const cardRedeems = [{
  //     topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม',
  //     detail: 'แลก 70 Points เพื่อรับแพคเกจ JOOX VIP นาน 7 วัน มูลค่า 69 บาท'
  //   },
  //   {
  //     topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม',
  //     detail: 'แลก 70 Points เพื่อรับแพคเกจ JOOX VIP นาน 7 วัน มูลค่า 69 บาท'
  //   },
  //   {
  //     topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม',
  //     detail: 'แลก 70 Points เพื่อรับแพคเกจ JOOX VIP นาน 7 วัน มูลค่า 69 บาท'
  //   },
  //   {
  //     topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม',
  //     detail: 'แลก 70 Points เพื่อรับแพคเกจ JOOX VIP นาน 7 วัน มูลค่า 69 บาท'
  //   },
  //   {
  //     topic: 'ทริปศึกษาดูงานประเทศ เวียดนาม',
  //     detail: 'แลก 70 Points เพื่อรับแพคเกจ JOOX VIP นาน 7 วัน มูลค่า 69 บาท'
  //   }
  // ]
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await ApiRedeem.get({})
      if (res.status === 200) {
        setData(res.data.data)
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
  }

  return (
    <div id="RedeemHomePage">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="redeem" header={t('text.point')} />
      </Drawer>
      <Header />
      <div className="columns">
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu page="redeem" header={t('text.point')} />
        </div>
        <div className="column is-7 border-div">
          <div className="button layer1 has-background-link-dark m-5 is-flex is-justify-content-space-between">
            <div className="is-flex ml-3">
              <img src={process.env.PUBLIC_URL + "/images/redeemPage/Logo1.png"} alt="logo1" />
              <p className="title-h has-text-white ml-3">{t('text.point')}</p>
            </div>
            <p className="title-h1 has-text-white">{userState.profile?.pointAmt}</p>
          </div>
          <div className="m-6">
            <div className="is-flex is-align-items-center">
              <img src={process.env.PUBLIC_URL + "/images/redeemPage/Logo2.png"} alt="logo1" />
              <p className="title-h my-3 ml-3">{t('text.redeem')}</p>
            </div>
            <p className="subtitle-p">{t('text.redeem_desc')}</p>
          </div>
          <div className="is-flex is-flex-wrap-wrap container-card">
            {data.map((d, index) => {
              return (
                <div className="card layoutCard m-4" key={d.id}>
                  <div className="card-image">
                    <figure className="image">
                      <img
                        src={process.env.REACT_APP_IMAGE_BACKOFFICE + d.domainPath}
                        alt="image_domain" />

                    </figure>
                    {/* <figure className="image imgPosiotion">
                      <img src={process.env.PUBLIC_URL + "/images/redeemPage/Score.png"} alt="Placeholder image" />
                    </figure> */}
                  </div>
                  <div className="card-content" >
                    <div className="content is-flex is-flex-direction-column layoutContentCard is-justify-content-space-between is-align-items-center">
                      <p className="subtitle-p has-text-centered">{languageState == "th" ? d.rdName : d.rdNameen}</p>
                      <p className="subtitle-p1 has-text-centered mb-6">{languageState == "th" ? d.rdDesc : d.rdDescen}</p>
                      <Link to={`/redeemDetail?id=${d.id}`}>
                        <button className="button is-rounded has-text-white layoutButton">{t('text.details')}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* <div className="column is-3 pl-5 pt-5 hidden-b">
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.trending')} data={trend}/>
          </div>
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.following')} data={friend}/>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default RedeemPage
