import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiConfig extends Component {

  static getList = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtConfig/GetList`,
      method: "post",
    });
    return result;
  };

  static getCompetency = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtConfig/Competency`,
      method: "get",
    });
    return result;
  };

  static getCountry = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtConfig/Country`,
      method: "get",
    });
    return result;
  };
}

export default ApiConfig;
