import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import "./index.scss";
import { Link } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import Post from "../../components/Post";
import Cardlist from "../../components/Cardlist";
import CardTaglist from "../../components/CardTaglist";
import Menu from "../../components/Menu";
import ApiContent from "../../api/ApiContent";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import { ConvertDateShort } from "../../utils/date";
import { WEB_IMAGE } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import MainPost from "../../components/PostV2";
import CSwl from "../../utils/alert";
import ApiNotification from "../../api/ApiNotification";
import ApiAuth from "../../api/ApiAuth";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileList from "../../components/ProfileList";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import { CheckFile, destroyPage, firstViewPage, useWindowUnloadEffect } from "../../utils";
import ApiOrg from "../../api/ApiOrg";
import MenuChat from "../../components/MenuChat";
import { PhotoIcon, PaperClipIcon, PaperAirplaneIcon, FaceSmileIcon, XMarkIcon, UserGroupIcon } from '@heroicons/react/20/solid'
import { DocumentIcon, UserPlusIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'

import moment from "moment";
import ApiChat from "../../api/ApiChat";
import ModalImage from "react-modal-image";
import socketIO from 'socket.io-client';
//import 'emoji-mart/css/emoji-mart.css'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useBeforeunload } from 'react-beforeunload';
import Autosuggest from "react-autosuggest";
import ApiTranProfile from "../../api/ApiTranProfile";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const initialState = {
  pageNumber: 1,
  pageSize: 10,
};

const ChatPage = (props) => {
  let socket = socketIO.connect(process.env.REACT_APP_CHAT_URL);
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Chat", firstTime: firstTime }), true);

  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  var userId = new URLSearchParams(props.location.search).get("userId");
  var profileId = new URLSearchParams(props.location.search).get("profileId");
  var profileImage = new URLSearchParams(props.location.search).get("profileImage");
  const [roomDetail, setroomDetail] = useState({});

  const [stateOrg, setstateOrg] = useState({
    bu: [],
    subbu: [],
    company: [],
    division: [],
    department: [],
    section: []
  });

  const [thisRoom, setthisRoom] = useState({
    roomId: 0,
    users: []
  });
  const [roomList, setroomList] = useState([]);
  const [readby, setreadby] = useState([]);

  const [groupList, setGroupList] = useState([]);

  const dispatch = useDispatch();
  const [state, setstate] = useState(initialState);
  const [contents, setcontents] = useState("");
  const [loadingMore, setloadingMore] = useState(false);
  const [profileFollow, setProfileFollow] = useState([]);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const lastMessageRef = useRef(null);
  const [messages, setmessages] = useState(
    [
      // {
      //   createdate: moment(),
      //   message: 'ทดสอบคุณ Amorrat',
      //   userId: 3,
      //   type: "message",
      //   file: null
      // },
      // {
      //   createdate: moment(),
      //   message: 'ทดสอบคุณ Somchai',
      //   userId: 1,
      //   type: "message",
      //   file: null
      // }
    ]
  );
  const [roomPrivate, setRoomPrivate] = useState({
    userId: 0,
    sendToUserId: 0
  });

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener('unload', handleEndConcert)
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser)
  //     window.removeEventListener('unload', handleEndConcert)
  //   }
  // }, [])


  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };
  const userState = useSelector((state) => state.user);
  const reduxRoomId = useSelector((state) => state.roomId);

  useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getChatRoomList();
    const promise2 = getProfileFollow();
    const promise3 = getChatGroupList();
    getNotification();

    if (userId) {
      setRoomPrivate({
        sendToUserId: userId,
        image: profileImage,
        roomId: 0
      })
    }
    //const promise5 = getNotification();
    //sendToken();
    Promise.all([promise1, promise2, promise3]).then(
      (results) => {
        CSwl.SwalClose();
      }
    )
    return () => { };
  }, []);

  useEffect(() => {
    if (profileId) {
      getChatRoom({
        sendToUserId: profileId,
        userID: userId
      });
    }
  }, []);

  const getChatRoom = async (data) => {
    CSwl.SwalLoad();
    try {
      const roomResult = await ApiChat.getRoom(data.userID, data.sendToUserId);
      if (roomResult.status == 200) {
        if (roomResult.data?.data) {
          setRoomPrivate({
            userId: userState.employee.userId,
            sendToUserId: data.userID,
            toUserId: data.userID,
            roomId: roomResult.data?.data.id,
          })
        } else {
          setRoomPrivate({
            userId: userState.employee.userId,
            sendToUserId: data.userID,
            toUserId: data.userID,
            roomId: 0,
            profileID: profileId,
          })
        }

      }
    } catch (error) {

    }
    CSwl.SwalClose()
  }

  useEffect(() => {
    //socket = socketIO.connect(process.env.REACT_APP_CHAT_URL);
    if (roomPrivate.roomId !== reduxRoomId) {
      dispatch({ type: "set_user", roomId: roomPrivate.roomId });
      socket.emit(`leaveRoom`, {
        username: userState.employee.userId,
        roomId: roomPrivate.roomId
      });
    }
    //
    if (roomPrivate.roomId == 0) {
      setmessages([]);
      setroomDetail({

      })
    } else if (roomPrivate.roomId > 0) {
      getMessage(roomPrivate.roomId);
      getChatRoomList();
      socket.emit(`newUser`, {
        username: userState.employee.userId,
        roomId: roomPrivate.roomId
      });
    }
  }, [roomPrivate.roomId]);

  useBeforeunload((event) => {
    if (roomPrivate.roomId > 0) {
      socket.emit(`leaveRoom`, {
        username: userState.employee.userId,
        roomId: roomPrivate.roomId
      });
    }
  });

  const handleUnload = (e) => {
    if (reduxRoomId > 0) {
      socket.emit(`leaveRoom`, {
        username: userState.employee.userId,
        roomId: reduxRoomId
      });
    }
  };

  useEffect(() => {
    socket.on(`messageResponse-${roomPrivate.roomId}`, (data) => {
      setmessages([...messages, data])
    });
    return () => {
      socket.off(`messageResponse-${roomPrivate.roomId}`);
    }
  }, [roomPrivate.roomId, messages]);

  useEffect(() => {
    console.log(roomPrivate)
  }, [roomPrivate]);
  // useEffect(() => {
  //   socket.on('roomResponse', (data) => {
  //     setthisRoom(data);
  //   });
  // }, [socket]);



  useEffect(() => {
    socket.on(`roomResponse-${roomPrivate.roomId}`, (data) => {
      setreadby([...data.filter(x => x != userState.employee.userId)])
    });
    return () => {
      socket.off(`roomResponse-${roomPrivate.roomId}`);
      setreadby([])
    }
    // if (thisRoom.users.length > 0) {
    //   const users = thisRoom.users.filter(x => x != userState.employee.userId);
    //   if (users.length > 0) {
    //     setreadby(users);
    //   }
    // }
  }, [roomPrivate.roomId]);



  // useEffect(() => {
  //   socket.on('newUserResponse', (data) => {
  //     console.log(data)
  //   });
  // }, [socket]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });

  }, [messages]);


  const getMessage = async (roomid) => {
    const messageResponse = await ApiChat.getMessage(roomid);
    const roomResponse = await ApiChat.getRoomDetail(roomid);
    if (messageResponse.status == 200) {
      getNotification();
      const { data } = messageResponse.data;
      if (data?.length > 0) {
        setmessages(data);
      } else {
        setmessages([]);
      }
    }
    if (roomResponse.status == 200) {
      const { data } = roomResponse.data;
      setroomDetail(data);
    }
  }

  const getRoomDetail = async (roomid) => {
    const roomResponse = await ApiChat.getRoomDetail(roomid);
    if (roomResponse.status == 200) {
      const { data } = roomResponse.data;
      setroomDetail(data);
    }
  }

  const getChatRoomList = async () => {
    const result = await ApiChat.getRoomList(state);
    if (result.status == 200) {
      setroomList(result.data.data);
    }
  };

  const getChatGroupList = async () => {
    const result = await ApiChat.getRoomGroup(state);
    if (result.status == 200) {
      console.log('groupList::', result.data.data)
      setGroupList(result.data.data);
    }
  };

  const getContentLoadmore = async () => {
    // const result = await ApiContent.get(state);
    // if (result.status == 200) {
    //   setcontents({
    //     ...contents,
    //     items: [...contents.items, ...result.data.data.items],
    //   });
    //   console.log("loadmore::", result.data.data.items);
    // }
  };

  const getProfileFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);

    if (result.status == 200) {
      setProfileFollow(result.data.data);
    }
  };


  const getNotification = async () => {
    try {
      const notificationCount = await ApiNotification.count();
      if (notificationCount.status == 200) {
        const { data, logsLogin, isShare, chatNotification } = notificationCount.data;
        dispatch({ type: "set_user", count_notification: data, count_login: logsLogin, isShare: isShare, count_chat_notification: chatNotification });
      }
    } catch (error) {

    }
  };


  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 });
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then((results) => {
        setloadingMore(false);
      })
    }
    return () => { };
  }, [state]);

  const sendMessage = async (message) => {
    const newMessages = [...messages];
    if (message.type == "message") {
      newMessages.push({
        createdate: moment(),
        message: message.message,
        userId: userState.employee.userId,
        toUserId: roomPrivate.toUserId,
        type: message.type,
        file: null,
        roomId: roomPrivate.roomId,
        readby: readby.join(','),
        readBy: readby.join(','),
      })
    } else if (message.type == "filedoc") {
      newMessages.push({
        createdate: moment(),
        message: message.message,
        type: message.type,
        //file: message.file,
        userId: userState.employee.userId,
        toUserId: roomPrivate.toUserId,
        path: message.path,
        roomId: roomPrivate.roomId,
        readby: readby.join(','),
        readBy: readby.join(','),
      })
    }
    else if (message.type == "image") {
      newMessages.push({
        createdate: moment(),
        message: message.message,
        type: message.type,
        //file: message.file,
        userId: userState.employee.userId,
        toUserId: roomPrivate.toUserId,
        path: message.path,
        roomId: roomPrivate.roomId,
        readby: readby.join(','),
        readBy: readby.join(','),
      })
    }
    try {
      //console.log('message', newMessages[newMessages.length - 1])

      const chatresponse = await ApiChat.sendChat({
        //createdate: moment(),
        message: message.message,
        type: message.type,
        userId: userState.employee.userId,
        toUserId: roomPrivate.toUserId ?? userId,
        path: message.path,
        roomId: roomPrivate.roomId,
        readby: readby.join(',')
      })
      if (roomPrivate.roomId == 0 && chatresponse.data.room.id > 0) {
        await getChatRoomList();
        setRoomPrivate({
          ...roomPrivate,
          roomId: chatresponse.data.room.id,
        })
        newMessages[newMessages.length - 1].roomId = chatresponse.data.room.id;
        newMessages[newMessages.length - 1].toUserId = roomPrivate.toUserId ?? userId;
        setmessages(newMessages);
      }
      if (chatresponse.data.room.id > 0) {
        newMessages[newMessages.length - 1].readBy = chatresponse.data.chat.readBy;
      }
      console.log(' newMessages[newMessages.length - 1]::,', newMessages[newMessages.length - 1])
      socket.emit(`message`, newMessages[newMessages.length - 1]);
    } catch (error) {
      console.log('sockererror ', error)
    }

    //setmessages(newMessages)
  }


  const removeUser = () => {
    socket.emit('removeUser', {
      roomId: roomPrivate.roomId,
      username: userState.employee.userId
    })
  }

  const deletePrivateGroup = async (item) => {
    if (item.userId == userState.employee.userId) {
      const deleteConfirm = await CSwl.SwalConfirmBody(`การลบประวัติข้อความของคุณจะไม่สามารถเรียกคืนได้ คุณจะไม่ สามารถดูข้อความก่อนหน้าได้ แต่จะยังคงได้รับข้อความใหม่ใน แชทนี้`);
      if (deleteConfirm.isConfirmed) {
        const deleteResponse = await ApiChat.deleteGroup(item.roomId)
        if (deleteResponse.status == 200) {
          await getChatGroupList();
          setRoomPrivate({
            sendToUserId: 0
          })
        }
      }
    } else {
      const deleteConfirm = await CSwl.SwalConfirmBody(`คุณต้องการออกจากกลุ่มหรือไม่ ?`);
      if (deleteConfirm.isConfirmed) {
        const deleteResponse = await ApiChat.leaveGroup(item.roomId, userState.employee.userId)
        if (deleteResponse.status == 200) {
          await getChatGroupList();
          setRoomPrivate({
            sendToUserId: 0
          })
        }
      }
    }
  }

  return (
    <div id="first-page">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer page="rank" header={t('menu.home')} />
      </Drawer>
      <Header />
      <div className="sm:flex gap-x-5">
        {/* section menu */}

        <div className="px-5 sm:hidden flex w-100">
          {
            !(roomPrivate.roomId > 0 || roomPrivate.userId != "") && <MenuChat setRoomPrivate={setRoomPrivate}
              roomList={roomList}
              groupList={groupList}
              page="rank" profileFollow={profileFollow}
              getChatGroupList={getChatGroupList}
              getChatRoomList={getChatRoomList}
              header={t('menu.chat')} />
          }
        </div>
        <div className="px-5 sm:w-[350px] sm:max-w-[350px] sm:block hidden">
          {
            <MenuChat setRoomPrivate={setRoomPrivate}
              roomList={roomList}
              groupList={groupList}
              page="rank" profileFollow={profileFollow}
              getChatGroupList={getChatGroupList}
              getChatRoomList={getChatRoomList}
              header={t('menu.chat')} />
          }
        </div>
        {/* section post */}
        <div className="flex-1 sm:relative absolute top-[150px] sm:top-0 w-full">
          {
            roomPrivate.sendToUserId != "" && roomPrivate?.type != "group" &&
            <MessageBody
              roomDetail={roomDetail}
              removeUser={removeUser}
              lastMessageRef={lastMessageRef}
              roomPrivate={roomPrivate}
              setRoomPrivate={setRoomPrivate}
              messages={messages.filter(x => x.roomId == reduxRoomId)}
              onSubmit={sendMessage} />
          }
          {
            roomPrivate?.type == "group" && roomPrivate?.roomId > 0 && roomDetail?.roomId > 0 &&
            <MessageBodyGroup
              deletePrivateGroup={deletePrivateGroup}
              getRoomDetail={getRoomDetail}
              roomDetail={roomDetail}
              roomPrivate={roomPrivate}
              messages={messages.filter(x => x.roomId == reduxRoomId)}
              onSubmit={sendMessage} />
          }
          {/* <h2 className="fontH mb-2 mt-2">{`${t('text.all_content')} ${`(${contents.totalItems ?? 0})`}`}</h2>
          <div className="ar"></div>
          {contents && (
            <InfiniteScroll
              dataLength={contents.items.length}
              next={() => handleLoadMore()}
              hasMore={contents.totalPages > state.pageNumber}
              loader={<h4>Loading...</h4>}
            >
              {contents &&
                contents.items.map((d, index) => {
                  return (
                    <div className="row fn ar" key={d.id}>
                      <MainPost
                        stateOrg={stateOrg}
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )} */}
        </div>
        {/* section Cardlist */}
      </div>
    </div>
  );
};

const MessageBody = ({ onSubmit = () => { }, messages = [], roomPrivate, lastMessageRef, removeUser, roomDetail, setRoomPrivate, profileId = 0 }) => {
  const [message, setmessage] = useState('');
  const [stateMessage, setstateMessage] = useState([]);
  const userState = useSelector((state) => state.user);
  const docRef = useRef(null);
  const photoRef = useRef(null)
  const [fileDocState, setfileDocState] = useState([]);
  const [emojiModal, setemojiModal] = useState(false);
  const [thisRoom, setthisRoom] = useState({});
  const [userEmployee, setUserEmpProyee] = useState({
    profile: {},
    employee: {},
  });
  const [tags, setTags] = useState([]);
  const [speciallistTags, setSpeciallistTags] = useState([]);
  const [smeCatagory, setSmeCatagory] = useState([]);
  const [follow, setFollow] = useState(false);
  const { t, i18n } = useTranslation();
  const fileDoc = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    if (CheckFile({
      file,
      size: 150,
      type: ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
      message: "รองรับเฉพาะไฟล์ .pdf,.docx,pptx เท่านั้น",
    })) {
      setfileDocState([...fileDocState, file]);
      const popupConfirm = await CSwl.SwalConfirm(`ส่งไฟล์ ${file.name} ใช่หรือไม่`);
      if (popupConfirm.isConfirmed) {
        const formData = new FormData();
        if (file) {
          [file].map((item) => {
            formData.append("file", item);
          })
        }
        if (file) {
          CSwl.SwalLoad();
          const uploadedFile = await ApiChat.upload(formData);
          if (uploadedFile.status == 200) {
            onSubmit({
              message: uploadedFile.data?.file[0].name,
              path: uploadedFile.data?.file[0].path,
              file: file,
              type: "filedoc"
            })
          }
          CSwl.SwalClose();
        }

      }
    }
  };

  const filePhoto = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    if (CheckFile({
      file,
      size: 150,
      type: ["image/jpeg", "image/png"],
      message: "รองรับเฉพาะไฟล์ .png,.jpeg เท่านั้น",
    })) {
      setfileDocState([...fileDocState, file]);
      const popupConfirm = await CSwl.SwalConfirm(`ส่งไฟล์ ${file.name} ใช่หรือไม่`);
      if (popupConfirm.isConfirmed) {
        const formData = new FormData();
        if (file) {
          [file].map((item) => {
            formData.append("file", item);
          })
        }
        if (file) {
          CSwl.SwalLoad();
          const uploadedFile = await ApiChat.upload(formData);
          if (uploadedFile.status == 200) {
            onSubmit({
              message: uploadedFile.data?.file[0].name,
              path: uploadedFile.data?.file[0].path,
              file: file,
              type: "image"
            })
          }
          CSwl.SwalClose();
        }

      }
    }
  };

  useEffect(() => {
  }, [messages]);

  useEffect(() => {
    if (roomDetail.profile?.id > 0) {
      getProfile(roomDetail.profile.id)
    } else {
      //getProfile(roomPrivate.toUserId)
    }
  }, [roomDetail.profile?.id, roomPrivate]);

  useEffect(() => {
    if (roomPrivate.roomId == 0) {
      getProfile(roomPrivate.profileID)
    }

  }, [roomPrivate]);

  useEffect(() => {
    return () => {
      console.log('leaveChat')
      if (roomDetail.roomId > 0) {
        ApiChat.leaveChat(roomDetail.roomId, userState.employee.userId)
      }
    }
  }, [roomDetail])



  const getProfile = async (profileId) => {
    try {
      const response = await ApiAuth.getUserByProfile(profileId);
      if (response.status == 200) {
        const { data } = response.data;
        setUserEmpProyee(data);
        setFollow(data.isFollowed);
        if (data.profile.tagJson != null) {
          var tagP = JSON.parse(data.profile.tagJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push(tagP[i].Name);
          }
          setTags(tmpTag);
        }
        if (data.profile.specialistJson != null) {
          var tagP = JSON.parse(data.profile.specialistJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push(tagP[i].name);
          }
          setSpeciallistTags(tmpTag);
        }
        if (data.employee.smeCategory != null) {
          var smeCategoryList = data.employee.smeCategory.split(',');
          setSmeCatagory(smeCategoryList);
        }

      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };


  return <div className="divide-y h-[100vh]">
    {
      userEmployee.profile?.id && <div className="flex p-3 min-w-full items-center gap-x-4 justify-between bg-white z-20 flex-1 border-b ">
        <div className="flex gap-x-4 items-center">
          <div className="flex gap-x-2">
            <figure className="image w-[36px] h-[36px] cursor-pointer">
              <img
                onClick={() => {
                  const profile = roomDetail.profile;
                  window.open(`/profileDetail?id=${profile.id}`, "_blank");
                }}
                className={`is-rounded image-cover ${roomDetail.employee?.smeCategory ? `border-[#f97316] border-4` : ""}`}
                style={{ width: '36px', height: '36px' }}
                src={
                  userEmployee.profile.domainPath
                    ? process.env.REACT_APP_IMAGE + userEmployee.profile.domainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
                } />
            </figure>
          </div>
          <span className="font-bold">
            {`${userEmployee.employee?.firstNameEnglish} ${userEmployee.employee?.lastNameEnglish}`}
          </span>

        </div>
        <div >
          <XMarkIcon onClick={() => {
            setRoomPrivate({
              userId: 0,
              sendToUserId: 0
            })
          }} className="w-6 h-6 cursor-pointer" />
        </div>
      </div>
    }
    {/* sm:h-[calc(100vh-60px)] h-[calc(100vh-150px)]  */}
    <div className="pl-2 sm:pl-0 pb-[100px] min-w-screen gap-y-5 flex max-h-screen overflow-hidden pr-2 !border-t-0 mt-2 gap-x-4">

      <div className="flex flex-1 flex-column gap-y-5 overflow-auto">
        {
          messages.map((item, index) => {
            return item.userId == userState.employee.userId ?
              <div className="flex justify-end" key={'message-send-' + index}>
                <div className="flex w-fit items-end gap-x-2">
                  <div className="flex flex-col items-end">
                    {
                      item.readBy && item.readBy.split(',').includes(item.toUserId) ?
                        <span className="text-xs">{"อ่านแล้ว"}</span>
                        : <></>
                    }
                    <span className="text-xs">{moment(item.createdate).format("DD/MM/YY HH:mm")}</span>
                  </div>

                  <div className="bg-[lightblue] rounded-lg p-2">
                    {
                      item.type == "message" && <span>{item.message}</span>
                    }
                    {
                      item.type == "filedoc" && <div onClick={() => {
                        window.open(`${process.env.REACT_APP_IMAGE}${encodeURIComponent(item.path).replace("%2F", "/")}`, '_blank')
                      }} className="flex gap-x-2 cursor-pointer">
                        <DocumentIcon color="#3273DC" className="w-10 h-10" />
                        <div className="flex flex-column">
                          <span className="text-[#3273DC]">{item.message}</span>
                          {/* <span>{(item.file?.size / 1000 / 1000).toFixed(2)} MB</span> */}
                        </div>
                      </div>
                    }
                    {
                      item.type == "image" && <div className="flex gap-x-2">
                        {/* <DocumentIcon color="#3273DC" className="w-10 h-10" /> */}
                        <div className="flex flex-column">
                          <div className="h-[200px] w-[200px]">
                            <ModalImage

                              className="h-[200px] w-[200px] image-cover"
                              small={process.env.REACT_APP_IMAGE + item.path}
                              large={process.env.REACT_APP_IMAGE + item.path}
                              alt="img-cer"
                            />
                          </div>

                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div> : <div className="flex justify-start" key={'message-receive-' + index}>
                <div className="flex w-fit items-end gap-x-2">
                  <figure className="image is-48x48">
                    <img
                      className={`is-rounded image-cover ${roomDetail.employee?.smeCategory ? `border-[#f97316] border-4` : ""}`}
                      style={{ width: '100%', height: '100%' }}
                      onClick={() => {
                        const profile = roomDetail.profile;
                        //console.log(roomDetail)
                        window.open(`/profileDetail?id=${profile.id}`, "_blank");
                      }}
                      src={process.env.REACT_APP_IMAGE + roomDetail.image} />
                  </figure>
                  <div className="bg-[#F4F4F4] rounded-lg p-2">
                    {
                      item.type == "message" && <span>{item.message}</span>
                    }
                    {
                      item.type == "filedoc" && <div onClick={() => {
                        window.open(`${process.env.REACT_APP_IMAGE}${encodeURIComponent(item.path).replace("%2F", "/")}`, '_blank')
                      }} className="flex gap-x-2 cursor-pointer">
                        <DocumentIcon color="#3273DC" className="w-10 h-10" />
                        <div className="flex flex-column">
                          <span className="text-[#3273DC]">{item.message}</span>
                          {/* <span>{(item.file?.size / 1000 / 1000).toFixed(2)} MB</span> */}
                        </div>
                      </div>
                    }
                    {
                      item.type == "image" && <div className="flex gap-x-2">
                        {/* <DocumentIcon color="#3273DC" className="w-10 h-10" /> */}
                        <div className="flex flex-column">
                          <div className="h-[200px] w-[200px]">
                            <ModalImage

                              className="h-[200px] w-[200px] image-cover"
                              small={process.env.REACT_APP_IMAGE + item.path}
                              large={process.env.REACT_APP_IMAGE + item.path}
                              alt="img-cer"
                            />
                          </div>

                        </div>
                      </div>
                    }
                  </div>
                  <span className="text-xs">{moment(item.createdate).format("DD/MM/YY HH:mm")}</span>
                </div>

              </div>
          })
        }
        <div ref={lastMessageRef} />
      </div>
      {userEmployee.profile?.id && <div className="w-[300px] border-l-2 p-2 hidden lg:block">
        <div className="flex items-center justify-center flex-column">
          <figure
            onClick={() => {
              window.open(`/profileDetail?id=${userEmployee.profile?.id}`, "_blank");
            }}
            className="image cursor-pointer !w-32 !h-32"
          >
            <img
              src={
                userEmployee.profile.domainPath
                  ? process.env.REACT_APP_IMAGE + userEmployee.profile.domainPath
                  : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
              }
              alt="profile_image"
              //class="is-rounded img-card image-cover"
              className={`is-rounded img-card image-cover !w-32 !h-32 ${userEmployee.ranking?.rankingName == "SMEs" ? `border-[#f97316] border-[5px]` : ""}`}
            />
          </figure>
          <div className="is-flex is-flex-direction-column items-center justify-center layer2 flex-1 !ml-0">
            <div>
              {
                userEmployee.ranking?.rankingName == "SMEs" ?
                  // <Link
                  //   target="_blank"
                  //   to="/ranking">
                  <div onClick={() => {
                    window.open(`/profileDetail?id=${userEmployee.profile?.id}`, "_blank");
                  }} className="bg-[#f97316] px-2 left-7 cursor-pointer">
                    <span className="text-white text-sm">SMEs</span>
                  </div>
                  // </Link>
                  :
                  // <Link

                  //   target="_blank"
                  //   to="/ranking">
                  <div onClick={() => {
                    window.open(`/profileDetail?id=${userEmployee.profile?.id}`, "_blank");
                  }} className="bg-[dodgerblue] px-2 left-7 cursor-pointer">
                    <span className="text-white text-sm">{userEmployee.ranking?.rankingName}</span>
                  </div>
                // </Link>
              }
            </div>
            <div className="flex gap-x-2 items-center mt-2">
              <p onClick={() => {
                window.open(`/profileDetail?id=${userEmployee.profile?.id}`, "_blank");
              }} className="title-h text-lg font-bold cursor-pointer">{`${userEmployee.employee?.firstNameEnglish} ${userEmployee.employee?.lastNameEnglish}`}</p>
            </div>
            <p className="subtitle-h pb-3 text-center">
              {userEmployee.employee?.positionNameEnglish} /{" "}
              {userEmployee.employee?.companyDescription}
            </p>

          </div>
          <div className="is-flex section-content is-justify-content-space-around gap-x-2">
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <div className="is-flex is-align-items-center gap-x-2 items-center justify-center">
                <p className="title-h">{userEmployee.profile?.followerAmt}</p>
                <p className="subtitle-h">{t('menu.follower')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <div className="is-flex is-align-items-center gap-x-2 items-center justify-center">
                <p className="title-h">{userEmployee.profile?.followAmt}</p>
                <p className="subtitle-h">{t('menu.follow')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <div className="is-flex is-align-items-center gap-x-2 items-center justify-center">
                <p className="title-h">{userEmployee.profile?.articleAmt}</p>
                <p className="subtitle-h">{t('text.content')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      }

    </div>
    <div className="flex py-3 gap-x-2 items-center sm:w-[calc(100%-400px)] fixed bottom-0 bg-white w-100 px-2 sm:px-0">
      <input
        ref={docRef}
        onChange={fileDoc}
        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        id="doc_file"
        className="file-input"
        type="file"
        name="resume"
        hidden
      />
      <input
        ref={photoRef}
        onChange={filePhoto}
        accept="image/jpeg,image/png"
        id="image_file"
        className="file-input"
        type="file"
        name="resume"
        hidden
      />
      <PhotoIcon onClick={() => {
        photoRef.current.click()
      }} color="#3273DC" className="w-6 h-6 cursor-pointer" />
      <PaperClipIcon onClick={() => {
        docRef.current.click()
      }} color="#3273DC" className="w-6 h-6 cursor-pointer" />

      <div onClick={() => {
        //setemojiModal(true)
      }} className="relative">
        {/* <em-emoji onClick={() => {
          setemojiModal(true)
        }} native="😎" size="24px" set="facebook"></em-emoji> */}
        <FaceSmileIcon onClick={() => {
         setemojiModal(!emojiModal)
        }} color="#3273DC" className="w-6 h-6 cursor-pointer" />
        {emojiModal && <Picker data={data} style={{ position: 'absolute' }}  onEmojiSelect={(e) => {
          let emoji = e.native;
          setmessage(message + emoji);
          setemojiModal(false);
        }} />}
      </div>
      <span>
        {/* <Picker onSelect={(e) => {
          let emoji = e.native;
          setmessage(message + emoji);
        }} /> */}

      </span>
      <input value={message}
        onChange={(e) => {
          setmessage(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onSubmit({
              message: message,
              type: "message"
            })
            setmessage('');
          }
        }}
        className="bg-[#F4F4F4] w-full p-2 flex-1 focus:border-0" placeholder="พิมพ์ข้อความ"></input>
      <PaperAirplaneIcon color="#3273DC" onClick={() => {
        onSubmit({
          message: message,
          type: "message"
        })
        setmessage('');
      }} className="w-6 h-6 cursor-pointer" />
    </div>
  </div>
}

const MessageBodyGroup = ({ onSubmit = () => { }, messages = [], roomPrivate, roomDetail, deletePrivateGroup, getRoomDetail }) => {
  const { t, i18n } = useTranslation();
  const [message, setmessage] = useState('');
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const docRef = useRef(null);
  const photoRef = useRef(null)
  const [fileDocState, setfileDocState] = useState([]);
  const [emojiModal, setemojiModal] = useState(false);
  const [isModal, setisModal] = useState();
  const [isModalMember, setisModalMember] = useState();
  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState([]);
  const fileDoc = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    if (CheckFile({
      file,
      size: 150,
      type: ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
      message: "รองรับเฉพาะไฟล์ .pdf,.docx,pptx เท่านั้น",
    })) {
      setfileDocState([...fileDocState, file]);
      const popupConfirm = await CSwl.SwalConfirm(`ส่งไฟล์ ${file.name} ใช่หรือไม่`);
      if (popupConfirm.isConfirmed) {
        const formData = new FormData();
        if (file) {
          [file].map((item) => {
            formData.append("file", item);
          })
        }
        if (file) {
          CSwl.SwalLoad();
          const uploadedFile = await ApiChat.upload(formData);
          if (uploadedFile.status == 200) {
            onSubmit({
              message: uploadedFile.data?.file[0].name,
              path: uploadedFile.data?.file[0].path,
              file: file,
              type: "filedoc"
            })
          }
          CSwl.SwalClose();
        }

      }
    }
  };

  const filePhoto = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    if (CheckFile({
      file,
      size: 150,
      type: ["image/jpeg", "image/png"],
      message: "รองรับเฉพาะไฟล์ .png,.jpeg เท่านั้น",
    })) {
      setfileDocState([...fileDocState, file]);
      const popupConfirm = await CSwl.SwalConfirm(`ส่งไฟล์ ${file.name} ใช่หรือไม่`);
      if (popupConfirm.isConfirmed) {
        const formData = new FormData();
        if (file) {
          [file].map((item) => {
            formData.append("file", item);
          })
        }
        if (file) {
          CSwl.SwalLoad();
          const uploadedFile = await ApiChat.upload(formData);
          if (uploadedFile.status == 200) {
            onSubmit({
              message: uploadedFile.data?.file[0].name,
              path: uploadedFile.data?.file[0].path,
              file: file,
              type: "image"
            })
          }
          CSwl.SwalClose();
        }

      }
    }
  };

  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          console.log(data.data)
          return data.data.filter(x => !(roomDetail.profiles.map(x => x.userId).includes(x.userId)));
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    if (checked) {
    } else {
      setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    }
    //setsuggestionsSelectUser(suggestion);
    return "";
  };

  const renderSuggestionUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    console.log(suggestionSelectUser)
    setsuggestionUser(newValue);
  };

  const sendGroup = async () => {
    if (suggestionSelectUser.length == 0) {
      CSwl.SwalErr("กรุณาระบุสมาชิกในกลุ่ม");
      return false;
    } else {
      var userId = suggestionSelectUser.map(x => x.employee.userId).join(',');
      const model = {
        userId: userId,
        createBy: userState.employee.userId,
        roomId: roomDetail.roomId
      }
      const groupResponse = await ApiChat.inviteGroup(model);

      if (groupResponse.status == 200) {
        const { data } = groupResponse;
        setsuggestionsSelectUser([]);
        setisModal(false);
        await getRoomDetail(roomDetail.roomId);
      }
    }
  }

  useEffect(() => {
    return () => {
      console.log('leaveChat')
      if (roomDetail.roomId > 0) {
        ApiChat.leaveChat(roomDetail.roomId, userState.employee.userId)
      }
    }
  }, [roomDetail])




  return <div className="divide-y h-[100vh]">
    <div className="flex px-3 py-2 w-full items-center w-inherit gap-x-4 justify-between bg-white border-b">
      <div className="flex gap-x-4 items-center">
        <span className="font-bold">
          {roomDetail.fullname} {`(${roomDetail?.profiles?.length})`}
        </span>
        <div className="flex gap-x-2">
          {
            roomDetail.profiles.map((item, index) => {
              console.log(item)
              if (index == 5) {
                let countPeople = roomDetail.profiles.length - 5;
                return <div onClick={() => {
                  setisModalMember(true)
                }} className="items-center justify-center cursor-pointer" style={{ width: '36px', height: '36px', position: 'relative' }}>
                  <img className="is-rounded image-cover"
                    style={{ width: '36px', height: '36px', opacity: 0.1 }}
                    src={process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png"} />
                  <span className="font-bold absolute top-[7px] left-[7px]">{`+ ${countPeople}`}</span>
                </div>
              } else if (index > 5) {
                return <></>
              }
              return <figure
                onClick={() => {

                  window.open(`/profileDetail?id=${item.profile.id}`, "_blank");
                }}
                key={`imageGroup-${item.userId}`} className="image w-[36px] h-[36px] cursor-pointer">
                <img
                  className={`is-rounded image-cover ${item.employee.smeCategory ? `border-[#f97316] border-4` : ""}`}
                  style={{ width: '36px', height: '36px' }}
                  src={item.image
                    ? process.env.REACT_APP_IMAGE + item.image
                    : process.env.PUBLIC_URL +
                    "/images/profilePage/Profile.png"} />
              </figure>
            })
          }

        </div>
      </div>
      <div className="flex gap-x-2">
        <UserGroupIcon onClick={() => {
          setisModalMember(true)
        }} color="#3273DC" className="w-8 h-6 cursor-pointer" />
        <UserPlusIcon onClick={() => {
          setisModal(true)
        }} color="#3273DC" className="w-8 h-6 cursor-pointer" />
        <ArrowRightOnRectangleIcon color="#3273DC" className="w-8 h-6 cursor-pointer" onClick={() => {
          deletePrivateGroup(roomDetail)
        }} />
      </div>
    </div>
    <div className="h-[95%] w-full gap-y-5 flex flex-column max-h-screen overflow-auto mt-2 pr-2 !border-t-0">
      {
        messages.map((item, index) => {
          return item.userId == userState.employee.userId ?
            <div className="flex justify-end" key={'message-send-' + index}>
              <div className="flex w-fit items-end gap-x-2">
                <div className="flex flex-col items-end">
                  {
                    item.readBy && item.readBy.split(',').length > 0 ?
                      <span className="text-xs">{`อ่านแล้ว ${item.readBy.split(',').length}`}</span>
                      : <></>
                  }
                  <span className="text-xs">{moment(item.createdate).format("DD/MM/YY HH:mm")}</span>
                </div>
                {/* <span className="text-xs">{moment(item.createdate).format("DD/MM/YY HH:mm")}</span> */}
                <div className="bg-[lightblue] rounded-lg p-2">
                  {
                    item.type == "message" && <span>{item.message}</span>
                  }
                  {
                    item.type == "filedoc" && <div onClick={() => {
                      window.open(`${process.env.REACT_APP_IMAGE}${encodeURIComponent(item.path).replace("%2F", "/")}`, '_blank')
                    }} className="flex gap-x-2 cursor-pointer">
                      <DocumentIcon color="#3273DC" className="w-10 h-10" />
                      <div className="flex flex-column">
                        <span className="text-[#3273DC]">{item.message}</span>
                        {/* <span>{(item.file?.size / 1000 / 1000).toFixed(2)} MB</span> */}
                      </div>
                    </div>
                  }
                  {
                    item.type == "image" && <div className="flex gap-x-2">
                      {/* <DocumentIcon color="#3273DC" className="w-10 h-10" /> */}
                      <div className="flex flex-column">
                        <div className="h-[200px] w-[200px]">
                          <ModalImage
                            className="h-[200px] w-[200px]"
                            small={process.env.REACT_APP_IMAGE + item.path}
                            large={process.env.REACT_APP_IMAGE + item.path}
                            alt="img-cer"
                          />
                        </div>

                      </div>
                    </div>
                  }
                </div>
              </div>
            </div> : <div className="flex justify-start" key={'message-receive-' + index}>
              <div className="flex w-fit items-end gap-x-2">
                <figure className="image is-48x48 cursor-pointer">
                  <img
                    className={`is-rounded image-cover ${roomDetail.profiles.find(x => x.userId == item.userId)?.employee.smeCategory ? `border-[#f97316] border-4` : ""}`}
                    style={{ width: '100%', height: '100%' }}
                    src={roomDetail.profiles.length > 0 &&
                      process.env.REACT_APP_IMAGE + roomDetail.profiles.find(x => x.userId == item.userId)?.image}
                    onClick={() => {
                      const profile = roomDetail.profiles.find(x => x.userId == item.userId);
                      window.open(`/profileDetail?id=${profile.profile.id}`, "_blank");
                    }}
                  />
                </figure>
                <div className="bg-[#F4F4F4] rounded-lg p-2">
                  {
                    item.type == "message" && <span>{item.message}</span>
                  }
                  {
                    item.type == "filedoc" && <div onClick={() => {
                      window.open(`${process.env.REACT_APP_IMAGE}${encodeURIComponent(item.path).replace("%2F", "/")}`, '_blank')
                    }} className="flex gap-x-2 cursor-pointer">
                      <DocumentIcon color="#3273DC" className="w-10 h-10" />
                      <div className="flex flex-column">
                        <span className="text-[#3273DC]">{item.message}</span>
                        {/* <span>{(item.file?.size / 1000 / 1000).toFixed(2)} MB</span> */}
                      </div>
                    </div>
                  }
                  {
                    item.type == "image" && <div className="flex gap-x-2">
                      {/* <DocumentIcon color="#3273DC" className="w-10 h-10" /> */}
                      <div className="flex flex-column">
                        <div className="h-[200px] w-[200px]">
                          <ModalImage

                            className="h-[200px] w-[200px]"
                            small={process.env.REACT_APP_IMAGE + item.path}
                            large={process.env.REACT_APP_IMAGE + item.path}
                            alt="img-cer"
                          />
                        </div>

                      </div>
                    </div>
                  }
                </div>
                <span className="text-xs">{moment(item.createdate).format("DD/MM/YY HH:mm")}</span>
              </div>

            </div>
        })
      }
    </div>
    <div className="flex py-3 gap-x-2 items-center">
      <input
        ref={docRef}
        onChange={fileDoc}
        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        id="doc_file"
        className="file-input"
        type="file"
        name="resume"
        hidden
      />
      <input
        ref={photoRef}
        onChange={filePhoto}
        accept="image/jpeg,image/png"
        id="image_file"
        className="file-input"
        type="file"
        name="resume"
        hidden
      />
      <PhotoIcon onClick={() => {
        photoRef.current.click()
      }} color="#3273DC" className="w-6 h-6 cursor-pointer" />
      <PaperClipIcon onClick={() => {
        docRef.current.click()
      }} color="#3273DC" className="w-6 h-6 cursor-pointer" />
      <div onClick={() => {
        //setemojiModal(true)
      }} className="relative">
        {/* <em-emoji onClick={() => {
          setemojiModal(true)
        }} native="😎" size="24px" set="facebook"></em-emoji> */}
        <FaceSmileIcon onClick={() => {
            setemojiModal(!emojiModal)
        }} color="#3273DC" className="w-6 h-6 cursor-pointer" />
        {emojiModal && <Picker data={data} style={{ position: 'absolute' }} onEmojiSelect={(e) => {
          let emoji = e.native;
          setmessage(message + emoji);
          setemojiModal(false);
        }} />}
      </div>
      <input value={message}
        onChange={(e) => {
          setmessage(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onSubmit({
              message: message,
              type: "message"
            })
            setmessage('');
          }
        }}
        className="bg-[#F4F4F4] w-full p-2 flex-1 focus:border-0" placeholder="พิมพ์ข้อความ"></input>
      <PaperAirplaneIcon color="#3273DC" onClick={() => {
        onSubmit({
          message: message,
          type: "message"
        })
        setmessage('');
      }} className="w-6 h-6 cursor-pointer" />
    </div>
    <div class={`modal modal ${isModal ? "d-flex" : ""}`}>
      <div class="modal-background"></div>
      <div class="modal-content bg-white p-5 flex flex-column gap-y-4" style={{ borderRadius: 5 }}>
        <div
          className="flex justify-between items-center"
        >
          <span className="font-bold text-lg">สร้างกลุ่มใหม่</span>
          <XMarkIcon onClick={() => {
            setisModal(false)
          }} className="w-6 h-6 cursor-pointer" />
        </div>
        <div className="flex flex-column gap-y-2">
          <span className="font-bold">ค้นหาสมาชิก</span>
          {/* <input className="input size-input" placeholder="#ชื่อกลุ่ม" /> */}
          <Autosuggest
            suggestions={suggestionsUser}
            onSuggestionsFetchRequested={onSuggestionsFetchRequestedUser}
            onSuggestionsClearRequested={onSuggestionsClearRequestedUser}
            getSuggestionValue={getSuggestionValueUser}
            renderSuggestion={renderSuggestionUser}
            inputProps={{
              placeholder: t('ค้นหา'),
              value: suggestionUser,
              onChange: onChangeSuggestionUser,
              className: 'input'
            }
            }
          />
        </div>
        <div className="flex flex-column gap-y-2">
          <span className="font-bold">สมาชิกในกลุ่ม</span>
          {
            suggestionSelectUser.map((item, index) => {
              return <div className="flex-1 relative" key={"userSelect-" + index}>
                <div className="flex justify-between items-center">
                  <div className="flex justify-between items-center gap-x-3">
                    <figure className="image is-48x48">
                      <img
                        className={`is-rounded image-cover ${item.smeCategory ? `border-[#f97316] border-4` : ""}`}
                        style={{ width: '100%', height: '100%' }}
                        src={item.image
                          ? process.env.REACT_APP_IMAGE + item.image
                          : process.env.PUBLIC_URL +
                          "/images/profilePage/Profile.png"} />
                    </figure>
                    <span>
                      {item.firstNameEnglish + " " + item.lastNameEnglish}
                    </span>
                  </div>
                  <XMarkIcon onClick={() => {
                    const x = suggestionSelectUser.splice(index, 1);
                    setSuggestionsUser(x);
                  }} className="w-6 h-6 cursor-pointer" />
                </div>
              </div>
            })
          }
        </div>
        <div className="flex justify-end gap-x-4 items-center">
          <span className="cursor-pointer" onClick={() => {
            setisModal(false)
          }}>{t("menu.cancel")}</span>
          <div onClick={() => sendGroup()} className="bg-[#3273DC] text-[#FFF] px-4 py-2 rounded-lg">
            <span>สร้างกลุ่ม</span>
          </div>
        </div>
      </div>
    </div>
    <div class={`modal modal ${isModalMember ? "d-flex" : ""}`}>
      <div class="modal-background"></div>
      <div class="modal-content bg-white p-5 flex flex-column gap-y-4" style={{ borderRadius: 5 }}>
        <div
          className="flex justify-between items-center"
        >
          <span className="font-bold text-lg">สมาชิกในกลุ่ม</span>
          <XMarkIcon onClick={() => {
            setisModalMember(false)
          }} className="w-6 h-6 cursor-pointer" />
        </div>
        <div className="flex flex-column gap-y-2 max-h-[500px] overflow-auto">
          {
            roomDetail.profiles.map((item, index) => {
              const profile = item.employee;
              return <>
                <div className="flex-1 relative" key={"userSelect-" + index}>
                  <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center gap-x-3">
                      <figure className="image is-48x48">
                        <img
                          className={`is-rounded image-cover ${profile.smeCategory ? `border-[#f97316] border-4` : ""}`}
                          style={{ width: '100%', height: '100%' }}
                          src={item.image
                            ? process.env.REACT_APP_IMAGE + item.image
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"} />
                      </figure>
                      <span>
                        {item.fullName}
                      </span>
                    </div>
                  </div>
                  {
                    item.smeCategory && <div className="bg-[#f97316] px-2 absolute top-[-10px] left-3">
                      <span className="text-white text-xs">SMEs</span>
                    </div>
                  }
                </div>
              </>
            })
          }
        </div>
        <div className="flex justify-end gap-x-4 items-center">
          <span className="cursor-pointer" onClick={() => {
            setisModalMember(false)
          }}>{t("menu.cancel")}</span>
        </div>
      </div>
    </div>
  </div>
}



export default ChatPage;
