import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiMtGdcemployee extends Component {
  static GetListByContentId = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtGdcemployee/GetListByContentId?id=${id}`,
      method: "get",
    });
    return result;
  };
}

export default ApiMtGdcemployee;
