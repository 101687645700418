import axios from "axios";
import moment from "moment";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import CSwl from "./alert";
import AlertError from "./alert";
import { getLocalStorage } from "./localStorage";
import { LogPageSpendTime } from "./log";

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
        if (comparer(this[i])) return true;
    }
    return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
    if (!this.inArray(comparer)) {
        this.push(element);
    }
};

export const setHeaderAuth = async () => {
    const token = await getLocalStorage("token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};


export const CheckFile = ({ file, size, type = [], message = "" }) => {

    if (!type.includes(file.type) && type.length > 0) {
        CSwl.SwalErr(
            // null,
            // message ? message : `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
            message ? message : `รองรับเฉพาะไฟล์ประเภท ${type.join(",")}`
        );
        return false;
    }
    if (file.size / 1000 / 1000 > size) {
        //CSwl.SwalErr(null, `Please upload a file smaller than ${size} MB`);
        CSwl.SwalErr(`Please upload a file smaller than ${size} MB`);
        return false;
    } else {
        return true;
    }
};

export const ConvertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const checkUserBool = (user) => {
    if (user.profile?.id > 0) {
        return true;
    } else {
        return false;
    }
};

export const checkStatusRedeem = (status) => {
    if (status == "W") {
        return "รออนุมัติ";
    }
    else if (status == "A") {
        return "อนุมัติแล้ว"
    }
    else if (status == "R") {
        return "ไม่อนุมัติ"
    }
};

export const arrayRemoveByIndex = (arr, position) => {
    return arr.filter(function (ele, index) {
        return index != position;
    });
}


export function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}


export const useWindowUnloadEffect = (handler, callOnCleanup) => {
    const cb = useRef();

    cb.current = handler;

    useEffect(() => {
        const handler = () => cb.current();

        window.addEventListener('beforeunload', handler);

        return () => {
            if (callOnCleanup) handler();

            window.removeEventListener('beforeunload', handler);
        };
    }, [cb]);
};

export const destroyPage = ({ page, firstTime = new Date() }) => {
    const destroyTime = new Date();
    var seconds = (destroyTime.getTime() - firstTime.getTime()) / 1000;
    LogPageSpendTime({ page: page, spendTime: seconds, });
}

export const firstViewPage = ({ page }) => {
    // LogPageSpendTime({ page: page, isView: true });
}

export const CertificateStatus = (status, reason) => {
    switch (status) {
        case "W":
            return "รออนุมัติ";
        case "A":
            return "อนุมัติเรียบร้อย";
        case "R":
            return `ไม่อนุมัติ (${reason})`;
        default:
            return "";
    }
}

export const ConvertDuration = (startDate, endDate) => {
    const diff = moment(endDate).diff(moment(startDate));
    if (!isNaN(diff)) {
        let durationText = "";

        var duration = moment.duration(diff);

        var years = parseInt(duration.asYears());
        if (years >= 1) {
            duration.subtract(moment.duration(years, 'years'));
            durationText += `${years} ปี `
        }


        var months = parseInt(duration.asMonths());
        if (months >= 1) {
            duration.subtract(moment.duration(months, 'months'));
            durationText += `${months} เดือน `
        }

        //Get Days and subtract from duration
        var days = duration.asDays();
        if (days >= 1) {
            duration.subtract(moment.duration(days, 'days'));
            durationText += `${days} วัน `
        }
        return durationText;
    } else {
        return "-"
    }
}