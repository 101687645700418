import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiMsGraph extends Component {
  static SendCalendar = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MsGraph/SendCalendar`,
      method: "post",
      data: data,
    });
    return result;
  };

  static CreateTeam = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MsGraph/CreateTeam`,
      method: "post",
      data: data,
    });
    return result;
  };
}

export default ApiMsGraph;
