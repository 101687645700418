import React, { useState, useMemo, useEffect } from "react";
import "./index.scss";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InitialState from "./initialState";
import ReactQuill, { Quill } from "react-quill";
import ApiContent from "../../../api/ApiContent";
import { WEB_IMAGE } from "../../../env";
import Swal from "sweetalert2";
import { ConvertDateShort } from "../../../utils/date";
import CSwal from "../../../utils/alert";
import Select from "react-select";
import ApiCategory from "../../../api/ApiCategory";
import { TagsInput as ReactTags } from "react-tag-input-component";
import { useHistory } from "react-router-dom";
import { arrayRemoveByIndex, CheckFile, destroyPage, useWindowUnloadEffect } from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PostV2Preview from "../../../components/PostV2Preview";
import PostDetailPreview from "../../../components/PostBackendPreview";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import Autosuggest from 'react-autosuggest';
import ApiTranProfile from "../../../api/ApiTranProfile";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ApiOrg from "../../../api/ApiOrg";
import moment from "moment";
window.Quill = Quill;

const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);

const CreateLiveSession = () => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Create Live Sharing", firstTime: firstTime }), true);
  const userState = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState(InitialState);
  const [catagory, setcatagory] = useState([]);
  const [tags, setTags] = React.useState([]);
  const [fileDocState, setfileDocState] = useState([]);
  const [fileVideoState, setVideoState] = useState("");
  const [isCalendar, setisCalendar] = useState(false);
  const [startDate, setStateDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataPreview, setdataPreview] = useState({});
  const [isPreview, setisPreview] = useState(false);
  var quillObj = React.useRef(null);
  var form = React.useRef(null);
  const [suggestion, setsuggestion] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionSelect, setsuggestionsSelect] = useState([]);

  const [suggestionUser, setsuggestionUser] = useState('');
  const [suggestionsUser, setSuggestionsUser] = useState([]);
  const [suggestionSelectUser, setsuggestionsSelectUser] = useState([]);

  const [stateOrg, setstateOrg] = useState({
    bu: [],
    subbu: [],
    company: [],
    division: [],
    department: [],
    section: []
  });

  const imageHandler = (image, callback) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;

      uploadFiles(file, fileName, quillObj.current);
    };
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        // See optional "config" below
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"], // remove formatting button
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const uploadFiles = async (uploadFileObj, filename, quillObj) => {
    Swal.showLoading();
    const range = quillObj.getEditorSelection();
    console.log(range);
    console.log(uploadFileObj);
    console.log(filename);
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiContent.uploadHtmlFile(formData);
    const { data } = result.data;
    quillObj
      .getEditor()
      .insertEmbed(range.index, "image", process.env.REACT_APP_IMAGE + data.path);
    Swal.close();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    state.ctstatus = "A";
    CSwal.SwalLoad();
    const regex = /(<([^>]+)>)/gi;
    const content_html = state.ctdeschtml.replace(regex, "");
    let jsonCatagoryId = {};
    let jsonCatagoryJson = {};;
    if (content_html == "") {
      CSwal.SwalErr("กรุณากรอกรายละเอียดองค์ความรู้");
      return false;
    }

    if (state.ctcatagoryJson.length > 0) {
      jsonCatagoryId = state.ctcatagoryJson
        .map((x) => {
          return x.value;
        })
        .join(",");
      jsonCatagoryJson = state.ctcatagoryJson.map((x) => {
        return { Id: x.value, Name: x.label };
      });
    } else {
      CSwal.SwalErr("กรุณากรอกหมวดหมู่ขององค์ความรู้");
      return false;
    }

    try {
      const created = await ApiContent.createLiveSharing(
        {
          ...state,
          ctcatagoryid: jsonCatagoryId,
          ctcatagoryJson: jsonCatagoryJson,
          ctstartdate: moment(state.ctstartdate).add(7, 'hours'),
          ctenddate: moment(state.ctenddate).add(7, 'hours'),
        });
      const formData = new FormData();
      if (fileVideoState) {
        formData.append("video", fileVideoState);
      }
      const refno = created.data.data.ctrefno;
      formData.append("refno", refno);
      if (fileDocState || fileVideoState) {
        const uploadedFile = await ApiContent.uploadLiveSharing(formData);
      }
      Swal.fire("สำเร็จ", "", "success").then(async (result) => {
        // history.push("/home");
      });
    } catch (error) {
      try {
        const { data } = error.response;
        CSwal.SwalErr(data.message);
      } catch (ex) {
        CSwal.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  };

  const getCatagory = async () => {
    const result = await ApiCategory.get();
    const { data } = result.data;
    setcatagory(data);
  };

  useEffect(() => {
    getCatagory();
    return () => { };
  }, []);

  useEffect(() => {
    if (state.isSpecific == 1) {
      if (stateOrg.bu.length == 0) {
        func_loadOrg();
      }
    }
    return () => { };
  }, [state]);

  const func_loadOrg = async () => {
    try {
      CSwal.SwalLoad();
      var res = await ApiOrg.getOrg();
      CSwal.SwalClose();
      const { bu, subbu, company, division, department, section, status } = res.data;
      if (res.status === 200) {
        setstateOrg({
          bu: bu,
          subbu,
          company,
          division,
          department,
          section
        })
        // if (status) {
        //   var setDDL = [];
        //   for (var i = 0; i < data.length; i++) {
        //     var model = {
        //       value: data[i].text2,
        //       label: data[i].text,
        //       label2: data[i].text3,
        //     };

        //     setDDL.push(model);
        //   }
        //   console.log(setDDL);
        //   setBuDDL(setDDL);
        // }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
  };

  const handleTagAddition = async (tag) => {
    setTags(tag);

    var tagModel = [];
    for (var i = 0; i < tag.length; i++) {
      var tagsub = {
        name: tag[i],
      };
      tagModel.push(tagsub);
    }
    setState({ ...state, cttagtJson: tagModel });
  };






  const fileVideo = async (e) => {
    if (e.target.files.length == 0) return false;
    var file = e.target.files[0];
    if (CheckFile({
      file,
      size: 150,
      type: ["video/mp4"],
      message: "รองรับเฉพาะไฟล์ .mp4 เท่านั้น",
    })) {
      setVideoState(file);
    };
  };

  const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiContent.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValue = (suggestion) => {
    const checked = suggestionSelect.find(x => x.ctrefno == suggestion.ctrefno);
    if (checked) {

    } else {
      setsuggestionsSelect([...suggestionSelect, suggestion])
    }

    return "";
  };

  const renderSuggestion = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.ctname}
    </div>
  );

  const onSuggestionsFetchRequested = async ({ value }) => {

    setSuggestions(await getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChangeSuggestion = (event, { newValue }) => {
    setsuggestion(newValue);
  };

  const getSuggestionsUser = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength < 3) return [];
    else {
      try {
        const content = await ApiTranProfile.GetAutocomplete(inputValue);
        if (content.status == 200) {
          const { data } = content;
          return data.data;
        }
      } catch (error) {
        return [];
      }
    }
  };

  const getSuggestionValueUser = (suggestion) => {
    const checked = suggestionSelectUser.find(x => x.id == suggestion.id);
    if (checked) {
    } else {
      setsuggestionsSelectUser([...suggestionSelectUser, suggestion])
    }

    return "";
  };

  const renderSuggestionUser = suggestion => (
    <div style={{ padding: 10, border: '1px solid #efefef' }}>
      {suggestion.firstNameEnglish + " " + suggestion.lastNameEnglish}
    </div>
  );

  const onSuggestionsFetchRequestedUser = async ({ value }) => {

    setSuggestionsUser(await getSuggestionsUser(value));
  };

  const onSuggestionsClearRequestedUser = () => {
    setSuggestionsUser([]);
  };

  const onChangeSuggestionUser = (event, { newValue }) => {
    setsuggestionUser(newValue);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div id="createContent-page">

      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.createLiveSession')} />
      </Drawer>
      <Header />
      <div className="columns" style={{ height: "100%" }}>
        {/* section menu */}
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.createLiveSession').replace("สร้าง", "").replace("Create", "")} />
        </div>
        <form ref={form} className="d-flex flex-1" onSubmit={onSubmit}>
          <div className="column is-8 border-div1">
            <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap size-wrap">
              <div className="is-flex is-align-items-center">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h1">{t('menu.createLiveSession')}</p>
              </div>
              <div className="is-flex">
                <button
                  type={"submit"}
                  className="button is-outlined has-text-weight-bold has-text-white subtitle-p4 mx-2"
                  style={{ backgroundColor: "#F24C20" }}
                >
                  {t('text.publish')}
                </button>
              </div>
            </div>
            <hr className="hr-size1" />
            <div className="is-flex is-flex-direction-column size-col">

              <div className="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap my-4 px-1">
                <p className="font">{t('text.content_name')}</p>
                <input
                  className="input size-input"
                  type="text"
                  placeholder="กรุณาระบุชื่อความรู้"
                  value={state.ctname}
                  required
                  onChange={(e) =>
                    setState({ ...state, ctname: e.target.value })
                  }
                />
              </div>

              <div className="is-flex is-flex-direction-column px-2">
                <p className="font">{t('text.content_desc')}</p>
                <ReactQuill
                  style={{ marginTop: 20, marginBottom: 20 }}
                  ref={quillObj}
                  value={state.ctdeschtml}
                  modules={modules}
                  onChange={(content, delta, source, editor) => {
                    setState({ ...state, ctdeschtml: content });
                  }}
                />
              </div>
              <div className="flex px-1 mb-3 gap-x-4 justify-between">
                <div className="flex-1">
                  <div className="is-flex is-justify-content-space-between">
                    <div className="is-flex is-align-items-center">
                      <p>วัน-เวลาที่เริ่ม Live</p>
                    </div>
                  </div>
                  <div className="is-flex is-justify-content-space-between ">
                    <DatePicker
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      className="input"
                      selected={state.ctstartdate}
                      onChange={(date) => setState({ ...state, ctstartdate: date })}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="is-flex is-justify-content-space-between">
                    <div className="is-flex is-align-items-center">
                      <p>วัน-เวลาที่ปิด Live</p>
                    </div>
                  </div>
                  <div className="is-flex is-justify-content-space-between">
                    <DatePicker
                      minDate={new Date()}
                      filterTime={filterPassedTime}
                      className="input"
                      selected={state.ctenddate}
                      onChange={(date) => setState({ ...state, ctenddate: date })}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:mm"
                    />
                  </div>
                </div>
              </div>

              {/* inputFile */}

              <div className="flex gap-x-4 flex-wrap grid grid-cols-1 px-1">
                {/* section-inputVdo */}
                <div className="is-flex is-flex-direction-column flex-1">
                  <p className="title-h1">{t('text.content_video')}</p>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex-1">
                      <input
                        className="input"
                        type="text"
                        placeholder="ระบุเป็น Link จาก MS Stream"
                        value={state.cturl}
                        
                        onChange={(e) =>
                          setState({ ...state, cturl: e.target.value })
                        }
                      />
                    </div>
                    <div className="file is-boxed flex-1">
                      <label className="file-label">
                        <input
                          className="file-input"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={fileVideo}
                          id="video_file"
                          type="file"
                          name="resume"
                        />
                        <span className="file-cta !w-full is-flex-direction-row is-justify-content-center border-s">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/content/IconVdo.png"
                            }
                            alt="Icon"
                          />
                          <div className="is-flex is-flex-direction-column pl-2">
                            <p className="subtitle-p2">{t('text.content_video_desc_100mb')}</p>
                          </div>
                        </span>

                      </label>
                    </div>

                  </div>
                  {fileVideoState && (
                    <div className="d-flex file-row mt-3" style={{ position: 'relative' }}>
                      <label className="flex-1">
                        {fileVideoState.name}
                      </label>
                      <button onClick={(e) => {
                        e.stopPropagation();
                        setVideoState("")
                      }}
                        class="delete" style={{ position: 'absolute', right: 10 }}></button>
                    </div>
                  )}
                </div>
              </div>
              <div className="column is-4 pl-5 pt-5 hidden-d">
                <div className="is-flex is-align-items-center mt-4">
                  <figure className="image mr-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                      alt="Icon"
                    />
                  </figure>
                  <p className="title-h1">{t('text.summary')}</p>
                </div>
                <div className="is-flex mt-4">
                  <div className="is-flex is-flex-direction-column my-1">
                    <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                    <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                    <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
                  </div>
                  <div className="is-flex is-flex-direction-column my-1">
                    <p className="subtitle-p5 mr-6">
                      {ConvertDateShort(new Date(), "DD MMMM YYYY")}
                    </p>
                    <p className="subtitle-p5 mr-6">-</p>
                  </div>
                </div>
                {/* <hr className="hr-size2" /> */}
                <div className="column">
                  <div className="is-flex is-align-items-center">
                    <figure className="image mr-3">
                      <img
                        src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                        alt="Icon"
                      />
                    </figure>
                    <p className="title-h2">{t('text.category')}</p>
                  </div>
                  <div className="mt-3" style={{ width: "100%" }}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{ width: 200 }}
                      required
                      isMulti
                      onChange={(e) => {
                        setState({
                          ...state,
                          ctcatagoryJson: e,
                        });
                      }}
                      options={catagory.map((x) => {
                        return { label: x.categoryName, value: x.id };
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* column-right */}
          <div className="column is-4 hidden-b pl-3 pr-3">
            <div className="is-flex is-align-items-center mt-4">
              <figure className="image mr-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/content/IconChart.png"}
                  alt="Icon"
                />
              </figure>
              <p className="title-h1">{t('text.summary')}</p>
            </div>
            <div className="is-flex mt-4">
              <div className="is-flex is-flex-direction-column my-1">
                <p className="subtitle-p5 mr-6">{t('text.create_date')}</p>
                <p className="subtitle-p5 mr-6">{t('text.content_no')}:</p>
                <p className="subtitle-p5 mr-6">{t('text.status')}:</p>
              </div>
              <div className="is-flex is-flex-direction-column my-1">
                <p className="subtitle-p5 mr-6">
                  {ConvertDateShort(new Date(), "DD MMMM YYYY")}
                </p>
                <p className="subtitle-p5 mr-6">-</p>
              </div>
            </div>
            {/* <hr className="hr-size2" /> */}
            <div className="column">
              <div className="is-flex is-align-items-center mt-5">
                <figure className="image mr-3">
                  <img
                    src={process.env.PUBLIC_URL + "/images/content/Icon1.png"}
                    alt="Icon"
                  />
                </figure>
                <p className="title-h2">{t('text.category')}</p>
              </div>
              <div className="mt-3" style={{ width: "100%" }}>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={{ width: 200 }}
                  required
                  isMulti
                  onChange={(e) => {
                    setState({
                      ...state,
                      ctcatagoryJson: e,
                    });
                  }}
                  options={catagory.map((x) => {
                    return { label: x.categoryName, value: x.id };
                  })}
                />
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class={`modal ${isPreview ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5">
          {dataPreview.id &&
            // <PostV2Preview
            //   time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
            //   item={dataPreview}
            //   title={dataPreview.ctname}
            //   detail={dataPreview.ctdeschtml}
            //   name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
            //   image={dataPreview.imageProfile ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
            // />
            <PostDetailPreview
              page={'detail'}
              contentid={dataPreview.id}
              image={dataPreview.imageProfile ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
              name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
              time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
              title={dataPreview.ctname}
              detail={dataPreview.ctdeschtml}
              item={dataPreview}
              comments={[]}
            />
          }
        </div>
        <button onClick={() => {
          setisPreview(false);
          setdataPreview({});
        }}
          class="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  );
};

export default CreateLiveSession;
