import React from 'react'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import Cardlist from '../../../components/Cardlist';
import '../detail/index.scss'
import { Link } from "react-router-dom";
import ApiRedeem from '../../../api/ApiRedeem'
import ApiAuth from '../../../api/ApiAuth'
import { format } from 'date-fns'
import { th } from 'date-fns/locale'
import CSwl from '../../../utils/alert';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RedeemDetail = (props) => {
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const history = useHistory();
  const RedeemId = new URLSearchParams(props.location.search).get(
    'id'
  );
  const userState = useSelector((state) => state.user);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await ApiRedeem.getById(RedeemId)
      if (res.status === 200) {
        setData(res.data.data)
      }
      setLoading(true);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          const { message } = err.response;
          console.log(message);
        }
      }
    }
    finally {
      setLoading(false);
    }
  }

  if (loading === true) {
    return (
      <div className="text-center mt-5">
      </div>
    );
  }

  const handleClick = async () => {
    CSwl.SwalLoad();
    const postdata = {
      Rdid: data.id,
      Rdpoint: data.rdPoint,
      ProfilePointOrg: 5000,
      ProfilePoinBal: 2000,
    }
    try {
      const resp = await ApiRedeem.insert(postdata);
      if (resp.status == 200) {
        getProfile();
        Swal.fire("สำเร็จ", "", "success").then(async (result) => {
        });
      }
    }
    catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.title);
    }
  }


  const getProfile = async () => {
    try {
      const response = await ApiAuth.getUser();
      if (response.status == 200) {
        const { data } = response.data;
        dispatch({ type: "set_user", user: data });
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };


  return (
    <div id="RedeemDetailPage">
      <Header />
      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="redeem" header={t('text.redeem')} />
        </div>
        <div className="column is-7 border-div1">
          <Link to="/redeem" className="is-flex pt-6 pl-4 pb-3">
            <img src={process.env.PUBLIC_URL + "/images/redeemPage/Vector.png"} alt="logo" width='24' height='24' />
            <p className="subtitle-p pl-4">{t('text.back')}</p>
          </Link>
          <hr className="hr-size" />
          <div className="is-flex is-flex-direction-column px-5">
            <div className="is-flex is-flex-direction-column is-align-items-center mb-6">
              <img
                className="my-5"
                src={process.env.REACT_APP_IMAGE_BACKOFFICE + data.domainPath}
                alt="image_domain" />

              <p className="title-h">{languageState == "th" ? data.rdName : data.rdNameen}</p>
              <p className="subtitle-p">{languageState == "th" ? data.rdDesc : data.rdDescen}</p>
            </div>
            <div className="ml-3 mb-6">
              <p className="title-h">{t('text.redeem_condition')}</p>
              <div className="subtitle-p mb-5" dangerouslySetInnerHTML={{ __html: languageState == "th" ? data.rdDetailHtml : data.rdDetailHtmlEn }} />
            </div>
            <div className="ml-3 mb-6">
              <p className="title-h mb-4">{t('text.redeem_expire')}</p>
              <span className="subtitle-p">{format(new Date(data.rdStartdate), 'dd MMM yyyy', { locale: th })} - {format(new Date(data.rdEnddate), 'dd MMM yyyy', { locale: th })}</span>
            </div>
            <div className="ml-3 mb-6">
              <p className="title-h mb-4">{t('text.address')}</p>
              <p className="subtitle-p">{data.rdLocation}</p>
            </div>
            <div className="is-flex is-justify-content-center my-4">
              <button className="button is-rounded color-button mx-4"
                disabled={userState.profile?.pointAmt < data.rdPoint}
                onClick={handleClick}>{t('text.redeem')}</button>
            </div>
          </div>
        </div>
        {/* <div className="column is-3 pl-5 pt-5 hidden-b">
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.trending')} data={trend}/>
          </div>
          <div class="mt-3 mr-6">
            <Cardlist title={t('text.following')} data={friend}/>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default RedeemDetail
