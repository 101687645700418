import React, { useState } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../../utils/alert";
import { ConvertDuration, destroyPage, useWindowUnloadEffect } from "../../../utils";
import ApiFollow from "../../../api/ApiFollow";
import ApiMtTag from "../../../api/ApiTag";
import CardTaglist from "../../../components/CardTaglist";
import ProfileList from "../../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import ApiContent from "../../../api/ApiContent";
import ModalImage from "react-modal-image";
import { ConvertDateShort } from "../../../utils/date";
import Drawer from "rc-drawer";
import MenuDrawer from "../../../components/MenuDrawer";


const list_category = [{ id: 1, label: "Capability development" }, { id: 2, label: "Cost Reduction" }, { id: 3, label: "Culture Improvement" },
{ id: 4, label: "Delivery Improvement" },
{ id: 5, label: "Environmental Improvement" },
{ id: 6, label: "Existing Product Improvement" },
{ id: 7, label: "Fixed Cost Reduction" },
{ id: 8, label: "Law & Regulations" },
{ id: 9, label: "Market Lost Avoidance" },
{ id: 10, label: "Morale & Culture" },
{ id: 11, label: "New Product Development" },
{ id: 12, label: "Others" },
{ id: 13, label: "Price increase" },
{ id: 14, label: "Process Improvement" },
{ id: 15, label: "Product Compliance" },
{ id: 16, label: "Product mix improvement" },
{ id: 17, label: "Productivitiy Improvement" },
{ id: 18, label: "Quality Improvement" },
{ id: 19, label: "Replacement" },
{ id: 20, label: "Revenue Increase" },
{ id: 21, label: "Safety Improvement" },
{ id: 22, label: "Sales volume increase" },
{ id: 23, label: "System Improvement" },
{ id: 24, label: "Uptime Improvement" },
{ id: 25, label: "Variable Cost Reduction" },
{ id: 26, label: "Waste Reduction" },
{ id: 27, label: "Working Capital Reduction" },
]


const PastProjectDetail = () => {
  const history = useHistory();
  const [firstTime, setFirstTime] = useState(new Date());
  //useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const userState = useSelector((state) => state.user);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [file, setFile] = useState([]);
  const [fileImage, setfileImage] = useState('');
  const listTypeImage = [".jpeg", ".jpg", ".png"]
  const { id } = useParams();
  const [sasToken, setsasToken] = useState('');

  const [state, setstate] = useState({
    cert: {},
    compentency: [],
    user: {}
  });

  React.useEffect(() => {
    // CSwl.SwalLoad();
    // const promise1 = getProfileUnFollow();
    // const promise2 = getToptag();
    // Promise.all([promise1, promise2]).then(async (result) => {
    //   CSwl.SwalClose();
    //   await getData();
    // })
    getData();
    getBlobContainerSAS();
    return () => { };
  }, []);

  const getBlobContainerSAS = async () => {
    try {
      const response = await ApiContent.GetBlobContainerSAS();
      if (response.status == 200) {
        const { sasToken } = response.data;
        setsasToken(sasToken)
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };


  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageSize: 3,
      pageNumber: 1,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };


  const getData = async () => {
    try {
      const res = await ApiContent.GetProjectExpById(id);
      if (res.status === 200) {
        setstate({
          cert: res.data.data,
          compentency: res.data.compentency,
          user: res.data.user
        })
        const files = res.data.data.files ? JSON.parse(res.data.data.files) : "";
        if (files) {
          const fileImage = files.find((x) => {
            return listTypeImage.some(v => x.Path.includes(v))
          })
          setFile(files);
          setfileImage(fileImage)
        }

        // setDataDetail(res.data.data)
        // var div = document.createElement("div");
        // div.innerHTML = res.data.data.ctdeschtml;
        // var text = div.textContent || div.innerText || "";
        // setdescription(text);
        // if (res.data.data.ctdeschtml.includes('<img')) {
        //   var str = res.data.data.ctdeschtml;
        //   var elem = document.createElement('div');
        //   elem.style.display = 'none';
        //   document.body.appendChild(elem);
        //   elem.innerHTML = str;
        //   setimageBanner(elem.querySelector('img').src);
        // }
        // console.log(res.data.data.result)
      }
    } catch (error) {
      try {
        const { data } = error.response;
        CSwl.SwalErr(data.message).then(x => history.go(-1));
      } catch (ex) {
        CSwl.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  }

  return (
    <div id="PastProjectDetailPage">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('menu.projecthistory')} />
        </div>
        <div className="column is-7 pt-0">
          <div className="px-5 sm:p-5 sm:pt-6">
            <div className="is-flex is-align-items-center justify-between">
              <p className="title-h1">{t('menu.projectExp')}</p>
              {
                state.user?.userId == state.cert?.createby && <div className="flex items-center justify-center gap-2">
                  <Link to={`/pastProjectEdit/0`}>
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <figure className="image-cover cursor-pointer h-6 w-6">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/addx2.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                  <Link className="" to={`/pastProjectEdit/${state.cert?.id}`}>
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <figure className="image-cover cursor-pointer h-8 w-8 mt-3">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                </div>
              }
            </div>
          </div>

          {
            fileImage && <div className="px-5">
              <ModalImage
                className="sm:h-60 w-full sm:w-auto"
                small={fileImage.Path + sasToken}
                large={fileImage.Path + sasToken}
                alt="img-cer"
              />
            </div>
          }
          <div className="has-background-light px-2 sm:px-5 py-5 rounded-lg">
            <div className="px-5 sm:pb-5">
              <div className="is-flex is-align-items-center">
                <p className="title-h1">{t('text.details')}</p>
              </div>
            </div>
            {
              state.cert &&
              <div className="flex px-5 gap-x-5">
                <div className="flex flex-col gap-y-4">
                  <div className="font-bold">
                    {t('text.employeename')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectname')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectdetail')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projecttype')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectstartdate')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectenddate')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectduration')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectroles_short')} :
                  </div>
                  <div className="font-bold">
                    {t('text.descriptionrole')} :
                  </div>
                  <div className="font-bold">
                    {t('text.resultsproject')} :
                  </div>
                  <div className="font-bold">
                    {t('text.factory')} :
                  </div>
                  <div className="font-bold">
                    {t('text.projectleader')} :
                  </div>
                  <div className="font-bold">
                    {t('text.relatedcapabilities')} :
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-y-4">
                  <p>{`${state.user?.firstNameThai} ${state.user.lastNameThai}`}</p>
                  <p>{state.cert.name ?? "-"}</p>
                  <p>{state.cert.description ?? "-"}</p>
                  {/* <p>{state.cert.category ?? "-"}</p> */}
                  <p>{list_category.find(x => x.id == state.cert.category)?.label ?? ""}</p>
                  <p>{state.cert.startDate ? `${ConvertDateShort(state.cert.startDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"}</p>
                  <p>{state.cert.endDate ? `${ConvertDateShort(state.cert.endDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                  <p>{ConvertDuration(state.cert.startDate, state.cert.endDate)}</p>
                  <p>{state.cert.role ?? "-"}</p>
                  <p>{state.cert.roleDescription ?? "-"}</p>
                  <p>{state.cert.keyDeliverable ?? "-"}</p>
                  <p>{state.cert.location ?? "-"}</p>
                  <p>{state.cert.projectManager ?? "-"}</p>
                  <p>{state.compentency.map(x => x.competencyNameEn).join(', ') ?? "-"}</p>
                </div>
              </div>
            }
          </div>
          {
            (file && file.filter((x) => {
              return !listTypeImage.some(v => x.Path.includes(v))
            })) && <div className="px-5 !pt-3 sm:!pt-6">
              <div className="is-flex is-align-items-center">
                <p className="title-h1">{t('menu.certfiletitle')}</p>
              </div>
            </div>
          }
          {
            file && file.filter((x) => {
              return !listTypeImage.some(v => x.Path.includes(v))
            }).map((item, index) => {
              return <div className="mt-3">
                <div onClick={() =>
                  window.open(item.Path + sasToken, '_blank')
                } className="filename-size has-background-light px-5 py-3 cursor-pointer flex gap-x-4 items-center">
                  <img
                    className="h-8 w-6"
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/file.png"
                    }
                    alt="cer"
                  />
                  <p className="filename !text-lg">{item.Name}</p>
                </div>
              </div>
            })
          }
        </div>
        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div
            className="is-3 flex-1"
            style={{ position: "fixed", width: "inherit" }}
          >
            <div className="mt-3 mr-6">
              <CardTaglist
                title={t('text.trending')}
                data={trend}
                topage={"trend"}
              />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PastProjectDetail