import axios from 'axios';
import { osName } from 'react-device-detect'
import ApiLog from '../api/ApiLog';
import { setHeaderAuth } from "../utils";

export const LogPageSpendTime = async ({ page = "", spendTime = 0 }) => {
    try {
        await ApiLog.create({
            osName: osName,
            page: page,
            spendTime: spendTime,
            url:window.location.href
        });
    }
    catch (error) {
        console.log(error);
    }
};

