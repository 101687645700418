import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiContent extends Component {
  static get = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByPage`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getCreator = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCreator`,
      method: "get",
      // data: data,
    });
    return result;
  };

  static getLiveSharingByPage = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetLiveSharingByPage`,
      method: "post",
      data: data,
    });
    return result;
  };


  static Approve = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/Approve`,
      method: "post",
      data: data,
    });
    return result;
  };
  static ApproveSMEs = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/ApproveSMEs`,
      method: "post",
      data: data,
    });
    return result;
  };
  

  static Reject = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/Reject`,
      method: "post",
      data: data,
    });
    return result;
  };

  static GetCertificate = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCertificate`,
      method: "get",
    });
    return result;
  };

  static GetBlobContainerSAS = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetBlobContainerURL`,
      method: "get",
    });
    return result;
  };

  static GetCertificateByUser = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCertificateByUser/${id}`,
      method: "get",
    });
    return result;
  };


  static GetProjectExp = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetProjectExp`,
      method: "get",
    });
    return result;
  };

  static GetProjectExpByUser = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetProjectExpByUser/${id}`,
      method: "get",
    });
    return result;
  };

  static GetCertificateById = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCertificateById/${id}`,
      method: "get",
    });
    return result;
  };

  static GetProjectExpById = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetProjectExpById/${id}`,
      method: "get",
    });
    return result;
  };

  static SendSME = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/SendSME`,
      method: "post",
      data: data,
    });
    return result;
  };

  static SMEReject = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/SMEReject`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getListByBO = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListByBO`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getCountTranContentByBO = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCountTranContentByBO`,
      method: "get",
    });
    return result;
  };

  static getCountApproveCertificate = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCountApproveCertificate`,
      method: "get",
    });
    return result;
  };

  static getCountProjectExp = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetCountApproveProjectExp`,
      method: "get",
    });
    return result;
  };

  static getListApproveCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListApproveCertificate`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getListApproveProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListApproveProjectExp`,
      method: "post",
      data: data,
    });
    return result;
  };


  static ApproveCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/ApproveCertificate`,
      method: "post",
      data: data,
    });
    return result;
  };

  
  static RejectCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/RejectCertificate`,
      method: "post",
      data: data,
    });
    return result;
  };

  static ApproveProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/ApproveProjectExp`,
      method: "post",
      data: data,
    });
    return result;
  };

  
  static RejectProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/RejectProjectExp`,
      method: "post",
      data: data,
    });
    return result;
  };

  static GetListSMEByBO = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListSMEByBO`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getBookmark = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByBookmark`,
      method: "post",
      data: data,
    });
    return result;
  };

  static getPopular = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByPagePopular`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getBySearch = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetBySearch`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getByCatalog = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByCatalog`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getByTag = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByTag`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getByDept = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/dept/${id}`,
      method: "get",
    });
    return result;
  };
  static getByUser = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListByUser`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getByUserProfile = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListByUser/${data.profileId}`,
      method: "post",
      data: data,
    });
    return result;
  };
  static getListWaitingByUser = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetListWaitingByUser`,
      method: "post",
      data: data,
    });
    return result;
  };
  static create = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent",
      method: "post",
      data: data,
    });
    return result;
  };

  static createLiveSharing = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/LiveSharing",
      method: "post",
      data: data,
    });
    return result;
  };

  static createCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/Certificate",
      method: "post",
      data: data,
    });
    return result;
  };

  static updateCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/Certificate",
      method: "put",
      data: data,
    });
    return result;
  };

  static updateProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/ProjectExp",
      method: "put",
      data: data,
    });
    return result;
  };

  static createProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/ProjectExp",
      method: "post",
      data: data,
    });
    return result;
  };

  static createPreview = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/Preview",
      method: "post",
      data: data,
    });
    return result;
  };

  static UpdateContent = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UpdateContent",
      method: "post",
      data: data,
    });
    return result;
  };

  static UpdateLiveSharing = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UpdateLiveSharing",
      method: "post",
      data: data,
    });
    return result;
  };

  static upload = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/Upload",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };

  static uploadLiveSharing = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UploadLiveSharing",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
  

  static uploadCertificate = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UploadCertificate",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
  static uploadProjectExp = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UploadProjectExp",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
  static uploadHtmlFile = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "TranContent/UploadImageHTML",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
  static update = async (data, id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/${id}`,
      method: "put",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
  static delete = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/${id}`,
      method: "delete",
    });
    return result;
  };

  static Like = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/Like/${id}`,
      method: "get",
    });
    return result;
  };

  static LikeSharing = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/LikeSharing/${id}`,
      method: "get",
    });
    return result;
  };


  static Delete = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/${id}`,
      method: "delete",
    });
    return result;
  };

  static Bookmark = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/Bookmark/${id}`,
      method: "get",
    });
    return result;
  };

  static Spam = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/Spam`,
      method: "post",
      data: data,
    });
    return result;
  };

  static GetContentById = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetContentById/${id}`,
      method: "get",
    });
    return result;
  };

  static GetEditById = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetEditById/${id}`,
      method: "get",
    });
    return result;
  };

  static GetAutocomplete = async (search) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranContent/GetByAutocomplete/${search}`,
      method: "get",
    });
    return result;
  };
}

export default ApiContent;
