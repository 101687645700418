import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiRanking extends Component {
    static getById = async (RankId) => {
        await setHeaderAuth();
        const result = await axios({
            url: `MtRanking/GetById/${RankId}`,
            method: "post",
        });
        return result;
    };
    static getAll = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `MtRanking`,
            method: "get",
        });
        return result;
    };
}

export default ApiRanking;