import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ApiContent from "../api/ApiContent";
import ApiMtGdcemployee from "../api/ApiMtGdcemployee";
import CSwl from "../utils/alert";
import IconComponent from "./Icons";
import {
  faEllipsisH,
  faBookmark as faBookmarkSolid,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import "./PostV2.scss";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import CTable from "./CTable/CTable";
import PostDetailPreview from "../components/PostBackendPreview";
import { ConvertDateShort } from "../utils/date";
import { useTranslation } from "react-i18next";

const MainPost = ({
  image,
  name,
  title,
  detail,
  page,
  item,
  isLike,
  isBookmark,
  totalLike,
  time,
  mode = "normal",
  onchange = () => { },
}) => {
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const [imageFirst, setimageFirst] = useState("");
  const [detailRemoveImage, setdetailRemoveImage] = useState(detail);
  const history = useHistory();
  const [like, setLike] = useState(totalLike);
  const [pIsLike, setPisLike] = useState(isLike);
  const [pIsBookMark, setPisBookmark] = useState(isBookmark);
  const [tags, settags] = useState([]);
  const [spam, setspam] = useState(false);
  const [options, setOptions] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [isSmeModal, setisSmeModal] = useState(false);
  const [spamValue, setspamValue] = useState("spam");
  const [spamNote, setspamNote] = useState("");
  const [reason, setReason] = useState("");
  const [smeGroup, setSmeGroup] = useState([]);
  const [isCheckAllGdc, setIsCheckAllGdc] = useState(false);
  const [isRemarkModal, setIsRemarkModal] = useState(false);

  const [dataPreview, setdataPreview] = useState({});
  const [isPreview, setisPreview] = useState(false);

  useEffect(() => {
    if (detail.includes("<img")) {
      var str = detail;
      var elem = document.createElement("div");
      elem.style.display = "none";
      document.body.appendChild(elem);
      elem.innerHTML = str;
      setimageFirst(elem.querySelector("img").src);
      var content = detail.replace(/<img[^>]*>/g, "");
      setdetailRemoveImage(content);
    }
    if (item.cttagtJson != "[]") {
      let tags = JSON.parse(item.cttagtJson);
      settags(tags.map((x) => x.Name));
    }
    return () => { };
  }, []);

  const fields = [
    { key: "action", label: "", _style: { width: "1%" }, filter: false },
    { key: "empId", label: "Employee ID" },
    { key: "fullName", label: "User Name" },
    { key: "email", label: "Email" },
  ];

  const preview = async () => {
    setdataPreview(item);
    setisPreview(true);
  };

  const func_like = async (e) => {
    CSwl.SwalLoad();
    e.stopPropagation();
    const result = await ApiContent.Like(item.id);
    if (result.status == 200) {
      if (result.data.statusLike) {
        setLike(like + 1);
        setPisLike(true);
      } else {
        setLike(like - 1);
        setPisLike(false);
      }
    }
    CSwl.SwalClose();
  };

  const func_loadsmecontent = async () => {
    setisSmeModal(true);
    setspam(false);
    setIsCheckAllGdc(true);
    setSmeGroup([]);
    try {
      const result = await ApiMtGdcemployee.GetListByContentId(item.id);
      if (result.status == 200) {
        var items = result.data.data;
        for (var i = 0; i < items.length; i++) {
          items[i].checkSme = true;
        }
        setSmeGroup(items);
      }
    } catch { }
  };

  const func_bookmark = async (e) => {
    // CSwl.SwalLoad();
    e.stopPropagation();
    const result = await ApiContent.Bookmark(item.id);
    if (result.status == 200) {
      if (result.data.statusBookmark) {
        setPisBookmark(true);
      } else {
        setPisBookmark(false);
      }
    }
    // CSwl.SwalClose();
  };

  const func_edit = async (e) => {
    e.stopPropagation();
    history.push(`editcontent?c=${item.id}`);
  };

  const func_tag = async (e, item) => {
    e.stopPropagation();
    history.push(`/tag?q=${item}`);
  };

  const spamPost = async (e) => {
    e.stopPropagation();
    const result = await ApiContent.Spam({
      ctid: item.id,
      spamCode: spamValue,
      spamNote: spamNote,
    });
    if (result.status == 200) {
      setspamValue("spam");
      setspamNote("");
      setisModal(false);
      CSwl.SwalSuccess("รายงานโพสต์นี้เรียบร้อยแล้ว");
    }
  };

  const func_Approve = async () => {
    CSwl.SwalLoad();
    const result = await ApiContent.Approve({
      id: item.id,
    });
    if (result.status == 200) {
      setisModal(false);
      CSwl.SwalSuccess("อนุมัติเรียบร้อย").then(() => {
        window.location.reload();
      });
    }
  };

  const func_Reject = async () => {
    if (reason == "") {
      CSwl.SwalErr("กรุณากรอกเหตุผล");
      return;
    }

    CSwl.SwalLoad();
    const result = await ApiContent.Reject({
      id: item.id,
      remark: reason,
    });
    if (result.status == 200) {
      setisModal(false);
      CSwl.SwalSuccess("รายการถูก Reject แล้ว").then(() => {
        window.location.reload();
      });
    }
  };

  const func_SendToSme = async () => {
    var dataCk = smeGroup.filter((x) => x.checkSme);
    var empIdList = [];
    for (var i = 0; i < dataCk.length; i++) {
      empIdList.push(dataCk[i].empId);
    }

    if (empIdList.length == 0) {
      CSwl.SwalErr("Please Select User");
      return;
    }

    var statusAll = false;
    if (dataCk.length == smeGroup.length) {
      statusAll = true;
    }

    var model = {
      contentModel: {
        id: item.id,
      },
      isall: statusAll,
      empIDList: empIdList,
    };

    CSwl.SwalLoad();
    const result = await ApiContent.SendSME(model);
    if (result.status == 200) {
      setisSmeModal(false);
      CSwl.SwalSuccess("ส่งให้ SMEs เรียบร้อย").then(() => {
        window.location.reload();
      });
    }
  };

  const func_selectAllGdc = () => {
    for (var i = 0; i < smeGroup.length; i++) {
      var check = document.getElementById(`checkgdc-${i}`);
      if (check != null && check != undefined) {
        if (!isCheckAllGdc) {
          check.checked = true;
          smeGroup[i].checkSme = true;
        } else {
          check.checked = false;
          smeGroup[i].checkSme = false;
        }
      }
    }
    setIsCheckAllGdc(!isCheckAllGdc);
  };

  const _exportPdf = async (e) => {
    CSwl.SwalLoad();
    console.log(e);
    var element = e.target.nextElementSibling;

    element.style.display = "inherit";
    const opt = {
      margin: 20,
      filename: title,
      //image: { type: 'jpeg', quality: 1 },
      // html2canvas: { scale: 3 },
      html2canvas: { dpi: 192, letterRendering: true },
      //jsPDF: { orientation: 'portrait', unit: 'pt', format: 'a4' },
      image: { type: "jpeg", quality: 0.98 },
      // filename: 'report.pdf',
      // html2canvas: { scale: 3 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i);
          // set footer font
          pdf.setFont("helvetica");
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
          pdf.text(
            pdf.internal.pageSize.getWidth() - 30,
            pdf.internal.pageSize.getHeight() - 10,
            "YOUR TEXT GOES HERE!"
          );

          // you can add the line separator as an image, consult the docs below to properly set the place of the image
          // pdf.addImage(img, 'png', 0, 0, 52, 23)
        }
      })
      .save();
    setTimeout(() => {
      element.style.display = "none";
      CSwl.SwalClose();
    }, 1000);
  };

  const renderEditButton = () => {
    return (
      <div className="pl-2 is-flex is-align-items-center is-absolute is-right-0 is-top-10 p-5">
        <div style={{ position: "relative" }}>
          <div
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setOptions(!options);
            }}
          >
            <IconComponent
              className="icon-20x ml-3"
              color="#333"
              icon={faEllipsisH}
            />
          </div>
          {options && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="spam-container"
            >
              <ul>
                <li
                  className="cursor-pointer"
                  style={{ backgroundColor: "#73e600" }}
                  onClick={(e) => {
                    func_Approve();
                  }}
                >
                  Approve
                </li>
                <li
                  className="cursor-pointer"
                  style={{ backgroundColor: "#F24C20" }}
                  onClick={(e) => {
                    func_loadsmecontent();
                  }}
                >
                  Forward To SMEs
                </li>
                <li
                  style={{ backgroundColor: "#ff3333" }}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setisModal(true);
                    setReason("");
                  }}
                >
                  Reject
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="subpost" class="columns block is-relative">
        <div class={`column ${imageFirst ? " is-8" : ""} pb-0`}>
          <div
            id="post-share-pdf"
            style={{ cursor: "pointer" }}
            onClick={() => {
              preview();
            }}
          >
            <div className="d-flex is-align-items-center">
              <div className="d-flex is-align-items-center flex-1">
                <figure className="image is-32x32">
                  <img
                    style={{ height: '100%', width: '100%' }}
                    // alt={'image_profile'}
                    className="is-rounded image-cover"
                    src={image}
                  />
                </figure>
                <div className='d-flex flex-column ml-3'>
                  <span className="has-text-weight-bold is-size-7">{name}</span>
                  <span className="has-text-grey-light is-size-10">{time}</span>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-3 ml-1">

                <div className="d-flex is-align-items-center">
                  {item.isVerify == 1 &&
                    <div className="mr-2" style={{ color: "#66cc00" }}>
                      <IconComponent
                        className="icon-20x mr-2" color="#66cc00"
                        icon={faCheckCircle}
                      />
                      {t('text.passed_inspection')}
                    </div>
                  }

                </div>
                <h2 className="has-text-weight-bold is-size-4">{title}</h2>
                <h3
                  className="description-short"
                  dangerouslySetInnerHTML={{ __html: detailRemoveImage }}
                ></h3>
                {item.remark != null &&
                  item.remark != undefined &&
                  item.remark != "" && (
                    <span style={{ color: "red" }}>
                      เหตุผลการไม่อนุมัติ : {item.remark}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div
            className="mt-2 d-flex flex-wrap"
            style={{ marginLeft: "-1rem" }}
          >
            {tags &&
              tags.map((item, index) => {
                if (index > 4) {
                  return false;
                }
                return (
                  <div
                    onClick={(e) => {
                      func_tag(e, item);
                    }}
                    className="pl-4 is-flex is-align-items-center cursor-pointer"
                  >
                    <span className="is-size-7 tag">#{item}</span>
                  </div>
                );
              })}
          </div>

          <div className="mt-3 d-flex flex-wrap">
            <div className="pr-2">
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#66cc00" }}
                onClick={(e) => {
                  func_Approve();
                }}
              >
                <div>Approve</div>
              </button>
            </div>
            <div className="pr-2">
              <button
                className="button is-link is-rounded size-fontonPost"
                onClick={(e) => {
                  func_loadsmecontent();
                }}
              >
                <div className="font-button">Forward To SMEs</div>
              </button>
            </div>
            <div className="pr-2">
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#ff3333" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setisModal(true);
                  setReason("");
                }}
              >
                <div className="font-button">Reject</div>
              </button>
            </div>
            {/*<div>
              <button
                className="button is-link is-rounded size-fontonPost"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRemarkModal(true);
                }}
              >
                <div className="font-button">เหตุผล</div>
              </button>
              </div>*/}
          </div>
        </div>
        {imageFirst &&
          <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
            <img src={imageFirst} alt="image_content" class="size-img" width="160" height="160"></img>
          </div>
        }
      </div>
      <div class={`modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
          <div className="d-flex-center flex-column">
            <h1>Reject</h1>
            <div
              className="control flex-column d-flex-center mt-5"
              style={{ alignItems: "flex-start", minWidth: 300 }}
            >
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="เหตุผล"
                rows={4}
                className="p-2"
                style={{ width: "100%" }}
              ></textarea>
            </div>
            <div className="mt-5 d-flex">
              <button
                onClick={() => {
                  setisModal(false);
                }}
                className="button is-light is-link is-rounded mr-5"
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
              <button
                onClick={(e) => {
                  func_Reject();
                }}
                className="button is-link is-rounded"
              >
                <div className="font-button">ไม่อนุมัติ</div>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setisModal(false);
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>

      <div class={`modal ${isRemarkModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
          <div className="d-flex-center flex-column">
            <h1>เหตุผล</h1>
            <div
              className="control flex-column d-flex-center mt-5"
              style={{ alignItems: "flex-start", minWidth: 300 }}
            >
              <textarea
                value={item.remark}
                style={{ width: "100%" }}
              ></textarea>
            </div>
            <div className="mt-5 d-flex">
              <button
                onClick={() => {
                  setIsRemarkModal(false);
                }}
                className="button is-light is-link is-rounded mr-5"
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setIsRemarkModal(false);
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
      <div class={`modal ${isSmeModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5 }}>
          <div className="d-flex-center flex-column">
            <h1>Forward To SMEs</h1>
            <div
              className="control flex-column d-flex-center mt-5"
              style={{ alignItems: "flex-start", minWidth: 300 }}
            >
              <div style={{ marginBottom: "5px" }}>
                <button
                  onClick={() => {
                    func_selectAllGdc();
                  }}
                  className="button is-link is-rounded"
                >
                  <div className="font-button">
                    {isCheckAllGdc ? "Unselect All" : "Select All"}
                  </div>
                </button>
              </div>
              <CTable
                fields={fields}
                items={smeGroup}
                CustomField={{
                  action: (item, index) => (
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <input
                        id={`checkgdc-${index}`}
                        type="checkbox"
                        defaultChecked={true}
                        onChange={(e) => {
                          item.checkSme = e.target.checked;
                        }}
                        value={item.checkSme}
                      />
                    </div>
                  ),
                }}
              />
            </div>
            <div className="mt-5 d-flex">
              <button
                onClick={() => {
                  setisSmeModal(false);
                }}
                className="button is-light is-link is-rounded mr-5"
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
              <button
                onClick={() => {
                  func_SendToSme();
                }}
                className="button is-link is-rounded"
              >
                <div className="font-button">ส่ง</div>
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            setisSmeModal(false);
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>

      <div class={`modal ${isPreview ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5">
          {dataPreview.id && (
            // <PostV2Preview
            //   time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
            //   item={dataPreview}
            //   title={dataPreview.ctname}
            //   detail={dataPreview.ctdeschtml}
            //   name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
            //   image={dataPreview.imageProfile ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}
            // />
            <PostDetailPreview
              page={"detail"}
              contentid={dataPreview.id}
              image={
                dataPreview.imageProfile
                  ? process.env.REACT_APP_IMAGE + dataPreview.imageProfile
                  : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
              }
              name={`${dataPreview.employee?.firstNameEnglish} ${dataPreview.employee?.lastNameEnglish}`}
              time={ConvertDateShort(dataPreview.cttrandate, "DD MMM YYYY HH:mm น.")}
              title={dataPreview.ctname}
              detail={dataPreview.ctdeschtml}
              item={dataPreview}
              comments={[]}
            />
          )}
        </div>
        <button
          onClick={() => {
            setisPreview(false);
            setdataPreview({});
          }}
          class="modal-close is-large"
          aria-label="close"
        ></button>
      </div>
    </>
  );
};

export default MainPost;
