import React, { useState } from "react";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../../utils/alert";
import { destroyPage, useWindowUnloadEffect } from "../../../utils";
import ApiFollow from "../../../api/ApiFollow";
import ApiMtTag from "../../../api/ApiTag";
import CardTaglist from "../../../components/CardTaglist";
import ProfileList from "../../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import ApiContent from "../../../api/ApiContent";
import { ConvertDateShort } from "../../../utils/date";
import ModalImage from "react-modal-image";
import Drawer from "rc-drawer";
import MenuDrawer from "../../../components/MenuDrawer";
const list_certType = [{ id: 1, label: "Language" }, { id: 2, label: "Safety" }, { id: 3, label: "Software / IT / Digital" }
  , { id: 4, label: "ISO / Environment" }
  , { id: 5, label: "Technical / Operation / Engineering" }
  , { id: 6, label: "Soft side / Leadership" }
  , { id: 7, label: "Accounting / Finance / Audit" }
  , { id: 8, label: "Other" }]

const CertificateDetail = () => {
  const history = useHistory();
  const [firstTime, setFirstTime] = useState(new Date());
  //useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  const [trend, setTrend] = useState([]);
  const userState = useSelector((state) => state.user);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [file, setFile] = useState([]);
  const [fileImage, setfileImage] = useState('');
  const listTypeImage = [".jpeg", ".jpg", ".png"]
  const { id } = useParams();
  const [state, setstate] = useState({
    cert: {},
    compentency: [],
    user: {}
  });
  const [sasToken, setsasToken] = useState('');
  React.useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getProfileUnFollow();
    const promise2 = getToptag();
    const promise3 = getBlobContainerSAS();
    Promise.all([promise1, promise2, promise3]).then(async (result) => {
      CSwl.SwalClose();
      await getData();
    })
    return () => { };
  }, []);


  const getBlobContainerSAS = async () => {
    try {
      const response = await ApiContent.GetBlobContainerSAS();
      if (response.status == 200) {
        const { sasToken } = response.data;
        setsasToken(sasToken)
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };


  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageSize: 3,
      pageNumber: 1,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };


  const getData = async () => {
    try {
      const res = await ApiContent.GetCertificateById(id);
      if (res.status === 200) {
        setstate({
          cert: res.data.data,
          compentency: res.data.compentency,
          user: res.data.user
        })
        const files = res.data.data.files ? JSON.parse(res.data.data.files) : "";
        if (files) {
          const fileImage = files.find((x) => {
            return listTypeImage.some(v => x.Path.includes(v))
          })
          setFile(files);
          setfileImage(fileImage);
        }
        // setDataDetail(res.data.data)
        // var div = document.createElement("div");
        // div.innerHTML = res.data.data.ctdeschtml;
        // var text = div.textContent || div.innerText || "";
        // setdescription(text);
        // if (res.data.data.ctdeschtml.includes('<img')) {
        //   var str = res.data.data.ctdeschtml;
        //   var elem = document.createElement('div');
        //   elem.style.display = 'none';
        //   document.body.appendChild(elem);
        //   elem.innerHTML = str;
        //   setimageBanner(elem.querySelector('img').src);
        // }
        // console.log(res.data.data.result)
      }
    } catch (error) {
      try {
        const { data } = error.response;
        CSwl.SwalErr(data.message).then(x => history.go(-1));
      } catch (ex) {
        CSwl.SwalErr("ไม่สามารถสร้างบทความได้");
      }
    }
  }

  return (
 
    <div id="CertificateDetailPage">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <div className="columns">
        <div className="hidden sm:block column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('text.certificate')} />
        </div>
        <div className="column is-7 pt-0">
          <div className="px-5 sm:p-5 sm:pt-6">
            <div className="is-flex is-align-items-center justify-between">
              <p className="title-h1">{t('menu.certandaward')}</p>
              {
                state.user?.userId == state.cert?.createby && <div className="flex items-center justify-center gap-2">
                  <Link to={`/certificateEdit/0`}>
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <figure className="image-cover cursor-pointer h-6 w-6">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/addx2.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                  <Link className="" to={`/certificateEdit/${state.cert?.id}`}>
                    <div className="is-flex is-align-items-center cursor-pointer">
                      <figure className="image-cover cursor-pointer h-8 w-8 mt-3">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                          }
                          alt="logo1"
                        />
                      </figure>
                    </div>
                  </Link>
                </div>
              }

            </div>
          </div>

          {
            fileImage && <div className="px-5">
              <ModalImage
                className="sm:h-60 w-full sm:w-auto"
                small={fileImage.Path + sasToken}
                large={fileImage.Path + sasToken}
                alt="img-cer"
              />
            </div>
          }

          <div className="has-background-light px-2 sm:px-5 py-5 rounded-lg mt-3">
            <div className="px-5 sm: pb-5">
              <div className="is-flex is-align-items-center">
                <p className="title-h1">{t('text.details')}</p>
              </div>
            </div>
            {
              state.cert &&
              // <div className="px-5">
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.employeename')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{`${state.user?.firstNameThai} ${state.user.lastNameThai}`}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.nameofcertificate')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{state.cert.name}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.certificatecode')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{state.cert.code}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.country')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{state.cert.country}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.institutionDetail')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{state.cert.instituteName}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.dateofissue')} :
              //     </div>
              //     <div className="column is-3">
              //       <p>{state.cert.issuedDate ? `${ConvertDateShort(state.cert.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"}</p>
              //     </div>

              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.expirationdate')} :
              //     </div>
              //     <div className="column is-3">
              //       <p>{state.cert.expiredDate ? `${ConvertDateShort(state.cert.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.certtype')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>ภาษา</p>
              //     </div>
              //   </div>
              //   <div className="columns">
              //     <div className="column is-3 mb-0 subtitle">
              //       {t('text.capabilities')} :
              //     </div>
              //     <div className="column is-9">
              //       <p>{state.compentency.map(x => x.competencyNameEn).join(', ')}</p>
              //     </div>
              //   </div>
              // </div>
              <div className="flex px-5 gap-x-5 ">
                <div className="flex flex-col gap-y-4">
                  <div className="font-bold">
                    {t('text.employeename')} :
                  </div>
                  <div className="font-bold">
                    {t('text.nameofcertificate')} :
                  </div>
                  <div className="font-bold">
                    {t('text.certificatecode')} :
                  </div>
                  <div className="font-bold">
                    {t('text.country')} :
                  </div>
                  <div className="font-bold">
                    {t('text.institutionDetail')} :
                  </div>
                  <div className="font-bold">
                    {t('text.dateofissue')} :
                  </div>
                  <div className="font-bold">
                    {t('text.expirationdate')} :
                  </div>
                  <div className="font-bold">
                    {t('text.certtype')} :
                  </div>
                  <div className="font-bold">
                    {t('text.capabilities')} :
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-y-4">
                  <p>{`${state.user?.firstNameThai} ${state.user.lastNameThai}`}</p>
                  <p>{state.cert.name}</p>
                  <p>{state.cert.code}</p>
                  <p>{state.cert.country}</p>
                  <p>{state.cert.instituteName}</p>
                  <p>{state.cert.issuedDate ? `${ConvertDateShort(state.cert.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"}</p>
                  <p>{state.cert.expiredDate ? `${ConvertDateShort(state.cert.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                  {/* <p>ภาษา</p> */}
                  {/* <p>{state.cert.type}</p> */}
                  <p>{list_certType.find(x => x.id == state.cert.type)?.label ?? ""}</p>
                  <p>{state.compentency.map(x => x.competencyNameEn).join(', ')}</p>
                </div>
              </div>
            }
          </div>
          {
            (file && file.filter((x) => {
              return !listTypeImage.some(v => x.Path.includes(v))
            })) && <div className="px-5 !pt-3 sm:!pt-6">
              <div className="is-flex is-align-items-center">
                <p className="title-h1">{t('menu.certfiletitle')}</p>
              </div>
            </div>
          }
          {
            file && file.filter((x) => {
              return !listTypeImage.some(v => x.Path.includes(v))
            }).map((item, index) => {
              return <div className="mt-3">
                <div onClick={() =>
                  window.open(`${item.Path}${sasToken}`, '_blank')
                } className="filename-size has-background-light px-5 py-3 cursor-pointer flex gap-x-4 items-center">
                  <img
                    className="h-8 w-6"
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/file.png"
                    }
                    alt="cer"
                  />
                  <p className="filename !text-lg">{item.Name}</p>
                </div>
              </div>
            })
          }
        </div>
        {/* section Cardlist */}
        <div className="column is-3 hidden-b">
          <div
            className="is-3 flex-1"
            style={{ position: "fixed", width: "inherit" }}
          >
            <div className="mt-3 mr-6">
              <CardTaglist
                title={t('text.trending')}
                data={trend}
                topage={"trend"}
              />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID,
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificateDetail