export const setLocalStorage = (key, data) => {
    localStorage.setItem(key, data);
};

export const getLocalStorage = async (key) => {
    const result = await localStorage.getItem(key);
    return result;
};

export const removeLocalStorage = async (key) => {
    await localStorage.removeItem(key);
};
