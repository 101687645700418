import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiMtTag extends Component {
  static GetTopTag = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtTag/GetTopTag`,
      method: "post",
      data: data
    });
    return result;
  };

  static MenuGetList = async (limit) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtTag/MenuGetList?limit=${limit}`,
      method: "get",
    });
    return result;
  };

  static MenuGetListTopTag = async (limit) => {
    await setHeaderAuth();
    const result = await axios({
      url: `MtTag/MenuGetListTopTag?limit=${limit}`,
      method: "get",
    });
    return result;
  };
}
export default ApiMtTag;
