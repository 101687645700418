import React from "react";
import { Link } from "react-router-dom";
import ListTrend from "./ListTrend";
import "../style/Cardlist/Cardlist.scss";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const Cardlist = ({ title, data, topage = "" }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const datas = data;
  return (
    <div
      id="Cardlist-com"
      className="card p-4 "
      style={{
        backgroundColor: "#f6f8fa",
        borderRadius: "1rem",
        boxShadow: "0 0em 0em",
      }}
    >
      <div className="is-flex is-justify-content-space-between">
        <h1 className="header">{title}</h1>
        {title === t('text.trending') && (
          <Link to="/home" className="has-text">
            <img
              src={process.env.PUBLIC_URL + "/images/firstPage/setting.png"}
              alt="logo1"
              width="24"
              height="24"
            />
          </Link>
        )}
      </div>

      {datas.slice(0, 3).map((d, index) => {
        return (
          <div className="mt-3" key={index}>
            {title === t('text.trending') ? (
              <ListTrend
                idx={index + 1}
                name={"#" + d.tagName}
                like={d.count + " จำนวน Trading"}
              />
            ) : (
              <ListTrend image={d.image} name={d.name} />
            )}
          </div>
        );
      })}
      <p
        className="is-flex is-justify-content-center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(topage);
        }}
      >
        {t('text.see_more')}
      </p>
    </div>
  );
};

export default Cardlist;
