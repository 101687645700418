import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiChat extends Component {

  static getRoom = async (userId,sendToUserId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/ChatRoom?userId=${userId}&sendToUserId=${sendToUserId}`,
      method: "get",
    });
    return result;
  };

  static getMessage = async (roomId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/GetMessage?roomId=${roomId}`,
      method: "get",
    });
    return result;
  };

  static getRoomDetail = async (roomId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/GetRoomDetail?roomId=${roomId}`,
      method: "get",
    });
    return result;
  };

  static deleteRoom = async (roomId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/DeleteRoom?roomId=${roomId}`,
      method: "delete",
    });
    return result;
  };

  static deleteGroup = async (roomId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/DeleteGroup?roomId=${roomId}`,
      method: "delete",
    });
    return result;
  };

  static leaveGroup = async (roomId,userId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/LeaveGroup?roomId=${roomId}&userId=${userId}`,
      method: "delete",
    });
    return result;
  };

  static leaveChat = async (roomId,userId) => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/LeaveChat?roomId=${roomId}&userId=${userId}`,
      method: "delete",
    });
    return result;
  };


  static getRoomList = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/ChatRoomList`,
      method: "get",
    });
    return result;
  };

  static getRoomGroup = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `Chat/ChatRoomGroup`,
      method: "get",
    });
    return result;
  };

  static sendChat = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Chat/SendChat",
      method: "post",
      data: data,
    });
    return result;
  };

  static createGroup = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Chat/CreateGroup",
      method: "post",
      data: data,
    });
    return result;
  };

  static inviteGroup = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Chat/inviteGroup",
      method: "post",
      data: data,
    });
    return result;
  };

  static upload = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: "Chat/Upload",
      method: "post",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result;
  };
}

export default ApiChat;
