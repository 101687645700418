import React from "react";
import { Link } from "react-router-dom";
import Cardlist from "../../../components/Cardlist";
import CardTaglist from "../../../components/CardTaglist";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import "./index.scss";
import CSwl from "../../../utils/alert";
import ApiContent from "../../../api/ApiContent";
import { useEffect, useState } from "react";
import Waitting from "../../../components/Waitting";
import { ConvertDateShort } from "../../../utils/date";

import ApiFollow from "../../../api/ApiFollow";
import ApiMtTag from "../../../api/ApiTag";
import { WEB_IMAGE } from "../../../env";
import ProfileList from "../../../components/ProfileList";
import InfiniteScroll from "react-infinite-scroll-component";
import MainPost from "../../../components/PostV2Waiting";
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
const ContentPlan = () => {
  const { t, i18n } = useTranslation();
  const initialState = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [trend, setTrend] = useState([]);
  const [loadingMore, setloadingMore] = useState(false);
  const [profileUnFollow, setProfileUnFollow] = useState([]);

  const [state, setstate] = useState(initialState);
  const friend = [
    {
      name: "Savannah Nguyen",
    },
    {
      name: "Jacob Jones",
    },
    {
      name: "Janis Joplin",
    },
  ];
  const dataplan = [
    {
      name: "Brooklyn Simmons",
      depart: "Infarstructure",
      topic: "บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก",
      detail: "For people stressed or intimidated by fitness culture",
      date: "Jan 24 ● 12:42",
    },
    {
      name: "Brooklyn Simmons",
      depart: "Infarstructure",
      topic: "บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก",
      detail: "For people stressed or intimidated by fitness culture",
      date: "Jan 24 ● 12:42",
    },
    {
      name: "Brooklyn Simmons",
      depart: "Infarstructure",
      topic: "บัฟเฟตต์ vs ฮั่วเถิง เจ้าของบริษัทที่มี พอร์ตลงทุน ใหญ่สุดในโลก",
      detail: "For people stressed or intimidated by fitness culture",
      date: "Jan 24 ● 12:42",
    },
  ];

  useEffect(() => {
    getContent();
    getToptag();
    getProfileUnFollow();
  }, []);
  const [myContent, setmyContent] = useState("");

  const pageState = {
    pageNumber: 1,
    pageSize: 10,
  };

  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
        console.log(result.data.data);
      }
    }
  };

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const getContent = async () => {
    CSwl.SwalLoad();
    try {
      const response = await ApiContent.getListWaitingByUser(state);
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        console.log(data.items);
        setmyContent(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getContentLoadmore = async () => {
    const result = await ApiContent.getListWaitingByUser(state);
    if (result.status == 200) {
      setmyContent({
        ...myContent,
        items: [...myContent.items, ...result.data.data.items],
      });
      console.log("loadmore::", result.data.data.items);
    }
  };

  function handleLoadMore() {
    if (loadingMore == false) {
      setloadingMore(true);
      setstate({ ...state, pageNumber: state.pageNumber + 1 });
    }
  }

  useEffect(() => {
    if (state.pageNumber != 1) {
      const promise1 = getContentLoadmore();
      Promise.all([promise1]).then((results) => {
        setloadingMore(false);
      })
    }
    return () => { };
  }, [state]);

  return (
    <div id="ContentPlan">
      <Header />
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.draft')} />
      </Drawer>
      <div className="columns" style={{ height: "max-content" }}>
        <div className="column is-hidden-mobile  is-2 layer">
          <Menu data={[]} page="rank" header={t('menu.draft')} />
        </div>
        <div className="column is-7 border-div1">
          <div className="is-flex is-align-items-center m-4">
            <figure className="image mr-3">
              <img
                src={process.env.PUBLIC_URL + "/images/content/IconTime.png"}
                alt="Icon"
              />
            </figure>
            <p className="title-h1">{t('menu.draft')}</p>
          </div>
          <hr className="hr-size1" />
          <div className="layout mt-0">
            {myContent.length > 0 &&
              myContent.map((d, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    <Waitting
                      contentid={d.id}
                      index={index}
                      profileid={12}
                      name={d.ctname}
                      depart={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                    />
                  </div>
                );
              })}

            {myContent && (
              <InfiniteScroll
                dataLength={myContent.items.length}
                next={() => handleLoadMore()}
                hasMore={myContent.totalPages > state.pageNumber}
                loader={<h4>Loading...</h4>}
              >
                {myContent &&
                  myContent.items.map((d, index) => {
                    return (
                      <div className="row fn ar" key={d.id}>
                        <MainPost
                          time={ConvertDateShort(
                            d.cttrandate,
                            "DD MMM YYYY HH:mm น."
                          )}
                          totalLike={d.ctlikeAmt}
                          isLike={d.isLike}
                          isBookmark={d.isBookmark}
                          item={d}
                          title={d.ctname}
                          comments={d.comments}
                          detail={d.ctdeschtml}
                          name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                          image={
                            d.imageProfile
                              ? process.env.REACT_APP_IMAGE + d.imageProfile
                              : process.env.PUBLIC_URL +
                              "/images/profilePage/Profile.png"
                          }
                        />
                      </div>
                    );
                  })}
              </InfiniteScroll>
            )}
          </div>
          <hr className="hr-size1" />
        </div>
        {/* section Cardlist */}
        <div
          className="column is-3 hidden-b"
        >
          <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
            <div className="mt-3 mr-6">
              <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
            </div>
            <div className="mt-3 mr-6">
              <ProfileList
                title={t('text.following')}
                data={profileUnFollow.map((x) => {
                  return {
                    name: x.fullNameEng,
                    image: x.image
                      ? process.env.REACT_APP_IMAGE + x.image
                      : process.env.PUBLIC_URL +
                      "/images/profilePage/Profile.png",
                    profileID: x.profileID
                  };
                })}
                topage={"unfollow"}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContentPlan;
