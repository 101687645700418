import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

class ApiTranProfile extends Component {
  static UpdateTag = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranProfile/UpdateTag`,
      method: "post",
      data: data,
    });
    return result;
  };
  static UpdatePDPA = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranProfile/UpdatePDPA`,
      method: "post",
      data: data,
    });
    return result;
  };
  static GetProfileBySearch = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranProfile/GetBySearch`,
      method: "post",
      data: data
    });
    return result;
  };

  static GetProfileByCategory = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranProfile/GetByCatagory`,
      method: "post",
      data: data
    });
    return result;
  };

  static GetAutocomplete = async (search) => {
    await setHeaderAuth();
    const result = await axios({
      url: `TranProfile/GetByAutocomplete/${search}`,
      method: "get",
    });
    return result;
  };
}
export default ApiTranProfile;
