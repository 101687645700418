import React, { useState } from 'react'
import Cardlist from '../../components/Cardlist'
import CardTaglist from "../../components/CardTaglist";
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import './index.scss'
import bulmaAccordion from 'bulma-extensions/bulma-accordion/dist/js/bulma-accordion.min'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { removeLocalStorage } from '../../utils/localStorage'
import { useHistory } from 'react-router-dom';
import { CheckFile, checkUserBool, handleLogout } from '../../utils'
import ApiMtTag from '../../api/ApiTag'
import ApiFollow from '../../api/ApiFollow'
import CSwl from '../../utils/alert'
import ProfileList from '../../components/ProfileList';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,



} from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../authConfig';
import Drawer from 'rc-drawer';
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import { useTranslation } from "react-i18next";
import ApiAuth from '../../api/ApiAuth';
const myMsal = new PublicClientApplication(msalConfig);


const initialState = {
  pageNumber: 1,
  pageSize: 20,
};

const SettingPage = () => {
  const { t, i18n } = useTranslation();
  const languageState = useSelector((state) => state.language);
  const { instance, accounts, inProgress } = useMsal();
  const [state, setstate] = useState(initialState);
  const [trend, setTrend] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow(state);
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (
        CheckFile({
          file,
          size: 2,
          type: ["image/png", "image/jpg", "image/jpeg"],
        })
      ) {
        uploadSignature(file, file.name);
      }
    } catch (error) { }
  };

  const getProfile = async () => {
    CSwl.SwalLoad();
    try {
      const response = await ApiAuth.getUser();
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        dispatch({ type: "set_user", user: data });
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const uploadSignature = async (uploadFileObj, filename) => {
    CSwl.SwalLoad();
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiAuth.uploadSignature(formData);
    if (result.status == 200) {
      const { data } = result.data;
      getProfile();
    }
    CSwl.SwalClose();
  };

  const friend = [{
    name: 'Savannah Nguyen'
  },
  {
    name: 'Jacob Jones'
  },
  {
    name: 'Janis Joplin'
  }
  ]

  React.useEffect(() => {
    CSwl.SwalLoad();
    var accordions = bulmaAccordion.attach('.accordions');
    const promise3 = getProfileUnFollow();
    const promise4 = getToptag();
    Promise.all([promise3, promise4]).then(results => {
      CSwl.SwalClose();
    })
  }, [])

  const logout = async () => {
    const logoutRequest = {
      account: myMsal.getAccountByHomeId(accounts[0].homeAccountId),
      mainWindowRedirectUri: process.env.REACT_APP_URL
    }
    const result = await myMsal.logoutPopup(logoutRequest);
    await removeLocalStorage('token');
    sessionStorage.clear()
    dispatch({
      type: 'clear_all',
    });
    setTimeout(() => {
      history.push("/")
    }, 200);

  }



  const getToptag = async () => {
    const result = await ApiMtTag.GetTopTag({
      pageNumber: 1,
      pageSize: 3,
    });
    if (result.status == 200) {
      if (result.data.data.length > 0) {
        setTrend(result.data.data);
      }
    }
  };

  const changeLanguage = async () => {
    dispatch({ type: "set", language: languageState == "th" ? "en" : "th" });
    i18n.changeLanguage(languageState == "th" ? "en" : "th");
  }


  return (
    <>
      {
        checkUserBool(userState) && <div id="SettingPage">
          <Header />
          <Drawer className="is-hidden-desktop is-hidden-tablet">
            <MenuDrawer data={[]} page="rank" header={t('menu.setting')} />
          </Drawer>
          <div className="columns" style={{ height: 'max-content' }}>
            <div className="column is-2 my-3 layer hidden-b is-hidden-mobile">
              <Menu page="rank" header={t('menu.setting')} />
            </div>
            <div className="column is-7 border-div1">
              <div className="">
                <section className="accordions">
                  <article className="accordion ">
                    <div className="accordion-header toggle has-background-white">
                      <div className="is-flex is-align-items-center">
                        <figure className="image">
                          <img src={process.env.PUBLIC_URL + "/images/settingPage/Logo1.png"} alt="logo1" />
                        </figure>
                        <p className="title-h ml-3">{t('menu.setting')}</p>
                      </div>
                    </div>
                    <div className="accordion-body hr-size">
                    </div>
                  </article>
                  <article className="accordion mt-5">
                    <div className="accordion-header toggle has-background-white">
                      <div className="is-flex is-align-items-center">
                        <figure className="image">
                          <img src={process.env.PUBLIC_URL + "/images/settingPage/Logo2.png"} alt="logo1" />
                        </figure>
                        <p className="title-h ml-3">{t('text.edit_profile')}</p>
                      </div>
                      <figure className="image">
                        <img src={process.env.PUBLIC_URL + "/images/settingPage/vector1.png"} alt="logo1" />
                      </figure>
                    </div>
                    <div className="accordion-body hr-size">
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.display_name')}</p>
                        <span className="subtitle-p1 pr-5">{userState.employee.firstNameThai} {userState.employee.lastNameThai}</span>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.department')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.departmentThai}</p>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.position')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.positionNameThai}</p>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.company')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.companyThai}</p>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.site')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.locationDescription}</p>
                      </div>
                      <hr className="hr-size1" />
                      {/* <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.tel')}</p>
                        <div className="is-flex is-align-items-center">
                          <p className="subtitle-p1 pr-5">{userState.employee.telephoneMobile}</p>
                          <Link to='/settingEdit/tel'>
                            <p className="subtitle-p3 ">แก้ไข</p>
                          </Link>
                        </div>
                      </div> */}
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.tel')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.telephoneMobile}</p>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.email')}</p>
                        <p className="subtitle-p1 pr-5">{userState.employee.email}</p>
                      </div>
                      <hr className="hr-size1" />
                      <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.signature')}</p>
                        <p className="subtitle-p1 pr-5">
                          <figure className="image" onClick={() => handleClick()}>
                            <img
                              className="image-cover cursor-pointer"
                              style={{ maxHeight: 100 }}
                              src={
                                userState.profile.signaturePhysicalPath
                                  ? process.env.REACT_APP_IMAGE +
                                  userState.profile.signaturePhysicalPath
                                  : process.env.PUBLIC_URL + "/images/profilePage/BG.png"
                              }
                              alt="backgroundImage"
                            />
                          </figure>
                        </p>
                      </div>

                      {/* <div className="accordion-content has-background-white is-flex is-justify-content-space-between">
                        <p className="subtitle-p pl-6">{t('text.email')}</p>
                        <div className="is-flex is-align-items-center">
                          <p className="subtitle-p1 pr-5">{userState.employee.email}</p>
                          <Link to='/settingEdit/mail'>
                            <p className="subtitle-p3 ">แก้ไข</p>
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </article>
                  {/* <article className="accordion hr-size">
                    <div className="accordion-header toggle has-background-white ">
                      <div className="is-flex is-align-items-center ml-3">
                        <figure className="image">
                          <img src={process.env.PUBLIC_URL + "/images/settingPage/Logo3.png"} alt="logo3" />
                        </figure>
                        <p className="title-h ml-3">บัญชี</p>
                      </div>
                      <figure className="image mr-3">
                        <img src={process.env.PUBLIC_URL + "/images/settingPage/vector2.png"} alt="vertor2" />
                      </figure>
                    </div>
                  </article> */}
                  <article className="accordion hr-size">
                    <div className="accordion-header toggle has-background-white ">
                      <div className="is-flex is-align-items-center ml-3">
                        <figure className="image">
                          <img src={process.env.PUBLIC_URL + "/images/settingPage/Logo4.png"} alt="logo4" />
                        </figure>
                        <p className="title-h ml-3">{t('text.language')}</p>
                      </div>
                      <div onClick={() => changeLanguage()} className="is-flex is-align-items-center">
                        <p className="subtitle-p2 mr-3">{languageState == "th" ? "ภาษาไทย" : "English"}</p>
                        <figure className="image mr-3">
                          <img src={process.env.PUBLIC_URL + "/images/settingPage/vector2.png"} alt="vertor2" />
                        </figure>
                      </div>
                    </div>
                  </article>
                  <article className="accordion hr-size">
                    <div className="accordion-header toggle has-background-white ">
                      <div onClick={logout} className="is-flex is-align-items-center ml-3">
                        <p className="title-h ml-3">{t('text.logout')}</p>
                      </div>
                    </div>
                  </article>
                </section>
              </div>
            </div>
            {/* section Cardlist */}
            <div
              className="column is-3 hidden-b is-hidden-mobile"
            >
              <div className="is-3 flex-1" style={{ position: "fixed", width: 'inherit' }}>
                <div className="mt-3 mr-6">
                  <CardTaglist title={t('text.trending')} data={trend} topage={"trend"} />
                </div>
                <div className="mt-3 mr-6">
                  <ProfileList
                    title={t('text.following')}
                    data={profileUnFollow.map((x) => {
                      return {
                        name: x.fullNameEng,
                        image: x.image
                          ? process.env.REACT_APP_IMAGE + x.image
                          : process.env.PUBLIC_URL +
                          "/images/profilePage/Profile.png",
                        profileID: x.profileID
                      };
                    })}
                    topage={"unfollow"}
                  />
                </div>
              </div>

            </div>
          </div>
          <input
            accept=".png,.jpg,.jpeg"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            type="file"
            style={{ display: "none" }}
          />
        </div>
      }
    </>
  )
}

export default SettingPage
