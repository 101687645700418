import React from "react";
import { Link } from "react-router-dom";
import ListTrend from "./ListTrend";
import "../style/Cardlist/Cardlist.scss";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const ProfileList = ({ title, data, topage = "" }) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const datas = data;
    return (
        <div
            id="Cardlist-com"
            className="card p-4 "
            style={{
                backgroundColor: "#f6f8fa",
                borderRadius: "1rem",
                boxShadow: "0 0em 0em",
            }}
        >
            <div className="is-flex is-justify-content-space-between">
                <h1 className="header">{title}</h1>
            </div>
            {datas.slice(0, 3).map((d, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item is-align-item-center">
                                <li className="is-flex">
                                    <Link to={`profileDetail?id=${d.profileID}`} className="is-flex cursor-pointer">
                                        <figure className="image is-48x48">
                                            <img 
                                                className={`is-rounded image-cover ${d.smeCategory ? `border-[#f97316] border-4` : ""}`}
                                                style={{ width: '100%', height: '100%' }}
                                                src={d.image} />
                                        </figure>
                                        <p className="is-flex is-align-items-center has-text-black has-text-weight-bold pl-2">
                                            {d.name}
                                        </p>
                                    </Link>
                                </li>
                                <hr className="hr-s" />
                            </li>
                        </ul>
                    </div>
                );
            })}
            <p
                className="is-flex is-justify-content-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    history.push(topage);
                }}
            >
                {t('text.see_more')}
            </p>
        </div>
    );
};


export default ProfileList;
