import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import "../profilePage/index.scss";
import Cardlist from "../../components/Cardlist";
import Post from "../../components/Post";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiAuth from "../../api/ApiAuth";
import CSwl from "../../utils/alert";
import ApiContent from "../../api/ApiContent";
import { ConvertDateShort } from "../../utils/date";
import Swal from "sweetalert2";
import { CheckFile, destroyPage, useWindowUnloadEffect } from "../../utils";
import { WEB_IMAGE } from "../../env";
import ApiFollow from "../../api/ApiFollow";
import ApiTranProfile from "../../api/ApiTranProfile";
import ApiMsGraph from "../../api/ApiMsGraph";
import ApiMtTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import { TagsInput as ReactTags } from "react-tag-input-component";
import MainPost from "../../components/PostV2";
import { loginRequest } from "../../authConfig";
import DatePicker from "react-datepicker";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import MenuDrawer from "../../components/MenuDrawer";
import moment from "moment";
import IconComponent from "../../components/Icons";
import { faPhone, faCalendar, faBookmark as faBookmarkSolid, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isDesktop } from 'react-device-detect'

const ProfilePage = (props) => {
  const [firstTime, setFirstTime] = useState(new Date());
  useWindowUnloadEffect(() => destroyPage({ page: "Profile Detail", firstTime: firstTime }), true);
  const { t, i18n } = useTranslation();
  var profileId = new URLSearchParams(props.location.search).get("id");

  const pageState = {
    profileId: profileId,
    pageNumber: 1,
    pageSize: 10,
  };


  const [teamName, setTeamName] = useState("");
  const [description, setDescription] = useState("");
  const [channelName, setChannelName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [smeFunctionJson, setSmeFunctionJson] = useState("");
  const [body, setBody] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const { instance, accounts, inProgress } = useMsal();
  const [trend, setTrend] = useState([]);
  const [tags, setTags] = useState([]);
  const [speciallistTags, setSpeciallistTags] = useState([]);
  const [smeCatagory, setSmeCatagory] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  // const userState = useSelector((state) => state.user);
  const [myContent, setmyContent] = useState("");
  const [profileUnFollow, setProfileUnFollow] = useState([]);
  const [isAddtag, setIsAddtag] = useState(false);
  const [userState, setuserState] = useState({
    profile: {},
    employee: {},
  });
  const [showCer, setShowCer] = useState(false)
  const [showProject, setShowProject] = useState(false)

  const listTypeImage = [".jpeg", ".jpg", ".png"]
  const [certificate, setcertificate] = useState([]);
  const [projectExp, setprojectExp] = useState([]);


  React.useEffect(() => {
    CSwl.SwalLoad();
    const promise1 = getProfile();
    const promise2 = getContent();
    Promise.all([promise1, promise2]).then(async (result) => {
      await getCertificate();
      await getProjectExp();
      CSwl.SwalClose();
    })

    return () => { };
  }, []);

  const getProfile = async () => {
    try {
      const response = await ApiAuth.getUserByProfile(profileId);
      if (response.status == 200) {
        const { data } = response.data;
        setuserState(data);
        setFollow(data.isFollowed);
        if (data.profile.tagJson != null) {
          var tagP = JSON.parse(data.profile.tagJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push(tagP[i].Name);
          }
          setTags(tmpTag);
        }
        if (data.profile.specialistJson != null) {
          var tagP = JSON.parse(data.profile.specialistJson);
          var tmpTag = [];
          for (var i = 0; i < tagP.length; i++) {
            tmpTag.push(tagP[i].name);
          }
          setSpeciallistTags(tmpTag);
        }
        if (data.employee.smeCategory != null) {
          var smeCategoryList = data.employee.smeCategory.split(',');
          setSmeCatagory(smeCategoryList);
        }

      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getContent = async () => {
    try {
      const response = await ApiContent.getByUserProfile(pageState);
      if (response.status == 200) {
        CSwl.SwalClose();
        const { data } = response.data;
        console.log(data);
        setmyContent(data);
      }
    } catch (error) {
      const { data } = error.response;
    }
  };

  const getCertificate = async () => {
    try {
      const response = await ApiContent.GetCertificateByUser(profileId);
      if (response.status == 200) {
        const { data } = response.data;
        console.log(data);
        setcertificate(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const getProjectExp = async () => {
    try {
      const response = await ApiContent.GetProjectExpByUser(profileId);
      if (response.status == 200) {
        const { data } = response.data;
        console.log('projectExp::', data)
        setprojectExp(data);
      }
    } catch (error) {
      const { data } = error.response;
      CSwl.SwalErr(data.message);
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    // hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (
        CheckFile({
          file,
          size: 2,
          type: ["image/png", "image/jpg", "image/jpeg"],
        })
      ) {
        uploadFiles(file, file.name);
      }
    } catch (error) { }
  };

  const uploadFiles = async (uploadFileObj, filename) => {
    CSwl.SwalLoad();
    const formData = new FormData();
    formData.append("file", uploadFileObj);
    const result = await ApiAuth.upload(formData);
    if (result.status == 200) {
      const { data } = result.data;
      getProfile();
    }
    Swal.close();
  };

  const callBackComment = (result) => {
    try {
      const comment = result.data;
      // const { data } = result;
      let newContents = { ...myContent };
      let newContentsItem = newContents.items;

      const findIndex = newContentsItem.findIndex((x) => x.id == comment.ctid);
      newContentsItem[findIndex].comments.unshift(comment);
      newContents.items = newContentsItem;
      setmyContent(newContents);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileUnFollow = async () => {
    const result = await ApiFollow.GetTranProfileForUnFollow({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setProfileUnFollow(result.data.data);
    }
  };

  const func_setAddingTag = () => {
    if (isAddtag) {
      var tagModel = [];
      for (var i = 0; i < tags.length; i++) {
        var tagsub = {
          name: tags[i],
        };
        tagModel.push(tagsub);
      }
      func_saveTag(tagModel);
    } else {
      setIsAddtag(true);
    }
  };

  const handleTagAddition = (tag) => {
    setTags(tag);
  };

  const func_saveTag = async (tagmodel) => {
    var model = {
      id: userState.profile.id,
      tagjontran: tagmodel,
    };
    try {
      const result = await ApiTranProfile.UpdateTag(model);
      if (result.status == 200) {
        setIsAddtag(false);
      }
    } catch (ex) {
      CSwl.SwalErr("Can Not Update Tag");
    }
  };

  const func_sendCalendarprepare = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        func_sendCalendar(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => { });
      });
  };

  const func_sendCalendar = async (token) => {
    if (body == "" || body == null) {
      CSwl.SwalErr("กรุณากรอกเนื้อหา");
      return;
    } else if (startDate == "" || startDate == null) {
      CSwl.SwalErr("กรุณากรอกวันที่เริ่มต้น");
      return;
    } else if (startTime == "" || startTime == null) {
      CSwl.SwalErr("กรุณากรอกเวลาเริ่มต้น");
      return;
    }
    else if (endTime == "" || endTime == null) {
      CSwl.SwalErr("กรุณากรอกเวลาสิ้นสุด");
      return;
    }


    const setmodel = {
      name: `${userState.employee.firstNameThai} ${userState.employee.lastNameThai}`,
      smeFunctionJson: smeFunctionJson ? JSON.stringify(smeFunctionJson) : "",
      smeFunctionString: smeFunctionJson ? smeFunctionJson.map(x => x.label).join(',') : "",
      body: body,
      token: token,
      email: userState.employee.email,
      userId: userState.profile.id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      startTime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DDTHH:mm:ss")
    };


    CSwl.SwalLoad();
    try {
      const result = await ApiMsGraph.SendCalendar(setmodel);
      CSwl.SwalClose();
      if (result.status === 200) {
        console.log(result.data);
        CSwl.SwalSuccess("สร้างกำหนดการสำเร็จ");
        setIsModal(false);
        setBody("");
        setStartDate("");
        setStartTime("");
        setEndTime("");
      }
    } catch (ex) {
      CSwl.SwalClose();
      CSwl.SwalErr("ไม่สามารถสร้างกำหนดการได้");
    }
  };

  const func_createTeamprepare = () => {

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        func_createTeam(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => { });
      });
  };

  const func_createTeam = async (token) => {
    if (channelName == "" || channelName == null) {
      CSwl.SwalErr("กรุณากรอกชื่อห้อง");
      return;
    }

    const setmodel = {
      token: token,
      userId: userState.profile.id,
      email: userState.employee.email,
      channelName: channelName,
    };

    CSwl.SwalLoad();
    try {
      const result = await ApiMsGraph.CreateTeam(setmodel);
      CSwl.SwalClose();
      if (result.status === 200) {
        CSwl.SwalSuccess("สร้างทีมสำเร็จ");
        setIsModal2(false);
        setDescription("");
        setTeamName("");
        setChannelName("");
      }
    } catch (ex) {
      CSwl.SwalClose();
      CSwl.SwalErr("ไม่สามารถสร้างกำหนดการได้");
    }
  };

  const func_prepareCall = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    console.log(accounts[0]);
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        func_call(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => { });
      });
  };

  const func_call = async (token) => {
    console.log(token);

    const setmodel = {
      token: token,
    };

    try {
      const result = await ApiMsGraph.CreateTeam(setmodel);
      CSwl.SwalClose();
      if (result.status === 200) {
        CSwl.SwalSuccess("สร้างทีมสำเร็จ");
      }
    } catch (ex) {
      CSwl.SwalClose();
      CSwl.SwalErr("ไม่สามารถสร้างกำหนดการได้");
    }

    return;
    var model = {
      "@odata.type": "#microsoft.graph.call",
      callbackUri: "https://bot.contoso.com/callback",
      targets: [
        {
          "@odata.type": "#microsoft.graph.invitationParticipantInfo",
          identity: {
            "@odata.type": "#microsoft.graph.identitySet",
            user: {
              "@odata.type": "#microsoft.graph.identity",
              displayName: "John",
              id: "5e27d87e-5b71-4412-b0f6-20b617f0c3ef",
            },
          },
        },
      ],
      requestedModalities: ["audio"],
      mediaConfig: {
        "@odata.type": "#microsoft.graph.serviceHostedMediaConfig",
      },
    };
    try {
      const result = await axios.post(
        `https://graph.microsoft.com/v1.0/communications/calls`,
        model,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  const checkStatus = (status, reason) => {
    switch (status) {
      case "W":
        return "รออนุมัติ";
      case "A":
        return "อนุมัติเรียบร้อย";
      case "R":
        return `ไม่อนุมัติ (${reason})`;
      default:
        return "";
    }
  }

  const [follow, setFollow] = useState(false);

  const func_follow = async (e) => {
    e.stopPropagation();
    const result = await ApiFollow.FollowORUnFollow(profileId);
    if (result.status == 200) {
      if (!follow) {
        setFollow(true);
      } else {
        setFollow(false);
      }
    }
  };

  return (
    <div id="ProfilePage">
      <Drawer className="is-hidden-desktop is-hidden-tablet">
        <MenuDrawer data={[]} page="rank" header={t('menu.profile')} />
      </Drawer>
      <Header />

      <div className="columns">
        <div className="column is-2 my-3 layer hidden-b">
          <Menu page="rank" header={t('menu.profile')} />
        </div>
        <div className="column is-7">
          <div className="">
            <figure className="image">
              {/* <img
                src={process.env.PUBLIC_URL + "/images/profilePage/BG.png"}
                alt="backgroundImage"
              /> */}
              <img
                className="image-cover"
                style={{ maxHeight: 300 }}
                src={
                  userState.profile.coverDomainPath
                    ? process.env.REACT_APP_IMAGE +
                    userState.profile.coverDomainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/BG.png"
                }
                alt="backgroundImage"
              />
            </figure>
          </div>
          <div className="layer1 section-content flex gap-x-[50px] items-center px-10 py-4">
            <figure
              onClick={() => handleClick()}
              className="image cursor-pointer"
            >
              <img
                src={
                  userState.profile.domainPath
                    ? process.env.REACT_APP_IMAGE + userState.profile.domainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"
                }
                alt="profile_image"
                //class="is-rounded img-card image-cover"
                className={`is-rounded img-card image-cover ${userState.ranking?.rankingName == "SMEs" ? `border-[#f97316] border-[5px]` : ""}`}
              />
            </figure>
            <div className="is-flex is-flex-direction-column layer2 flex-1 !ml-0">
              <div className="flex gap-x-2 items-center">
                <p className="title-h">{`${userState.employee?.firstNameEnglish} ${userState.employee?.lastNameEnglish}`}</p>

                <div style={{ paddingRight: "10px" }}>
                  {
                    userState.ranking?.rankingName == "SMEs" ?
                      <Link
                        to="/ranking">
                        <div className="bg-[#f97316] px-2 left-7">
                          <span className="text-white text-sm">SMEs</span>
                        </div>
                      </Link> :
                      <Link
                        to="/ranking">
                        <div className="bg-[dodgerblue] px-2 left-7">
                          <span className="text-white text-sm">{userState.ranking?.rankingName}</span>
                        </div>
                      </Link>
                  }
                </div>
              </div>
              <p className="subtitle-h pb-3">
                {userState.employee?.positionNameEnglish} /{" "}
                {userState.employee?.companyDescription}
              </p>
              <div className="is-flex gap-x-5 items-center">

                <div className="row is-flex is-justify-content-center">
                  <button
                    onClick={(e) => {
                      func_follow(e);
                    }}
                    className="button is-link is-rounded ml-1"
                    style={{ height: 'auto', backgroundColor: '#3273dc', color: 'white', fontSize: 14 }}
                  >
                    {!follow ? t('menu.follow') : t('menu.unfollow')}
                  </button>
                </div>
                <button
                  onClick={() => {
                    setIsModal(true);
                  }}
                  className="is-link ml-1 bg-white"
                  style={{ height: 'auto' }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/booking.png"}
                    alt="logo1"
                    width="28"
                    height="26"
                  />
                </button>
                <button
                  onClick={() => {
                    setIsModal2(true)
                  }}
                  className="is-link ml-1 bg-white"
                  style={{ height: 'auto' }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/video-call.png"}
                    alt="logo1"
                    width="28"
                    height="26"
                  />
                </button>
                <Link to={`/chat?userId=${userState.employee.userId}&profileId=${profileId}&profileImage=${userState.profile.domainPath
                    ? process.env.REACT_APP_IMAGE + userState.profile.domainPath
                    : process.env.PUBLIC_URL + "/images/profilePage/Profile.png"}`} className="is-link bg-white h-fit pt-1 pb-1"
                    >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" color="#3273DC" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg> */}
                    <img
                    src={process.env.PUBLIC_URL + "/images/chat.png"}
                    alt="logo1"
                    width="28"
                    height="26"
                  />
                  </Link>
              </div>
            </div>
          </div>
          <div className="is-flex section-content is-justify-content-space-around py-3">
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.followerAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-ss-following1.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('menu.follower')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.followAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-rr-users2.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('menu.follow')}</p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <p className="title-h">{userState.profile?.articleAmt}</p>
              <div className="is-flex is-align-items-center">
                <figure className="image is-16x16">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/profilePage/fi-ss-document1.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="subtitle-h pl-2">{t('text.content')}</p>
              </div>
            </div>
          </div>
          <div className="is-flex is-flex-direction-column section-content p-5">
            <div className="is-flex is-align-items-center mb-3">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge1.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 pl-3">{t('text.expertise')}</p>
            </div>
            <div className="is-flex is-flex-wrap-wrap pb-2">
              {speciallistTags.map((i, index) => {
                return (
                  <div
                    onClick={() => {
                      history.push(`/catalog?q=${i}`);
                    }}
                    className="mr-2 mb-2"
                    key={index}
                  >
                    <button className="button is-info is-rounded has-text-grey has-background-info-light">
                      {i}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="is-flex is-flex-direction-column section-content p-5">
            <div className="is-flex is-align-items-center mb-3">
              <figure className="image is-24x24">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/vector.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 pl-3">{t('menu.attention')}</p>
            </div>
            <div className="is-flex is-flex-wrap-wrap pb-2">
              {!isAddtag ? (
                tags.map((i, index) => {
                  return (
                    <div
                      onClick={() => {
                        history.push(`/tag?q=${i}`);
                      }}
                      className="mr-2 mb-2"
                      key={index}
                    >
                      <button className="button is-info is-rounded has-text-grey has-background-info-light">
                        {"#" + i}
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className="mt-3" style={{ width: "100%" }}>
                  <ReactTags
                    className="form-control form-control-md mt-3"
                    value={tags}
                    name="tags"
                    onChange={(tag) => handleTagAddition(tag)}
                    placeholder="พิมพ์ tag แล้วกด Enter"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="is-flex section-content is-justify-content-space-between p-5">
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge1.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 px-2">{t('text.experience')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <p className="subtitle-h2 px-2">
                {userState.profile?.rankingAmt}
              </p>
            </div>
          </div>
          <div className="is-flex section-content is-justify-content-space-between p-5">
            <div className="is-flex is-align-items-center">
              <figure className="image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/profilePage/badge2.png"
                  }
                  alt="logo1"
                />
              </figure>
              <p className="title-h1 px-2">{t('text.point')}</p>
            </div>
            <div className="is-flex  is-align-items-center">
              <p className="subtitle-h2 px-2">{userState.profile?.pointAmt}</p>
            </div>
          </div>
          <div className="section-content p-5">
            <div className="is-flex is-justify-content-space-between">
              <div className="is-flex is-align-items-center">
                <figure className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/certificate.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="title-h1 px-2">{t('text.certificate')} ({certificate.length})</p>
              </div>
              <div className="is-flex  is-align-items-center cursor-pointer">
                {
                  showCer ?
                    <figure className="image" onClick={() => setShowCer(false)}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                        }
                        alt="logo1"
                      />
                    </figure> :
                    <figure className="image" onClick={() => setShowCer(true)}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                        }
                        alt="logo1"
                      />
                    </figure>
                }
              </div>
            </div>
            {
              certificate.length > 0 && showCer &&
              <div className="pt-5 sm:pl-5">
                {
                  certificate.map((item, index) => {
                    return (
                      <div className="is-flex  is-justify-content-space-between py-3 cer-line" key={item.id}>
                        <div className="is-flex">
                          <div className="pl-3">
                            <div className="flex gap-x-1">
                              <p className="subtitle-h2">ชื่อ:</p>
                              <p className="subtitle-h2">{item.name}</p>
                            </div>

                            <div className="flex gap-x-1">
                              <p className="subtitle-h3">ออกโดย:</p>
                              <p className="subtitle-h3">{item.instituteName}</p>
                            </div>
                            <p className="cer-date">{item.issuedDate ? `วันที่ออก ${ConvertDateShort(item.issuedDate, 'DD MMM YYYY')}` : "ไม่มีวันที่ออก"} • {item.expiredDate ? `วันที่หมดอายุ ${ConvertDateShort(item.expiredDate, 'DD MMM YYYY')}` : "ไม่มีวันหมดอายุ"}</p>
                            {/* <div className="flex gap-x-2">
                                <p className="cer-date">สถานะ:</p>
                                <p className={`cer-date ${item.status == "A" && "!text-green-600"} ${item.status == "R" && "!text-red-500"}`}>{checkStatus(item.status, item.reason)}</p>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    )

                  })
                }
              </div>
            }
          </div>
          <div className="section-content p-5">
            <div className="is-flex is-justify-content-space-between">
              <div className="is-flex is-align-items-center">
                <figure className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/profilePage/history_project.png"
                    }
                    alt="logo1"
                  />
                </figure>
                <p className="title-h1 px-2">{t('text.project_history')} ({projectExp.length})</p>
              </div>
              <div className="is-flex is-align-items-center cursor-pointer">
                {
                  showProject ?
                    <figure className="image" onClick={() => setShowProject(false)}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                        }
                        alt="logo1"
                      />
                    </figure> :
                    <figure className="image" onClick={() => setShowProject(true)}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                        }
                        alt="logo1"
                      />
                    </figure>
                }
              </div>
            </div>
            {
              projectExp.length > 0 && showProject &&
              <div className="pt-5 sm:pl-5">
                {
                  projectExp.map((item, index) => {
                    return (
                      <div className="is-flex  is-justify-content-space-between py-3 cer-line" key={item.id}>
                        <div className="is-flex is-flex-grow-1">
                          <div className="pl-3">
                            <div className="flex gap-x-1">
                              <p className="subtitle-h2">ชื่อ:</p>
                              <p className="subtitle-h2">{item.name}</p>
                            </div>
                            <div className="flex gap-x-1">
                              <p className="subtitle-h3">ตำแหน่ง:</p>
                              <p className="subtitle-h3">{item.role}</p>
                            </div>
                            <p className="cer-date">{item.startDate ? `วันที่เริ่ม ${ConvertDateShort(item.startDate, 'DD MMM YYYY')}` : "-"} • {item.endDate ? `วันที่สิ้นสุด ${ConvertDateShort(item.endDate, 'DD MMM YYYY')}` : "-"}</p>
                          </div>
                        </div>
                      </div>
                    )

                  })
                }
              </div>
            }
          </div>
          <div className="is-flex is-flex-direction-column section-content">
            <div>
              {/* {dataPost.map((d, index) => {
                return (
                  <div className="mb-2" key={index}>
                    <Post name={d.name} time={d.time} title={d.title} detail={d.detail} totalLike={d.totalLike} totalComment={d.totalComment} />
                  </div>
                )
              })} */}
              {myContent &&
                myContent.items.map((d, index) => {
                  return (
                    // <div className="row mb-2" key={index}>
                    //   <Post
                    //     contentid={d.id}
                    //     imageComment={
                    //       userState.profile.domainPath
                    //         ? process.env.REACT_APP_IMAGE + userState.profile.domainPath
                    //         : process.env.PUBLIC_URL +
                    //         "/images/profilePage/Profile.png"
                    //     }
                    //     image={
                    //       d.imageProfile
                    //         ? process.env.REACT_APP_IMAGE + d.imageProfile
                    //         : process.env.PUBLIC_URL +
                    //         "/images/profilePage/Profile.png"
                    //     }
                    //     name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                    //     time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                    //     title={d.ctname}
                    //     detail={d.ctdeschtml}
                    //     isLike={d.isLike}
                    //     isComment={
                    //       userState.profile.id == d.profileid ? true : true
                    //     }
                    //     comments={d.comments}
                    //     // subdetail={d.ctdeschtml}
                    //     totalLike={d.ctlikeAmt}
                    //     totalComment={d.ctcommentAmt}
                    //     contentStyles={{ maxHeight: 440, overflow: "hidden" }}
                    //     onComment={(result) => callBackComment(result)}
                    //   />
                    // </div>
                    <div
                      className="row fn ar"
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                      key={d.id}
                    >
                      <MainPost
                        time={ConvertDateShort(d.cttrandate, "DD MMM YYYY HH:mm น.")}
                        totalLike={d.ctlikeAmt}
                        isLike={d.isLike}
                        isBookmark={d.isBookmark}
                        item={d}
                        title={d.ctname}
                        comments={d.comments}
                        detail={d.ctdeschtml}
                        name={`${d.employee?.firstNameEnglish} ${d.employee?.lastNameEnglish}`}
                        image={
                          d.imageProfile
                            ? process.env.REACT_APP_IMAGE + d.imageProfile
                            : process.env.PUBLIC_URL +
                            "/images/profilePage/Profile.png"
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div class={`modal modal ${isModal ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5, width: 'fit-content' }}>
          <div
            className="d-flex-center flex-column"
            style={{ minHeight: "500px" }}
          >
            <h1>ทำการนัดหมาย</h1>
            <div>
              <div style={{ marginBottom: "10px", width: '300px' }}>
                <label>{t('text.category')}</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  styles={{ width: 300 }}
                  required
                  isMulti
                  onChange={(e) => {
                    setSmeFunctionJson(e);
                  }}
                  options={smeCatagory.map((x) => {
                    return { label: x, value: x };
                  })}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>เนื้อหา</label>
                <textarea
                  rows={4}
                  value={body}
                  className="input size-input"
                  required
                  onChange={(e) => {
                    setBody(e.target.value);
                  }}
                />
              </div>

              <div style={{ marginBottom: "10px" }}>
                <label>วันที่เริ่มต้น</label>
                <DatePicker
                  className="input size-input"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>เวลาที่เริ่มต้น</label>
                <DatePicker
                  className="input size-input"
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm:ss aa"
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <label>เวลาที่สิ้นสุด</label>
                <DatePicker
                  className="input size-input"
                  selected={endTime}
                  onChange={(date) => {
                    setEndTime(date)
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm:ss aa"
                />
              </div>
            </div>
            <div className="mt-5 d-flex">
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#66cc00", marginRight: "10px" }}
                onClick={(e) => {
                  func_sendCalendarprepare();
                }}
              >
                <div>ส่ง</div>
              </button>
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#ff3333" }}
                onClick={(e) => {
                  setIsModal(false);

                  setBody("");
                  setStartDate("");
                  setStartTime("");
                  setEndTime("");
                }}
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class={`modal ${isModal2 ? "d-flex" : ""}`}>
        <div class="modal-background"></div>
        <div class="modal-content bg-white p-5" style={{ borderRadius: 5, width: '85%' }}>
          <div
            className="d-flex-center flex-column"
          // style={{ minHeight: "500px" }}
          >
            <h1>กำหนดการ</h1>
            <div>
              <div style={{ marginBottom: "10px" }}>
                <label>ชื่อเรื่องที่นัดหมาย</label>
                <input
                  value={channelName}
                  className="input size-input"
                  type="text"
                  required
                  onChange={(e) => {
                    setChannelName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ marginBottom: "10px" }}>
                {/* <label>ขั้นตอนการนัดหมาย</label> */}
                <div class="column d-flex is-align-items-center is-justify-content-center pt-5">
                  {isDesktop && <img src={`${process.env.PUBLIC_URL + "/images/firstPage/KM_CALL.png"}`} alt="image_content" class="size-img is-hidden-mobile"></img>
                  }
                  {!isDesktop &&
                    <img src={`${process.env.PUBLIC_URL + "/images/firstPage/KM_CALL_MOBILE.png"}`}
                      alt="image_content"
                      class="size-img is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only">
                    </img>
                  }


                </div>
              </div>
            </div>
            <div className="mt-5 d-flex">
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#66cc00", marginRight: "10px", fontSize: 18 }}
                onClick={(e) => {
                  func_createTeamprepare();
                }}
              >
                <div>สร้าง</div>
              </button>
              <button
                className="button is-link is-rounded size-fontonPost"
                style={{ backgroundColor: "#ff3333", fontSize: 18 }}
                onClick={(e) => {
                  setIsModal2(false);
                  setDescription("");
                  setTeamName("");
                  setChannelName("");
                }}
              >
                <div className="font-button">{t("menu.cancel")}</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <input
        accept=".png,.jpg,.jpeg"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        type="file"
        style={{ display: "none" }}
      />
    </div >
  );
};

export default ProfilePage;
