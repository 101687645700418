import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CSwl from "../../utils/alert";
import { destroyPage, useWindowUnloadEffect } from "../../utils";
import ApiFollow from "../../api/ApiFollow";
import ApiMtTag from "../../api/ApiTag";
import CardTaglist from "../../components/CardTaglist";
import ProfileList from "../../components/ProfileList";
import "rc-drawer/assets/index.css";
import { useTranslation } from "react-i18next";
import "./index.scss"
import ApiContent from "../../api/ApiContent";
import { ConvertDateShort } from "../../utils/date";

const PastProject = () => {
    const [firstTime, setFirstTime] = useState(new Date());
    useWindowUnloadEffect(() => destroyPage({ page: "Profile", firstTime: firstTime }), true);
    const { t, i18n } = useTranslation();
    const [trend, setTrend] = useState([]);
    const userState = useSelector((state) => state.user);
    const [profileUnFollow, setProfileUnFollow] = useState([]);
    const [showApprove, setShowApprove] = useState(false)
    const [showNoApprove, setShowNoApprove] = useState(false)
    const [showDraft, setShowDraft] = useState(false)
    const [countApprove, setCountApprove] = useState(0)
    const [countNoApprove, setCountNoApprove] = useState(0)
    const [countDraft, setCountDraft] = useState(0)
    const [projectExp, setProjectExp] = useState([]);
    const listTypeImage = [".jpeg", ".jpg", ".png"]

    React.useEffect(() => {
        CSwl.SwalLoad();
        const promise1 = getProfileUnFollow();
        const promise2 = getToptag();
        const promise3 = getProjectExp();
        Promise.all([promise1, promise2, promise3]).then((result) => {
            CSwl.SwalClose();
        })
        return () => { };
    }, []);

    const getProfileUnFollow = async () => {
        const result = await ApiFollow.GetTranProfileForUnFollow({
            pageNumber: 1,
            pageSize: 10,
        });
        if (result.status == 200) {
            setProfileUnFollow(result.data.data);
        }
    };

    const getToptag = async () => {
        const result = await ApiMtTag.GetTopTag({
            pageSize: 3,
            pageNumber: 1,
        });
        if (result.status == 200) {
            if (result.data.data.length > 0) {
                setTrend(result.data.data);
                console.log(result.data.data);
            }
        }
    };

    const getProjectExp = async () => {
        try {
            const response = await ApiContent.GetProjectExp();
            if (response.status == 200) {
                const { data } = response.data;
                setProjectExp(data);
            }
        } catch (error) {
            const { data } = error.response;
            CSwl.SwalErr(data.message);
        }
    };


    return (
        <div id="PastProjectPage">
            <Header />

            <div className="columns">
                <div className="hidden sm:block column is-2 my-3 layer hidden-b">
                    <Menu page="rank" header={t('menu.projecthistory')} />
                </div>
                <div className="column is-7 pt-0">
                    <div className="is-flex is-flex-direction-column section-content px-5 py-4 sm:py-5 sm:pt-6">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center">
                                <figure className="image">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + "/images/profilePage/list_certificate.png"
                                        }
                                        alt="logo1"
                                    />
                                </figure>
                                <p className="title-h1 px-2">{t('menu.listpastproject')} ({projectExp.length})</p>
                            </div>
                            <Link to={`/pastProjectEdit/0`}>
                                <div className="is-flex is-align-items-center cursor-pointer">
                                    <figure className="image h-6 w-6 sm:h-8 sm:w-8">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL + "/images/profilePage/addx2.png"
                                            }
                                            alt="logo1"
                                        />
                                    </figure>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.approve')} ({projectExp.filter(x => x.status == "A").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showApprove ?
                                        <figure className="image" onClick={() => setShowApprove(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowApprove(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        {
                            projectExp.length > 0 &&
                            showApprove &&
                            projectExp.map((item, index) => {
                                if (item.status === "A") {
                                    const files = item.files ? JSON.parse(item.files) : "";
                                    let fileImage = "";
                                    if (files) {
                                        fileImage = files.find((x) => {
                                            return listTypeImage.some(v => x.Path.includes(v))
                                        })
                                    }
                                    return (
                                        <div className={`is-flex  is-justify-content-space-between py-3 ${index < projectExp.length - 1 && "cer-line"}`} key={item.id}>
                                            <div className="is-flex">
                                                <div className="img-cer">
                                                    {
                                                        fileImage ?
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMAGE + fileImage.Path
                                                                }
                                                                alt="cer"
                                                            /> :
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                }
                                                                alt="cer"
                                                            />
                                                    }
                                                </div>
                                                <div className="pl-3">
                                                    <Link to={`/pastProjectDetail/${item.id}`}>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h2">ชื่อ:</p>
                                                            <p className="subtitle-h2">{item.name}</p>
                                                        </div>

                                                    </Link>
                                                    <div className="flex gap-x-1">
                                                        <p className="subtitle-h3">ตำแหน่ง:</p>
                                                        <p className="subtitle-h3">{item.role}</p>
                                                    </div>
                                                    <p className="cer-date">{item.startDate ? `วันที่เริ่ม ${ConvertDateShort(item.startDate, 'DD MMM YYYY')}` : "-"} • {item.endDate ? `วันที่สิ้นสุด ${ConvertDateShort(item.endDate, 'DD MMM YYYY')}` : "-"}</p>
                                                </div>
                                            </div>
                                            <Link to={`/pastProjectEdit/${item.id}`}>
                                                <figure className="image-cover cursor-pointer">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                        }
                                                        alt="logo1"
                                                    />
                                                </figure>
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.waitingforapproval')} ({projectExp.filter(x => x.status == "W").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showNoApprove ?
                                        <figure className="image" onClick={() => setShowNoApprove(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowNoApprove(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        {
                            projectExp.length > 0 &&
                            showNoApprove &&
                            projectExp.map((item, index) => {
                                if (item.status === "W") {
                                    const files = item.files ? JSON.parse(item.files) : "";
                                    let fileImage = "";
                                    if (files) {
                                        fileImage = files.find((x) => {
                                            return listTypeImage.some(v => x.Path.includes(v))
                                        })
                                    }
                                    return (
                                        <div className={`is-flex  is-justify-content-space-between py-3 ${index < projectExp.length - 1 && "cer-line"}`} key={item.id}>
                                            <div className="is-flex">
                                                <div className="img-cer">
                                                    {
                                                        fileImage ?
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMAGE + fileImage.Path
                                                                }
                                                                alt="cer"
                                                            /> :
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                }
                                                                alt="cer"
                                                            />
                                                    }
                                                </div>
                                                <div className="pl-3">
                                                    <Link to={`/pastProjectDetail/${item.id}`}>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h2">ชื่อ:</p>
                                                            <p className="subtitle-h2">{item.name}</p>
                                                        </div>

                                                    </Link>
                                                    <div className="flex gap-x-1">
                                                        <p className="subtitle-h3">ตำแหน่ง:</p>
                                                        <p className="subtitle-h3">{item.role}</p>
                                                    </div>
                                                    <p className="cer-date">{item.startDate ? `วันที่เริ่ม ${ConvertDateShort(item.startDate, 'DD MMM YYYY')}` : "-"} • {item.endDate ? `วันที่สิ้นสุด ${ConvertDateShort(item.endDate, 'DD MMM YYYY')}` : "-"}</p>
                                                </div>
                                            </div>
                                            <Link to={`/pastProjectEdit/${item.id}`}>
                                                <figure className="image-cover cursor-pointer">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                        }
                                                        alt="logo1"
                                                    />
                                                </figure>
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                    <div className="section-content px-5">
                        <div className="is-flex is-justify-content-space-between">
                            <div className="is-flex is-align-items-center py-3">
                                <p className="title-status px-2">{t('menu.reject')} ({projectExp.filter(x => x.status == "R").length})</p>
                            </div>
                            <div className="is-flex  is-align-items-center cursor-pointer">
                                {
                                    showDraft ?
                                        <figure className="image" onClick={() => setShowDraft(false)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector4.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure> :
                                        <figure className="image" onClick={() => setShowDraft(true)}>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/profilePage/vector3.png"
                                                }
                                                alt="logo1"
                                            />
                                        </figure>
                                }
                            </div>
                        </div>
                        {
                            projectExp.length > 0 &&
                            showDraft &&
                            projectExp.map((item, index) => {
                                if (item.status === "R") {
                                    const files = item.files ? JSON.parse(item.files) : "";
                                    let fileImage = "";
                                    if (files) {
                                        fileImage = files.find((x) => {
                                            return listTypeImage.some(v => x.Path.includes(v))
                                        })
                                    }
                                    return (
                                        <div className={`is-flex  is-justify-content-space-between py-3 ${index < projectExp.length - 1 && "cer-line"}`} key={item.id}>
                                            <div className="is-flex">
                                                <div className="img-cer">
                                                    {
                                                        fileImage ?
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_IMAGE + fileImage.Path
                                                                }
                                                                alt="cer"
                                                            /> :
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                                }
                                                                alt="cer"
                                                            />
                                                    }
                                                </div>
                                                <div className="pl-3">
                                                    <Link to={`/pastProjectDetail/${item.id}`}>
                                                        <div className="flex gap-x-1">
                                                            <p className="subtitle-h2">ชื่อ:</p>
                                                            <p className="subtitle-h2">{item.name}</p>
                                                        </div>

                                                    </Link>
                                                    <div className="flex gap-x-1">
                                                        <p className="subtitle-h3">ตำแหน่ง:</p>
                                                        <p className="subtitle-h3">{item.role}</p>
                                                    </div>
                                                    <p className="cer-date">{item.startDate ? `วันที่เริ่ม ${ConvertDateShort(item.startDate, 'DD MMM YYYY')}` : "-"} • {item.endDate ? `วันที่สิ้นสุด ${ConvertDateShort(item.endDate, 'DD MMM YYYY')}` : "-"}</p>
                                                    <div className="flex gap-x-2">
                                                        <p className="cer-date">เหตุผล:</p>
                                                        <p className={`cer-date !text-red-500`}>{item.reason}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/pastProjectEdit/${item.id}`}>
                                                <figure className="image-cover cursor-pointer">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                        }
                                                        alt="logo1"
                                                    />
                                                </figure>
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                    {/* <div className="section-content px-5">
                        {
                            mockupCer.length > 0 &&
                            mockupCer.map((item, index) => {
                                return (
                                    <div className={`is-flex  is-justify-content-space-between py-3 ${index < mockupCer.length - 1 && "cer-line"}`} key={item.id}>
                                        <div className="is-flex">
                                            <div className="img-cer">
                                                {
                                                    item.image ?
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + item.image
                                                            }
                                                            alt="cer"
                                                        /> :
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL + "/images/profilePage/file.png"
                                                            }
                                                            alt="cer"
                                                        />
                                                }
                                            </div>
                                            <div className="pl-3">
                                                <p className="subtitle-h2">{item.title}</p>
                                                <p className="subtitle-h3">{item.position}</p>
                                                <p className="cer-date">{item.startDate ? `วันที่ออก ${item.startDate}` : "ไม่มีวันที่ออก"} • {item.endDate ? `วันที่หมดอายุ ${item.endDate}` : "ไม่มีวันหมดอายุ"}</p>
                                            </div>
                                        </div>
                                        <Link to={`/pastProjectEdit/${item.id}`}>
                                            <figure className="image-cover cursor-pointer">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + "/images/profilePage/pencil.png"
                                                    }
                                                    alt="logo1"
                                                />
                                            </figure>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
                {/* section Cardlist */}
                <div className="column is-3 hidden-b hidden sm:block">
                    <div
                        className="is-3 flex-1"
                        style={{ position: "fixed", width: "inherit" }}
                    >
                        <div className="mt-3 mr-6">
                            <CardTaglist
                                title={t('text.trending')}
                                data={trend}
                                topage={"trend"}
                            />
                        </div>
                        <div className="mt-3 mr-6">
                            <ProfileList
                                title={t('text.following')}
                                data={profileUnFollow.map((x) => {
                                    return {
                                        name: x.fullNameEng,
                                        image: x.image
                                            ? process.env.REACT_APP_IMAGE + x.image
                                            : process.env.PUBLIC_URL +
                                            "/images/profilePage/Profile.png",
                                        profileID: x.profileID,
                                    };
                                })}
                                topage={"unfollow"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PastProject