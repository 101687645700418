import firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
    // Project Settings => Add Firebase to your web app
    apiKey: "AIzaSyB3GNvC86YFnrX6Dg6fD5yW0oFCjs32u90",
    authDomain: "scg-km-f315a.firebaseapp.com",
    projectId: "scg-km-f315a",
    storageBucket: "scg-km-f315a.appspot.com",
    messagingSenderId: "947694655044",
    appId: "1:947694655044:web:e2b5770829d501c1f04e11",
    measurementId: "G-BN8FEL7EDE"
});
let messaging = "";
if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();
}
export { messaging };
