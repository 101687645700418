import React, { useEffect, useState } from "react";
import "../style/Menu/Menu.scss";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiContent from "../api/ApiContent";
import { useTranslation } from "react-i18next";
const Menu = ({ data, page, subpage, header }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const dataPro = data;
  const count_login = useSelector((state) => state.count_login);
  const [countManager, setCountManager] = useState(0);
  const [countSme, setCountSme] = useState(0);
  const [countCertificate, setcountCertificate] = useState(0);
  const [countProjectExp, setcountProjectExp] = useState(0);

  const getContentManager = async () => {
    const result = await ApiContent.getCountTranContentByBO();
    if (result.status == 200) {
      setCountManager(result.data.data);
      //setcontents(result.data.data);
    }
  };

  const getApproveCentificate = async () => {
    const result = await ApiContent.getCountApproveCertificate();
    if (result.status == 200) {
      setcountCertificate(result.data.data);
      //setcontents(result.data.data);
    }
  };


  const getApproveProjectExp = async () => {
    const result = await ApiContent.getCountProjectExp();
    if (result.status == 200) {
      setcountProjectExp(result.data.data);
      //setcontents(result.data.data);
    }
  };


  const getContentSme = async () => {
    const result = await ApiContent.GetListSMEByBO({
      pageNumber: 1,
      pageSize: 10,
    });
    if (result.status == 200) {
      setCountSme(result.data?.data?.totalItems ?? 0);
    }
  };

  useEffect(() => {
    getContentManager();
    getContentSme();
    getApproveCentificate();
    getApproveProjectExp();
  }, []);
  return (
    <div id="Menu-com">
      {page !== "redeem" ? (
        <div>
          {subpage !== "explore" ? (
            <div>
              <div>
                <p className="fontH">{header}</p>
              </div>
              <div className="row ml-4 hidden-b">
                <p
                  onClick={() => history.push("/profile")}
                  className="font pt-5 cursor-pointer"
                >{`${userState.employee?.firstNameEnglish} ${userState.employee?.lastNameEnglish}`}</p>
                <p
                  onClick={() => history.push("/bookmark")}
                  className="font pt-5 cursor-pointer"
                >
                  {t('menu.bookmarks')}
                </p>
                <p
                  onClick={() => history.push("/interest")}
                  className="cursor-pointer font pt-5"
                >
                  {t('menu.attention')}
                </p>
                <hr />
                <div className="flex gap gap-y-5 flex-col">
                  <Link
                    to="/createcontent"
                    className="has-text is-flex is-align-items-center"
                  >
                    <button className="button is-link is-rounded is-fullwidth max-w-[200px] !py-2">
                      {t('menu.createcontent')}
                    </button>
                  </Link>
                  {userState.isAdmin && (
                    <Link
                      to="/createLiveSession"
                      className="has-text is-flex is-align-items-center"
                    >
                      <button className="button is-link is-rounded is-fullwidth max-w-[200px]  !py-2">
                        {t('menu.createLiveSession')}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <p className="fontH">{header}</p>
              </div>
              <div className="row ml-4 hidden-b">
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/firstPage/Explore_logo.png"
                    }
                    alt="logo1"
                    width="20"
                    height="20"
                  />
                  <p className="font pl-2">ทั้งหมด</p>
                </Link>
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/Menu/Vector2.png"}
                    alt="logo1"
                    width="20"
                    height="20"
                  />
                  <p className="font pl-2">การตลาด</p>
                </Link>
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/Menu/Vector3.png"}
                    alt="logo1"
                    width="15"
                    height="20"
                  />
                  <p className="font pl-2">เทคโนโลยี</p>
                </Link>
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/Menu/Vector4.png"}
                    alt="logo1"
                    width="20"
                    height="20"
                  />
                  <p className="font pl-2">ไอที</p>
                </Link>
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/Menu/Vector5.png"}
                    alt="logo1"
                    width="20"
                    height="20"
                  />
                  <p className="font pl-2">ธุรกิจ</p>
                </Link>
                <Link
                  to="/home"
                  className="pt-5 has-text is-flex is-align-items-center"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/Menu/Vector6.png"}
                    alt="logo1"
                    width="20"
                    height="20"
                  />
                  <p className="font pl-2">หมวดหมู่ทั้งหมด</p>
                </Link>
              </div>
            </div>
          )}
          {/* {page === "first" && (
            <div
              className="columns n-hidden is-justify-content-space-around"
              style={{ overflow: "auto" }}
            >
              {dataPro.map((d, index) => {
                return (
                  <div className="column" key={index}>
                    <figure className="image is-64x64">
                      <img
                        className="is-rounded"
                        src="https://bulma.io/images/placeholders/128x128.png"
                      />
                    </figure>
                    <p className="font" style={{ overflow: "auto" }}>
                      {d.name}
                    </p>
                  </div>
                );
              })}
            </div>
          )} */}
          {page === "rank" && (
            <div className="row ml-4 hidden-b">
              <hr />
              <Link to="/contentwaiting">
                <p className="font">{t('menu.draft')}</p>
              </Link>
              {/* <Link to="/contentPlan">
                <p className="font pt-5">กำหนดเวลา</p>
              </Link> */}
              <Link to="/setting">
                <p className="font pt-5">{t('menu.setting')}</p>
              </Link>
              {countManager > 0 && (
                <Link to="/managerapprove" >
                  <div className="!mt-5">
                    <button className="button is-link is-rounded is-fullwidth max-w-[200px] flex gap-x-2 pr-3">
                      <span className="flex flex-1 mr-2 items-center justify-center">{t('menu.approve')}</span>
                      {countManager > 0 && (
                        <span
                          // className="count-approve !text-white !border-white ml-2"
                          className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countManager}</span>
                      )}
                    </button>
                  </div>
                </Link>
              )}

              {userState.issme && (
                <Link to="/smeapprove" >
                  <div className="!mt-5">
                    <button className="button is-link is-rounded is-fullwidth max-w-[200px] flex gap-x-2 pr-3">
                      <span className="flex flex-1 mr-2 items-center justify-center">{t('menu.sme_approve')}</span>
                      {countSme > 0 && (
                        <span
                          // className="count-approve !text-white !border-white ml-2"
                          className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countSme}</span>
                      )}
                    </button>
                  </div>
                </Link>
              )}
              {countCertificate > 0 && (
                <Link to="/certificateApprove" >
                  <div className="!mt-5">
                    <button className="button is-link is-rounded is-fullwidth max-w-[200px]  flex gap-x-2 pr-3">
                      <span className="flex flex-1 mr-2">{t('menu.approve_certificate')}</span>
                      {countCertificate > 0 && (
                        <span
                          // className="count-approve !text-white !border-white ml-2"
                          className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countCertificate}</span>
                      )}
                    </button>
                  </div>
                </Link>
              )}
              {countProjectExp > 0 && (
                <Link to="/projectExpApprove" >
                  <div className="!mt-5">
                    <button className="button is-link is-rounded is-fullwidth max-w-[200px]  flex gap-x-2 pr-3">
                      <span className="flex flex-1">{t('menu.approve_projectExp')}</span>
                      {countProjectExp > 0 && (
                        <span className="bg-red-600 py-1 flex items-center justify-center rounded-full text-sm w-[25px]">{countProjectExp}</span>
                      )}
                    </button>
                  </div>
                </Link>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            <p className="fontH">{header}</p>
          </div>
          <div className="row ml-4 hidden-b mb-5">
            <p className="font pt-5 cursor-pointer">{`${userState.employee?.firstNameEnglish} ${userState.employee?.lastNameEnglish}`}</p>
          </div>
          <Link to="/redeemHistory">
            <button className="button button-size button-c is-hovered is-rounded is-fullwidth max-w-[200px] ">
              {t('text.redeem_history')}
            </button>
          </Link>
        </div>
      )}
      <div className="row ml-4 hidden-b mt-5">
        <div className="text-sm">{t("text.number_of_visitor")} <b>({count_login})</b></div>
      </div>

      {/* <div className="fixed left-[20px] bottom-[20px] border p-2 bg-gray-100">จำนวนผู้ล็อคอิน <b>({count_login})</b></div> */}
    </div>
  );
};

export default Menu;
