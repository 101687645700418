export const msalConfig = {
  auth: {
    //clientId: "5e27d87e-5b71-4412-b0f6-20b617f0c3ef", // server SCG
    clientId: "896f4d6e-24a1-4e1f-bfda-ada22c826d02", // server SCG2
    //clientId: "a6c5c81d-5dd8-4383-82b9-de2b40acbd39", // server P'Aem
    //authority: "https://login.microsoftonline.com/661a6efb-de13-4ffc-b1e9-5228a679e87d", // server SCG
    //authority: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4", // server SCG 2
    authority: "https://login.microsoftonline.com/common/", // server SCG 2
    //authority: "https://login.microsoftonline.com/eb73a6a1-de1f-4d17-947a-e09134492ea5", // server PAEM
    redirectUri: process.env.REACT_APP_URL + "loginSuccess",
    navigateToLoginRequestUrl: false,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
