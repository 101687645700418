import React, { useEffect, useState } from "react";
import "../style/Post/post.scss";
import { Link } from "react-router-dom";
import ApiFollow from "../api/ApiFollow";
import { useHistory } from "react-router";
import CSwl from "../utils/alert";
import ApiContent from "../api/ApiContent";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const Waitting = ({
  contentid,
  name,
  depart,
  level,
  totalArticle,
  totalFollow,
  onunfollow = () => { },
}) => {
  const { t, i18n } = useTranslation();
  useEffect(() => { }, []);

  const history = useHistory();
  const func_movePage = async () => {
    history.push(`editcontent?c=${contentid}`);
  };

  const func_remove = async (contentid) => {
    const result = await CSwl.SwalConfirm("คุณต้องการจะลบบทความนี้");
    if (result.isConfirmed) {
      const result = await ApiContent.Delete(contentid);
      if (result.status == 200) {
        await CSwl.SwalSuccess("ลบสำเร็จ");
        window.location.reload();
      }
    }
  };
  return (
    <div id="post-com" className="card">
      <div className="content container-header">
        <div className="media is-justify-content-space-between is-align-items-center">
          <div className="media pt-5" style={{ borderTop: 0 }}>
            {/*<div>
              <figure className="image is-48x48">
                <img class="is-rounded" src={image} alt="Placeholder_image" />
              </figure>
            </div>*/}
            <div
              className="media-content title-header"
              style={{ marginLeft: "20px" }}
            >
              <span className="has-text-weight-bold font-button">{name}</span>
              <span className="has-text-grey-light font-button">{depart}</span>
            </div>
          </div>
          <div className="is-flex is-align-items-center mr-3">
            <button
              onClick={() => {
                func_movePage();
              }}
              className="button is-link is-rounded"
            >
              <div className="font-button">{t('text.edit')}</div>
            </button>
            <button
              onClick={() => {
                func_remove(contentid);
              }}
              className="button is-rounded ml-2 bg-danger"
            >
              <div className="font-button">{t('text.delete')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waitting;
